import { useSelector } from 'react-redux';
import { isExistingUser } from '../app/verificationTypeSelectors';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getIsAccountCreationStep } from '../flow-management/getIsAccountCreationStep';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { getPrivacyPolicyLabel } from '../hubspot-forms/form-data-utils/getPrivacyPolicyLabel';
import { OptionalDataKeys } from '../signup-data/OptionalDataKeys';
import { getNextSteps } from '../store/flow/selectors';
import { useTracker } from '../tracking/useTracker';
import { getStepConfig } from '../views/getStepConfig';
import { Step } from 'signup-constants/Step';
import { useSignupData } from '../signup-data/useSignupData';
import { Data } from 'signup-constants/signupData/Data';
import { CmsSignupIntentOption } from 'signup-constants/signupData/CmsOption';
export const usePrivacyBanner = () => {
  const {
    currentStep
  } = useCurrentStep();
  const Tracker = useTracker();
  const isAccountCreationStep = useSelector(state => getIsAccountCreationStep(state, getNextSteps(state)));
  const isExisitngUserCreatingPortal = useSelector(isExistingUser);
  const privacyPolicyTextI18nLabel = getPrivacyPolicyLabel();
  const createAccountTextI18nLabel = getFlowConfig().createAccountTextI18nLabel;
  const handleClickPrivacyPolicy = () => {
    Tracker.click({
      message: 'click privacy banner',
      linkLocation: 'footer'
    });
  };
  const {
    data
  } = useSignupData(Data.CmsSignupIntent);
  const showToSCMSIntent = getFlowConfig().shouldSkipHubletSelectionNewUser && currentStep === Step.CmsSignupIntent && data === CmsSignupIntentOption.Personal && !isExisitngUserCreatingPortal;
  const handleClickTermsOfService = () => {
    Tracker.click({
      message: 'click terms of service',
      linkLocation: 'footer'
    });
  };
  const handleConnectTermsOfUseClick = () => {
    Tracker.click({
      message: 'click connect terms of use',
      linkLocation: 'footer'
    });
  };
  const handleClickDeveloperTermsOfService = () => {
    Tracker.click({
      message: 'click developer terms of service',
      linkLocation: 'footer'
    });
  };
  const stepConfig = getStepConfig(currentStep);
  const shouldShowToS = isAccountCreationStep && !isExisitngUserCreatingPortal && getFlowConfig().shouldShowTosOnAccountCreationStep || showToSCMSIntent;
  const isOptionalQuestion = OptionalDataKeys.includes(currentStep);
  const shouldShowPrivacyBanner = stepConfig ? stepConfig.shouldShowPrivacyBanner : false;
  return {
    privacyPolicyTextI18nLabel,
    createAccountTextI18nLabel,
    shouldShowPrivacyBanner,
    shouldShowToSNonOptionalStep: shouldShowToS && !isOptionalQuestion,
    shouldShowToSOptionalStep: shouldShowToS && isOptionalQuestion,
    isOnLandingPage: currentStep === Step.LandingPage,
    isOnHublet: currentStep === Step.HubletSelection,
    handleClickTermsOfService,
    handleClickPrivacyPolicy,
    handleClickDeveloperTermsOfService,
    handleConnectTermsOfUseClick
  };
};