import { ExperimentFetchStatus } from '../../experiments/ExperimentFetchStatus';
import { ExperimentIdentifier } from '../../experiments/ExperimentIdentifier';
import { ExperimentParameterGroup } from '../../experiments/ExperimentParameterGroup';
import { getExperiment } from '../../experiments/Experiments';
import { experimentsEnabled } from '../../experiments/experimentsEnabled';
export const getExperimentFromState = (state, experimentId) => {
  return state.experiments.treatments[experimentId];
};
export const getExperimentsFromState = state => {
  return state.experiments.treatments;
};
export const getUnenrolledExperiments = state => {
  return state.experiments.unenrolledExperiments;
};
export const isExperimentActive = (state, experimentId) => {
  const experiment = getExperimentFromState(state, experimentId);
  const experimentObject = getExperiment(experimentId);
  if (!experiment || !experimentObject) return false;
  return experimentsEnabled() && experimentObject.shouldEnroll(state);
};
export const isCopyExperimentActive = (state, experimentId) => {
  const experiment = getExperimentFromState(state, experimentId);
  if (!experiment) return false;
  return experiment.status && experiment.status.isActive && !experiment.status.isDefaulted;
};
export const arePreAccountCreatedExperimentsFetched = state => {
  const areUtkExperimentsFetched = state.experiments.experimentsStatus[ExperimentIdentifier.Utk] === ExperimentFetchStatus.Fetched;
  const areUuidExperimentsFeteched = state.experiments.experimentsStatus[ExperimentIdentifier.Uuid] === ExperimentFetchStatus.Fetched;
  return areUtkExperimentsFetched || areUuidExperimentsFeteched;
};
export const arePortalExperimentsFetched = state => {
  return state.experiments.experimentsStatus[ExperimentIdentifier.PortalId] === ExperimentFetchStatus.Fetched;
};
export const getExperimentIdentifierPhase = state => {
  return state.experiments.identifierPhase;
};
export const getParameter = (state, experimentId) => {
  const experiment = getExperimentFromState(state, experimentId);
  if (!experiment) return '';
  return experiment.parameters.group;
};
export const getHasExposureBeenTracked = (state, experimentId) => {
  const experiment = getExperimentFromState(state, experimentId);
  if (!experiment) return false;
  return experiment.trackedExposure;
};
export const getIsActiveAndAssignedToVariant = (state, experimentId, variantsToCheck = [ExperimentParameterGroup.Variant]) => {
  if (!experimentsEnabled()) return false;
  const parameter = getParameter(state, experimentId);
  const experimentActive = isExperimentActive(state, experimentId);
  const isInVariants = variantsToCheck.includes(parameter);
  return isInVariants && experimentActive;
};
export const getActiveExperimentsIds = state => {
  const experimentsFromState = getExperimentsFromState(state);
  const unenrolledExperiments = getUnenrolledExperiments(state);
  if (!experimentsFromState) {
    return [];
  }
  const experimentKeys = Object.keys(experimentsFromState);
  const activeExperiments = experimentsFromState ? experimentKeys.filter(experimentId => isExperimentActive(state, experimentId) && !unenrolledExperiments.includes(experimentId)) : [];
  return activeExperiments;
};
export const getActiveExperimentIdsFromCompleteVerification = state => state.experiments.activeExperimentsParameters;
export const getExperimentUtk = state => {
  return state.experiments.experimentUtk;
};