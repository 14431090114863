import { Data } from 'signup-constants/signupData/Data';
import { getGuessedDataBiden } from '../store/guessed-prefill/selectors';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { trackInteraction } from './trackEvent';
export const monitorChangedCompanyDomainValue = (action, state) => {
  const bidenCompanyDomain = getGuessedDataBiden(Data.CompanyDomain)(state);
  const submittedCompanyDomain = getSignupDataValue(state, Data.CompanyDomain);
  if (bidenCompanyDomain && bidenCompanyDomain !== submittedCompanyDomain) {
    trackInteraction({
      action: `company-domain-prefilled-domain-changed`
    });
  }
};
export const monitorChangedCompanyNameValue = (action, state) => {
  const bidenCompanyName = getGuessedDataBiden(Data.CompanyName)(state);
  const submittedCompanyName = getSignupDataValue(state, Data.CompanyName);
  if (bidenCompanyName && bidenCompanyName !== submittedCompanyName) {
    trackInteraction({
      action: `company-domain-prefilled-companyName-changed`
    });
  }
};