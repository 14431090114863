import { ErrorKey } from '../app-active-error/ErrorKey';
import { setAuth } from '../auth/setAuth';
import { getSentryExtra } from '../error/getSentryExtra';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import { setupAuthForOAuthExistingUserCOAC0002 } from '../experiments/coac0002/COAC0002Utils';
import { isPostVerification } from '../flow-settings/settingsSelectors';
import Logger from '../lib/Logger';
import { setHasRefreshedNewPortalAuth, setIsExistingUserSession, setIsNewUser, setWhichOAuthLoading } from '../store/app/action-creators';
import { AppActionTypes } from '../store/app/action-types';
import { getWhichOAuthSelected } from '../store/app/selectors';
import { existingOAuthUser, initializeAuth, oAuthExistingAuthFailed } from '../store/auth/action-creators';
import { setError } from '../store/error/action-creators';
import { getIsActiveAndAssignedToVariant } from '../store/experiments/selectors';
import { reloadFlow } from '../store/flow/action-creators';
import { Tracker } from '../store/tracking/action-creators';
import { checkLoginStatusIsSuspiciousUser } from './authUtils';
const LOG = Logger.getLogger('EndpointsAuth');
export const storeAuthResponse = (response, dispatch) => {
  dispatch(setAuth(response));
};
export const handleSetupAuthSuccess = (response, dispatch) => {
  storeAuthResponse(response, dispatch);
  if (response) {
    dispatch(initializeAuth(true, response.email));
    dispatch(Tracker.authState('authed'));
    dispatch(setIsExistingUserSession(true));
    if (!isPostVerification()) {
      dispatch(Tracker.signupPageView('authenticated'));
    }
  }
};
export const handleSetupAuthFailure = (error, dispatch) => {
  dispatch(initializeAuth(false));
  dispatch(Tracker.authState('not-authed'));
  if (!isPostVerification()) {
    dispatch(Tracker.signupPageView('unauthenticated'));
  }
};
export const handleSetupAuthForNewPortalSuccess = (response, dispatch) => {
  storeAuthResponse(response, dispatch);
  dispatch(setHasRefreshedNewPortalAuth(true));
  dispatch({
    type: AppActionTypes.SignupEnded
  });
};
export const handleSetupAuthForNewPortalFailure = error => {
  LOG.error('authentication after portal login error', {
    extra: getSentryExtra(error)
  });
};
export const handleSetupAuthForOAuthExistingUserSuccess = (response, dispatch, getState) => {
  storeAuthResponse(response, dispatch);
  const state = getState();
  const oAuthProviderSelected = getWhichOAuthSelected(state);
  if (response && oAuthProviderSelected) {
    dispatch(existingOAuthUser(response.email, oAuthProviderSelected));
    dispatch(setIsNewUser(false));
    if (getIsActiveAndAssignedToVariant(state, ExperimentKeys.COAC0002, [ExperimentParameterGroup.Variant_A])) {
      setupAuthForOAuthExistingUserCOAC0002(state, ExperimentKeys.COAC0002, dispatch);
    } else {
      dispatch(reloadFlow());
    }
    dispatch(setWhichOAuthLoading(null));
  }
};
export const handleSetupAuthForOAuthExistingUserFailure = (error = {}, dispatch, getState) => {
  const {
    message
  } = error;
  const state = getState();
  const oAuthProviderSelected = getWhichOAuthSelected(state);
  const isSuspiciousUserLoginStatus = checkLoginStatusIsSuspiciousUser(state);
  if (oAuthProviderSelected) {
    if (isSuspiciousUserLoginStatus) {
      dispatch(Tracker.signupInteraction('oauth-existing-user-auth-failure-login-code'));
    } else {
      LOG.error(`Error verifying ${oAuthProviderSelected} oauth on login service`, {
        extra: Object.assign({}, getSentryExtra(error), {
          message
        })
      });
    }
    dispatch(oAuthExistingAuthFailed(oAuthProviderSelected));
  } else {
    LOG.error(`Missing oAuthProvider`, {
      extra: Object.assign({}, getSentryExtra(error), {
        message
      })
    });
  }
  dispatch(setError(ErrorKey.OAuthExistingLoginFailed, error));
  dispatch(setWhichOAuthLoading(null));
};