export let CompanySizeOption;
(function (CompanySizeOption) {
  CompanySizeOption["One"] = "one";
  CompanySizeOption["TwoToFive"] = "twoToFive";
  CompanySizeOption["SixToTen"] = "sixToTen";
  CompanySizeOption["ElevenToTwentyFive"] = "elevenToTwentyFive";
  CompanySizeOption["TwentySixToFifty"] = "twentySixToFifty";
  CompanySizeOption["FiftyOneToTwoHundred"] = "fiftyOneToTwoHundred";
  CompanySizeOption["TwoHundredOneToOneThousand"] = "twoHundredOneToOneThousand";
  CompanySizeOption["OneThousandOnetoTenThousand"] = "oneThousandOnetoTenThousand";
  CompanySizeOption["TenThousandOneOrMore"] = "tenThousandOneOrMore";
})(CompanySizeOption || (CompanySizeOption = {}));