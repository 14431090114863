import { CMS_FREE_SIGNUP, CRM_SIGNUP, MARKETING_SIGNUP, SALES_SIGNUP, SERVICE_SIGNUP, TRIAL_SIGNUP } from 'growth-onboarding-signup-redirect/constants/signupConstants';
import { getRouteParams } from '../routing/getRouteParams';
import { getHubletApiUrl, getHubletAppUrl } from '../utils/urls';
import Logger from '../lib/Logger';
import { getGatesWithUrlOverrides } from '../auth/authUtils';
import { getApplicationLocale } from '../i18n/LocaleUtils';
const LogInstances = {
  [MARKETING_SIGNUP]: Logger.getLogger('marketingRedirect'),
  [CRM_SIGNUP]: Logger.getLogger('crmRedirect'),
  [SALES_SIGNUP]: Logger.getLogger('salesRedirect'),
  [SERVICE_SIGNUP]: Logger.getLogger('serviceRedirect'),
  [CMS_FREE_SIGNUP]: Logger.getLogger('cmsFreeRedirect'),
  [TRIAL_SIGNUP]: Logger.getLogger('trialRedirect')
};
export default function getOnboardingRedirectOptions(portalId, signupType, hublet, auth, signupStepNumber, survey, signupExperimentTreatments) {
  const {
    queryParams
  } = getRouteParams();
  const appUrl = getHubletAppUrl(hublet);
  const apiUrl = getHubletApiUrl(hublet);
  const gateOverrides = getGatesWithUrlOverrides([]);
  const locale = getApplicationLocale();
  return {
    includeParams: Object.assign({}, queryParams, {
      lang: locale,
      gates: gateOverrides
    }),
    hubsMeta: {
      'signup-cta': queryParams['hubs_signup-cta'],
      'signup-url': queryParams['hubs_signup-url']
    },
    portalId,
    signupType,
    locale,
    logger: LogInstances[signupType],
    auth,
    survey,
    signupExperimentTreatments,
    appUrl,
    apiUrl,
    signupStepNumber
  };
}