export const loadScript = (src, id, defer = true, async = false) => {
  if (!id) {
    throw new Error(`An id must be provided.`);
  }
  if (document.getElementById(id)) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.id = id;
    script.type = 'text/javascript';
    script.src = src;
    script.defer = defer;
    script.async = async;
    script.onload = function () {
      resolve();
    };
    script.onerror = function () {
      reject(new Error(`Error occurred while trying to load ${src}`));
    };
    const head = document.querySelector('head');
    if (head) {
      head.appendChild(script);
    }
  });
};