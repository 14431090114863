import { HubletUrl } from '../utils/urls';
import { ONBOARDING_VIEWS } from 'user-context/onboardingSettings';
import { ONBOARDING_SALES_FREE } from 'getting-started-shared-tasks/onboardingKeys';
import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
const unknownExperiences = [{
  redirectPath: portalId => {
    return {
      // We treat unidentifiable users as our mostsuccesful person which is Sales Leaders
      url: `${HubletUrl.getAppUrl()}/user-guide/${portalId}`,
      view: ONBOARDING_VIEWS.SALES,
      onboardingKey: ONBOARDING_SALES_FREE,
      groupKey: FREE_GROUP_KEYS.freeSalesView
    };
  },
  checks: []
}];
export default unknownExperiences;