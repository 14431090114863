export const CATEGORY_JOB_ROLES_MAP = {
  'revenue-ops': ['revenue-operations-analyst', 'revenue-operations-manager', 'revenue-operations-specialist'],
  administrator: ['administrative-analyst', 'administrative-assistant', 'administrative-manager'],
  'marketing-ops': ['marketing-operations-manager', 'marketing-operations-specialist', 'marketing-operations-analyst'],
  'sales-ops': ['sales-operations-analyst', 'sales-operations-manager', 'sales-operations-specialist'],
  'sales-director-manager': ['chief-sales-officer', 'director-inside-sales', 'outside-sales-manager', 'area-sales-manager', 'senior-vice-president-sales', 'vice-president-sales', 'sales-manager', 'sales-director', 'sales-executive'],
  'sales-rep': ['b2b-sales-specialist', 'direct-salesperson', 'group-sales', 'sales-analyst', 'sales-associate', 'sales-representative'],
  'customer-service-rep': ['client-service-specialist', 'customer-care-associate', 'customer-care-representative', 'customer-service-agent', 'customer-service-expert', 'customer-service-representative', 'customer-success-intern', 'customer-support-associate', 'customer-support-representative'],
  'director-manager': ['chief-customer-officer', 'senior-vice-president-customer-service', 'senior-vice-president-accounting', 'customer-service-manager', 'customer-support-manager', 'customer-success-team-lead', 'customer-success-manager', 'customer-service-supervisor', 'customer-experience-director', 'customer-service-executive'],
  'marketing-director-manager': ['chief-marketing-officer', 'content-marketing-manager', 'digital-marketing-manager', 'product-marketing-director', 'marketing-communications-manager', 'senior-vice-president-marketing', 'marketing-director', 'marketing-manager', 'senior-vice-president-product-marketing', 'vice-president-marketing', 'vice-president-product-marketing'],
  'designer-developer': ['web-designer', 'web-developer', 'web-editor', 'graphic-designer'],
  'marketing-content-creator': ['ecommerce-marketing-specialist', 'marketing-consultant', 'marketing-specialist', 'product-marketer', 'social-media-assistant', 'social-media-manager', 'social-media-specialist', 'content-creator', 'content-strategist', 'content-writer', 'digital-marketing-specialist', 'growth-marketer', 'campaign-specialist'],
  'administrator-operations': ['sales-engineer', 'customer-service-engineer', 'marketing-research-analyst', 'system-administrator', 'systems-engineer']
};