import styled from 'styled-components';
import UIButton from 'UIComponents/button/UIButton';
import UICardWrapper from 'UIComponents/card/UICardWrapper';
import { PANTERA, EERIE } from 'HubStyleTokens/colors';
export const StyledAccountCard = styled(UICardWrapper).withConfig({
  displayName: "AccountCardstyles__StyledAccountCard",
  componentId: "el0mig-0"
})(["max-width:18.75rem;padding:2rem;margin-top:2rem;margin-bottom:2rem;border-radius:10px;box-shadow:rgba(0,0,0,0) 0 0 0 0,rgba(0,0,0,0) 0 0 0 0,rgba(0,0,0,0.25) 0 25px 50px -12px;", " ", ""], props => props.theme.embedded && `box-shadow:none;`, props => props.theme.embedded && props.shouldShowBoxAccountCard && `box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.25) 0 25px 50px -12px;`);
export const AccountCardUserName = styled.div.withConfig({
  displayName: "AccountCardstyles__AccountCardUserName",
  componentId: "el0mig-1"
})(["margin:1rem auto 0.2rem;font-size:1.4rem;font-weight:600;color:", ";"], PANTERA);
export const AccountCardEmail = styled.div.withConfig({
  displayName: "AccountCardstyles__AccountCardEmail",
  componentId: "el0mig-2"
})(["color:", ";white-space:nowrap;text-overflow:ellipsis;overflow-x:hidden;overflow-y:hidden;"], EERIE);
export const AccountCardContinueButton = styled(UIButton).withConfig({
  displayName: "AccountCardstyles__AccountCardContinueButton",
  componentId: "el0mig-3"
})(["margin-top:2rem;white-space:normal !important;"]);