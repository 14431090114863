import { ErrorKey } from '../app-active-error/ErrorKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { AuthActionTypes } from '../store/auth/action-types';
import { getActiveError } from '../store/error/selectors';
import { FlowActionTypes } from '../store/flow/action-types';
import { getCurrentStep } from '../store/flow/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { TrackingActionTypes } from '../store/tracking/action-types';
import { setupRecaptcha } from './setupRecaptcha';
import { generateRecaptchaToken } from './utils/generateRecaptchaToken';
let installed = false;
const LOG = Logger.getLogger('RecaptchaMiddleware');
const refreshToken = store => {
  const state = store.getState();
  const currentStep = getCurrentStep(state);
  const activeError = getActiveError(state);
  const activeLoading = getActiveLoading(state);
  const currentStepOrActiveError = currentStep || activeError;
  if (currentStepOrActiveError && !activeLoading && activeError !== ErrorKey.RecaptchaCreationError) {
    store.dispatch(generateRecaptchaToken(currentStepOrActiveError));
  }
};
export const RecaptchaMiddleware = store => next => action => {
  if (installed) {
    if (action.type === TrackingActionTypes.StepView) {
      refreshToken(store);
    }
  } else {
    if (action.type === FlowActionTypes.StepCompleted || action.type === TrackingActionTypes.VerifyIdentityCompleted || action.type === AuthActionTypes.ClickCreateNewAccountSameUser || action.type === FlowActionTypes.Load) {
      setupRecaptcha().then(() => {
        installed = true;
        refreshToken(store);
      }).catch(error => {
        LOG.error(`Error loading recaptcha enterprise. ${error.message}`, {
          extra: getSentryExtra(error)
        });
      });
    }
  }
  return next(action);
};