import { getTreatmentsClient } from './treatmentsClient';
import { isExperimentActive } from '../store/experiments/selectors';
import { ExperimentIdentifier } from './ExperimentIdentifier';
import { getExperimentFromConfig } from './getExperimentFromConfig';
import { getPortalIdTreatmentsClient } from './portalTreatmentsClient';
import { experimentsEnabled } from './experimentsEnabled';
export const logExposure = id => (dispatch, getState) => {
  const state = getState();
  const canLogExposure = isExperimentActive(state, id);
  if (experimentsEnabled() && canLogExposure) {
    const experiment = getExperimentFromConfig(id);
    if (experiment && experiment.identifierType === ExperimentIdentifier.PortalId) {
      getPortalIdTreatmentsClient(state).logExposure(id);
    } else {
      getTreatmentsClient().logExposure(id);
    }
  }
};
export default logExposure;