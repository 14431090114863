import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppKey } from '../app/AppKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { setWhichOAuthLoading, setWhichOAuthSelected, updateKeyValue } from '../store/app/action-creators';
import { oAuthAuthFailed, oAuthAuthStarted, oAuthAuthSucceeded, verifyOAuthVerificationPayload } from '../store/oauth/action-creators';
import { APPLE_POPUP_BLOCKED, APPLE_POPUP_CLOSED, getAppleAuthClient, getNameFromAppleUser, trackApplePrivateEmail } from './appleUtils';
const LOG = Logger.getLogger('useAppleOAuthSignIn');
export const useAppleOAuthSignIn = ({
  shouldSignInWithApple,
  setShouldSignInWithApple,
  appleNonce
}) => {
  const dispatch = useDispatch();
  const appleAuthClient = getAppleAuthClient();
  useEffect(() => {
    if (shouldSignInWithApple && appleAuthClient && appleNonce) {
      dispatch(oAuthAuthStarted(OAuthProviders.Apple));
      dispatch(setWhichOAuthSelected(OAuthProviders.Apple));
      appleAuthClient.auth.signIn().then(res => {
        dispatch(setWhichOAuthLoading(OAuthProviders.Apple));
        const {
          authorization,
          user
        } = res;
        if (user) {
          const {
            email
          } = user;
          trackApplePrivateEmail(dispatch, email);
        }
        dispatch(oAuthAuthSucceeded({
          token: authorization.id_token
        }, OAuthProviders.Apple));
        dispatch(updateKeyValue(AppKey.FirstOAuthPartnerClick, OAuthProviders.Apple));
        dispatch(verifyOAuthVerificationPayload(Object.assign({
          idToken: authorization.id_token,
          nonce: appleNonce
        }, getNameFromAppleUser(user)), OAuthProviders.Apple)).catch(error => {
          LOG.error('Error during verification of the apple id token', {
            extra: getSentryExtra(error)
          });
        });
      }).catch(({
        error
      }) => {
        dispatch(oAuthAuthFailed(error, OAuthProviders.Apple));
        if (error === APPLE_POPUP_CLOSED) {
          LOG.warn(`Apple User Cancelled Flow by Closing the popup`, {
            extra: getSentryExtra(error)
          });
        } else if (error === APPLE_POPUP_BLOCKED) {
          LOG.warn(`Apple popup blocked by browser`, {
            extra: getSentryExtra(error)
          });
        } else {
          LOG.error(`Error with Apple Authentication`, {
            extra: getSentryExtra(error)
          });
        }
        dispatch(updateKeyValue(AppKey.WhichOAuthEndpointFailed, OAuthProviders.Apple));
        setShouldSignInWithApple(false);
      });
    }
  }, [appleAuthClient, shouldSignInWithApple, setShouldSignInWithApple, dispatch, appleNonce]);
};