import { Flow } from 'signup-constants/Flow';
import { Microapp } from 'signup-constants/Microapp';
import { isExistingUserExistingAccount } from '../app/verificationTypeSelectors';
import { EndpointKey } from '../http/EndpointKey';
import { getHasRefreshedNewPortalAuth, getIsNewPortalCreated, getIsNewUser } from '../store/app/selectors';
import { isPortalIdDefined } from '../store/auth/selectors';
import { getCurrentStep, isEndOfFlow } from '../store/flow/selectors';
import { isEndpointStatusSuccess } from '../store/http-responses/selectors';
import { getIsIntegratedAppAuthFinished } from '../store/integrations/selectors';
import { hasAnsweredAllPostExistingAccountSelectedQuestions } from '../store/signup-data/selectors';
import { isFlow } from '../utils/isFlow';
import { isMicroapp } from '../utils/isMicroapp';
import { isIntegratedApp } from '../utils/isIntegratedApp';
export const canInstallFallbackTimer = state => {
  const portalIdDefined = isPortalIdDefined(state);
  const isNewPortalCreated = getIsNewPortalCreated(state);
  const currentStep = getCurrentStep(state);
  const hasRefreshedNewPortalAuth = getHasRefreshedNewPortalAuth(state);
  const endOfFlow = isEndOfFlow(state);
  const isIntegratedAppAuthFinished = getIsIntegratedAppAuthFinished(state);
  const isNewUser = getIsNewUser(state);
  const haveScopesBeenGrantedForBlogIdeasGenerator = isEndpointStatusSuccess(EndpointKey.CheckScopesForBlogIdeasGeneratorNewUser)(state);
  if (isMicroapp(Microapp.BlogIdeasGenerator) && isNewUser && !haveScopesBeenGrantedForBlogIdeasGenerator) {
    return false;
  }
  if (isIntegratedApp() && !isIntegratedAppAuthFinished) {
    return false;
  }
  if (isExistingUserExistingAccount(state) && !isFlow(Flow.StandaloneCmsPartner)) {
    return endOfFlow && portalIdDefined && hasAnsweredAllPostExistingAccountSelectedQuestions(state);
  }
  return endOfFlow && hasRefreshedNewPortalAuth && !currentStep && isNewPortalCreated && portalIdDefined;
};