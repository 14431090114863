import quickFetch from 'quick-fetch';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
const LOG = Logger.getLogger('makeRequest');
export const makeRequest = ({
  earlyRequestKey,
  fetch,
  shouldUseEarlyRequest = true,
  onError
}) => {
  const earlyRequest = earlyRequestKey && quickFetch.getRequestStateByName(earlyRequestKey);
  if (!earlyRequest || !shouldUseEarlyRequest) {
    return fetch();
  }
  return new Promise((resolve, reject) => {
    earlyRequest.whenFinished(result => {
      quickFetch.removeEarlyRequest(earlyRequestKey);
      resolve(result);
    });
    earlyRequest.onError(xhr => {
      if (onError) {
        onError(xhr, resolve, reject);
      } else {
        makeRequest({
          fetch
        }).then(resolve, reject).catch(error => LOG.error('makeRequest error:', {
          extra: getSentryExtra(error)
        }));
      }
      quickFetch.removeEarlyRequest(earlyRequestKey);
    });
  });
};