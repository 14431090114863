import { normalizeUrl } from '../utils/normalizeUrl';
// @ts-expect-error Untyped module
import Interframe from 'interframe';
import { SignupEmbedder, SignupEmbedderOptions } from 'signup-embedder';
import { isGoogleFallbackRedirect } from '../embedded/isGoogleFallbackRedirect';
import { isKnownDomainEmbeddingSignup } from '../embedded/isKnownDomainEmbeddingSignup';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { Metrics } from '../metrics/Metrics';
import InterframeContainer from './InterframeContainer';
import { MetricsCounter } from '../metrics/MetricsCounter';
const Log = Logger.getLogger('setupInteframe');
export const setupInterframe = () => {
  try {
    if (SignupEmbedder.isActive()) {
      const embeddingPage = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_EMBEDDING_PAGE_OPTION) || document.referrer;
      let embeddingPageUrl = {
        hostname: '',
        protocol: 'https:'
      };
      const normalizedUrl = normalizeUrl(embeddingPage);
      try {
        embeddingPageUrl = new URL(normalizedUrl);
      } catch (error) {
        Log.info('Invalid embeddingPageUrl', {
          extra: Object.assign({}, getSentryExtra(error), {
            embeddingPage: normalizedUrl
          })
        });
      }
      const {
        hostname
      } = embeddingPageUrl;

      // If we are in the case of the google fallback authentication then
      // the  hostname is the domain of google authentication before the redirect.
      // In order to enable the communication between interframe and embedder,
      // use the parentUrl to pass to the interframe as the referrer.
      if (isGoogleFallbackRedirect(hostname)) {
        embeddingPageUrl = new URL(document.location.href);
      }

      // This will check if the page embedding signup contains a known domain
      if (!isKnownDomainEmbeddingSignup(embeddingPageUrl.hostname)) {
        Log.error(`The embedding page contains an unknown domain.`, {
          extra: {
            embeddingPage,
            hostname: embeddingPageUrl.hostname
          }
        });
        Metrics.counter(MetricsCounter.EmbeddingPageContainsUnknownDomain).increment();
      }
      const referrer = `${embeddingPageUrl.protocol}//${embeddingPageUrl.hostname}`;
      const interframe = new Interframe(referrer, SignupEmbedder.isEmbedded() ? window.parent : window.opener);
      InterframeContainer.setInstance(interframe);
      return interframe;
    }
  } catch (error) {
    Log.error('Error while setting up interframe communication', {
      extra: Object.assign({}, getSentryExtra(error), {
        embeddingPage: SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_EMBEDDING_PAGE_OPTION) || document.referrer
      })
    });
  }
  return undefined;
};