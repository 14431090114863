import { decideBranch } from '../flow-branches/decideBranch';
import { clearNextSteps, finishSignup, goNextStep } from '../store/flow/action-creators';
import { getIsAccountCreationStep } from './getIsAccountCreationStep';
import { skipPrefilled } from './skipPrefilled';
import { getFlowStepsFromState } from '../store/flow/selectors';
import { getIsVerificationExpired } from '../store/app/selectors';
export const setNextStep = ({
  rebuilder,
  accountCreated = false
} = {}) => (dispatch, getState) => {
  const state = getState();
  const {
    preVerification,
    postVerification,
    postAccountCreation,
    postExistingAccountSelection
  } = getFlowStepsFromState(state);
  const transformedFlow = decideBranch(state, dispatch);
  let flowSteps = skipPrefilled(transformedFlow, state);
  flowSteps = rebuilder ? rebuilder(flowSteps) : flowSteps;
  if (!flowSteps) {
    return null;
  }
  const step = flowSteps[0];
  const nextSteps = flowSteps.slice(1);
  const isAccountCreationStep = getIsAccountCreationStep(getState(), flowSteps);
  const isVerificationExpired = getIsVerificationExpired(getState());
  if (isAccountCreationStep && !accountCreated && !isVerificationExpired) {
    if (flowSteps.length === 0) {
      dispatch(clearNextSteps());
    }
    return dispatch(finishSignup());
  } else {
    return dispatch(goNextStep({
      step,
      nextSteps,
      preVerificationSteps: preVerification,
      postAccountCreationSteps: postAccountCreation,
      postVerificationSteps: postVerification,
      postExistingAccountSelectionSteps: postExistingAccountSelection
    }));
  }
};