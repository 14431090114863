import { getExperimentKeys } from './ExperimentKeys';
import { ExperimentIdentifier } from './ExperimentIdentifier';
import { getExperimentFromConfig } from './getExperimentFromConfig';
export const isPortalExperiment = experimentId => {
  const experiment = getExperimentFromConfig(experimentId);
  return experiment.identifierType === ExperimentIdentifier.PortalId;
};
export const getAllUtkExperiments = () => {
  return getExperimentKeys().filter(experimentKey => {
    return !isPortalExperiment(experimentKey);
  });
};