import { getHublet } from '../store/app/selectors';
import { isPortalIdDefined } from '../store/auth/selectors';
import { isNewLoggedInAccount } from './endpointStateChecks';
import { EndpointKey } from './EndpointKey';
import { getIsActiveAndAssignedToVariant, getParameter } from '../store/experiments/selectors';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import Logger from '../lib/Logger';
import { getSentryExtra } from '../error/getSentryExtra';
import { retryEndpoint } from '../store/http-responses/action-creators';
import { getEndpointRetries } from '../store/http-responses/selectors';
import { writeHubUserAttribute } from '../utils/hubUserAttribute';
import { Tracker } from '../store/tracking/action-creators';
const COAC0002_TREATMENT_HUB_USER_ATTRIBUTE_KEY = 'GrowthCoaching:COAC0002Treatment';
const storeCOAC0002Treatment = state => {
  const hublet = getHublet(state);
  const COAC0002Treatment = getParameter(state, ExperimentKeys.COAC0002);
  return writeHubUserAttribute(COAC0002_TREATMENT_HUB_USER_ATTRIBUTE_KEY, COAC0002Treatment, hublet);
};
export const LOG = Logger.getLogger('storeCOAC0002TreatmentError');
export const EndpointsStoreCOAC0002Treatment = {
  [EndpointKey.StoreCOAC0002Treatment]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: state =>
    // Is the Portal created so we can use the Hub User Attributes API?
    isPortalIdDefined(state) &&
    // Is the user in the COAC0002 experiment?
    getIsActiveAndAssignedToVariant(state, ExperimentKeys.COAC0002, [ExperimentParameterGroup.Control, ExperimentParameterGroup.Variant_A]) &&
    // Is the user authed for API usage?
    isNewLoggedInAccount(state),
    method: storeCOAC0002Treatment,
    handleSuccess: (response, dispatch) => {
      dispatch(Tracker.signupInteraction('success-store-COAC0002-treatment'));
    },
    handleError: (error, dispatch, getState) => {
      dispatch(Tracker.signupInteraction('error-store-COAC0002-treatment'));
      const numberOfStoreTreatmentRetries = getEndpointRetries(getState(), EndpointKey.StoreCOAC0002Treatment);
      LOG.error('Storing COAC0002 treatment error', {
        extra: Object.assign({}, getSentryExtra(error), {
          totalNumberOfCalls: numberOfStoreTreatmentRetries
        })
      });
      // Retry every second, up to 5 times
      if (numberOfStoreTreatmentRetries < 5) {
        dispatch(Tracker.signupInteraction('retry-store-COAC0002-treatment', {
          totalNumberOfCalls: numberOfStoreTreatmentRetries
        }));
        dispatch(retryEndpoint(EndpointKey.StoreCOAC0002Treatment, 1000));
      }
    }
  }
};