export let VisitorContextActionTypes;
(function (VisitorContextActionTypes) {
  VisitorContextActionTypes["Fetch"] = "[Visitor Context] Fetch";
  VisitorContextActionTypes["Filled"] = "[Visitor Context] Filled";
  VisitorContextActionTypes["NotFilled"] = "[Visitor Context] Not Filled";
  VisitorContextActionTypes["ExistingUser"] = "[Visitor Context] Existing User";
  VisitorContextActionTypes["Failure"] = "[Visitor Context] Failure";
  VisitorContextActionTypes["RateLimited"] = "[Visitor Context] Rate Limited";
  VisitorContextActionTypes["NewUser"] = "[Visitor Context] New User";
  VisitorContextActionTypes["NotFound"] = "[Visitor Context] Not Found";
  VisitorContextActionTypes["Update"] = "[Visitor Context] Update";
})(VisitorContextActionTypes || (VisitorContextActionTypes = {}));