import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tracker } from '../store/tracking/action-creators';
import { hasAcceptedCookies, hasDeclinedCookies } from './cookieUtils';
let hasTrackedCookiesConsent = false;
export function getHasTrackedCookiesConsent() {
  return hasTrackedCookiesConsent;
}
export function setHasTrackedCookiesConsent(value) {
  hasTrackedCookiesConsent = value;
}
export function resetHasTrackedCookiesConsent() {
  hasTrackedCookiesConsent = false;
}
export const useTrackCookiesConsent = () => {
  const dispatch = useDispatch();
  const hasTrackedCookiesConsentInteraction = getHasTrackedCookiesConsent();
  useEffect(() => {
    if (!hasTrackedCookiesConsentInteraction) {
      const acceptedCookies = hasAcceptedCookies();
      const declinedCookies = hasDeclinedCookies();
      if (acceptedCookies) {
        dispatch(Tracker.signupInteraction('has-already-accepted-cookies'));
      } else if (declinedCookies) {
        dispatch(Tracker.signupInteraction('has-already-declined-cookies'));
      } else {
        dispatch(Tracker.signupInteraction('has-not-given-cookies-consent'));
      }
      setHasTrackedCookiesConsent(true);
    }
  });
};