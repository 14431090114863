import { ValidationStatus } from '../types';
import { isValidUrl } from './isValidUrl';
import { hasEmoji, hasNumbers, hasSpecialCharacters } from './matchers';
export let NameError;
(function (NameError) {
  NameError["INVALID"] = "invalid";
  NameError["REQUIRED"] = "required";
  NameError["URL"] = "url";
  NameError["MAX_LENGTH"] = "max-length";
})(NameError || (NameError = {}));
export const syncNameValidator = value => {
  if (!value || !value.trim().length) {
    return NameError.REQUIRED;
  }
  value = value.toLowerCase();
  const invalid = hasSpecialCharacters(value) || hasNumbers(value) || hasEmoji(value) || isValidUrl(value);
  if (invalid) {
    return NameError.INVALID;
  }
  return ValidationStatus.VALID;
};