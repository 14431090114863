import { Flow } from 'signup-constants/Flow';
import { getFlowKey } from '../routing/getFlowKey';
import { EndpointsIntegrations } from '../integrations-flow/EndpointsIntegrations';
import { EndpointsStandaloneCmsPartner } from './EndpointsStandaloneCmsPartner';
import { EndpointsUserOnlyFlow } from './http-user-only-signup/EndpointsUserOnlyFlow';
export const getFlowEndpointOverrides = (flowKey = getFlowKey()) => {
  switch (flowKey) {
    case Flow.Integrations:
      return EndpointsIntegrations;
    case Flow.StandaloneCmsPartner:
      return EndpointsStandaloneCmsPartner;
    case Flow.Connect:
      return EndpointsUserOnlyFlow;
    default:
      return {};
  }
};