import { ErrorActionTypes } from './action-types';
export const initialErrorState = {
  activeError: null,
  error: null
};
const errorReducer = (state = initialErrorState, action) => {
  switch (action.type) {
    case ErrorActionTypes.SetError:
      return Object.assign({}, state, {
        activeError: action.errorKey,
        error: action.error
      });
    case ErrorActionTypes.ClearError:
      return Object.assign({}, state, {
        activeError: null,
        error: null
      });
    default:
      return state;
  }
};
export default errorReducer;