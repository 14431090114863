import { getQueryParam } from '../routing/getQueryParam';
import { getHistory } from '../routing/history';
export const checkUrlForUUID = () => {
  return Boolean(getQueryParam('uuid'));
};
export const hasAnonQueryParam = () => {
  const anonParam = getQueryParam('anon');
  const uuid = getQueryParam('uuid');
  return anonParam && anonParam === 'true' || uuid;
};
export const scrubUuidFromUrl = () => {
  const searchParams = new URLSearchParams(getHistory().location.search);
  const uuidFromQueryParam = searchParams.get('uuid');
  if (uuidFromQueryParam) {
    searchParams.delete('uuid');
    getHistory().replace(`${getHistory().location.pathname}?${searchParams}`);
  }
};