export let WaveType;
(function (WaveType) {
  WaveType["Error"] = "ErrorWave";
  WaveType["LowYellow"] = "LowYellowWave";
  WaveType["LowOrange"] = "LowOrangeWave";
  WaveType["Yellow"] = "YellowWave";
})(WaveType || (WaveType = {}));
export let LayoutType;
(function (LayoutType) {
  LayoutType["CentredStep"] = "CentredStep";
  LayoutType["FramedCentredStep"] = "FramedCentredStep";
  LayoutType["FramedCentredModalStep"] = "FramedCentredModalStep";
  LayoutType["TwoColumn"] = "TwoColumn";
  LayoutType["LeftAlignedTwoColumn"] = "LeftAlignedTwoColumn";
  LayoutType["LeftAligned"] = "LeftAligned";
})(LayoutType || (LayoutType = {}));
export let DecorativeBgComponent;
(function (DecorativeBgComponent) {
  DecorativeBgComponent["Circles"] = "Circles";
  DecorativeBgComponent["CirclesWithStars"] = "CirclesWithStars";
})(DecorativeBgComponent || (DecorativeBgComponent = {}));