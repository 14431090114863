import { getFullUrl } from 'hubspot-url-utils';
import { FormType } from 'signup-ui-lego-core/constants/FormType';
import { getUtk } from 'usage-tracker-core/common/defaultTrackers';
const FORM_TITLE = {
  [FormType.SignupCompleted]: 'HubSpot Signup Form',
  [FormType.SignupInterest]: 'HubSpot Signup Interest Form'
};
export const getSubmitContext = (formType = FormType.SignupCompleted) => {
  const utk = getUtk();
  return Object.assign({
    pageUri: `${getFullUrl('app')}/signup-hubspot/`,
    pageName: FORM_TITLE[formType]
  }, utk ? {
    hutk: utk
  } : {});
};