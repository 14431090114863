import { Step } from 'signup-constants/Step';
import { FlowStateManager } from '../../flow-management/FlowStateManager';
import { shouldAddHubletStep } from '../../hublets/hubletUtils';
export const BranchAddHublet = {
  name: 'BranchAddHublet',
  stateCheck: state => {
    return shouldAddHubletStep(state);
  },
  rebuildFlow: (flow, state, dispatch) => {
    return dispatch(FlowStateManager.addLastStepsBeforeAccountCreation(Step.HubletSelection));
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: [Step.ExistingAccounts, Step.HublessNoAccount]
};