import { useEffect } from 'react';
import { alertBeforeUnload } from '../routing/alertBeforeUnload';
import { useAppDispatch } from './useAppDispatch';
export const useUnloadAlert = ({
  shouldAdd = false
} = {}) => {
  const dispatch = useAppDispatch();
  const unloadAlert = alertBeforeUnload(dispatch);
  useEffect(() => {
    if (shouldAdd) {
      unloadAlert.addAlert();
    }
  }, [shouldAdd, unloadAlert]);
  return {
    unloadAlert
  };
};