import { getFullUrl } from 'hubspot-url-utils';
export const prefetchPage = (pagePrefetchRoute, options = {}) => {
  const iframeId = `prefetcher-${pagePrefetchRoute.replace(/^\/+/, '')}`;
  // Prevent duplicate appending iframe
  if (document.getElementById(iframeId)) {
    return;
  }
  const {
    url = getFullUrl('app')
  } = options;
  const iframe = document.createElement('iframe');
  iframe.id = iframeId;
  iframe.src = `${url}/${pagePrefetchRoute}`;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
};