import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getIntentLink } from '../intents/intentSelectors';
import PortalIdContainer from '../portal-id-container/PortalIdContainer';
import { getRouteParams } from '../routing/getRouteParams';
import { getHublet } from '../store/app/selectors';
import { AuthActionTypes } from '../store/auth/action-types';
import { getPortalId } from '../store/auth/selectors';
import { getSurveyDataForRedirect } from '../store/signup-data/selectors';
import { redirectToUrl } from '../utils/redirectToUrl';
import { getSpecificRedirect } from './getFlowSpecificRedirect';
import { getPortalHomeUrl } from './getPortalHomeUrl';
import { getRedirectSignupType } from './getRedirectSignupType';
import { getShouldRedirectWindow } from './getShouldRedirectWindow';
import { redirectToIntent } from './redirectToIntent';
import { removeUnloadPopup } from './removeUnloadPopup';
export const redirectExistingUser = (store, action, setIsProcessing) => {
  const {
    intentKey
  } = getRouteParams();
  setIsProcessing(true);
  removeUnloadPopup(store);
  const state = store.getState();
  let portalId = getPortalId(state);
  let hublet = getHublet(state);
  if (action.type === AuthActionTypes.RedirectExistingUser && !getFlowConfig().isUserOnlyFlow && action.payload.portalId && action.payload.hublet) {
    portalId = action.payload.portalId;
    hublet = action.payload.hublet;
  }
  const survey = getSurveyDataForRedirect(state);
  if (portalId) {
    PortalIdContainer.set(portalId);
  }
  const intentLink = getIntentLink(state, portalId, hublet);
  const specificRedirect = getSpecificRedirect();
  const shouldRedirectWindow = getShouldRedirectWindow();
  let redirectLink;
  if (specificRedirect) {
    redirectLink = specificRedirect(state);
  } else if (intentKey && intentLink) {
    redirectLink = redirectToIntent({
      intentLink,
      survey,
      portalId,
      hublet,
      signupType: getRedirectSignupType()
    });
  } else {
    redirectLink = getPortalHomeUrl(portalId, hublet);
  }
  store.dispatch(redirectToUrl(redirectLink, shouldRedirectWindow));
  setIsProcessing(false);
  return Promise.resolve();
};