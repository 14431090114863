import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getSignupApiEndpoint } from '../http-clients/getSignupApiEndpoint';
import { EndpointPath } from '../http-clients/signupClientPaths';
import { getInitialInterestBody } from '../hubspot-forms/getInitialInterestBody';
export const initialInterest = state => {
  const url = getSignupApiEndpoint(EndpointPath.initialInterest);
  const initialInterestBody = getInitialInterestBody(state);
  return noAuthApiClient.post(url, {
    data: initialInterestBody
  });
};