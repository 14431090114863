/**
 * Microsoft Platform Library Wrapper and Initializator.
 *
 * @class MicrosoftPlatform
 */
class MicrosoftPlatform {
  /**
   * Creates an instance of MicrosoftPlatform.
   * @memberof MicrosoftPlatform
   */
  constructor() {
    this._loadPromiseMicrosoftIdentityServices = null;
    this._loadTimeoutMicrosoftIdentityServices = undefined;
  }

  /**
   * Register a new callback to be called right after
   * the Microsoft Sign In script initializes
   *
   * @memberof MicrosoftPlatform
   */
  registerMicrosoftIdentityServicesCallback(resolve, reject) {
    const w = window;
    this._loadTimeoutMicrosoftIdentityServices = setTimeout(() => {
      if (!w.msal) {
        reject('Load microsoft-platform script timed out.');
      }
    }, 60 * 1000 * 2); // 2 minutes
  }
  /**
   * Loads the Microsoft Identity Services.
   *
   * @returns {Promise} A Promise instance to be resolved when the script initializes.
   * @memberof MicrosoftPlatform
   */
  loadMicrosoftIdentityServices() {
    if (!this._loadPromiseMicrosoftIdentityServices) {
      this._loadPromiseMicrosoftIdentityServices = new Promise((resolve, reject) => {
        if (!window.msal) {
          this.registerMicrosoftIdentityServicesCallback(resolve, reject);
          const script = document.createElement('script');
          script.addEventListener('load', () => {
            clearTimeout(this._loadTimeoutMicrosoftIdentityServices);
            resolve(true);
          });
          script.id = MicrosoftPlatform.MS_CLIENT_SCRIPT_ID;
          script.async = true;
          script.defer = true;
          script.crossOrigin = 'anonymous';
          script.src = MicrosoftPlatform.MS_CLIENT_SCRIPT;
          script.integrity = MicrosoftPlatform.MS_INTEGRITY;
          document.body.appendChild(script);
        } else {
          resolve(true);
        }
      });
    }
    return this._loadPromiseMicrosoftIdentityServices;
  }
  reset() {
    this._loadPromiseMicrosoftIdentityServices = null;
    this._loadTimeoutMicrosoftIdentityServices = undefined;
  }
}
MicrosoftPlatform.MS_CLIENT_SCRIPT = 'https://alcdn.msauth.net/browser/2.32.1/js/msal-browser.min.js';
MicrosoftPlatform.MS_CLIENT_SCRIPT_ID = 'load-msal';
MicrosoftPlatform.MS_INTEGRITY = 'sha384-UrQz8fjd/68UVLcPZl2ZTrABZEfujnPqnuQt7CV6903eSw62F3KEsOwGudSw2A9Q';
export default new MicrosoftPlatform();