import { startupApplication, startupPartnersApplication } from './productLinks';
import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { getPortalId } from '../store/auth/selectors';
import { getHublet, getIsNewPortalCreated } from '../store/app/selectors';
import { getIntentLink } from '../intents/intentSelectors';
export const getHubSpotForStartupsRedirect = state => {
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  const intentLink = getIntentLink(state);
  const isNewPortal = getIsNewPortalCreated(state);
  return makeLinkWithQueryParams({
    link: startupApplication({
      portalId,
      hublet
    }),
    queryParamsToInclude: {
      via: isNewPortal ? 'hubspot-for-startups-signup' : 'hubspot-for-startups-existing-account',
      redirect: intentLink
    },
    hublet
  });
};
export const getHubSpotPartnersRedirect = state => {
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  const intentLink = getIntentLink(state);
  const isNewPortal = getIsNewPortalCreated(state);
  return makeLinkWithQueryParams({
    link: startupPartnersApplication({
      portalId,
      hublet
    }),
    queryParamsToInclude: {
      via: isNewPortal ? 'hubspot-partners-signup' : 'hubspot-partners-existing-account',
      redirect: intentLink
    },
    hublet
  });
};