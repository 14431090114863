import { SignupEmbedderOptions } from 'signup-embedder';
import { canUseExistingUserSignupEndpoint, isCodeVerificationSignup } from '../app/verificationTypeSelectors';
import { getSignupApiEndpoint } from './getSignupApiEndpoint';
export let EndpointPath;
(function (EndpointPath) {
  EndpointPath["initiateVerification"] = "initiateVerification";
  EndpointPath["initiateCodeVerification"] = "initiateCodeVerification";
  EndpointPath["completeVerification"] = "completeVerification";
  EndpointPath["completeCodeVerification"] = "completeCodeVerification";
  EndpointPath["signupExisting"] = "signup/existing";
  EndpointPath["signup"] = "signup";
  EndpointPath["signupCode"] = "signupCode";
  EndpointPath["postSignup"] = "postSignup";
  EndpointPath["initialInterest"] = "initialInterest";
})(EndpointPath || (EndpointPath = {}));
export const getInitiateVerificationPath = () => {
  const shouldEnforceEmailVerification = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_ENFORCE_EMAIL_VERIFICATION);
  const initiateVerificationApiPath = shouldEnforceEmailVerification ? EndpointPath.initiateVerification : EndpointPath.initiateCodeVerification;
  return getSignupApiEndpoint(initiateVerificationApiPath);
};
export const getCompleteVerificationPath = state => {
  const completeVerificationApiPath = isCodeVerificationSignup(state) ? EndpointPath.completeCodeVerification : EndpointPath.completeVerification;
  return getSignupApiEndpoint(completeVerificationApiPath);
};
export const getSignupPath = state => {
  if (canUseExistingUserSignupEndpoint(state)) {
    return getSignupApiEndpoint(EndpointPath.signupExisting);
  }
  if (isCodeVerificationSignup(state)) {
    return getSignupApiEndpoint(EndpointPath.signupCode);
  }
  return getSignupApiEndpoint(EndpointPath.signup);
};