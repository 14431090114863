import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { getApplicationLocale } from '../i18n/LocaleUtils';
import imageUrls from '../images/imageUrls';
import { ap1, eu1, na1, na2, na3 } from 'hubspot-url-utils/hublets-next';
const KnowledgeBaseLanguage = {
  [Locale.ja]: 'jp',
  [Locale.ptBr]: 'pt'
};
export const getKnowledgeBaseLink = (locale = getApplicationLocale()) => {
  let knowledgeArticleUrl = 'https://knowledge.hubspot.com';
  if (locale !== Locale.en) {
    const articleLanguage = KnowledgeBaseLanguage[locale] || locale;
    knowledgeArticleUrl = `${knowledgeArticleUrl}/${articleLanguage}`;
  }
  knowledgeArticleUrl = `${knowledgeArticleUrl}/account/hubspot-cloud-infrastructure-and-data-hosting-frequently-asked-questions`;
  return knowledgeArticleUrl;
};
export const HubletToImageUrl = {
  // eslint-disable-next-line hubspot-dev/no-hublet-references
  [eu1]: imageUrls.hubletEu,
  // eslint-disable-next-line hubspot-dev/no-hublet-references
  [na1]: imageUrls.hubletUS,
  // eslint-disable-next-line hubspot-dev/no-hublet-references
  [na2]: imageUrls.hubletUS,
  // eslint-disable-next-line hubspot-dev/no-hublet-references
  [na3]: imageUrls.hubletNA3,
  // eslint-disable-next-line hubspot-dev/no-hublet-references
  [ap1]: imageUrls.hubletAP1
};