import { parse, stringify } from 'hub-http/helpers/params';
import { buildUrl, parseUrl } from 'hub-http/helpers/url';
export function getSearchParams() {
  return parse(window.location.search.slice(1));
}

/**
 * Adds a map of query params into a url's query string
 * @param {String} url the base url
 * @param {Object} params an object representing query params
 * @returns {String}
 */
export function mergeQueryParamsToUrl(url, params) {
  if (!params) {
    return url;
  }
  const parsedUrl = parseUrl(url);
  const urlQuery = parsedUrl.query;
  const parsedUrlQuery = parse(urlQuery);
  parsedUrl.query = stringify(Object.assign({}, parsedUrlQuery, params));
  return buildUrl(parsedUrl);
}