import { Step } from 'signup-constants/Step';
import { FlowActionTypes } from '../store/flow/action-types';
import { getNextSteps } from '../store/flow/selectors';
import { isEndpointStatusError } from '../store/http-responses/selectors';
import { EndpointKey } from '../http/EndpointKey';
import { hasIntersection } from '../utils/data-structure-utils/hasIntersection';
import { getAppKeyValue } from '../store/app/selectors';
import { AppKey } from '../app/AppKey';
const PREFILLABLE_STEPS = [Step.Industry, Step.CompanyDomain, Step.CompanyName];
export const shouldRetryCompanyInfo = (state, action) => {
  const nextSteps = getNextSteps(state);
  const isStepCompletedActionType = action.type === FlowActionTypes.StepCompleted;
  const hasCompanyInfoFailed = isEndpointStatusError(EndpointKey.CompanyInfo)(state);
  const somesNextStepsCanBePrefilled = hasIntersection(nextSteps, PREFILLABLE_STEPS);
  const isFreeEmailDomain = getAppKeyValue(AppKey.IsFreeEmailDomain)(state);
  return isStepCompletedActionType && hasCompanyInfoFailed && somesNextStepsCanBePrefilled && !isFreeEmailDomain;
};