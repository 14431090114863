import { EventName } from './EventName';
import { getTracker } from './getTracker';

/**
 * @description Tracking Middleware - if any of the below actions are dispatched,
 * a correspoinding event will be tracked, or added to the queue.
 */

export const trackEvent = (name, properties = {}, sendAsBeacon = false) => {
  getTracker().track(name, properties, sendAsBeacon);
};
export const trackInteraction = ({
  action,
  properties = {},
  sendAsBeacon = false
}) => trackEvent(EventName.Interaction, Object.assign({
  action
}, properties), sendAsBeacon);