import { useSelector } from 'react-redux';
import { useSignupEmbedder } from 'signup-embedder/hooks/useSignupEmbedder';
import { isGoogleSignup } from '../app/verificationTypeSelectors';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { getActiveError } from '../store/error/selectors';
import { getPostAccountCreationSteps, getPrevFlowStates, getPreviousStep } from '../store/flow/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { getStepConfig } from '../views/getStepConfig';
import { Step } from 'signup-constants/Step';
export const useCanGoBack = () => {
  const {
    isPopup
  } = useSignupEmbedder();
  const {
    currentStep
  } = useCurrentStep();
  const stepConfig = getStepConfig(currentStep);
  const prevFlowStates = useSelector(getPrevFlowStates);
  const activeError = useSelector(getActiveError);
  const activeLoading = useSelector(getActiveLoading);
  const googleSignup = useSelector(isGoogleSignup);
  const previousStep = useSelector(getPreviousStep);
  const postAccountCreation = useSelector(getPostAccountCreationSteps);
  if (previousStep === null || !stepConfig) {
    return {
      canGoBack: false,
      completedStep: null
    };
  }
  const isGoogleFallback = isPopup && googleSignup;
  const isFirstStepAfterGoogleAuthFallback = [Step.LandingPage, Step.ExistingUser].includes(previousStep) && isGoogleFallback;
  const firstStepPostAccountCreation = postAccountCreation[0];
  const isFirstStepPostAccountCreation = firstStepPostAccountCreation === currentStep;
  const isPrevFlowStatesPopulated = prevFlowStates.length !== 0;
  const canGoBack = !activeLoading && !activeError && isPrevFlowStatesPopulated && stepConfig && !stepConfig.hideBackButton && !isFirstStepPostAccountCreation && !isFirstStepAfterGoogleAuthFallback;
  const lastIndex = prevFlowStates.length - 1;
  const {
    completedStep
  } = prevFlowStates[lastIndex];
  return {
    canGoBack,
    completedStep
  };
};