import { runReducersFirst } from '../http/runReducersFirst';
import { clearLoadingStatus } from '../store/loading-status/action-creators';
import { LoadingStatusActionTypes } from '../store/loading-status/action-types';
import { checkShouldClearLoading, getLoadingStack } from '../store/loading-status/selectors';
export const ClearLoadingMiddleware = store => next => action => {
  const dispatchedAction = runReducersFirst(next, action);
  const loadingStack = getLoadingStack(store.getState());
  loadingStack.forEach(loadingKey => {
    if (loadingKey && checkShouldClearLoading(store.getState(), loadingKey) && !(action.type === LoadingStatusActionTypes.ClearLoadingStatus)) {
      store.dispatch(clearLoadingStatus(loadingKey));
    }
  });
  return dispatchedAction;
};