import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { requestIdleCallback, cancelIdleCallback } from '../utils/idleCallback';
import { loadScript } from '../utils/loadScript';
const LOG = Logger.getLogger('setupTalon');
let idleCallbackId = -1;
let requestAnimationFrameId = -1;
let talonScriptPromise = null;
function loadTalon(src) {
  return loadScript(src, 'talon-script', true, true).then(() => {
    const talonCallback = window.eHawkTalon;
    if (!talonCallback) {
      throw new Error('Talon callback no found on window object.');
    }
    return talonCallback;
  }).then(talonCallback => {
    return new Promise(resolve => {
      idleCallbackId = requestIdleCallback(() => {
        talonCallback();
        resolve(null);
      }, {
        timeout: 2000
      });
    });
  }).catch(error => {
    LOG.error('Error loading the talon script.', {
      extra: getSentryExtra(error)
    });
    throw error;
  });
}
export const setupTalon = () => {
  const talonScriptMetaTag = document.querySelector('meta[name="eHawkTalonScript"]');
  // We get the bundle location from a meta tag located in the index.html.jade file.
  if (!talonScriptMetaTag) {
    return Promise.resolve(null);
  }
  try {
    // Because the fingerprint calculation is very expensive we do this inside a
    // requestAnimationFrame callback to prevent the main thread to be frozen during
    // the process.
    return new Promise((resolve, reject) => {
      requestAnimationFrameId = requestAnimationFrame(() => {
        const src = talonScriptMetaTag.content;
        talonScriptPromise = loadTalon(src).then(resolve).catch(reject);
      });
    });
  } catch (error) {
    LOG.error('Error executing talon script.', {
      extra: getSentryExtra(error)
    });
    cleanUpTalon();
    if (talonScriptPromise !== null) {
      return talonScriptPromise.finally(() => {
        talonScriptPromise = null;
      });
    }
    return Promise.resolve(error);
  }
};
export function cleanUpTalon() {
  if (requestAnimationFrameId) {
    cancelAnimationFrame(requestAnimationFrameId);
    requestAnimationFrameId = -1;
  }
  if (idleCallbackId) {
    cancelIdleCallback(idleCallbackId);
    idleCallbackId = -1;
  }
}