import { useEffect } from 'react';
import { getFlowKey } from '../routing/getFlowKey';
import { startApp } from '../store/app/action-creators';
import { useAppDispatch } from './useAppDispatch';
export const useStartApp = () => {
  const flowKey = getFlowKey();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (flowKey) {
      dispatch(startApp());
    }
  }, [flowKey, dispatch]);
};