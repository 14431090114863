import { Flow } from 'signup-constants/Flow';
import { Data } from 'signup-constants/signupData/Data';
import { ErrorKey } from '../app-active-error/ErrorKey';
import { LoadingKey } from '../app-active-loading/LoadingKey';
import SignupClient from '../http-clients/SignupClient';
import { handleSignupError } from '../http-signup/handleSignupError';
import { handleSignupSuccess } from '../http-signup/handleSignupSuccess';
import { isFlow } from '../utils/isFlow';
import { fetchRegisteredLeads } from '../standalone-cms-partner/fetchRegisteredLeads';
import { handleRegisteredLeadsSuccess } from '../standalone-cms-partner/handleRegisteredLeadsSuccess';
import { AuthActionTypes } from '../store/auth/action-types';
import { setError } from '../store/error/action-creators';
import { hasAnsweredAllPostExistingAccountSelectedQuestions } from '../store/signup-data/selectors';
import { EndpointKey } from './EndpointKey';
import { getHasPaidProducts } from '../store/portal-tier/selectors';
export const EndpointsStandaloneCmsPartner = {
  [EndpointKey.PartnerRegistrationLeadLookup]: {
    method: fetchRegisteredLeads,
    handleSuccess: handleRegisteredLeadsSuccess,
    reduxActionType: AuthActionTypes.PortalSelected,
    stateCheck: () => isFlow(Flow.StandaloneCmsPartner),
    loadingKey: LoadingKey.StandardLoading,
    handleError: (error, dispatch) => {
      dispatch(setError(ErrorKey.PartnerNoRegisteredLeads));
    }
  },
  [EndpointKey.Signup]: {
    stateCheck: state => isFlow(Flow.StandaloneCmsPartner) && hasAnsweredAllPostExistingAccountSelectedQuestions(state),
    getRequestData: state => {
      return [Data.Email, Data.CompanyDomain, Data.CompanyName, ...(getHasPaidProducts(state) ? [Data.HubletSelection] : [])];
    },
    handleError: handleSignupError,
    handleSuccess: handleSignupSuccess,
    loadingKey: LoadingKey.SignupRequest,
    method: SignupClient.signup
  }
};