import { getIsPostVerification } from '../../routing/getIsPostVerification';
import { earlyRequestClient } from '../earlyRequestorClient';
export const HUBLESS_INFO_REQUEST_KEY = 'hubless-info';
export const HUBLESS_INFO_URL = '/users/v2/app/users/plus-hub-ids/hubless';
export const makeHublessEarlyRequest = () => {
  const isPostVerificationFlow = getIsPostVerification();
  if (!isPostVerificationFlow) {
    earlyRequestClient.get({
      apiUrl: `${HUBLESS_INFO_URL}`,
      earlyRequestKey: HUBLESS_INFO_REQUEST_KEY
    });
  }
};