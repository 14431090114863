import { useEffect } from 'react';
import { getCurrentDomain } from '../constants/apiEnvironments';
import { getFlowKey } from '../routing/getFlowKey';
import { getAppleAuthClient, getAppleClientId } from './appleUtils';
export const useLoadAppleAuth = nonceForAppleClient => {
  const appleAuthClient = getAppleAuthClient();
  useEffect(() => {
    const appleConfig = {
      clientId: getAppleClientId(),
      redirectURI: `${getCurrentDomain()}/signup-hubspot/${getFlowKey()}`,
      scope: 'name email',
      usePopup: true,
      nonce: nonceForAppleClient
    };
    if (appleAuthClient && nonceForAppleClient) {
      appleAuthClient.auth.init(appleConfig);
    }
  }, [appleAuthClient, nonceForAppleClient]);
};