import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { copyCarouselHasFinished } from '../store/loading-status/action-creators';
import { doesCarouselTitleExist, getCarouselTitle } from './carouselUtils';
let titleIndex = 1;
const copySwitchingInterval = 2000;
export const useCopyCarousel = ({
  baseI18n,
  loadingKey,
  canSwitchCopy = true,
  textDisplayDelay = 0,
  shouldGoRound = false,
  textSwitchingInterval = copySwitchingInterval,
  setTimeoutForCopyCarousel = 0
}) => {
  const firstI18n = `${baseI18n}_1`;
  const hasCopyCaroussel = isValidI18nKey(firstI18n);
  const [copyToDisplay, setCopyToDisplay] = useState(firstI18n || baseI18n);
  const dispatch = useDispatch();
  useEffect(() => {
    let timeoutId;
    let intervalId;
    let timeoutCopyCarouselFinsihed;
    if (hasCopyCaroussel) {
      timeoutId = setTimeout(() => {
        intervalId = setInterval(() => {
          if (canSwitchCopy) {
            titleIndex += 1;
            if (!doesCarouselTitleExist(baseI18n, titleIndex)) {
              if (loadingKey) {
                if (setTimeoutForCopyCarousel === 0) {
                  dispatch(copyCarouselHasFinished(loadingKey));
                } else {
                  timeoutCopyCarouselFinsihed = setTimeout(() => dispatch(copyCarouselHasFinished(loadingKey)), setTimeoutForCopyCarousel);
                }
              }
              titleIndex = shouldGoRound ? 1 : titleIndex - 1;
              if (!shouldGoRound) {
                clearInterval(intervalId);
                intervalId = -1;
              }
            }
            setCopyToDisplay(getCarouselTitle(baseI18n, titleIndex));
          }
        }, textSwitchingInterval);
      }, textDisplayDelay);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (timeoutCopyCarouselFinsihed) {
        clearTimeout(timeoutCopyCarouselFinsihed);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (titleIndex > 1) {
        titleIndex = 1;
      }
    };
  }, [baseI18n, hasCopyCaroussel, loadingKey, dispatch, canSwitchCopy, textDisplayDelay, shouldGoRound, textSwitchingInterval, setTimeoutForCopyCarousel]);
  return {
    copyToDisplay
  };
};