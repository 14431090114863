import { getAppleAuthClient } from './appleUtils';

/**
 * Apple ID Library Wrapper and Initializator.
 *
 * @class ApplePlatform
 */
class ApplePlatform {
  /**
   * Creates an instance of ApplePlatform.
   * @memberof ApplePlatform
   */
  constructor() {
    this._loadPromiseAppleIdServices = null;
    this._loadTimeoutAppleIdServices = undefined;
  }

  /**
   * Register a new callback to be called right after
   * the Appled Id script initializes
   *
   * @memberof ApplePlatform
   */
  registerAppleIdCallback(resolve, reject) {
    const appleClient = getAppleAuthClient();
    this._loadTimeoutAppleIdServices = setTimeout(() => {
      if (!appleClient) {
        reject('Load apple id script timed out.');
      }
    }, 60 * 1000 * 2); // 2 minutes
  }
  /**
   * Loads the Appled Id Script.
   *
   * @returns {Promise} A Promise instance to be resolved when the script initializes.
   * @memberof ApplePlatform
   */
  loadAppleId() {
    if (!this._loadPromiseAppleIdServices) {
      this._loadPromiseAppleIdServices = new Promise((resolve, reject) => {
        const appleClient = getAppleAuthClient();
        if (!appleClient) {
          this.registerAppleIdCallback(resolve, reject);
          const script = document.createElement('script');
          script.addEventListener('load', () => {
            clearTimeout(this._loadTimeoutAppleIdServices);
            resolve(true);
          });
          script.async = true;
          script.defer = true;
          script.src = ApplePlatform.APPLE_ID_SCRIPT;
          document.body.appendChild(script);
        } else {
          resolve(true);
        }
      });
    }
    return this._loadPromiseAppleIdServices;
  }
  reset() {
    this._loadPromiseAppleIdServices = null;
    this._loadTimeoutAppleIdServices = undefined;
  }
}
ApplePlatform.APPLE_ID_SCRIPT = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
export default new ApplePlatform();