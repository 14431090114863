import { useSelector } from 'react-redux';
import { getActiveError } from '../store/error/selectors';
import { getPostAccountCreationSteps } from '../store/flow/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { getStepConfig } from '../views/getStepConfig';
import { useCurrentStep } from './useCurrentStep';
export const useIsWideStep = () => {
  const activeLoading = useSelector(getActiveLoading);
  const postAccountCreation = useSelector(getPostAccountCreationSteps);
  const {
    currentStep
  } = useCurrentStep();
  const activeError = useSelector(getActiveError);
  const step = activeError || currentStep;
  const stepConfig = getStepConfig(step);
  const isWideStep = stepConfig ? stepConfig.isWideStep : false;
  const isPostAccountCreationStep = postAccountCreation.includes(currentStep);
  if (activeLoading) {
    return {
      isWideStep: true
    };
  }
  if (isPostAccountCreationStep) {
    return {
      isWideStep
    };
  }
  return {
    isWideStep
  };
};