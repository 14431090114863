import { setUpOnboardingConfig } from 'growth-onboarding-signup-redirect/redirect';
import { getIntentLink } from '../intents/intentSelectors';
import { getHublet, getIsNewUser } from '../store/app/selectors';
import { getAuth, getPortalId } from '../store/auth/selectors';
import { getSurveyDataForRedirect } from '../store/signup-data/selectors';
import { getOnboardingUrl } from './getOnboardingUrl';
import { getRedirectSignupType } from './getRedirectSignupType';
import { getSpecificEnrollment, getSpecificRedirect } from './getFlowSpecificRedirect';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { getFlowKey } from '../routing/getFlowKey';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import getOnboardingRedirectOptions from './getOnboardRedirectOptions';
import { getActiveExperiments } from '../experiments/ExperimentUtils';
import { getCurrentStepNumbering } from '../flow-management/stepNumberingSelectors';
const LOG = Logger.getLogger('getRedirectMethod');
export const getRedirectMethod = state => {
  const portalId = getPortalId(state);
  const auth = getAuth(state);
  const hublet = getHublet(state);
  const survey = getSurveyDataForRedirect(state);
  const intentLink = getIntentLink(state);
  const signupType = getRedirectSignupType();
  const specificRedirect = getSpecificRedirect();
  const specificEnrollment = getSpecificEnrollment();
  const flowKey = getFlowKey();
  const integrationKey = getIntegrationKey();
  const isNewUser = getIsNewUser(state);
  const activeExperiments = getActiveExperiments(state);
  const {
    currentStep: signupStepNumber
  } = getCurrentStepNumbering(state);
  return specificRedirect ? () => {
    const maybeSpecificOnboardingConfigSetup = specificEnrollment ? setUpOnboardingConfig(getOnboardingRedirectOptions(portalId, specificEnrollment, hublet, auth, signupStepNumber, survey)) : Promise.resolve();
    return maybeSpecificOnboardingConfigSetup.then(() => specificRedirect(state)).catch(error => {
      LOG.error(`Failed to build specific redirect for flow: ${flowKey} ${integrationKey}`, {
        extra: getSentryExtra(error)
      });
      return Promise.reject(error);
    });
  } : () => {
    return getOnboardingUrl({
      portalId,
      auth,
      survey,
      intentLink,
      hublet,
      signupExperimentTreatments: activeExperiments
    }, signupType, isNewUser, signupStepNumber);
  };
};