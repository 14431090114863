export let Data;
(function (Data) {
  Data["CompanyDomain"] = "COMPANY_DOMAIN";
  Data["CompanyName"] = "COMPANY_NAME";
  Data["CompanySize"] = "COMPANY_SIZE";
  Data["CrmExperienceLevel"] = "CRM_EXPERIENCE_LEVEL";
  Data["Email"] = "EMAIL";
  Data["FirstName"] = "FIRST_NAME";
  Data["Industry"] = "INDUSTRY";
  Data["JobField"] = "JOB_FIELD";
  Data["JobRole"] = "JOB_ROLE";
  Data["LastName"] = "LAST_NAME";
  Data["Password"] = "PASSWORD";
  Data["Verification"] = "VERIFICATION";
  Data["CodeVerification"] = "CODE_VERIFICATION";
  Data["Token"] = "TOKEN";
  Data["HubInterest"] = "HUB_INTEREST";
  Data["HubletSelection"] = "HUBLET_SELECTION";
  Data["IntegratedAppRecaptchaToken"] = "INTEGRATED_APP_RECAPTCHA_TOKEN";
  Data["Country"] = "COUNTRY";
  Data["PhoneNumber"] = "PHONE_NUMBER";
  Data["OptionalPhoneNumber"] = "OPTIONAL_PHONE_NUMBER";
  Data["GoogleIdToken"] = "GoogleIdToken";
  Data["MicrosoftAccessToken"] = "MicrosoftAccessToken";
  Data["AppleToken"] = "AppleToken";
  Data["IntegrationsAccountCreatedCheck"] = "INTEGRATIONS_ACCOUNT_CREATED_CHECK";
  Data["InstallSalesExtension"] = "INSTALL_SALES_EXTENSION";
  Data["CmsSignupIntent"] = "CMS_SIGNUP_INTENT";
  Data["CmsBusinessMaturity"] = "CMS_BUSINESS_MATURITY";
  Data["MicrosoftLoginIdToken"] = "MICROSOFT_LOGIN_ID_TOKEN";
  Data["ManagementLevel"] = "MANAGEMENT_LEVEL";
  Data["ProblemAwareness"] = "PROBLEM_AWARENESS";
  Data["ProductExperience"] = "PRODUCT_EXPERIENCE";
  Data["GoogleAccountId"] = "GOOGLE_ACCOUNT_ID";
  Data["UseCase"] = "USE_CASE";
})(Data || (Data = {}));