import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidationStatus } from 'signup-ui-lego-core/types';
import { setSignupDataCompleted, setSignupDataValue, setStepAsSkipped } from '../store/signup-data/action-creators';
import { getSignupDataErrorMessage, getSignupDataPrefilled, getSignupDataSkipped, getSignupDataValidation, getSignupDataValue } from '../store/signup-data/selectors';
export const useSignupData = (dataKey, shouldTrimData = false) => {
  const dispatch = useDispatch();
  const data = useSelector(state => getSignupDataValue(state, dataKey));
  const validationState = useSelector(state => getSignupDataValidation(state, dataKey));
  const skipped = useSelector(state => getSignupDataSkipped(state, dataKey));
  const errorMessage = useSelector(state => getSignupDataErrorMessage(state, dataKey));
  const isPrefilled = useSelector(state => getSignupDataPrefilled(state, dataKey));
  const setData = useCallback(value => {
    const dataValue = shouldTrimData ? value.trim() : value;
    dispatch(setSignupDataValue(dataKey, dataValue));
  }, [dataKey, dispatch, shouldTrimData]);
  const setDataCompleted = useCallback(() => dispatch(setSignupDataCompleted(dataKey)), [dataKey, dispatch]);
  const setSkipped = useCallback(() => {
    dispatch(setStepAsSkipped(dataKey));
  }, [dispatch, dataKey]);
  return {
    data,
    setData,
    setDataCompleted,
    skipped,
    setSkipped,
    isValid: validationState === ValidationStatus.VALID,
    isInvalid: validationState === ValidationStatus.INVALID,
    errorMessage,
    isPrefilled
  };
};