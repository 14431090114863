import { FormType } from 'signup-ui-lego-core/constants/FormType';
import { getActiveExperimentsParameters } from '../experiments/ExperimentUtils';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getApplicationLocale, getLocaleName } from '../i18n/LocaleUtils';
import { getPreferredReferrer } from '../i18n/ReferrerUtils';
import { getIntegrationOrFlowKey } from '../routing/getIntegrationOrFlowKey';
import { getQueryParams } from '../routing/getQueryParams';
import { Data } from 'signup-constants/signupData/Data';
import { getVerificationType } from '../store/app/selectors';
import { getFlatSignupDataValueMap } from '../store/signup-data/selectors';
import getAppFromLoginRedirectUrl from '../tracking/getAppFromLoginRedirectUrl';
import { getSignupMode } from '../tracking/getSignupMode';
import { getUserDeviceType } from '../utils/deviceUtils';
import trimEmptyValuesFromObject from '../utils/trimEmptyValuesFromObject';
import { getUrlData } from '../utils/urls';
import { parseUtmData } from '../utm/utmHelpers';
import { getLegalConsentOptionsForSignupRequest } from './form-data-utils/getLegalConsentOptions';
import { getSubmitContext } from './form-data-utils/getSubmitContext';
import { getMicroappKey } from '../routing/getMicroappKey';
export const getInitialInterestBody = state => {
  const signupData = getFlatSignupDataValueMap(state);
  const queryParams = getQueryParams();
  const utms = parseUtmData(queryParams);
  const signupContext = getSubmitContext(FormType.SignupInterest);
  const referrer = getPreferredReferrer();
  const referrerData = getUrlData(referrer);
  const experimentParameters = getActiveExperimentsParameters(state);
  const requiredData = {
    email: signupData[Data.Email],
    flowKey: getIntegrationOrFlowKey()
  };
  const signupProperties = {
    signupType: getFlowConfig().appName,
    signupMode: getSignupMode(),
    signupVersion: 'odyssey',
    signupDevice: getUserDeviceType()
  };
  const microapp = getMicroappKey();
  const optionalData = trimEmptyValuesFromObject(Object.assign({
    firstName: signupData[Data.FirstName],
    lastName: signupData[Data.LastName],
    pageUri: signupContext.pageUri,
    pageName: signupContext.pageName,
    hutk: signupContext.hutk,
    legalConsent: getLegalConsentOptionsForSignupRequest(),
    locale: getLocaleName(),
    shouldSkipValidation: true,
    queryParams,
    signupProperties,
    languageOptions: getApplicationLocale(),
    signupVerificationType: getVerificationType(state),
    applicationId: getAppFromLoginRedirectUrl()
  }, experimentParameters.length !== 0 && {
    experimentParameters
  }, Object.keys(utms).length !== 0 && {
    utms
  }, Object.keys(referrerData).length !== 0 && {
    referrerData
  }, microapp && {
    microapp
  }));
  const initialInterestBody = Object.assign({}, requiredData, optionalData);
  return initialInterestBody;
};