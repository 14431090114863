import { Data } from 'signup-constants/signupData/Data';
const DataKeyInputProps = {
  [Data.Email]: {
    type: 'email',
    spellCheck: 'false',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    autoComplete: 'email'
  },
  [Data.FirstName]: {
    type: 'text',
    spellCheck: 'false',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    autoComplete: 'given-name'
  },
  [Data.LastName]: {
    type: 'text',
    spellCheck: 'false',
    autoCorrect: 'off',
    autoCapitalize: 'off',
    autoComplete: 'family-name'
  },
  [Data.Password]: {
    autoComplete: 'new-password'
  },
  [Data.CompanyDomain]: {
    autoComplete: 'url'
  },
  [Data.CompanyName]: {
    autoComplete: 'organization'
  }
};
export const getDataInputProps = dataKey => {
  if (!dataKey || !DataKeyInputProps[dataKey]) {
    return {};
  }
  return DataKeyInputProps[dataKey];
};