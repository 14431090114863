export let ManagementLevel;
(function (ManagementLevel) {
  ManagementLevel["Owner"] = "owner";
  ManagementLevel["ExecutiveTeam"] = "executive-team";
  ManagementLevel["Manager"] = "manager";
  ManagementLevel["Employee"] = "employee";
  ManagementLevel["StudentIntern"] = "student-intern";
  ManagementLevel["Freelancer"] = "freelancer";
  ManagementLevel["Other"] = "other";
})(ManagementLevel || (ManagementLevel = {}));
export const ManagementLevelOptions = Object.values(ManagementLevel).filter(option => option !== ManagementLevel.Other);
export const ManagementLevelFormValue = {
  [ManagementLevel.Owner]: 'Owner',
  [ManagementLevel.ExecutiveTeam]: 'Executive Team',
  [ManagementLevel.Manager]: 'Manager',
  [ManagementLevel.Employee]: 'Employee',
  [ManagementLevel.Freelancer]: 'Freelancer',
  [ManagementLevel.StudentIntern]: 'Student/Intern',
  [ManagementLevel.Other]: 'Other'
};
export const JobRoleFromManagementLevel = {
  [ManagementLevel.Owner]: 'other',
  [ManagementLevel.ExecutiveTeam]: 'other',
  [ManagementLevel.Manager]: 'other',
  [ManagementLevel.Employee]: 'sales-rep',
  [ManagementLevel.Freelancer]: 'other',
  [ManagementLevel.StudentIntern]: 'other',
  [ManagementLevel.Other]: 'other'
};