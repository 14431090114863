import { getUniqueOnboardingUrl, performOnboardingSetup } from './utils/redirectUtils';
import { mergeQueryParamsToUrl } from './utils/searchUtils';
import { HubletUrl } from './utils/urls';
import { getDecorationParams, maybeGetReplacementRedirectUrl } from './data/redirectData';
import { maybeMapJobFieldToHubInterest } from './utils/surveyMappingUtils';
export const getOnboardingRedirectUrl = options => {
  const {
    hublet,
    apiUrl,
    appUrl
  } = options;
  HubletUrl.initializeURLs(hublet, appUrl, apiUrl);
  maybeMapJobFieldToHubInterest(options.survey);
  return getUniqueOnboardingUrl(options);
};
export const setUpOnboardingConfig = options => {
  const {
    hublet,
    apiUrl,
    appUrl
  } = options;
  HubletUrl.initializeURLs(hublet, appUrl, apiUrl);
  maybeMapJobFieldToHubInterest(options.survey);
  return performOnboardingSetup(options);
};
export function decorateRedirectUrl({
  url,
  signupType,
  survey = {},
  includeParams = {},
  queryParams = {},
  signupIntent,
  allowUrlReplacement = false,
  hublet,
  appUrl,
  apiUrl
}) {
  HubletUrl.initializeURLs(hublet, appUrl, apiUrl);
  maybeMapJobFieldToHubInterest(survey);
  return mergeQueryParamsToUrl(allowUrlReplacement ? maybeGetReplacementRedirectUrl(url, {
    queryParams,
    signupType
  }) : url, Object.assign({}, includeParams, getDecorationParams({
    intentLink: url,
    signupIntent,
    signupType,
    survey,
    queryParams
  })));
}