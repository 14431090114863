import { ValidationStatus } from '../types';
import { hasEmoji } from './matchers';
export let CompanyNameErrors;
(function (CompanyNameErrors) {
  CompanyNameErrors["required"] = "required";
  CompanyNameErrors["invalid"] = "invalid";
})(CompanyNameErrors || (CompanyNameErrors = {}));
export const syncCompanyNameValidator = companyName => {
  if (!companyName) {
    return CompanyNameErrors.required;
  }
  if (!hasEmoji(companyName)) {
    return ValidationStatus.VALID;
  }
  return CompanyNameErrors.invalid;
};