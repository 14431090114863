const IP_LOOKUP_ENDPOINT = 'https://wtcfns.hubspot.com/ip-lookup';
export const LOOKUP_INTERVAL = 1000;
export const MAX_NUMBER_RETRIES = 5;
export const getVisitorLocaleFromWindow = () => {
  if (window._hsg && window._hsg.visitorLocale) {
    return window._hsg.visitorLocale;
  }
};
const setVisitorLocaleOnWindow = visitorLocale => {
  if (!window._hsg) {
    window._hsg = {};
  }
  window._hsg.visitorLocale = visitorLocale;
};
const fetchVistorLocaleFallback = async () => {
  const response = await fetch(IP_LOOKUP_ENDPOINT);
  if (response.ok) {
    const ipResponse = await response.json();
    const visitorLocale = {
      ip: ipResponse.IP,
      country: ipResponse.GeoIpInfo.country
    };
    setVisitorLocaleOnWindow(visitorLocale);
    return visitorLocale;
  }
  // Fetch promises only reject with a TypeError when a network error occurs.
  // Since 4xx and 5xx response arent network errors, we should throw an error to catch it.
  throw new Error(`Status ${response.status} from ${response.url}`);
};
export const getVisitorLocaleInfo = () => {
  return new Promise((resolve, reject) => {
    let timeoutId;
    const checkVisitorLocale = attempt => {
      const info = getVisitorLocaleFromWindow();
      if (info) {
        clearTimeout(timeoutId);
        resolve(info);
        return;
      }
      if (attempt < MAX_NUMBER_RETRIES) {
        timeoutId = setTimeout(() => checkVisitorLocale(++attempt), LOOKUP_INTERVAL);
      } else {
        clearTimeout(timeoutId);
        fetchVistorLocaleFallback().then(result => {
          resolve(result);
        }).catch(error => {
          reject(Error(`getVisitorLocale fallback fetch failed. ${error.message}`));
        });
      }
    };
    checkVisitorLocale(0);
  });
};