import { Flow } from 'signup-constants/Flow';
import { isFlow } from '../../utils/isFlow';
import { AppActionTypes } from '../app/action-types';
import { TrackingActionTypes } from './action-types';
import { Metrics } from '../../metrics/Metrics';
import { MetricsCounter } from '../../metrics/MetricsCounter';
const signupInteraction = (action, properties = {}, sendAsBeacon = false) => dispatch => {
  dispatch({
    type: TrackingActionTypes.SignupInteraction,
    payload: {
      action,
      properties,
      sendAsBeacon
    }
  });
};
const signupInitiated = () => dispatch => {
  dispatch({
    type: TrackingActionTypes.SignupInitiated
  });
};
const stepView = (currentStep, subscreen) => dispatch => {
  dispatch({
    type: TrackingActionTypes.StepView,
    screen: currentStep,
    subscreen
  });
};
const messagingScrolling = (currentStep, percentage) => dispatch => {
  dispatch({
    type: TrackingActionTypes.MessagingScrolling,
    screen: currentStep,
    percentage
  });
};
const click = ({
  message,
  linkLocation
}) => dispatch => {
  dispatch({
    type: TrackingActionTypes.Click,
    message,
    linkLocation
  });
};
const industryCollected = ({
  industry,
  userInput,
  selectedChoice
}) => dispatch => {
  dispatch({
    type: TrackingActionTypes.IndustryCollected,
    industryName: industry,
    industryId: industry,
    userInput,
    selectedChoice
  });
};
export const invalidDomainCollected = invalidDomain => dispatch => dispatch({
  type: TrackingActionTypes.InvalidDomain,
  invalidDomain
});
const backButton = (fromStep, toStep) => dispatch => {
  dispatch({
    type: TrackingActionTypes.BackButton,
    fromStep,
    toStep
  });
};
const creationError = (actionMessage, statusMessage) => dispatch => {
  dispatch({
    type: TrackingActionTypes.CreationError,
    actionMessage,
    statusMessage
  });
};
const leavePage = message => dispatch => {
  dispatch({
    type: TrackingActionTypes.LeavePage,
    message
  });
};
const authState = message => dispatch => dispatch({
  type: TrackingActionTypes.AuthState,
  message
});
const utmVerification = query => dispatch => {
  dispatch({
    type: TrackingActionTypes.UtmVerification,
    utmMedium: query.utm_medium ? query.utm_medium : 'none',
    utmSource: query.utm_source ? query.utm_source : 'none'
  });
};
const formError = (eventSubtype, error = 'error-unknown') => dispatch => {
  dispatch({
    type: TrackingActionTypes.FormError,
    eventSubtype,
    formError: error
  });
};
const redirect = properties => dispatch => {
  dispatch({
    type: TrackingActionTypes.SignupRedirect,
    url: properties['url']
  });
};
const verifyIdentityStarted = verificationMethod => dispatch => {
  dispatch({
    type: TrackingActionTypes.VerifyIdentityStarted,
    verificationMethod
  });
};
const verifyIdentityCompleted = verificationMethod => dispatch => {
  dispatch({
    type: TrackingActionTypes.VerifyIdentityCompleted,
    verificationMethod
  });
};
const surveyInteraction = () => dispatch => {
  dispatch({
    type: TrackingActionTypes.SurveyInteraction,
    message: isFlow(Flow.Academy) ? 'academy submit survey' : 'new user standardSurvey submitted'
  });
};
export const signupCompleted = () => dispatch => {
  Metrics.counter(MetricsCounter.SignupCompleted).increment();
  return dispatch({
    type: AppActionTypes.SignupCompleted
  });
};
export const surveyCompleted = () => dispatch => dispatch({
  type: TrackingActionTypes.SurveyCompleted
});
export const loginSuccess = numOfRetries => dispatch => dispatch({
  type: TrackingActionTypes.LoginSuccess,
  numOfRetries
});
export const signupPageView = action => dispatch => dispatch({
  type: TrackingActionTypes.SignupPageView,
  action
});
export const Tracker = {
  authState,
  backButton,
  click,
  creationError,
  formError,
  industryCollected,
  leavePage,
  messagingScrolling,
  signupCompleted,
  signupInitiated,
  signupInteraction,
  stepView,
  utmVerification,
  redirect,
  verifyIdentityStarted,
  verifyIdentityCompleted,
  surveyInteraction,
  surveyCompleted,
  loginSuccess,
  invalidDomainCollected,
  signupPageView
};