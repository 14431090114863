import { MicroappActionTypes } from './action-types';
import { Microapp } from 'signup-constants/Microapp';
export const initialMicroappState = {
  [Microapp.InvoiceGenerator]: {
    contactId: null,
    companyId: null,
    invoiceId: null,
    lineItemIds: [],
    productBridgeComplete: false
  }
};
const microappReducer = (state = initialMicroappState, action) => {
  switch (action.type) {
    case MicroappActionTypes.UpdateMicroapp:
      return Object.assign({}, state, {
        [action.microapp]: Object.assign({}, state[action.microapp], action.data, {
          productBridgeComplete: true
        })
      });
    default:
      return state;
  }
};
export default microappReducer;