import { isPreVerification } from '../../flow-settings/settingsSelectors';
import { getIsNewPortalCreated } from '../app/selectors';
import { getExistingAccountsFetched } from '../existing-accounts/selectors';
import { hasEndpointBeenFetched } from '../http-responses/selectors';
import { EndpointKey } from '../../http/EndpointKey';
export const getAuthState = state => {
  return state.auth;
};
export const getIsAuthenticated = state => {
  return !!getAuthState(state).user;
};
export const getAuthenticatedUser = state => {
  return getAuthState(state).user;
};
export const getAuthUserId = state => {
  return getAuthState(state).userId;
};
export const getIsAuthInitialized = state => {
  const authState = getAuthState(state);
  return authState && getAuthState(state).initialized;
};
export const getPortalId = state => {
  return getAuthState(state).portalId;
};
export const isPortalIdDefined = state => {
  return Boolean(getPortalId(state));
};
export const isUserIdDefined = state => {
  return Boolean(getAuthUserId(state));
};
export const getAuth = state => {
  return getAuthState(state);
};
export const checkIsAuthed = state => {
  if (!getIsAuthInitialized(state)) {
    return true;
  }
  const isExistingUser = getIsAuthenticated(state) && !getIsNewPortalCreated(state);
  return isExistingUser;
};
export const isNotAuthed = state => {
  const authInitialized = getIsAuthInitialized(state);
  const isAuthenticated = getIsAuthenticated(state);
  return authInitialized && !isAuthenticated;
};
export const authIsNotFinishedPreVerification = state => {
  const authInitialized = getIsAuthInitialized(state);
  return isPreVerification() && !authInitialized;
};
export const authIsFinishedPreVerification = state => {
  const authInitialized = getIsAuthInitialized(state);
  const isAuthed = getIsAuthenticated(state);
  const checkExistingAccountsIfAuthed = isAuthed ? getExistingAccountsFetched(state) : true;
  const isPortalTierFetched = isAuthed ? hasEndpointBeenFetched(state, EndpointKey.GetPortalTiers) : true;
  return isPreVerification() && authInitialized && checkExistingAccountsIfAuthed && isPortalTierFetched;
};
export const getLoginSuccessStatus = state => {
  return getAuthState(state).loginSuccessStatus;
};
export const getAuthEmail = state => {
  const email = getAuthState(state).auth.email;
  return email;
};