export let Product;
(function (Product) {
  Product["Sales"] = "sales";
  Product["SalesPro"] = "sales-pro";
  Product["Developers"] = "developer-tools";
  Product["Community"] = "marketing-community";
  Product["MarketingFree"] = "marketing-free-2016";
  Product["MarketingStarter"] = "marketing-starter-2016";
  Product["MarketingBasic"] = "marketing-basic-2013";
  Product["MarketingPro"] = "marketing-pro-2013";
  Product["MarketingEnterprise"] = "marketing-ent-2013";
  Product["Partner"] = "partner-account";
  Product["ServiceFree"] = "service-free";
  Product["StandaloneCmsDeveloper"] = "cms-standalone-free";
  Product["MarketingMarketplaceProvider"] = "marketing-marketplace-provider";
  Product["PartnerBase"] = "partner-base";
  Product["HubSpotFree"] = "hubspot-free";
})(Product || (Product = {}));