import enviro from 'enviro';
/**
 * Google Platform Library Wrapper and Initializator.
 *
 * @class GooglePlatform
 */
class GooglePlatform {
  /**
   * Creates an instance of GooglePlatform.
   * @memberof GooglePlatform
   */
  constructor() {
    this.getGoogleIdentityServicesClient = () => {
      return window.google;
    };
    this._loadPromiseGoogleIdentityServices = null;
    this._loadTimeoutGoogleIdentityServices = undefined;
  }

  /**
   * Returns the Google Client-id based on the current environment.
   *
   * @returns {string} The Google client-id.
   * @memberof GooglePlatform
   */
  getClientId() {
    const env = enviro.getShort().toUpperCase();
    return GooglePlatform.CLIENT_ID[env];
  }

  /**
   * Register a new callback to be called right after
   * the Google Sign In script initializes
   *
   * @memberof GooglePlatform
   */
  registerGoogleIdentityServicesCallback(resolve, reject) {
    const w = window;
    w.onGoogleLibraryLoad = () => {
      clearTimeout(this._loadTimeoutGoogleIdentityServices);
      resolve(true);
    };
    this._loadTimeoutGoogleIdentityServices = setTimeout(() => {
      reject('Load google-platform script timed out.');
    }, 60 * 1000 * 2); // 2 minutes
  }

  /**
   * Loads the Google Identity Services.
   *
   * @returns {Promise} A Promise instance to be resolved when the script initializes.
   * @memberof GooglePlatform
   */
  loadGoogleIdentityServices() {
    if (!this._loadPromiseGoogleIdentityServices) {
      this._loadPromiseGoogleIdentityServices = new Promise((resolve, reject) => {
        if (!window.google) {
          this.registerGoogleIdentityServicesCallback(resolve, reject);
          const script = document.createElement('script');
          script.id = GooglePlatform.GSI_CLIENT_SCRIPT_ID;
          script.async = true;
          script.defer = true;
          script.src = GooglePlatform.GSI_CLIENT_SCRIPT;
          document.body.appendChild(script);
        } else {
          resolve(true);
        }
      });
    }
    return this._loadPromiseGoogleIdentityServices;
  }
  reset() {
    this._loadPromiseGoogleIdentityServices = null;
    this._loadTimeoutGoogleIdentityServices = undefined;
  }
}
GooglePlatform.CLIENT_ID = {
  QA: '865670317198-giofriqoud2sbkf9lkapusrb6kd4tf43.apps.googleusercontent.com',
  PROD: '466680526474.apps.googleusercontent.com'
};
GooglePlatform.GSI_CLIENT_SCRIPT = 'https://accounts.google.com/gsi/client';
GooglePlatform.GSI_CLIENT_SCRIPT_ID = 'gsi-client';
export default new GooglePlatform();