import { VerificationType } from '../app/VerificationType';
import { loginWithApple, loginWithGoogle, loginWithMicrosoft } from '../http-login/LoginClient';
import imageUrls from '../images/imageUrls';
import { Data } from 'signup-constants/signupData/Data';
import { IdentityVerificationMethod } from '../tracking/IdentityVerificationMethod';
import { Step } from 'signup-constants/Step';
import { OAuthProviders } from './OAuthProviders';
export const OAuthToLogo = {
  [OAuthProviders.Microsoft]: imageUrls.microsoftLogo,
  [OAuthProviders.Apple]: imageUrls.appleLogo
};
export const OAuthToLogoDisabled = {
  [OAuthProviders.Microsoft]: imageUrls.microsoftWhiteLogo,
  [OAuthProviders.Apple]: imageUrls.appleWhiteLogo
};
export const OAuthToButtonBgColor = {
  [OAuthProviders.Microsoft]: '#2f2f2f',
  [OAuthProviders.Apple]: '#ffffff'
};
export const OAuthToButtonBorderColor = {
  [OAuthProviders.Microsoft]: '#2f2f2f',
  [OAuthProviders.Apple]: '#000000'
};
export const OAuthToButtonFontColor = {
  [OAuthProviders.Microsoft]: 'white',
  [OAuthProviders.Apple]: 'black'
};
export const OAuthToButtonHoverColor = {
  [OAuthProviders.Microsoft]: '#4d4d4d',
  [OAuthProviders.Apple]: '#eeeeee'
};
export const OAuthToText = {
  [OAuthProviders.Microsoft]: `views.${Step.LandingPage}.signupWithMicrosoft`,
  [OAuthProviders.Apple]: `views.${Step.LandingPage}.signupWithApple`
};
export const OAuthToVerificationType = {
  [OAuthProviders.Google]: VerificationType.Google,
  [OAuthProviders.Microsoft]: VerificationType.Microsoft,
  [OAuthProviders.Apple]: VerificationType.Apple
};
export const OAuthToIdentityVerificationMethod = {
  [OAuthProviders.Google]: IdentityVerificationMethod.GoogleVerification,
  [OAuthProviders.Microsoft]: IdentityVerificationMethod.MicrosoftVerification,
  [OAuthProviders.Apple]: IdentityVerificationMethod.AppleVerification
};
export const OAuthToDataToken = {
  [OAuthProviders.Google]: Data.GoogleIdToken,
  [OAuthProviders.Microsoft]: Data.MicrosoftLoginIdToken,
  [OAuthProviders.Apple]: Data.AppleToken
};
export const OAuthToVerifyEndpoint = {
  [OAuthProviders.Google]: '/signup/v1/oauth/v2/google/verify',
  [OAuthProviders.Microsoft]: '/signup/v1/oauth/v1/microsoft/verify',
  [OAuthProviders.Apple]: '/signup/v1/oauth/apple/verify'
};
export const oAuthToLoginFunction = {
  [OAuthProviders.Google]: loginWithGoogle,
  [OAuthProviders.Microsoft]: loginWithMicrosoft,
  [OAuthProviders.Apple]: loginWithApple
};
export const oAuthToButtonWidth = {
  [OAuthProviders.Google]: {
    max: 400,
    mobile: 320
  },
  [OAuthProviders.Apple]: {
    max: 375,
    mobile: 320
  }
};