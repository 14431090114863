import { enrollInUserGuide } from 'onboarding-utils/gettingStartedEnrollment';
import PortalIdParser from 'PortalIdParser';
import { gettingStartedRedirect, onboardingRedirectChecks, getDecorationParams } from '../data/redirectData';
import { CRM_SIGNUP_VIA_PARAM, MARKETING_SIGNUP_VIA_PARAM, SALES_SIGNUP_VIA_PARAM, SERVICE_SIGNUP_VIA_PARAM, GENERIC_SIGNUP_VIA_PARAM } from '../constants/queryParamConstants';
import { SALES_SIGNUP, CRM_SIGNUP, MARKETING_SIGNUP, SERVICE_SIGNUP } from '../constants/signupConstants';
import { getSearchParams } from './searchUtils';
import { mergeQueryParamsToUrl } from '../utils/searchUtils';
import { HubletUrl, homeLink } from '../utils/urls';
import { contains, testAgainstArray, testAgainstObject } from '../utils/comparisonUtils';
import { getFinalOnboardingSettings, setDefaultOnboardingSettings } from './onboardingKeyUtils';
function getGettingStartedUrl(portalId) {
  const {
    redirectPath,
    redirectParams
  } = gettingStartedRedirect();
  return mergeQueryParamsToUrl(`${HubletUrl.getAppUrl()}${redirectPath(portalId)}`, redirectParams);
}
function getHomeUrl() {
  return `${HubletUrl.getAppUrl()}${homeLink()}`;
}
export function applyRedirectChecks({
  checks = [],
  candidateSignupType,
  candidateSurvey,
  candidateMeta = {},
  candidateSignupExperimentTreatments = {},
  candidateGates = [],
  candidateLocale
}) {
  if (checks.length === 0) {
    // Without any specified check, the check should pass immediately
    return true;
  }
  // Immediately return true on the first check to match
  return checks.some(({
    redirectIf,
    signupType,
    survey,
    searchParams,
    hubsMeta,
    signupExperimentTreatments,
    gates,
    locale
  }) => {
    let isMatch = contains(signupType, candidateSignupType) && testAgainstObject(survey, candidateSurvey) && testAgainstObject(searchParams, getSearchParams()) && testAgainstObject(hubsMeta, candidateMeta) && testAgainstObject(signupExperimentTreatments, candidateSignupExperimentTreatments) && testAgainstArray(gates, candidateGates) && contains(locale, candidateLocale);
    if (isMatch && redirectIf) {
      isMatch = redirectIf();
    }
    return isMatch;
  });
}
export function matchRedirectConfig({
  signupType,
  survey = {},
  hubsMeta,
  locale,
  signupExperimentTreatments,
  auth
}) {
  return onboardingRedirectChecks.find(({
    checks = []
  }) => {
    return applyRedirectChecks({
      checks,
      candidateSignupType: signupType,
      candidateSurvey: survey,
      candidateMeta: hubsMeta,
      candidateSignupExperimentTreatments: signupExperimentTreatments,
      candidateGates: auth.gates,
      candidateLocale: locale
    });
  }) || {};
}
export function performOnboardingSetup(options, redirectPath = matchRedirectConfig(options).redirectPath) {
  const {
    auth = {
      userId: null
    },
    hublet,
    portalId = PortalIdParser.get(),
    survey = {},
    logger,
    doNotEnrollIntent = false,
    appUrl,
    apiUrl
  } = options;
  if (!portalId) {
    if (logger) {
      logger.error('No Portal ID present in arguments or from PortalIdParser, skipping redirecting to Home', {
        extra: {
          survey
        }
      });
    }
    return Promise.resolve(getHomeUrl());
  }
  HubletUrl.initializeURLs(hublet, appUrl, apiUrl);
  const redirectPathConfigPromise = redirectPath && redirectPath(portalId, auth);
  return Promise.resolve(redirectPathConfigPromise).then(pathConfig => {
    const isPathConfig = pathConfig && typeof pathConfig === 'object';
    const onboardingSettings = getFinalOnboardingSettings(pathConfig);
    const signupExperimentTreatments = options && options.signupExperimentTreatments;
    const preRedirectionBehaviours = [setDefaultOnboardingSettings(onboardingSettings, auth, portalId, logger, signupExperimentTreatments || undefined)];

    // By default users are enrolled in the user guide
    // Intent signup can override this by passing doNotEnrollIntent: true flag
    if (isPathConfig && !pathConfig.doNotEnroll && !doNotEnrollIntent) {
      preRedirectionBehaviours.push(enrollInUserGuide({
        hubletOriginOverride: HubletUrl.getApiUrl()
      }));
    }
    const beforeRedirect = matchRedirectConfig(options).beforeRedirect;
    return Promise.all(preRedirectionBehaviours).then(() => {
      return beforeRedirect ? beforeRedirect(portalId, auth, survey, hublet) : Promise.resolve();
    }).then(() => pathConfig).catch(() => pathConfig);
  });
}
export function getUniqueOnboardingUrl(options) {
  const {
    includeParams = {},
    portalId = PortalIdParser.get(),
    signupType,
    survey = {},
    logger = {
      error: () => {},
      warn: () => {}
    },
    signupStepNumber
  } = options;
  if (!portalId) {
    if (logger) {
      logger.error('No Portal ID present in arguments or from PortalIdParser, skipping redirecting to Home', {
        extra: {
          survey
        }
      });
    }
    return Promise.resolve(getHomeUrl());
  }
  if (!signupType) {
    logger.error('No signup type provided, redirecting to Getting Started as a fallback', {
      extra: {
        survey
      }
    });
    return mergeQueryParamsToUrl(getGettingStartedUrl(portalId), includeParams);
  }
  const {
    redirectParams,
    redirectPath
  } = matchRedirectConfig(options);
  const queryParams = Object.assign({}, signupStepNumber && {
    stepId: signupStepNumber.toString()
  }, includeParams, redirectParams, getDecorationParams({
    signupType,
    survey
  }));
  return performOnboardingSetup(options, redirectPath).then(pathConfig => {
    const url = pathConfig !== null && typeof pathConfig === 'object' ? pathConfig.url : pathConfig;
    if (!url) {
      logger.error('No matching redirect usecase found, redirecting to Getting Started as a fallback', {
        extra: {
          signupType,
          survey
        }
      });
      return mergeQueryParamsToUrl(getGettingStartedUrl(portalId), queryParams);
    }
    return mergeQueryParamsToUrl(url, queryParams);
  }).catch(error => {
    logger.error('Unexpected error occurred while resolving redirect URL, redirecting to Getting Started as a fallback', {
      extra: {
        error,
        signupType,
        survey
      }
    });
    return mergeQueryParamsToUrl(getGettingStartedUrl(portalId), queryParams);
  });
}
export const getViaRedirectionParam = signupType => {
  switch (signupType) {
    case CRM_SIGNUP:
      return CRM_SIGNUP_VIA_PARAM;
    case SALES_SIGNUP:
      return SALES_SIGNUP_VIA_PARAM;
    case MARKETING_SIGNUP:
      return MARKETING_SIGNUP_VIA_PARAM;
    case SERVICE_SIGNUP:
      return SERVICE_SIGNUP_VIA_PARAM;
    default:
      return GENERIC_SIGNUP_VIA_PARAM;
  }
};