import { useDispatch } from 'react-redux';
import { SignupEmbedderOptions } from 'signup-embedder';
import { NextStepManager } from '../flow-management/NextStepManager';
import { setNextStep } from '../flow-management/setNextStep';
import { EndpointKey } from '../http/EndpointKey';
import { retryEndpoint } from '../store/http-responses/action-creators';
import { Step } from 'signup-constants/Step';
import { Tracker } from '../store/tracking/action-creators';
export const useEmailVerification = () => {
  const dispatch = useDispatch();
  const shouldEnforceEmailVerification = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_ENFORCE_EMAIL_VERIFICATION);
  const verificationStep = shouldEnforceEmailVerification ? Step.Verification : Step.CodeVerification;
  const resendEmail = () => {
    dispatch(Tracker.signupInteraction('resend-email-verification'));
    dispatch(retryEndpoint(EndpointKey.InitiateVerification, 1000));
    dispatch(setNextStep({
      rebuilder: NextStepManager.pushNextSteps(verificationStep)
    }));
  };
  return {
    resendEmail
  };
};