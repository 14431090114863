import { Data } from 'signup-constants/signupData/Data';
import { companyInfo } from '../http-company-info/CompanyInfoClient';
import { handleCompanyInfoError, handleCompanyInfoSuccess } from '../http-company-info/handleCompanyInfoResponse';
import { getRecommendedHublet } from '../hublets/getRecommendedHublet';
import { getVerificationType } from '../store/app/selectors';
import { isStepInNextOrCurrentStep } from '../store/flow/selectors';
import { setSignupDataValue } from '../store/signup-data/action-creators';
import { VisitorContextActionTypes } from '../store/visitor-context/action-types';
import { Step } from 'signup-constants/Step';
import { fetchPublicVisitorContext } from '../visitor-context-fetch/fetchPublicVisitorContext';
import { handleVisitorContextSuccess } from '../visitor-context-fetch/handleVisitorContextResponse';
import { EndpointKey } from './EndpointKey';
import { shouldAddHubletStep } from '../hublets/hubletUtils';
import { isFlow } from '../utils/isFlow';
import { Flow } from 'signup-constants/Flow';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Tracker } from '../store/tracking/action-creators';

// NOTE: This object was wrapped in a function to avoid problematic cycles when frontend-infra-core migrates to Webpack 5
export const getEndpointsPrefilling = () => ({
  [EndpointKey.CompanyInfo]: {
    getRequestData: () => [Data.Email],
    handleError: handleCompanyInfoError,
    handleSuccess: handleCompanyInfoSuccess,
    method: companyInfo,
    stateCheck: state => {
      const isVerificationTypeSet = Boolean(getVerificationType(state));
      return isVerificationTypeSet && isStepInNextOrCurrentStep(state, [Step.CompanyDomain, Step.CompanyName, Step.Industry]);
    }
  },
  [EndpointKey.VisitorContextFetch]: {
    handleSuccess: handleVisitorContextSuccess,
    method: fetchPublicVisitorContext,
    reduxActionType: VisitorContextActionTypes.Fetch
  },
  [EndpointKey.GetRecommendedHublet]: {
    stateCheck: state => {
      return (shouldAddHubletStep(state) || isFlow([Flow.Trial, Flow.Purchase])) && !getSignupDataValue(state, Data.HubletSelection);
    },
    method: getRecommendedHublet,
    handleSuccess: (response, dispatch) => {
      dispatch(Tracker.signupInteraction('set-hublet-in-state', {
        selectedOption: response.region
      }));
      dispatch(setSignupDataValue(Data.HubletSelection, response.region));
    }
  }
});