import { NextStepManager } from '../flow-management/NextStepManager';
import { Step } from 'signup-constants/Step';
export const getFlowStepsWithEmailVerification = flowSteps => {
  const preVerificationSteps = NextStepManager.replaceStep(Step.CodeVerification, Step.Verification)(flowSteps().preVerification);
  return () => {
    return {
      preVerification: preVerificationSteps,
      postVerification: flowSteps().postVerification,
      postAccountCreation: flowSteps().postAccountCreation,
      postExistingAccountSelection: flowSteps().postExistingAccountSelection
    };
  };
};