import { Flow } from 'signup-constants/Flow';
import { getFlowKey } from '../routing/getFlowKey';
import { ValidationStatus } from 'signup-ui-lego-core/types';
import { shouldSkipPrefilledSteps } from '../flow-settings/prefillDataSelectors';
import { getStepData } from '../step/getStepData';
import { getPreFilledSignupDataKeysByPrefillType, getSignupDataValidation } from '../store/signup-data/selectors';
import { hasIntersection } from '../utils/data-structure-utils/hasIntersection';
import { Step } from 'signup-constants/Step';
import { PrefillType } from '../prefilling/prefillSignupData';
const NonSkippableStepsByFlow = new Map([[Flow.Integrations, [Step.LandingPage]]]);
export const filterPrefilledSteps = (flow, state) => {
  const flowKey = getFlowKey();
  const prefills = getPreFilledSignupDataKeysByPrefillType(state, PrefillType.EmbedderPostMessage);
  return flow.filter(stepKey => {
    const stepData = getStepData(stepKey);
    const allStepDataArePrefilled = stepData.every(dataKey => prefills.indexOf(dataKey) > -1);
    const nonSkippableSteps = NonSkippableStepsByFlow.get(flowKey);
    const doesStepDataContainPrefills = hasIntersection(prefills, stepData);
    if (doesStepDataContainPrefills) {
      const isEveryStepDataValid = stepData.every(dataKey => {
        const validation = getSignupDataValidation(state, dataKey);
        return validation && validation === ValidationStatus.VALID;
      });
      if (!isEveryStepDataValid) {
        return true;
      }
    }
    const shouldFilterStep = stepData.length === 0 || !allStepDataArePrefilled || nonSkippableSteps && nonSkippableSteps.includes(stepKey);
    return shouldFilterStep;
  });
};
export const skipPrefilled = (flow, state) => {
  return shouldSkipPrefilledSteps() ? filterPrefilledSteps(flow, state) : flow;
};