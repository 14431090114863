import { getMessage } from '../../messaging/MESSAGE';
import { isFirefox, isSafariOrIos } from '../../utils/deviceUtils';
import { OffsetType } from '../types';
import { ColorTheme, defaultColorOptions } from '../../messaging/ColorLibrary';
const getOffset = (type, messageKey, shouldUseConfigOffsetFirefox = false) => {
  const {
    blobBleedConfig
  } = getMessage(messageKey);
  const offset = blobBleedConfig && blobBleedConfig[type];
  if (isFirefox() && !shouldUseConfigOffsetFirefox) {
    return 0;
  }
  return offset || 0;
};
export const getXOffset = (messageKey, isCard = false) => {
  if (isSafariOrIos() && !isCard) {
    return 0;
  }
  return getOffset(OffsetType.xOffset, messageKey);
};
export const getYOffset = (messageKey, isCard = false, shouldUseConfigOffsetFirefox = false) => {
  if (isSafariOrIos() && !isCard) {
    return 0;
  }
  return getOffset(OffsetType.yOffset, messageKey, shouldUseConfigOffsetFirefox);
};
export const getOuterBlobHeight = messageKey => {
  const {
    blobBleedConfig: {
      outerBlobHeight = '100%'
    } = {}
  } = getMessage(messageKey);
  return outerBlobHeight;
};
export const getColorTheme = colorThemeOption => {
  const colorTheme = ColorTheme.get(colorThemeOption);
  return !colorTheme ? defaultColorOptions : colorTheme;
};