import { trackCopyExperimentExposure } from '../store/experiments/action-creators';
import { ExperimentActionTypes } from '../store/experiments/action-types';
import { getHasExposureBeenTracked } from '../store/experiments/selectors';
import { FlowActionTypes } from '../store/flow/action-types';
import { getActiveCopyExperimentIds } from './copyExperimentUtils';
export const ExperimentsTrackingMiddleware = store => next => action => {
  const state = store.getState();
  if (action.type === FlowActionTypes.Load || action.type === FlowActionTypes.StepCompleted || action.type === ExperimentActionTypes.ExperimentsFetched) {
    const copyExperimentKeys = getActiveCopyExperimentIds(state);
    copyExperimentKeys.forEach(id => {
      if (!getHasExposureBeenTracked(state, id)) {
        store.dispatch(trackCopyExperimentExposure(id));
      }
    });
  }
  return next(action);
};