export let OAuthActionTypes;
(function (OAuthActionTypes) {
  OAuthActionTypes["AuthStarted"] = "[OAuth] Auth Started";
  OAuthActionTypes["AuthFailed"] = "[OAuth] Auth Failed";
  OAuthActionTypes["AuthSucceeded"] = "[OAuth] Auth Succeeded";
  OAuthActionTypes["AuthDataStored"] = "[OAuth] Auth Data Stored";
  OAuthActionTypes["VerificationStarted"] = "[OAuth] Verification Started";
  OAuthActionTypes["VerificationFailed"] = "[OAuth] Verification Failed";
  OAuthActionTypes["VerificationSucceeded"] = "[OAuth] Verification Succeeded";
  OAuthActionTypes["PromptVerificationSucceeded"] = "[OAuth] Prompt Verification Succeeded";
})(OAuthActionTypes || (OAuthActionTypes = {}));