import I18n from 'I18n';
import { getDataInputProps } from './getDataInputProps';
import { useInputPlaceholder } from './useInputPlaceholder';
import { useSignupData } from './useSignupData';
export const useInputProps = dataKey => {
  const {
    isValid,
    isInvalid
  } = useSignupData(dataKey);
  const inputDataProps = getDataInputProps(dataKey);
  const placeholder = useInputPlaceholder(dataKey);
  const label = I18n.text(`data.${dataKey}.default.label`);
  return Object.assign({
    name: dataKey,
    dataKey,
    isInvalid,
    isValid,
    placeholder,
    label
  }, inputDataProps);
};