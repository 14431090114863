import { getLoginRedirectUrlFromQueryParam } from '../routing/getLoginRedirectUrlFromQueryParam';
const isHubSpotHost = hostname => /^(app|local).*\.hubspot(qa)?\.com/.test(hostname);
const isOauthPath = pathname => /^\/oauth\/.*/.test(pathname);
const isHubSpotOauthRedirect = (hostname, pathname) => isHubSpotHost(hostname) && isOauthPath(pathname);
const removeAffiliateCharacterEncoding = url => url.replace(/\+/g, encodeURIComponent(' '));
const hasOAuthSearchParams = searchParams => {
  const hasClientId = Boolean(searchParams.get('client_id'));
  const hasRedirectUri = Boolean(searchParams.get('redirect_uri'));
  const hasScope = Boolean(searchParams.get('scope'));
  return hasClientId && hasRedirectUri && hasScope;
};
export const parseScopesFromUrl = scopesInUrl => removeAffiliateCharacterEncoding(scopesInUrl);
export const isOauthFlow = () => {
  const loginRedirectUrl = getLoginRedirectUrlFromQueryParam(false);
  if (loginRedirectUrl) {
    const {
      hostname,
      pathname,
      searchParams
    } = loginRedirectUrl;
    return isHubSpotOauthRedirect(hostname, pathname) && hasOAuthSearchParams(searchParams);
  }
  return false;
};