import enviro from 'enviro';
export let GoogleRecaptchaSitekeys;
(function (GoogleRecaptchaSitekeys) {
  GoogleRecaptchaSitekeys["QAV2"] = "6LfzJD4aAAAAAMHpeo4Z4THpPCenK3-YfqyGRnnJ";
  GoogleRecaptchaSitekeys["QAV3"] = "6Le8Q94ZAAAAAFe5mmm8m2RatT-KZVqDV9VC92Pt";
  GoogleRecaptchaSitekeys["ProdV2"] = "6Lc_1TcaAAAAAHz7FEz79n5DGczt09PQyAXmXOuX";
  GoogleRecaptchaSitekeys["ProdV3"] = "6Lfi6CIaAAAAAA-cyJH5r4-i-1N5bxBEFOqoYOsr";
})(GoogleRecaptchaSitekeys || (GoogleRecaptchaSitekeys = {}));
export const getV2SiteKey = () => {
  return enviro.isProd() ? GoogleRecaptchaSitekeys.ProdV2 : GoogleRecaptchaSitekeys.QAV2;
};
export const getV3SiteKey = () => {
  return enviro.isProd() ? GoogleRecaptchaSitekeys.ProdV3 : GoogleRecaptchaSitekeys.QAV3;
};