import { FlowStateManager } from '../../flow-management/FlowStateManager';
import { Step } from 'signup-constants/Step';
import { getRouteParams } from '../../routing/getRouteParams';
import { getIntegrationKey } from '../../routing/getIntegrationKey';
import { Integration } from 'signup-constants/Integration';
import { isIntegratedAppCookielessBranch } from '../../utils/isIntegratedApp';
export const BranchIntegratedAppCookieless = {
  name: 'BranchIntegratedAppCookieless',
  stateCheck: () => {
    const {
      queryParamsMap
    } = getRouteParams();
    return isIntegratedAppCookielessBranch(queryParamsMap);
  },
  rebuildFlow: (flow, state, dispatch) => {
    const integrationName = getIntegrationKey();
    switch (integrationName) {
      case Integration.ShopifyEmbedded:
        return dispatch(FlowStateManager.replaceStep(Step.IntegratedAppAuthorization, Step.IntegratedAppConnectRedirect));
      case Integration.Wordpress:
      default:
        return dispatch(FlowStateManager.replaceStep(Step.WordpressAuthorization, Step.WordpressConnectRedirect));
    }
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};