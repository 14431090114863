import { CMS_ENTERPRISE_TRIAL_ID, CMS_PROFESSIONAL_TRIAL_ID, MARKETING_ENTERPRISE_TRIAL_ID, MARKETING_PRO_TRIAL_ID, OPERATIONS_ENTERPRISE_TRIAL_ID, OPERATIONS_PROFESSIONAL_TRIAL_ID, SALES_ENTERPRISE_TRIAL_ID, SALES_PROFESSIONAL_TRIAL_ID, SERVICE_ENTERPRISE_TRIAL_ID, SERVICE_PROFESSIONAL_TRIAL_ID } from 'self-service-api/constants/Trials';
import { HubInterestOption } from 'signup-constants/signupData/HubInterestOption';
export let TrialId;
(function (TrialId) {
  TrialId[TrialId["SalesEnterprise"] = SALES_ENTERPRISE_TRIAL_ID] = "SalesEnterprise";
  TrialId[TrialId["SalesProfessional"] = SALES_PROFESSIONAL_TRIAL_ID] = "SalesProfessional";
  TrialId[TrialId["MarketingEnterprise"] = MARKETING_ENTERPRISE_TRIAL_ID] = "MarketingEnterprise";
  TrialId[TrialId["MarketingProfessional"] = MARKETING_PRO_TRIAL_ID] = "MarketingProfessional";
  TrialId[TrialId["ServiceEnterprise"] = SERVICE_ENTERPRISE_TRIAL_ID] = "ServiceEnterprise";
  TrialId[TrialId["ServiceProfessional"] = SERVICE_PROFESSIONAL_TRIAL_ID] = "ServiceProfessional";
  TrialId[TrialId["CmsEnterprise"] = CMS_ENTERPRISE_TRIAL_ID] = "CmsEnterprise";
  TrialId[TrialId["CmsProfessional"] = CMS_PROFESSIONAL_TRIAL_ID] = "CmsProfessional";
  TrialId[TrialId["OperationsEnterprise"] = OPERATIONS_ENTERPRISE_TRIAL_ID] = "OperationsEnterprise";
  TrialId[TrialId["OperationsProfessional"] = OPERATIONS_PROFESSIONAL_TRIAL_ID] = "OperationsProfessional";
})(TrialId || (TrialId = {}));
export const TrialToHubInterestOption = new Map([[TrialId.SalesEnterprise, HubInterestOption.Sales], [TrialId.SalesProfessional, HubInterestOption.Sales], [TrialId.MarketingEnterprise, HubInterestOption.Marketing], [TrialId.MarketingProfessional, HubInterestOption.Marketing], [TrialId.ServiceEnterprise, HubInterestOption.CustomerService], [TrialId.ServiceProfessional, HubInterestOption.CustomerService], [TrialId.OperationsEnterprise, HubInterestOption.Operations], [TrialId.OperationsProfessional, HubInterestOption.Operations], [TrialId.CmsEnterprise, HubInterestOption.CMS], [TrialId.CmsProfessional, HubInterestOption.CMS]]);
export let ProTrialName;
(function (ProTrialName) {
  ProTrialName["MarketingProfessional"] = "MARKETING_PROFESSIONAL";
  ProTrialName["SalesProfessional"] = "SALES_PROFESSIONAL";
  ProTrialName["ServiceProfessional"] = "SERVICE_PROFESSIONAL";
  ProTrialName["CmsProfessional"] = "CMS_PROFESSIONAL";
  ProTrialName["OperationsProfessional"] = "OPERATIONS_PROFESSIONAL";
})(ProTrialName || (ProTrialName = {}));
export const TrialConfigByName = {
  [ProTrialName.SalesProfessional]: {
    illustrationKey: 'sales-professional',
    trialId: TrialId.SalesProfessional
  },
  [ProTrialName.MarketingProfessional]: {
    illustrationKey: 'marketing-pro',
    trialId: TrialId.MarketingProfessional
  },
  [ProTrialName.ServiceProfessional]: {
    illustrationKey: 'service-hub-professional',
    trialId: TrialId.ServiceProfessional
  },
  [ProTrialName.OperationsProfessional]: {
    illustrationKey: 'operations-custom-mappings',
    trialId: TrialId.OperationsProfessional
  },
  [ProTrialName.CmsProfessional]: {
    illustrationKey: 'add-on-website',
    trialId: TrialId.CmsProfessional
  }
};