import { ErrorActionTypes } from './action-types';
import { ErrorKey } from '../../app-active-error/ErrorKey';
import { clearAnyLoading } from '../loading-status/action-creators';
import Logger from '../../lib/Logger';
export const setError = (errorKey, error = {
  message: ''
}) => (dispatch, getState) => {
  const LOG = Logger.getLogger('setError');
  if (errorKey === ErrorKey.BlankScreenLoadingError) {
    const state = getState();
    LOG.error('Blank screen loading error triggered', {
      extra: {
        currentStep: state.flow.currentStep,
        loadingStack: state.loadingStatus.loadingStack,
        activeError: state.error.activeError
      }
    });
    return;
  }
  dispatch(clearAnyLoading());
  return dispatch({
    type: ErrorActionTypes.SetError,
    errorKey,
    error: {
      message: error.message
    }
  });
};
export const clearError = () => dispatch => {
  return dispatch({
    type: ErrorActionTypes.ClearError
  });
};