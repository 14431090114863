import { isExistingUserExistingAccount, isntExistingUserExistingAccount } from '../app/verificationTypeSelectors';
import { getHasRefreshedNewPortalAuth, getIsNewPortalCreated } from '../store/app/selectors';
import { isPortalIdDefined } from '../store/auth/selectors';
import { hasAnsweredAllPostAccountCreatedQuestions, hasAnsweredAllPostExistingAccountSelectedQuestions } from '../store/signup-data/selectors';
export const isNewLoggedInAccount = state => {
  return getHasRefreshedNewPortalAuth(state) && getIsNewPortalCreated(state);
};
export const hasAnsweredAllQuestions = state => {
  if (isntExistingUserExistingAccount(state)) return hasAnsweredAllPostAccountCreatedQuestions(state) && isPortalIdDefined(state);
  return hasAnsweredAllPostExistingAccountSelectedQuestions(state) && isPortalIdDefined(state);
};
export const checkIfLoggedInIfNotExistingAccount = state => {
  if (isntExistingUserExistingAccount(state)) return isNewLoggedInAccount(state);
  return isExistingUserExistingAccount(state);
};
export const logInAndQuestionsCheck = state => {
  return checkIfLoggedInIfNotExistingAccount(state) && hasAnsweredAllQuestions(state);
};