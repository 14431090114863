import { isAppleSignup, isGoogleSignup, isMicrosoftSignup } from '../app/verificationTypeSelectors';
import { Data } from 'signup-constants/signupData/Data';
import { getHublet } from '../store/app/selectors';
import { getAuthenticatedUser } from '../store/auth/selectors';
import { getFlatSignupDataValueMap, getSignupDataValue } from '../store/signup-data/selectors';
import LoginClient from './LoginClient';
export const loginNewUser = (state, dispatch) => {
  const authedUser = getAuthenticatedUser(state);
  if (authedUser && getSignupDataValue(state, Data.Email) === authedUser.email) {
    return Promise.resolve();
  }
  const signupData = getFlatSignupDataValueMap(state);
  const email = signupData[Data.Email];
  const password = signupData[Data.Password];
  const googleIdToken = signupData[Data.GoogleIdToken];
  const microsoftIdToken = signupData[Data.MicrosoftLoginIdToken];
  const appleIdToken = signupData[Data.AppleToken];
  const hublet = getHublet(state);
  const googleSignup = isGoogleSignup(state);
  const microsoftSignup = isMicrosoftSignup(state);
  const appleSignup = isAppleSignup(state);
  const hasDataForPasswordLogin = email && password;
  const hasDataForGoogleLogin = googleSignup && googleIdToken;
  const hasDataForMicrosoftLogin = microsoftSignup && microsoftIdToken;
  const hasDataForAppleLogin = appleSignup && appleIdToken;
  if (!hasDataForPasswordLogin && !hasDataForGoogleLogin && !hasDataForMicrosoftLogin && !hasDataForAppleLogin) {
    /* eslint-disable-next-line prefer-promise-reject-errors */
    return Promise.reject();
  }
  if (appleSignup) {
    return LoginClient.apple(appleIdToken);
  }
  if (microsoftSignup) {
    return LoginClient.microsoft(microsoftIdToken);
  }
  if (googleSignup) {
    return LoginClient.google(googleIdToken);
  }
  return LoginClient.password(email, password, hublet, dispatch);
};