import { useAppDispatch } from '../hooks/useAppDispatch';
import { completeStepAction } from '../store/flow/action-creators';
export const useView = ({
  stepKey
}) => {
  const dispatch = useAppDispatch();
  const submit = () => {
    if (!stepKey) {
      throw new Error('stepKey is required');
    }
    dispatch(completeStepAction({
      stepKey
    }));
  };
  return {
    submit
  };
};