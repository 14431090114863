import Logger from '../lib/Logger';
import { trackInteraction } from '../tracking/trackEvent';
import { getTalonInitialValue } from './getTalonInitialValue';
const LOG = Logger.getLogger('getTalonValue');
export const getTalonValue = () => {
  const talonElement = document.getElementById('talon');
  if (!talonElement) {
    LOG.error(`The talon element isn't present in the document`);
    trackInteraction({
      action: 'talon-element-not-present',
      sendAsBeacon: true
    });
    return getTalonInitialValue();
  } else {
    const talonValue = talonElement.value;
    if (talonValue.includes(getTalonInitialValue())) {
      LOG.error('Sending signup with talon fingerprint not properly initialized');
      trackInteraction({
        action: 'talon-fingerprint-not-initialized',
        sendAsBeacon: true
      });
    }
    trackInteraction({
      action: 'talon-fingerprint-success',
      sendAsBeacon: true
    });
    return talonValue;
  }
};