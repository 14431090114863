module.exports = {
  "/academy-embedded": {
    "success": {
      "landingPage": [
        "LANDING_PAGE"
      ],
      "existingUser": [
        "EXISTING_USER_VIEW"
      ],
      "embeddedExistingUser": [
        "ACADEMY_EMBEDDED_EXISTING_USER_VIEW"
      ],
      "existingAccounts": [
        "EXISTING_ACCOUNTS_VIEW"
      ],
      "jobField": [
        "JOB_FIELD_VIEW"
      ],
      "jobRole": [
        "JOB_ROLE_VIEW"
      ],
      "companyName": [
        "COMPANY_NAME_VIEW"
      ],
      "companySize": [
        "COMPANY_SIZE_VIEW"
      ],
      "companyDomain": [
        "COMPANY_DOMAIN_VIEW"
      ],
      "mobilePrompt": [
        "MOBILE_APP_PROMPT_VIEW"
      ],
      "verification": [
        "VERIFICATION_SUCCEEDED"
      ],
      "resendEmail": [
        "RESEND_EMAIL_VIEW"
      ],
      "password": [
        "PASSWORD_VIEW"
      ],
      "errorScreen": [
        "ERROR_SCREEN"
      ]
    },
    "partialSuccess": {
      "preventLoading": [
        "PREVENT_LOADING"
      ]
    },
    "error": [
      "applicationError"
    ]
  },
  "/:flow": {
    "name": "flow",
    "success": {
      "landingPage": [
        "LANDING_PAGE"
      ],
      "existingUser": [
        "EXISTING_USER_VIEW"
      ],
      "existingAccounts": [
        "EXISTING_ACCOUNTS_VIEW"
      ],
      "industry": [
        "INDUSTRY_VIEW"
      ],
      "jobField": [
        "JOB_FIELD_VIEW"
      ],
      "jobRole": [
        "JOB_ROLE_VIEW"
      ],
      "companyName": [
        "COMPANY_NAME_VIEW"
      ],
      "companySize": [
        "COMPANY_SIZE_VIEW"
      ],
      "hubInterest": [
        "HUB_INTEREST_VIEW"
      ],
      "companyDomain": [
        "COMPANY_DOMAIN_VIEW"
      ],
      "experienceLevel": [
        "CRM_EXPERIENCE_LEVEL_VIEW"
      ],
      "mobilePrompt": [
        "MOBILE_APP_PROMPT_VIEW"
      ],
      "verification": [
        "VERIFICATION_SUCCEEDED"
      ],
      "resendEmail": [
        "RESEND_EMAIL_VIEW"
      ],
      "password": [
        "PASSWORD_VIEW"
      ],
      "errorScreen": [
        "ERROR_SCREEN"
      ],
      "wordpressAuthorization": [
        "WORDPRESS_AUTHORIZATION"
      ],
      "integratedAppAuthorization": [
        "INTEGRATED_APP_AUTHORIZATION"
      ],
      "country": [
        "COUNTRY_VIEW"
      ],
      "cmsPartnerDomainAndName": [
        "CMS_PARTNER_DOMAIN_NAME"
      ],
      "productExperience": [
        "PRODUCT_EXPERIENCE_VIEW"
      ]
    },
    "partialSuccess": {
      "preventLoading": [
        "PREVENT_LOADING"
      ]
    },
    "error": [
      "applicationError"
    ]
  }
};