import { getEndpoint } from '../../http/EndpointConfig';
import { clearError } from '../error/action-creators';
import { setLoadingStatus } from '../loading-status/action-creators';
import { HttpResponseActionTypes } from './action-types';
export const retryEndpointAction = endpointKey => dispatch => {
  dispatch({
    type: HttpResponseActionTypes.RetryEndpoint,
    endpointKey
  });
};
export const httpSuccess = (endpointKey, response) => dispatch => {
  dispatch({
    type: HttpResponseActionTypes.Success,
    endpointKey,
    response
  });
};
export const httpError = (endpointKey, error) => dispatch => {
  dispatch({
    type: HttpResponseActionTypes.Error,
    endpointKey,
    error
  });
};
export const httpFetching = endpointKey => dispatch => {
  dispatch({
    type: HttpResponseActionTypes.RequestFetch,
    endpointKey
  });
};
export const retryEndpoint = (endpointKey, timeout) => dispatch => {
  const endpoint = getEndpoint(endpointKey);
  if (endpoint && endpoint.loadingKey) {
    dispatch(setLoadingStatus(endpoint.loadingKey));
  }
  dispatch(clearError());
  if (!timeout) {
    return dispatch(retryEndpointAction(endpointKey));
  }
  return setTimeout(() => {
    dispatch(retryEndpointAction(endpointKey));
  }, timeout);
};