import Logger from '../lib/Logger';
import { hasDataJustBeenFilled } from '../signup-data/hasDataJustBeenFilled';
import { getNextStep, isStepInNextOrCurrentStep } from '../store/flow/selectors';
import { HttpResponseActionTypes } from '../store/http-responses/action-types';
import { signupDataAreFilled } from '../store/signup-data/selectors';
import { getEndpoint } from './EndpointConfig';
const LOG = Logger.getLogger('EndpointHelper');
const getIsTriggerAction = endpoint => action => {
  if (!endpoint.reduxActionType) {
    return false;
  }
  if (Array.isArray(endpoint.reduxActionType)) {
    return endpoint.reduxActionType.includes(action.type);
  }
  return endpoint.reduxActionType === action.type;
};
const doesStateCheckPass = (state, endpoint) => {
  return Boolean(!endpoint.stateCheck || endpoint.stateCheck(state));
};
const isOnlyByTriggerAction = endpoint => {
  return Boolean(endpoint.reduxActionType);
};
const isOnlyByState = endpoint => endpoint.isStateOnlyTrigger || false;
const isRequestDataFilled = (state, endpoint) => {
  const requestData = endpoint.getRequestData && endpoint.getRequestData(state);
  const dataIsFilled = requestData && signupDataAreFilled(state, requestData);
  return !requestData || dataIsFilled || false;
};
const isRequestDataFilledByCurrentStep = (state, endpoint) => {
  const requestData = endpoint.getRequestData && endpoint.getRequestData(state);
  const isDataJustFilled = requestData && hasDataJustBeenFilled(state, requestData);
  return !requestData || isDataJustFilled || false;
};
const getShouldRetry = endpoint => action => {
  return action.type === HttpResponseActionTypes.RetryEndpoint && endpoint.endpointKey === action.endpointKey;
};
const hasAlreadyBeenFetched = endpoint => {
  return endpoint.once && endpoint.fetched || false;
};
const fetchForStepsAreMissed = (state, endpoint) => {
  if (!endpoint.fetchForSteps) return false;
  return !isStepInNextOrCurrentStep(state, endpoint.fetchForSteps);
};
const isNextStepCorrect = (state, endpoint) => {
  const nextStep = getNextStep(state);
  return !endpoint.nextStep || nextStep && endpoint.nextStep.includes(nextStep) || false;
};
export const EndpointHelper = (state, endpointKey) => {
  const endpoint = getEndpoint(endpointKey);
  if (!endpoint) {
    LOG.debug(`Calling unknown endpoint ${endpointKey}`);
    return null;
  }
  const extendedEndpoint = Object.assign({}, endpoint, {
    endpointKey
  });
  return {
    fetchForStepsAreMissed: fetchForStepsAreMissed(state, extendedEndpoint),
    hasAlreadyBeenFetched: hasAlreadyBeenFetched(extendedEndpoint),
    isNextStepCorrect: isNextStepCorrect(state, extendedEndpoint),
    isOnlyByState: isOnlyByState(extendedEndpoint),
    isOnlyByTriggerAction: isOnlyByTriggerAction(extendedEndpoint),
    isRequestDataFilled: isRequestDataFilled(state, extendedEndpoint),
    isRequestDataFilledByCurrentStep: isRequestDataFilledByCurrentStep(state, extendedEndpoint),
    doesStateCheckPass: doesStateCheckPass(state, extendedEndpoint),
    getShouldRetry: getShouldRetry(extendedEndpoint),
    getIsTriggerAction: getIsTriggerAction(extendedEndpoint)
  };
};