import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { getQueryParam } from '../routing/getQueryParam';
import { getDomainLocale } from './DomainUtils';
import { getPreferredReferrer } from './ReferrerUtils';
import { getSubdomainLocale } from './SubdomainUtils';
import { getSubfolderLocale } from './SubfolderUtils';
import { getApplicationLocale } from './LocaleUtils';

/**
 * Get a language from a given URL.
 *
 */
export const getLocaleFromUrl = url => {
  if (!url || typeof url !== 'string' || url.trim().length === 0) {
    return Locale.en;
  }
  try {
    url = new URL(url);
    if (!url.hostname.includes('hubspot')) {
      return Locale.en;
    }
    const tld = url.hostname.slice(url.hostname.lastIndexOf('.') + 1, url.hostname.length);
    const tldLocale = getDomainLocale(tld);
    const subdomain = url.hostname.slice(0, url.hostname.indexOf('.'));
    const subdomainLocale = getSubdomainLocale(subdomain);
    const hubsSignupUrl = getQueryParam('hubs_signup-url');
    const subfolder = hubsSignupUrl && hubsSignupUrl.split('/')[1];
    return getSubfolderLocale(subfolder) || subdomainLocale || tldLocale || Locale.en;
  } catch (exc) {
    return Locale.en;
  }
};

/**
 * Get the navigator languages.
 *
 * This method cover the case where a browser doesn't have the navigator.languages
 * available by returning an array with the current navigator.language.
 */
export const getNavigatorLanguages = () => {
  return navigator.languages ? [...navigator.languages] : [navigator.language];
};

/**
 * Override locale only for referrer != 'en' (br,jp, etc.) and
 * that locale not being top of the preferred languages.
 */
export const getLanguages = (navigatorLanguages = getNavigatorLanguages(), url = getPreferredReferrer()) => {
  const locale = getLocaleFromUrl(url);
  if (locale && locale !== Locale.en && navigatorLanguages.length > 0 && navigatorLanguages[0].toLowerCase().indexOf(locale) === -1) {
    return [locale].concat(navigatorLanguages);
  }
  return navigatorLanguages;
};
export const isEnglishLanguage = () => {
  const language = getApplicationLocale();
  return language === Locale.en;
};
export const isBrowserAndApplicationLanguageForLocale = locale => {
  const language = getApplicationLocale();
  const browserLanguages = getNavigatorLanguages();
  const isSelectedLanguage = language === locale && browserLanguages.length > 0 && browserLanguages[0].toLowerCase().startsWith(locale);
  return isSelectedLanguage;
};