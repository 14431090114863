import { CompanySizeOption } from 'signup-constants/signupData/CompanySizeOption';
import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
export const checkCompanySizeIsLessThan5 = companySize => {
  return companySize === CompanySizeOption.One || companySize === CompanySizeOption.TwoToFive;
};
export const cmsFreeOptionalDomain = '-hubspot-freecmstools.com';
let timestampUsedForOptionalDomain;
export const setTimestampUsedForOptionalDomain = timestamp => {
  timestampUsedForOptionalDomain = timestamp;
};
export const getPresetOptionalDomain = companyName => {
  const re = /([0-9]+|([A-Z][a-z]+)|[a-z]+|([A-Z]+)(?![a-z]))/g;
  const separatedWordArray = str => str.match(re) || [];
  let cleanedCompanyName = separatedWordArray(companyName).map(x => x.toLowerCase()).join('-');
  if (!cleanedCompanyName) {
    const timestamp = timestampUsedForOptionalDomain || Date.now();
    setTimestampUsedForOptionalDomain(timestamp);
    cleanedCompanyName = `${timestamp}`;
  }
  return `${cleanedCompanyName}${cmsFreeOptionalDomain}`;
};
export const hasPresetOptionalDomain = state => {
  const companyName = getSignupDataValue(state, Data.CompanyName);
  const companyDomain = getSignupDataValue(state, Data.CompanyDomain);
  const optionalDomain = getPresetOptionalDomain(companyName);
  return optionalDomain === companyDomain;
};