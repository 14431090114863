import { IntegrationName } from 'signup-ui-lego-core/constants/IntegrationName';
import Logger from '../lib/Logger';
import { getIntegrationKey } from '../routing/getIntegrationKey';
const LOG = Logger.getLogger('getIntegrationName');
export const getIntegrationName = () => {
  const integrationKey = getIntegrationKey();
  if (!integrationKey) {
    LOG.error(`Failed to get integrations name on url: ${window.location.href}`);
    return null;
  }
  const integrationName = IntegrationName[integrationKey];
  return integrationName;
};