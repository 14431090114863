import imageUrls from '../images/imageUrls';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';
const verificationSenderEmail = 'noreply@hubspot.com';
const GMAIL_LINK = `https://mail.google.com/mail/u/0/#search/is:unread+from:(${verificationSenderEmail})+verification`;
const YAHOO_LINK = `https://mail.yahoo.com/d/search/keyword=from:${verificationSenderEmail} verification code`;
const OUTLOOK_LINK = 'https://outlook.live.com/mail/inbox';
const APPLE_MAIL_LINK = 'https://icloud.com/mail';
export let EmailClient;
(function (EmailClient) {
  EmailClient["Gmail"] = "gmail";
  EmailClient["Yahoo"] = "yahoo";
  EmailClient["Outlook"] = "outlook";
  EmailClient["Apple"] = "apple";
})(EmailClient || (EmailClient = {}));
export const emailClientFromDomain = email => {
  const domain = getDomainFromEmail(email);
  if (domain) {
    if (domain.includes('gmail.com') || domain.includes('hubspot.com')) {
      return EmailClient.Gmail;
    }
    if (domain.includes('yahoo.com')) {
      return EmailClient.Yahoo;
    }
    if (domain.includes('outlook.com')) {
      return EmailClient.Outlook;
    }
    if (domain.includes('icloud.com') || domain.includes('apple.com')) {
      return EmailClient.Apple;
    }
  }
  return null;
};
export const mailOptionsMapper = {
  [EmailClient.Gmail]: {
    link: GMAIL_LINK,
    logo: imageUrls.gmailLogo
  },
  [EmailClient.Yahoo]: {
    link: YAHOO_LINK,
    logo: imageUrls.yahooLogo
  },
  [EmailClient.Outlook]: {
    link: OUTLOOK_LINK,
    logo: imageUrls.outlookLogo
  },
  [EmailClient.Apple]: {
    link: APPLE_MAIL_LINK,
    logo: imageUrls.appleMailLogo
  }
};