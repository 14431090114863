import { LoadingStatusActionTypes } from './action-types';
export const initialLoadingStatusState = {
  loadingStack: [],
  copyCarouselFinished: {}
};
const loadingReducer = (state = initialLoadingStatusState, action) => {
  switch (action.type) {
    case LoadingStatusActionTypes.SetLoadingStatus:
      return Object.assign({}, state, {
        loadingStack: [...state.loadingStack, action.loadingKey]
      });
    case LoadingStatusActionTypes.ClearLoadingStatus:
      return Object.assign({}, state, {
        loadingStack: state.loadingStack.filter(key => key !== action.loadingKey),
        copyCarouselFinished: state.copyCarouselFinished[action.loadingKey] ? Object.assign({}, state.copyCarouselFinished, {
          [action.loadingKey]: null
        }) : state.copyCarouselFinished
      });
    case LoadingStatusActionTypes.ClearAnyLoading:
      return Object.assign({}, state, {
        loadingStack: [],
        copyCarouselFinished: {}
      });
    case LoadingStatusActionTypes.CopyCarouselFinished:
      return Object.assign({}, state, {
        copyCarouselFinished: Object.assign({}, state.copyCarouselFinished, {
          [action.loadingKey]: true
        })
      });
    default:
      return state;
  }
};
export default loadingReducer;