import { Flow } from 'signup-constants/Flow';
import { getIsMobile } from '../utils/deviceUtils';
import { SignupEmbedder } from 'signup-embedder';
import { getIsChatBotEnabledByLanguage } from './getIsChatBotEnabledByLanguage';
export const shouldShowChatBot = ({
  flowKey
}) => {
  const isChatbotEnabledByLanguage = getIsChatBotEnabledByLanguage();
  const allowedFlows = [Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service, Flow.CmsFree, Flow.HubSpotPartners, Flow.HubspotForStartups, Flow.AssetProvider, Flow.ClassroomTraining, Flow.Developers, Flow.CmsDevelopers, Flow.DirectoryListing, Flow.SolutionsProvider, Flow.Trial];
  return !getIsMobile() && allowedFlows.includes(flowKey) && isChatbotEnabledByLanguage && !SignupEmbedder.isActive();
};