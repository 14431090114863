import { useWidthMediaQueries } from './useWidthMediaQueries';
import { ScreenSize } from './MediaQuery';
export const useResponsiveness = () => {
  const widthBreakpoint = useWidthMediaQueries();
  const isMobile = widthBreakpoint <= ScreenSize.MD;
  const isDesktop = widthBreakpoint >= ScreenSize.Desktop;
  return {
    isMobile,
    isDesktop
  };
};