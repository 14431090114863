import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { Data } from 'signup-constants/signupData/Data';
import { dataInvalid } from '../store/signup-data-validation/action-creators';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { codeVerificationFailed } from '../store/step-code-verification/action-creators';
import { CodeVerificationErrorOption } from './types';
import { Tracker } from '../store/tracking/action-creators';
export const LOG = Logger.getLogger('handleCompleteVerificationError');
export const handleCodeVerificationError = (error, dispatch, getState) => {
  const state = getState();
  const code = getSignupDataValue(state, Data.CodeVerification);
  dispatch(codeVerificationFailed());
  dispatch(Tracker.signupInteraction('error-code-verification-failed'));
  LOG.error('/completeCodeVerification error', {
    extra: getSentryExtra(error)
  });
  dispatch(dataInvalid({
    dataKey: Data.CodeVerification,
    errorMessage: CodeVerificationErrorOption.ExpiredOrInvalidCode,
    validatedValue: code
  }));
};