import { ValidationStatus } from '../types';
const CODE_LENGTH = 6;
export let CodeError;
(function (CodeError) {
  CodeError["INCORRECT_FORMAT"] = "incorrectFormat";
})(CodeError || (CodeError = {}));
export const syncCodeValidator = value => {
  if (value.length !== CODE_LENGTH || !/^\d+$/.test(value)) {
    return CodeError.INCORRECT_FORMAT;
  }
  return ValidationStatus.VALID;
};