export let Locale;
(function (Locale) {
  Locale["de"] = "de";
  Locale["en"] = "en";
  Locale["es"] = "es";
  Locale["fr"] = "fr";
  Locale["ja"] = "ja";
  Locale["it"] = "it";
  Locale["nl"] = "nl";
  Locale["ptBr"] = "pt-br";
  Locale["fi"] = "fi";
  Locale["pl"] = "pl";
  Locale["sv"] = "sv";
  Locale["pt"] = "pt";
  Locale["zhTw"] = "zh-tw";
  Locale["da"] = "da";
  Locale["no"] = "no-no";
})(Locale || (Locale = {}));