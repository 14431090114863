import { HubsTrackingAttr } from 'signup-constants/HubsTrackingAttr';
import { UtmTrackingAttr } from 'signup-constants/UtmTrackingAttr';

// describe the parameters of type hubs_* that should overwrite the parameters utm_* before sending to amplitude
// example: the value of 'hubs_campaign', if found will be replacing the value of 'utm_campaign'
const HubToUtm = {
  [HubsTrackingAttr.Campaign]: UtmTrackingAttr.Campaign,
  [HubsTrackingAttr.Content]: UtmTrackingAttr.Content,
  [HubsTrackingAttr.Medium]: UtmTrackingAttr.Medium,
  [HubsTrackingAttr.Source]: UtmTrackingAttr.Source,
  [HubsTrackingAttr.Cta]: UtmTrackingAttr.Id,
  // legacy, all links jusing hubs_cta should be changed using hubs_id so that this mapping can be removed
  [HubsTrackingAttr.Id]: UtmTrackingAttr.Id
};
export default HubToUtm;