export function memoize(fn, hasher) {
  const cache = new Map();
  const memoized = function memoized(...args) {
    const hash = hasher(...args).toString();
    if (!cache.has(hash)) {
      cache.set(hash, fn(...args));
    }
    return cache.get(hash);
  };
  memoized.cache = cache;
  return memoized;
}