import parseErrorData from './parseErrorData';
export const getStatusMessage = data => {
  const parsedData = parseErrorData(data);
  let statusMessage;
  if (data) {
    if (parsedData) {
      statusMessage = parsedData.status;
    }
    if (!statusMessage || statusMessage === 'bad request') {
      statusMessage = 'error';
    } else {
      statusMessage = statusMessage.replace(new RegExp(' ', 'g'), '-').toLowerCase();
    }
  }
  return statusMessage;
};