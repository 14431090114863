import { PromisableMiddleware } from '../middlewares/PromisableMiddleware';
import { AuthActionTypes } from '../store/auth/action-types';
import { canRedirectExistingUserExistingAccount } from './canRedirectExistingUserExistingAccount';
import { redirectUserAfterPortalCreation } from './redirectAfterPortalCreation';
import { redirectExistingUser } from './redirectExistingUser';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { TrackingActionTypes } from '../store/tracking/action-types';
import { GoogleRecaptchaActionTypes } from '../store/google-recaptcha/action-types';
import { isExistingUserExistingAccount } from '../app/verificationTypeSelectors';
import { isFlow } from '../utils/isFlow';
import { Flow } from 'signup-constants/Flow';
let isRedirectingExistingUsers = false;
export const setIsRedirectingExistingUsers = value => {
  isRedirectingExistingUsers = value;
};
export const RedirectionMiddleware = PromisableMiddleware( /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
({
  store,
  action,
  setIsProcessing
}) => {
  if (Object.values(TrackingActionTypes).includes(action.type) || Object.values(GoogleRecaptchaActionTypes).includes(action.type)) {
    return Promise.resolve();
  }
  const flowRedirectFunc = getFlowConfig().canRedirect;
  const canRedirect = flowRedirectFunc ? flowRedirectFunc(store.getState()) : true;
  if (!canRedirect) {
    return Promise.resolve();
  }
  const canRedirectExistingUser = action.type === AuthActionTypes.RedirectExistingUser || canRedirectExistingUserExistingAccount(store.getState());
  let redirectStrategy = null;
  if (canRedirectExistingUser && !isRedirectingExistingUsers) {
    redirectStrategy = redirectExistingUser(store, action, setIsRedirectingExistingUsers);
  } else if (!isExistingUserExistingAccount(store.getState()) || isFlow(Flow.StandaloneCmsPartner)) {
    redirectStrategy = redirectUserAfterPortalCreation(store, setIsProcessing);
  }
  return redirectStrategy;
});