import { LoadingStatusActionTypes } from './action-types';
import { LoadingKey } from '../../app-active-loading/LoadingKey';
import { LoadingScreen } from '../../app-active-loading/LoadingScreen';
import { getLoadingStack } from './selectors';
const loadingKeyTimeouts = Object.create(null);
function clearLoadingKeyTimeout(loadingKey) {
  if (Object.prototype.hasOwnProperty.call(loadingKeyTimeouts, loadingKey)) {
    clearTimeout(loadingKeyTimeouts[loadingKey]);
    delete loadingKeyTimeouts[loadingKey];
  }
}
export function clearAllTimeouts() {
  for (const loadingKey of Object.keys(loadingKeyTimeouts)) {
    clearLoadingKeyTimeout(loadingKey);
  }
}
export const clearLoadingStatus = (loadingKey = LoadingKey.StandardLoading) => dispatch => {
  clearLoadingKeyTimeout(loadingKey);
  return dispatch({
    type: LoadingStatusActionTypes.ClearLoadingStatus,
    loadingKey
  });
};
export const setLoadingStatus = (loadingKey, delay = 0) => (dispatch, getState) => {
  const state = getState();
  const loadingStack = getLoadingStack(state);
  const loadingConfig = LoadingScreen[loadingKey];
  const shouldNotOverrideOtherLoading = loadingConfig.shouldNotOverrideOtherLoading && loadingConfig.shouldNotOverrideOtherLoading(state);
  if (!LoadingKey[loadingKey] || loadingStack.includes(loadingKey) || shouldNotOverrideOtherLoading) return;
  if (delay > 0) {
    setTimeout(() => dispatch({
      type: LoadingStatusActionTypes.SetLoadingStatus,
      loadingKey
    }), delay);
  } else {
    dispatch({
      type: LoadingStatusActionTypes.SetLoadingStatus,
      loadingKey
    });
  }
};
export const clearAnyLoading = () => dispatch => {
  return dispatch({
    type: LoadingStatusActionTypes.ClearAnyLoading
  });
};
export const copyCarouselHasFinished = loadingKey => dispatch => {
  return dispatch({
    type: LoadingStatusActionTypes.CopyCarouselFinished,
    loadingKey
  });
};
export const setStandardLoading = (delay = 0) => dispatch => {
  return dispatch(setLoadingStatus(LoadingKey.StandardLoading, delay));
};