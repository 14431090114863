import { ErrorKey } from '../app-active-error/ErrorKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { completeVerificationComplete } from '../store/app/action-creators';
import { setError } from '../store/error/action-creators';
import { Tracker } from '../store/tracking/action-creators';
export const LOG = Logger.getLogger('handleCompleteVerificationError');
export const handleCompleteVerificationError = (error, dispatch) => {
  dispatch(completeVerificationComplete());
  dispatch(Tracker.signupInteraction('error-complete-verification-failed'));
  if (error.status === 404) {
    return dispatch(setError(ErrorKey.EmailVerificationNotFound, error));
  }
  if (error.status === 'invalid username') {
    dispatch(Tracker.signupInteraction('invalid-email-error-complete-verification'));
  }
  LOG.error('/completeVerification error', {
    extra: getSentryExtra(error)
  });
  return dispatch(setError(ErrorKey.InternalError, error));
};