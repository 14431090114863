import { getFlowandNextSteps } from '../../flow-management/FlowStateManager';
import { NextStepManager } from '../../flow-management/NextStepManager';
import { shouldAddOptionalPhoneNumber } from '../../step-code-verification/shouldRemoveOptionalPhoneNumber';
import { setFlow } from '../../store/flow/action-creators';
import { Step } from 'signup-constants/Step';
export const BranchOptionalPhoneNumber = {
  name: 'BranchOptionalPhoneNumber',
  stateCheck: shouldAddOptionalPhoneNumber,
  rebuildFlow: (flow, state, dispatch) => {
    const {
      flowSteps
    } = getFlowandNextSteps(state);
    let nextSteps = flow;
    nextSteps = NextStepManager.replaceStep(Step.CompanyDomain, Step.CompanyDomain, Step.OptionalPhoneNumber)(nextSteps);
    dispatch(setFlow(nextSteps, flowSteps.preVerification, flowSteps.postVerification, flowSteps.postAccountCreation, flowSteps.postExistingAccountSelection));
    return nextSteps;
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};