import { getSentryExtra } from '../error/getSentryExtra';
import { normalizeUrl } from '../utils/normalizeUrl';
import { getQueryParam } from './getQueryParam';
import Logger from '../lib/Logger';
const LOG = Logger.getLogger('getLoginRedirectUrlFromQueryParam');
export const getLoginRedirectUrlFromQueryParam = (shouldLogInvalidUrl = true) => {
  const stringifiedLoginRedirectUrl = getQueryParam('loginRedirectUrl');
  const isLoginRedirectUrlEmpty = Boolean(!stringifiedLoginRedirectUrl);
  if (isLoginRedirectUrlEmpty) {
    return null;
  }
  const normalizedUrl = normalizeUrl(stringifiedLoginRedirectUrl);
  try {
    const loginRedirectUrl = new URL(normalizedUrl);
    return loginRedirectUrl;
  } catch (error) {
    if (shouldLogInvalidUrl) {
      LOG.info('Invalid loginRedirectUrl', {
        extra: Object.assign({}, getSentryExtra(error), {
          stringifiedLoginRedirectUrl
        })
      });
    }
    return null;
  }
};