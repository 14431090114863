import enviro from 'enviro';
import Raven from 'raven-js';
import { Environment } from '../types/Environment';
export const loggerKebabCase = string => {
  const strippedWordOnlyArray = string.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+|[A-Z]|[0-9]+/g) || [];
  const result = strippedWordOnlyArray.join('-').toLowerCase();
  return result;
};
export default class Logger {
  constructor(isProd = enviro.isProd(), isDeployed = enviro.deployed(), fingerprint = []) {
    this.isProd = isProd;
    this.isDeployed = isDeployed;
    this.fingerprint = fingerprint;
  }
  debug(message, context = {}) {
    this.log('debug', message, context);
    if (!this.isProd) {
      /* eslint-disable-next-line no-console */
      console.debug(message, context);
    }
  }
  info(message, context = {}) {
    this.log('info', message, context);
    if (!this.isProd) {
      /* eslint-disable-next-line no-console */
      console.info(message, context);
    }
  }
  warn(message, context = {}) {
    this.log('warning', message, context);
    if (!this.isProd) {
      /* eslint-disable-next-line no-console */
      console.warn(message, context);
    }
  }
  error(message, context = {}) {
    this.log('error', message, context);
    if (!this.isProd) {
      /* eslint-disable-next-line no-console */
      console.error(message, context);
    }
  }
  critical(message, context = {}) {
    this.log('critical', message, context);
    if (!this.isProd) {
      /* eslint-disable-next-line no-console */
      console.error(message, context);
    }
  }
  getEnvironment() {
    return this.isProd ? Environment.Prod : Environment.QA;
  }
  log(level, message, context = {}) {
    const ravenOptions = Object.assign({}, context);
    const environment = this.getEnvironment();
    if (!ravenOptions.level) {
      ravenOptions.level = level;
    }
    if (!ravenOptions.fingerprint) {
      ravenOptions.fingerprint = [environment, ...this.fingerprint].concat([loggerKebabCase(message)]);
    }
    if (level !== 'debug') {
      this.reportToSentry(`[${environment.toUpperCase()}] ${message}`, ravenOptions);
    }
  }
  reportToSentry(message, ravenOptions) {
    if (!this.isDeployed) {
      return;
    }
    if (!this.isProd) {
      /* eslint-disable-next-line no-console */
      console.log(`[${ravenOptions.level}] (reported to Sentry) %s, %o`, message, ravenOptions);
    }
    Raven.captureMessage(message, ravenOptions);
  }
  static getLogger(name, loggerOptions = {}) {
    loggerOptions = Object.assign({}, {
      isProd: enviro.isProd(),
      isDeployed: enviro.deployed(),
      fingerprint: [name]
    }, loggerOptions);
    return new Logger(loggerOptions.isProd, loggerOptions.isDeployed, loggerOptions.fingerprint);
  }
}