export const getSentryExtra = value => {
  let extra = {};
  if (value === null) {
    return extra;
  }
  if (value instanceof Error) {
    extra = Object.assign({}, extra, {
      message: value.message,
      stack: value.stack
    });
  }
  if (typeof value === 'string' && value.length) {
    extra = Object.assign({}, extra, {
      message: value
    });
  }
  if (typeof value === 'object' && !(value instanceof Error)) {
    const {
      errorMessage,
      status,
      responseText,
      responseJSON = {}
    } = value;
    const {
      message
    } = responseJSON;
    if (errorMessage) Object.assign(extra, {
      message: errorMessage
    });
    if (status) Object.assign(extra, {
      status
    });
    if (responseText) Object.assign(extra, {
      response: responseText
    });
    if (responseJSON && message) Object.assign(extra, {
      responseMessage: message
    });
  }
  return extra;
};