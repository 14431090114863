import http from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
// We would prefer to use https://git.hubteam.com/HubSpot/frontend-preferences-client,
// but the current implementation doesn't support configuring Hublets.
export const writeHubUserAttribute = (key, value, hublet) => {
  return http.post(`${getFullUrl('app-api', {
    hubletOverride: hublet
  })}/user-attributes/v1/app-preferences/write`, {
    data: {
      name: key,
      value
    },
    query: {
      caller: 'signup-ui-lego'
    }
  }).then(responseJson => {
    if (!responseJson || typeof responseJson !== 'object' || responseJson['@result'] !== 'OK') {
      throw new Error('Hub User Attributes write failure', {
        cause: {
          responseJson,
          key,
          value,
          hublet
        }
      });
    }
  });
};