import { getExperimentsFromState, isCopyExperimentActive } from '../store/experiments/selectors';
import { getExperimentKeys, TEST_KEY } from './ExperimentKeys';
export const filterCopyExperiments = state => {
  const experiments = getExperimentsFromState(state);
  const nonCopyExperimentKeys = [...getExperimentKeys(), TEST_KEY];
  let copyExperimentKeys = [];
  Object.keys(experiments).forEach(key => {
    if (!nonCopyExperimentKeys.includes(key)) {
      copyExperimentKeys = [key, ...copyExperimentKeys];
    }
  });
  return copyExperimentKeys;
};
export const getActiveCopyExperimentIds = state => {
  const copyExperimentKeys = filterCopyExperiments(state);
  return copyExperimentKeys.filter(key => {
    return isCopyExperimentActive(state, key);
  });
};