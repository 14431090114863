import { getUtk } from 'usage-tracker-core/common/defaultTrackers';
import { getActiveExperimentsParameters } from '../experiments/ExperimentUtils';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getApplicationLocale } from '../i18n/LocaleUtils';
import { getPreferredReferrer } from '../i18n/ReferrerUtils';
import { getQueryParams } from '../routing/getQueryParams';
import { getVerificationType } from '../store/app/selectors';
import getAppFromLoginRedirectUrl from '../tracking/getAppFromLoginRedirectUrl';
import { getSignupMode } from '../tracking/getSignupMode';
import { getUserDeviceType } from '../utils/deviceUtils';
import { getUrlData } from '../utils/urls';
import { parseUtmData } from '../utm/utmHelpers';
export const getAmplitudeEventBody = state => {
  const signupProperties = {
    signupType: getFlowConfig().appName,
    signupMode: getSignupMode(),
    signupDevice: getUserDeviceType()
  };
  const queryParams = getQueryParams();
  const utms = parseUtmData(queryParams);
  const referrer = getPreferredReferrer();
  const referrerData = getUrlData(referrer);
  const experimentParameters = getActiveExperimentsParameters(state);
  const utk = getUtk();
  const applicationId = getAppFromLoginRedirectUrl();
  return Object.assign({
    signupProperties,
    queryParams,
    hutk: utk,
    signupVerificationType: getVerificationType(state),
    applicationId
  }, experimentParameters.length !== 0 && {
    experimentParameters
  }, Object.keys(referrerData).length !== 0 && {
    referrerData
  }, Object.keys(utms).length !== 0 && {
    utms
  }, {
    languageOptions: getApplicationLocale()
  });
};