import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { useEffect, useState } from 'react';
import Logger from '../lib/Logger';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('LoginClient');
const getGoogleNonceFromLoginApi = () => {
  return noAuthApiClient.post('/login-api/v1/google/init', {
    data: {
      rememberLogin: true
    }
  });
};
export const useGoogleLoginNonce = () => {
  const [googleSignInNonce, setGoogleSignInNonce] = useState();
  const [didNonceRequestFail, setDidNonceRequestFail] = useState(false);
  useEffect(() => {
    getGoogleNonceFromLoginApi().then(({
      nonce
    }) => {
      setGoogleSignInNonce(nonce);
    }).catch(e => {
      LOG.warn(`Failed to retrieve nonce parameter for Google from login`, {
        extra: getSentryExtra(e)
      });
      setDidNonceRequestFail(true);
    });
  }, []);
  return {
    googleSignInNonce,
    didNonceRequestFail
  };
};