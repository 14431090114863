import { waitForVariable } from 'signup-ui-lego-core/utils/waitForVariable';
import { GoogleRecaptchaActionTypes } from './action-types';
import { getV3SiteKey } from './sitekeys';
export const updateRecaptchaToken = (recaptchaToken, sitekey = getV3SiteKey()) => dispatch => {
  dispatch({
    type: GoogleRecaptchaActionTypes.UpdateRecaptchaToken,
    payload: {
      token: recaptchaToken,
      sitekey
    }
  });
};
export const generateRecaptchaTokenFailed = error => dispatch => {
  dispatch({
    type: GoogleRecaptchaActionTypes.RequestRecaptchaTokenFailed,
    payload: {
      error
    }
  });
};
export const generateRecaptchaToken = action => dispatch => {
  return new Promise((resolve, reject) => {
    waitForVariable('grecaptcha').then(() => {
      const recaptcha = window.grecaptcha;
      recaptcha.enterprise.ready(() => {
        if (!action) {
          reject(new Error('The action attribute is empty'));
          return;
        }
        recaptcha.enterprise.execute(getV3SiteKey(), {
          action
        }).then(token => {
          dispatch(updateRecaptchaToken(token));
          resolve(token);
        }).catch(error => {
          dispatch(generateRecaptchaTokenFailed(error));
          reject(new Error(`The grecaptcha.enterprise.execute failed with error: ${error}`));
        });
      });
    }).catch(() => {
      reject(new Error('The recaptcha is not available or not loaded'));
    });
  });
};