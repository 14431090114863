import styled, { keyframes } from 'styled-components';
import UISelectableButton from 'UIComponents/button/UISelectableButton';
import { isTransitionAndAnimationsDisabled } from '../selenium/seleniumFeatures';
const config = {
  slidePx: 3,
  spacing: 0.05,
  animationDelay: 0.3,
  animationTime: 0.2,
  disabled: isTransitionAndAnimationsDisabled()
};
const translateStart = `transform: translateX(${config.slidePx}px);`;
const translateEnd = `transform: translateX(0);`;
const appear = keyframes(["0%{", " opacity:", ";}100%{", ";opacity:1;visibility:visible;}"], translateStart, 0.4, translateEnd);
export const AnimatedButton = !config.disabled ? styled(UISelectableButton).withConfig({
  displayName: "ButtonAnimations__AnimatedButton",
  componentId: "sc-1uxokg1-0"
})(["transition:all;animation:", " ", "s cubic-bezier(0.42,0,0.58,1);animation-fill-mode:forwards;visibility:hidden;", ""], appear, config.animationTime, props => `
    animation-delay: ${config.animationDelay + config.spacing * props.index + 0.01 * Math.pow(props.index, 1.9)}s;
  `) : styled(UISelectableButton).withConfig({
  displayName: "ButtonAnimations__AnimatedButton",
  componentId: "sc-1uxokg1-1"
})([""]);