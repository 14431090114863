import I18n from 'I18n';
import { fetchIndustriesAvailable } from '../requests/fetchIndustriesAvailable';
/**
 * Industry Service
 *
 * @export
 * @class IndustryService
 */
export class IndustryService {
  /**
   * Holds the singleton instance of IndustryService.
   *
   * @static
   * @memberof IndustryService
   */

  /**
   * Gets the singleton instance of IndustryService.
   *
   * @static
   * @returns { IndustryService } The singleton instance.
   * @memberof IndustryService
   */
  static getInstance() {
    if (!IndustryService.instance) {
      IndustryService.instance = new IndustryService();
    }
    return IndustryService.instance;
  }

  /**
   * Translates a given slug into the localized name of an Industry
   *
   * @param {String} slug The industry slug
   * @returns {String} The localized name of the industry.
   * @memberof IndustryService
   */
  translate(slug) {
    return I18n.text(`industry.${slug}`);
  }

  /**
   * Transforms a list of { name, id } object into
   * { value, text } where text is the translated
   * name of the industry.
   *
   * @param {[{ name: String, id: Number }]} [industries=[]]
   * @returns {[{ slug: String, value: Number, text: String }]}
   * @memberof IndustryService
   */
  transform(industries) {
    return industries.map(entry => ({
      value: entry.name,
      text: this.translate(entry.name)
    }));
  }
  /**
   * Get all industries available.
   *
   * @returns {Promise} A Promise instance.
   * @memberof IndustryService
   */
  available(options = {}) {
    return fetchIndustriesAvailable(options).then(this.transform.bind(this));
  }
}
export default IndustryService.getInstance();