import { SignupDataActionTypes } from './action-types';
export const setSignupDataValue = (dataKey, newValue) => dispatch => dispatch({
  type: SignupDataActionTypes.UpdateData,
  dataKey,
  value: newValue
});
export const bulkUpdate = signupData => dispatch => {
  dispatch({
    type: SignupDataActionTypes.BulkUpdate,
    signupData
  });
};
export const stampPrefilled = (prefills, prefillType) => dispatch => {
  dispatch({
    type: SignupDataActionTypes.StampPrefilled,
    prefilledKeys: Object.keys(prefills),
    prefillType
  });
};
export const bulkSetSignupDataAndSetValid = signupData => dispatch => {
  return dispatch({
    type: SignupDataActionTypes.BulkUpdateValid,
    signupData
  });
};
export const bulkSetSignupDataCompleted = signupData => dispatch => {
  return dispatch({
    type: SignupDataActionTypes.BulkSetCompleted,
    signupData
  });
};
export const bulkSetSignupDataAndSetValidAndCompleted = signupData => dispatch => {
  return dispatch({
    type: SignupDataActionTypes.BulkSetValidAndCompleted,
    signupData
  });
};
export const bulkSetSignupDataSkip = signupData => dispatch => {
  return dispatch({
    type: SignupDataActionTypes.BulkSetSkipped,
    signupData
  });
};
export const bulkEmptySignupDataAndSetFresh = (signupDataKeys, {
  shouldEmptyPrefilledValues = true
} = {}) => dispatch => {
  if (shouldEmptyPrefilledValues) {
    return dispatch({
      type: SignupDataActionTypes.BulkEmptyFresh,
      signupDataKeys
    });
  }
  return dispatch({
    type: SignupDataActionTypes.BulkEmptyFresh,
    signupDataKeys
  });
};
export const setSignupDataCompleted = dataKey => dispatch => {
  return dispatch({
    type: SignupDataActionTypes.DataCompleted,
    dataKey
  });
};
export const setStepAsSkipped = dataKey => dispatch => {
  dispatch({
    type: SignupDataActionTypes.SetStepAsSkipped,
    dataKey
  });
};
export const prefillDataStored = () => dispatch => {
  dispatch({
    type: SignupDataActionTypes.PrefillDataStored
  });
};
export const resetDataFlags = dataKey => dispatch => {
  dispatch({
    type: SignupDataActionTypes.ResetDataFlags,
    dataKey
  });
};