import { useSelector } from 'react-redux';
import { useLoadFlow } from '../hooks/useLoadFlow';
import { useRestoreScroll } from '../hooks/useRestoreScroll';
import { useSetupUnloadAlert } from '../hooks/useSetupUnloadAlert';
import { useStartApp } from '../hooks/useStartApp';
import { useTrackStepView } from '../hooks/useTrackStepView';
import { useViewComponent } from '../hooks/useViewComponent';
import { getFlowKey } from '../routing/getFlowKey';
import { getCurrentStep } from '../store/flow/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { useTrackCookiesConsent } from '../tracking/useTrackCookiesConsent';
import { useTrackAcqExperimentExposure } from '../experiments/trackingExposure/trackingExposure';
export const useViewContainer = () => {
  const {
    unloadAlert
  } = useSetupUnloadAlert();
  const ViewComponent = useViewComponent({
    unloadAlert
  });
  const currentStep = useSelector(getCurrentStep);
  const activeLoading = useSelector(getActiveLoading);
  useStartApp();
  useLoadFlow();
  useTrackAcqExperimentExposure();
  useRestoreScroll();
  useTrackStepView();
  useTrackCookiesConsent();
  return {
    ViewComponent,
    viewContainerProps: {
      'data-step': currentStep,
      'data-loading': Boolean(activeLoading),
      'data-loading-key': activeLoading,
      flow: getFlowKey()
    }
  };
};