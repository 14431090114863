import { Step } from 'signup-constants/Step';
import { isEmailVerificationSignup } from '../app/verificationTypeSelectors';
import { isPostVerification } from '../flow-settings/settingsSelectors';
import { getCompletedSteps, getCurrentStep, getNextSteps, getPostAccountCreationSteps, getPostVerificationSteps, getPreVerificationSteps } from '../store/flow/selectors';
const UNCOUNTABLE_STEPS = [Step.ExistingUser, Step.LandingPage, Step.ResendEmail, Step.Verification, Step.CodeVerification, Step.HublessNoAccount, Step.IntegrationsAccountCreated, Step.CmsExistingUser];
const removeUncountableSteps = steps => {
  return steps ? steps.filter(stepKey => UNCOUNTABLE_STEPS.indexOf(stepKey) === -1) : [];
};
export const getStepNumbering = (state, nextSteps = []) => {
  const preVerification = getPreVerificationSteps(state);
  const postVerification = getPostVerificationSteps(state);
  const postAccountCreation = getPostAccountCreationSteps(state);
  const pre = removeUncountableSteps(preVerification);
  const post = removeUncountableSteps(postVerification);
  const postAccountCreationSteps = removeUncountableSteps(postAccountCreation);
  const previousSteps = removeUncountableSteps(getCompletedSteps(state));
  const previousStepsCount = previousSteps.length;
  nextSteps = removeUncountableSteps(nextSteps);
  const nextStepsCount = nextSteps.length;
  const currentStep = getCurrentStep(state);
  const shouldAddExtra = currentStep ? 1 : 0;
  const postVerificationCount = isPostVerification() ? nextStepsCount + previousStepsCount + shouldAddExtra : post.length + postAccountCreationSteps.length;
  const totalSteps = !isEmailVerificationSignup(state) ? nextStepsCount + previousStepsCount + shouldAddExtra : pre.length + postVerificationCount;
  const currentStepCount = isPostVerification() ? previousStepsCount + pre.length + 1 : previousStepsCount + 1;
  return {
    currentStep: currentStepCount,
    totalSteps
  };
};
export const getCurrentStepNumbering = state => {
  const nextSteps = getNextSteps(state);
  return getStepNumbering(state, nextSteps);
};
export const areTherePostAccountCreationSteps = state => {
  const postAccountCreation = getPostAccountCreationSteps(state);
  return postAccountCreation.length > 0;
};
export const onlyMobileAppPromptStep = state => {
  const postAccountCreation = getPostAccountCreationSteps(state);
  return postAccountCreation.length > 0 && postAccountCreation[0] === Step.MobileAppPrompt;
};