import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["type"];
import { getCurrentStep } from '../store/flow/selectors';
import { LOG } from './HttpRequester';
export const logEndpointTriggered = ({
  endpointKey,
  triggerAction,
  triggerReason,
  state
}) => {
  const currentStep = getCurrentStep(state);
  const {
      type
    } = triggerAction,
    actionData = _objectWithoutPropertiesLoose(triggerAction, _excluded);
  const actionDetails = Object.keys(actionData).length === 0 ? '' : `\n\t\t${JSON.stringify(actionData)}`;
  const actionDescription = `${type}${actionDetails}`;
  LOG.debug(`\nEndpoint [ ${endpointKey} ] triggered
    \tAction: ${actionDescription}
    \tReason: "${triggerReason}"
    \tAt current step: ${currentStep}
    `);
};