import { PortalTierActionTypes } from './action-types';
export const fetchPortalTierInfo = () => dispatch => {
  return dispatch({
    type: PortalTierActionTypes.FetchPortalTierInfo
  });
};
export const setPortalTierInfo = portalTierInfo => dispatch => {
  return dispatch({
    type: PortalTierActionTypes.SetPortalTierInfo,
    portalTiers: portalTierInfo
  });
};