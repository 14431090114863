import { useState } from 'react';
import { SignupEmbedder } from '../SignupEmbedder';
import { SignupEmbedderOptions } from '../SignupEmbedderOptions';
export const useSignupEmbedder = () => {
  const [isEmbedded] = useState(SignupEmbedder.isEmbedded());
  const [isPopup] = useState(SignupEmbedder.isPopup());
  const [isActive] = useState(SignupEmbedder.isActive());
  const [autoFocusDisabled] = useState(SignupEmbedderOptions.hasOption(SignupEmbedderOptions.SIGNUP_AUTO_FOCUS_DISABLED_OPTION));
  const [embeddingPage] = useState(SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_EMBEDDING_PAGE_OPTION));
  const [shouldShowMessaging] = useState(SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_MESSAGING));
  return {
    isEmbedded,
    isPopup,
    isActive,
    autoFocusDisabled,
    embeddingPage,
    shouldShowMessaging
  };
};