import { getFlowKey } from '../routing/getFlowKey';
import { getQueryParam } from '../routing/getQueryParam';
import { getIndustrySpecificMessaging, industryHasConfig } from './getIndustrySpecificMessaging';
import { getOverrideMessage, messageOverrideConfigExists } from './getMessageOverride';
import { getMessagePlatform } from './getMessagePlatform';
import { getSpecificMessagingFunction } from './getSpecificMessaging';
import { MessagingConfig } from './MessagingConfig';
import { DesktopDefaults, MobileDefaults } from './MessagingDefaults';
const getFlowDefault = isMobile => {
  const flowKey = getFlowKey();
  const platform = getMessagePlatform(isMobile);
  const platformMessaging = MessagingConfig[platform];
  const messaging = platformMessaging.get(flowKey);
  const specificMessagingFunction = getSpecificMessagingFunction();
  const specificMessaging = specificMessagingFunction && specificMessagingFunction();
  if (messaging) {
    if (specificMessaging) {
      return new Map([...Array.from(messaging.entries()), ...Array.from(specificMessaging.entries())]);
    }
    return messaging;
  } else {
    return isMobile ? MobileDefaults : DesktopDefaults;
  }
};
const getByIndustryAndStep = ({
  industry,
  currentStep,
  isMobile
}) => {
  const flowDefault = getFlowDefault(isMobile);
  const industryChanges = getIndustrySpecificMessaging({
    industry,
    isMobile
  });
  const updatedMessaging = industryChanges ? new Map([...Array.from(flowDefault.entries()), ...Array.from(industryChanges.entries())]) : flowDefault;
  return updatedMessaging.get(currentStep);
};
export const getDefaultMessageKey = ({
  step,
  isMobile
}) => {
  const flowDefaultMessaging = getFlowDefault(isMobile);
  return flowDefaultMessaging.get(step) || null;
};
export const getMessageKey = ({
  currentStep,
  industry,
  isMobile
}) => {
  const messageOverride = getQueryParam('message');
  const overrideMessage = getOverrideMessage({
    isMobile,
    step: currentStep,
    message: messageOverride
  });
  if (messageOverride && messageOverrideConfigExists({
    message: messageOverride,
    isMobile,
    step: currentStep
  }) && overrideMessage) {
    return overrideMessage;
  }
  if (!industry || !industryHasConfig(industry.toLowerCase())) {
    const defaultMessageKey = getDefaultMessageKey({
      step: currentStep,
      isMobile
    });
    return defaultMessageKey;
  }
  const industryKey = industry.toLowerCase();
  const messageKey = getByIndustryAndStep({
    industry: industryKey,
    currentStep,
    isMobile
  });
  return messageKey || null;
};