import { getFlowKey } from '../routing/getFlowKey';
import { getHubSpotConversationsClient } from './getHubSpotConversationsClient';
import { shouldShowChatBot } from './shouldShowChatBot';
export const useSetupChatbot = ({
  loaded,
  setLoaded
}) => {
  const flowKey = getFlowKey();
  const showChatBot = shouldShowChatBot({
    flowKey
  });
  const conversationsClient = getHubSpotConversationsClient();
  const setupChatbot = () => {
    if (showChatBot && conversationsClient && conversationsClient.widget && !loaded) {
      //This is so that we can use the Manage Cookies modal while the chatbot is on the page
      const interval = setInterval(() => {
        const container = document.getElementById('hubspot-messages-iframe-container');
        if (container) {
          container.style.zIndex = '1';
          clearInterval(interval);
        }
      }, 500);
      conversationsClient.widget.load();
      setLoaded(true); // it is actually not loaded but more like it's loading.
    }
  };
  return {
    setupChatbot
  };
};