import { getFullUrl as HSUtilsGetFullUrl } from 'hubspot-url-utils';
export const HubletUrl = {
  apiUrl: undefined,
  appUrl: undefined,
  getAppUrl() {
    return this.appUrl;
  },
  getApiUrl() {
    return this.apiUrl;
  },
  getFullUrl(subDomainPrefix, hublet) {
    const overrideConfig = {};
    if (hublet) {
      overrideConfig.hubletOverride = hublet;
    }
    return HSUtilsGetFullUrl(subDomainPrefix, overrideConfig);
  },
  initializeURLs(hublet, appUrl, apiUrl) {
    this.appUrl = appUrl;
    if (!this.appUrl) {
      this.appUrl = this.getFullUrl('app', hublet);
    }
    this.apiUrl = apiUrl;
    if (!this.apiUrl) {
      this.apiUrl = this.getFullUrl('app-api', hublet);
    }
  },
  // Just for unit testing cleanliness
  tearDownURLs() {
    this.appUrl = undefined;
    this.apiUrl = undefined;
  }
};

// Only used in the 'should be impossible' case of PortalId being undefined
export function homeLink() {
  return '/home';
}
export function gettingStartedLink(portalId) {
  return `/user-guide/${portalId}`;
}
export function productSetupLink(portalId) {
  if (!portalId) {
    return `${HubletUrl.getAppUrl()}/configuration/`;
  }
  return `${HubletUrl.getAppUrl()}/configuration/${portalId}/sales-free`;
}
export const GMAIL_URL = 'https://mail.google.com/mail';
export const getMSOffice365Url = () => `${HubletUrl.getAppUrl()}/office365app/signup-success`;
export const getMSVisualStudioOfficeToolsUrl = () => `${HubletUrl.getAppUrl()}/sales-client-iframes/vsto/onboarding/signup`;
export const getAiOnboardingFlowUrl = portalId => `${HubletUrl.getAppUrl()}/cms-onboarding/${portalId}/ai-website-builder/flow`;