export let VerificationType;
(function (VerificationType) {
  VerificationType["Google"] = "google";
  VerificationType["GooglePrompt"] = "google-prompt";
  VerificationType["Microsoft"] = "microsoft";
  VerificationType["Email"] = "email";
  VerificationType["ExistingUser"] = "existing-user";
  VerificationType["ExistingUserExistingAccount"] = "existing-user-existing-account";
  VerificationType["EmailByCode"] = "email-by-code";
  VerificationType["Apple"] = "apple";
})(VerificationType || (VerificationType = {}));