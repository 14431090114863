import { ColorThemeOption } from '../../messaging/ColorLibrary';
import { BlobKey } from './BlobKey';
export const BlobConfig = {
  [BlobKey.GridBlob]: {
    configColorThemeOption: ColorThemeOption.Marigold
  },
  [BlobKey.IllustrationBlob]: {
    configColorThemeOption: ColorThemeOption.YellowLight
  },
  [BlobKey.ImageBlob]: {
    configColorThemeOption: ColorThemeOption.YellowLight
  },
  [BlobKey.AnimationBlob]: {
    configColorThemeOption: ColorThemeOption.YellowLight
  }
};
export const DEFAULT_MESSAGING = {};