export let CmsSignupIntentOption;
(function (CmsSignupIntentOption) {
  CmsSignupIntentOption["Business"] = "business";
  CmsSignupIntentOption["Personal"] = "personal";
})(CmsSignupIntentOption || (CmsSignupIntentOption = {}));
export let CmsBusinessMaturityOption;
(function (CmsBusinessMaturityOption) {
  CmsBusinessMaturityOption["ExploringIdea"] = "exploring-idea";
  CmsBusinessMaturityOption["ReadyToLaunch"] = "ready-to-launch";
  CmsBusinessMaturityOption["RecentlyLaunched"] = "recently-launched";
  CmsBusinessMaturityOption["EstablishedBusiness"] = "established-business";
})(CmsBusinessMaturityOption || (CmsBusinessMaturityOption = {}));