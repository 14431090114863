import { MARKETING, OTHER, SALES, SERVICES_SUPPORT, SALES_HUB_INTEREST, MARKETING_HUB_INTEREST, CUSTOMER_SERVICE_HUB_INTEREST, SURVEY_KEYS } from '../constants/surveyConstants';
function mapJobFieldToHubInterest(jobField) {
  switch (jobField) {
    case SALES:
      return SALES_HUB_INTEREST;
    case MARKETING:
      return MARKETING_HUB_INTEREST;
    case SERVICES_SUPPORT:
      return CUSTOMER_SERVICE_HUB_INTEREST;
    case OTHER:
      return SALES_HUB_INTEREST;
    default:
      return SALES_HUB_INTEREST;
  }
}
export function maybeMapJobFieldToHubInterest(survey) {
  const jobField = survey && survey[SURVEY_KEYS.JOB_FIELD];
  if (!jobField || !survey || survey[SURVEY_KEYS.HUB_INTEREST]) {
    return survey;
  }
  survey[SURVEY_KEYS.HUB_INTEREST] = mapJobFieldToHubInterest(jobField);
  return survey;
}