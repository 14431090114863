import { CompanySizeNumber } from 'signup-constants/signupData/CompanySizeNumber';
import { CompanySizeAttributes } from 'signup-constants/signupData/CompanySizeAttributes';
import { CompanySizeOption } from 'signup-constants/signupData/CompanySizeOption';
export const getCompanySizeOptionFromEmployeesRange = employeesRange => {
  if (!employeesRange) {
    return;
  }
  const {
    min,
    max
  } = employeesRange;
  if (min >= CompanySizeNumber.TenThousandOneOrMore) {
    return CompanySizeOption.TenThousandOneOrMore;
  }
  const companySizeOptionToPrefill = Object.keys(CompanySizeAttributes).find(companySizeOption => {
    const {
      companySizeRange
    } = CompanySizeAttributes[companySizeOption];
    return min >= companySizeRange.min && max <= companySizeRange.max;
  });
  return companySizeOptionToPrefill;
};