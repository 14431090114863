import { GoogleRecaptchaActionTypes } from './action-types';
export const initialGoogleRecaptchaState = {
  token: null,
  sitekey: null,
  error: null
};
const googleRecaptchaReducer = (state = initialGoogleRecaptchaState, action) => {
  switch (action.type) {
    case GoogleRecaptchaActionTypes.UpdateRecaptchaToken:
      return Object.assign({}, state, {
        token: action.payload.token,
        sitekey: action.payload.sitekey
      });
    case GoogleRecaptchaActionTypes.RequestRecaptchaTokenFailed:
      return Object.assign({}, state, {
        error: action.payload.error
      });
    default:
      return state;
  }
};
export default googleRecaptchaReducer;