import { useSelector } from 'react-redux';
import { LoadingScreen } from '../app-active-loading/LoadingScreen';
import Logger from '../lib/Logger';
import { getIsCompleteVerificationComplete } from '../store/app/selectors';
import { authIsNotFinishedPreVerification } from '../store/auth/selectors';
import { getActiveError } from '../store/error/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { Step } from 'signup-constants/Step';
import { getStepConfig } from '../views/getStepConfig';
import { useCurrentStep } from './useCurrentStep';
const LOG = Logger.getLogger('useViewComponent');
export const useViewComponent = ({
  unloadAlert
}) => {
  const {
    currentStep
  } = useCurrentStep();
  const activeError = useSelector(getActiveError);
  const activeLoading = useSelector(getActiveLoading);
  const authIsNotFinished = useSelector(authIsNotFinishedPreVerification);
  const completeVerificationIsNotFinished = !useSelector(getIsCompleteVerificationComplete);
  const stepConfig = getStepConfig(currentStep);
  const canDisplayAStep = stepConfig && !authIsNotFinished && !completeVerificationIsNotFinished;
  // @ts-expect-error TODO: Find a way to convert an active error into a Step
  const errorConfig = getStepConfig(activeError);
  const isLoading = activeLoading && LoadingScreen[activeLoading];
  const shouldRender = canDisplayAStep || errorConfig || isLoading;
  if (!shouldRender) {
    return null;
  }
  if (activeLoading) {
    return LoadingScreen[activeLoading].view;
  }
  if (activeError) {
    unloadAlert.removeAlert();
    if (!errorConfig) {
      LOG.error('Unknown errorConfig', {
        extra: {
          activeError,
          activeLoading,
          canDisplayAStep,
          currentStep,
          isLoading
        }
      });
      return getStepConfig(Step.StandardCreationError).view;
    }
    return errorConfig.view;
  }
  return stepConfig ? stepConfig.view : null;
};