import { useEffect, useState } from 'react';
const COLUMN_MAPPING = [{
  charLength: 30,
  columnCount: 1
}];
export const useButtonGridPreferredColumnCount = (options = [], dataKey, getButtonText) => {
  const [preferredColumnCount, setPreferredColumnCount] = useState(0);
  useEffect(() => {
    const maxLength = Math.max(...options.map((key, index) => {
      const text = getButtonText({
        dataKey,
        key,
        index
      });
      return text && typeof text === 'string' ? text.length : -1;
    }));
    const columnMap = COLUMN_MAPPING.find(({
      charLength
    }) => {
      return maxLength >= charLength;
    });
    if (columnMap) {
      setPreferredColumnCount(columnMap.columnCount);
    }
  }, [getButtonText, options, dataKey]);
  return {
    preferredColumnCount
  };
};