import { isPreVerification } from '../flow-settings/settingsSelectors';
import PortalIdContainer from '../portal-id-container/PortalIdContainer';
import { updateAuth } from '../store/auth/action-creators';
import TrackerContainer from '../tracking/TrackerContainer';
export const setAuth = auth => dispatch => {
  const {
    hubIds = [],
    id: userId,
    email,
    firstName,
    lastName
  } = auth;
  const user = {
    firstName,
    lastName,
    email
  };
  const portalId = PortalIdContainer.get() || hubIds[0];
  if (portalId) {
    PortalIdContainer.set(portalId);
  }
  const trackerProperties = Object.assign({
    namespace: 'signup',
    email,
    userId
  }, portalId && {
    hubId: portalId
  });
  if (isPreVerification()) {
    TrackerContainer.switchToIdentifiableTracker(email);
    TrackerContainer.setProperties(trackerProperties);
  }
  dispatch(updateAuth(Object.assign({
    auth,
    user,
    userId,
    initialized: true
  }, portalId ? {
    portalId
  } : {
    gates: [],
    portal: {},
    portalId: null
  })));
  return Promise.resolve({
    email
  });
};