export let ExperimentActionTypes;
(function (ExperimentActionTypes) {
  ExperimentActionTypes["BulkUpdateExperiments"] = "[Experiments] Bulk Update Experiments";
  ExperimentActionTypes["ExperimentsFetched"] = "[Experiments] Experiments Fetched";
  ExperimentActionTypes["FetchExperiments"] = "[Experiments] Fetch Experiments";
  ExperimentActionTypes["TrackExposure"] = "[Experiments] Track Exposure";
  ExperimentActionTypes["SetExperimentUtk"] = "[Experiments] Set Experiment Utk";
  ExperimentActionTypes["UpdateActiveExperimentParameters"] = "[Experiments] Update Active Experiments Parameters";
  ExperimentActionTypes["UnenrollExperiment"] = "[Experiments] unenroll experiment";
  ExperimentActionTypes["ReenrollExperiment"] = "[Experiments] reenroll experiment";
})(ExperimentActionTypes || (ExperimentActionTypes = {}));