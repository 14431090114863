// @ts-expect-error dependency missing types
import SafeStorage from 'SafeStorage';
import { memoize } from '../utils/memoize';
const FEATURE_DISABLE_UNLOAD_ALERT = 'selenium.disable.unloadAlert';
const FEATURE_DISABLE_TRANSITIONS = 'selenium.disable.transitionsAndAnimations';
const FORCE_UTK_BEFORE_SESSION = 'selenium.hasUTK';
const FEATURE_DISABLE_OPTION_SHUFFLING = 'selenium.disable.optionShuffling';
const FEATURE_ENABLE_RESTRICTED_DOMAIN_TESTING = 'selenium.enable.restrictedDomainTest';
const DISABLE_ANIMATIONS_INTEGRATION_TESTS = 'disableAnimationsIntegrationTests';
const getParsedLocalStorageValue = memoize((key, defaultValue = null) => {
  try {
    return JSON.parse(SafeStorage.getItem(key)) || defaultValue;
  } catch (error) {
    return defaultValue;
  }
}, key => key);
const setLocalStorage = (key, value) => {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Failed to write to localStorage: ${error}`);
  }
};
const removeFromLocalStorage = key => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Failed to remove from localStorage: ${error}`);
  }
};
export const setDisableAnimationsIntegrationTests = () => {
  setLocalStorage(DISABLE_ANIMATIONS_INTEGRATION_TESTS, 'true');
};
export const removeDisableAnimationsIntegrationTests = () => {
  removeFromLocalStorage(DISABLE_ANIMATIONS_INTEGRATION_TESTS);
};
export const isUnloadAlertDisabled = () => getParsedLocalStorageValue(FEATURE_DISABLE_UNLOAD_ALERT, false);
export const isTransitionAndAnimationsDisabled = () => getParsedLocalStorageValue(FEATURE_DISABLE_TRANSITIONS, false) || getParsedLocalStorageValue(DISABLE_ANIMATIONS_INTEGRATION_TESTS, false);
export const isOptionShufflingDisabled = () => getParsedLocalStorageValue(FEATURE_DISABLE_OPTION_SHUFFLING, false);
export const seleniumTestHasUtk = () => getParsedLocalStorageValue(FORCE_UTK_BEFORE_SESSION, false);
export const isRestrictedDomainTest = () => getParsedLocalStorageValue(FEATURE_ENABLE_RESTRICTED_DOMAIN_TESTING, false);