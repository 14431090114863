var QueryParams;
(function (QueryParams) {
  QueryParams["irclickid"] = "irclickid";
  QueryParams["mpid"] = "mpid";
  QueryParams["utmCampaign"] = "utm_campaign";
})(QueryParams || (QueryParams = {}));
export const getParamFromUrl = (url, param) => new URL(url).searchParams.get(param);
export function getAttributionFromQueryParams(url) {
  const irclickid = getParamFromUrl(url, QueryParams.irclickid);
  const mpid = getParamFromUrl(url, QueryParams.mpid);
  return irclickid && mpid ? {
    irclickid,
    mpid
  } : null;
}
export function getAttributionFromUtmCampaign(url) {
  const utmCampaign = getParamFromUrl(url, QueryParams.utmCampaign);
  if (!utmCampaign) {
    return null;
  }
  const utmCampaignRegex = new RegExp(`amcid_([^_]+)_irpid_(.+)`);
  const match = utmCampaignRegex.exec(utmCampaign);
  if (match) {
    const [, irclickid, mpid] = match;
    return {
      irclickid,
      mpid
    };
  }
  return null;
}
export default function getUrlAttribution(url) {
  const urlAttribution = getAttributionFromQueryParams(url);
  if (urlAttribution) return urlAttribution;
  return getAttributionFromUtmCampaign(url);
}