import { ErrorKey } from '../app-active-error/ErrorKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { setupAuthForNewPortal } from '../store/auth/action-creators';
import { setError } from '../store/error/action-creators';
const LOG = Logger.getLogger('handleLoginNewPortalResponse');
export const handleLoginNewPortalSuccess = dispatch => {
  return dispatch(setupAuthForNewPortal());
};
export const handleLoginNewPortalFailure = (error, dispatch) => {
  const {
    status
  } = error;
  LOG.warn(`attemptUserLogin-cookie: ${status || 'no-status'}`, {
    extra: getSentryExtra(error)
  });
  dispatch(setError(ErrorKey.LoginFailed, error));
};