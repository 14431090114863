import { Step } from 'signup-constants/Step';
import { getFlowandNextSteps } from '../../flow-management/FlowStateManager';
import { getIsAuthenticated } from '../../store/auth/selectors';
import { getHasPaidProducts } from '../../store/portal-tier/selectors';
import { isExistingUserExistingAccount } from '../../app/verificationTypeSelectors';
import { setFlow } from '../../store/flow/action-creators';
export const BranchAddHubletStandaloneCmsPartner = {
  name: 'BranchAddHubletStandaloneCmsPartner',
  stateCheck: state => {
    return getIsAuthenticated(state) && isExistingUserExistingAccount(state) && getHasPaidProducts(state);
  },
  rebuildFlow: (flow, state, dispatch) => {
    const {
      flowSteps,
      oldNextSteps
    } = getFlowandNextSteps(state);
    dispatch(setFlow([...oldNextSteps, Step.HubletSelection], flowSteps.preVerification, flowSteps.postVerification, flowSteps.postAccountCreation, [...flowSteps.postExistingAccountSelection, Step.HubletSelection]));
    return [...oldNextSteps, Step.HubletSelection];
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};