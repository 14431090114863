import { decorateRedirectUrl } from 'growth-onboarding-signup-redirect/redirect';
import { Flow } from 'signup-constants/Flow';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getRouteParams } from '../routing/getRouteParams';
import { isFlow } from '../utils/isFlow';
import { getHubletApiUrl, getHubletAppUrl } from '../utils/urls';
export const redirectToIntent = ({
  intentLink,
  survey,
  portalId,
  hublet,
  signupType
}) => {
  const {
    intentKey,
    queryParams
  } = getRouteParams();
  const allowUrlReplacement = isFlow(Flow.Sales);
  const includeParams = Object.assign({
    via: getFlowConfig().viaParam
  }, survey && survey['crmExperience'] && survey['crmExperience'] !== '' ? {
    crmExperience: survey['crmExperience']
  } : null);
  const appUrl = getHubletAppUrl(hublet);
  const apiUrl = getHubletApiUrl(hublet);
  return decorateRedirectUrl({
    url: intentLink,
    portalId,
    allowUrlReplacement,
    signupType,
    survey,
    includeParams,
    queryParams,
    signupIntent: intentKey,
    appUrl,
    apiUrl
  });
};