import { Product } from 'signup-ui-lego-core/constants/Product';
import { getSentryExtra } from '../../error/getSentryExtra';
import { requestExistingAccounts } from '../../existing-accounts/requestExistingAccounts';
import { getFlowConfig } from '../../flow-initial/getFlowConfig';
import Logger from '../../lib/Logger';
import { clearAnyLoading } from '../loading-status/action-creators';
import { ExistingAccountActionTypes } from './action-types';
const LOG = Logger.getLogger('ExistingAccountsActions');
const doesProductsContainSpecialProducts = products => {
  const ProductsToRemove = [Product.Developers, Product.Community, Product.MarketingMarketplaceProvider];
  products = products.filter(product => !ProductsToRemove.includes(product));
  return products.length > 0;
};
export const doesAccountContainFlowKey = account => {
  // If we have a specific product for this flow key, filter by the product.
  const flowSpecificProduct = getFlowConfig().flowSpecificProduct;
  const products = account.products;
  if (flowSpecificProduct) {
    return products.includes(flowSpecificProduct);
  }
  return doesProductsContainSpecialProducts(products);
};
export const shouldSkipExistingAccountsStep = accounts => {
  const shouldSkipExistingAccountStepIfOnAccountByFlow = getFlowConfig().shouldSkipExistingAccountStepIfOneAccount;
  const shouldAlwaysSkipExistngAccountStep = getFlowConfig().shouldSkipExistingAccountStep;
  return shouldAlwaysSkipExistngAccountStep || accounts.length === 1 && shouldSkipExistingAccountStepIfOnAccountByFlow;
};
export const fetchExistingAccounts = () => dispatch => {
  dispatch({
    type: ExistingAccountActionTypes.AccountsRequested
  });
  return requestExistingAccounts().then(data => {
    let accounts = data.accounts;
    if (accounts) {
      accounts = accounts.filter(doesAccountContainFlowKey);
      dispatch({
        type: ExistingAccountActionTypes.AccountsFetched,
        payload: accounts
      });
    }
    dispatch({
      type: ExistingAccountActionTypes.HubsFailedToLoad,
      hubsFailedToLoad: data.hubsFailedToLoad
    });
  }).catch(error => {
    LOG.error('Error fetching existing user accounts', {
      extra: {
        errorType: 'page',
        details: error.status >= 500 ? 'error fetching existing accounts 50x' : 'not authorized user to call list of existing accounts',
        userInitiated: false,
        extra: getSentryExtra(error)
      }
    });
    dispatch({
      type: ExistingAccountActionTypes.AccountsFetchedFailed
    });
    return error;
  }).finally(() => {
    dispatch(clearAnyLoading());
  });
};