import { AppKey } from '../app/AppKey';
import { Data } from 'signup-constants/signupData/Data';
import { AppActionTypes } from '../store/app/action-types';
import { isOnFirstStep } from '../store/flow/selectors';
import { SignupDataActionTypes } from '../store/signup-data/action-types';
import { Tracker } from '../store/tracking/action-creators';
let hasTrackedFirstEngagement = false;
/**
 * @description If the user is on the first step and interacts with the
 * form for the first time, the new-user-first-enagement tracking event is sent.
 */
const FirstEngagementTrackingMiddleware = store => next => action => {
  const state = store.getState();
  const firstStepDataKeys = [Data.Email, Data.FirstName, Data.LastName];
  const isUpdatingFirstPageData = isOnFirstStep(state) && action.type === SignupDataActionTypes.UpdateData && firstStepDataKeys.includes(action.dataKey);
  const clickedOAuth = action.type === AppActionTypes.UpdateKeyValue && action.key === AppKey.FirstOAuthPartnerClick;
  if (!hasTrackedFirstEngagement && (isUpdatingFirstPageData || clickedOAuth)) {
    const verificationMethod = clickedOAuth ? action.value : 'email';
    store.dispatch(Tracker.signupInteraction('new-user-first-engagement', {
      verificationMethod
    }));
    hasTrackedFirstEngagement = true;
  }
  return next(action);
};
export default FirstEngagementTrackingMiddleware;