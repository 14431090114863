import { ExistingAccountActionTypes } from './action-types';
export const initialExistingAccountsState = {
  accounts: [],
  fetchError: false,
  requested: false,
  fetched: false,
  hubsFailedToLoad: false,
  fetching: false
};
const existingAccountsReducer = (state = initialExistingAccountsState, action) => {
  switch (action.type) {
    case ExistingAccountActionTypes.AccountsRequested:
      return Object.assign({}, state, {
        requested: true,
        fetching: true,
        fetchError: false
      });
    case ExistingAccountActionTypes.AccountsFetched:
      return Object.assign({}, state, {
        accounts: action.payload,
        fetchError: false,
        fetched: true,
        fetching: false
      });
    case ExistingAccountActionTypes.AccountsFetchedFailed:
      return Object.assign({}, state, {
        fetchError: true,
        fetched: true,
        fetching: false
      });
    case ExistingAccountActionTypes.HubsFailedToLoad:
      return Object.assign({}, state, {
        hubsFailedToLoad: action.hubsFailedToLoad
      });
    default:
      return state;
  }
};
export default existingAccountsReducer;