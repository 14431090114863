import { getCookie } from '../BrowserUtils';
var Cookies;
(function (Cookies) {
  Cookies["IR_ids"] = "IR_ids";
})(Cookies || (Cookies = {}));
function parseCookie(cookie, key) {
  const regex = new RegExp(`(^|;)\\s*${key}=([^;]*)`);
  const match = regex.exec(cookie);
  return match ? match[2] : null;
}
export default function getCookieAttribution() {
  const irIds = parseCookie(getCookie(), Cookies.IR_ids);
  if (!irIds) {
    return null;
  }
  const [irclickid, mpid] = irIds.split('|');
  return irclickid && mpid ? {
    irclickid,
    mpid
  } : null;
}