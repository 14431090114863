import { Flow } from 'signup-constants/Flow';
import { getApplicationDomain } from '../constants/apiEnvironments';
import { isFlow } from '../utils/isFlow';
import { isHubSpotHost } from '../utils/urls';
const getSubpath = () => {
  if (isFlow(Flow.Connect)) {
    return '/connect';
  } else if (isFlow(Flow.EngageAI)) {
    return '/engage-ai';
  }
  return '/beta';
};
const getSearch = (redirectUrl, email) => {
  if (!redirectUrl) {
    return '';
  }
  if (!isHubSpotHost(redirectUrl)) {
    return '';
  }
  if (!email) {
    return `?loginRedirectUrl=${encodeURIComponent(redirectUrl)}`;
  }
  return `?loginRedirectUrl=${encodeURIComponent(redirectUrl)}&email=${encodeURIComponent(email)}`;
};
export const getLoginUrl = ({
  redirectUrl,
  email,
  hublet
} = {}) => {
  const subpath = getSubpath();
  const search = getSearch(redirectUrl, email);
  return `${getApplicationDomain(hublet)}/login${subpath}${search}`;
};