// @ts-expect-error Interframe is not yet migrated
import Interframe from 'interframe';
class InterframeContainer {
  constructor() {
    this.instance = null;
  }
  getInstance() {
    return this.instance;
  }
  setInstance(interframeInstance) {
    if (!(interframeInstance instanceof Interframe)) {
      throw new Error('You must pass an instance of Interframe.');
    }
    this.instance = interframeInstance;
  }
}
export default new InterframeContainer();