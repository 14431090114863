import { getFlowConfig, getFlowSteps } from '../../flow-initial/getFlowConfig';
import { Step } from 'signup-constants/Step';
import { NextStepManager } from '../../flow-management/NextStepManager';
import { isPreVerification } from '../../flow-settings/settingsSelectors';
import { setFlow } from '../../store/flow/action-creators';
import { ExperimentKeys } from '../ExperimentKeys';
import { getWhichOAuthSelected } from '../../store/app/selectors';
import { getIsActiveAndAssignedToVariant, getParameter, isExperimentActive } from '../../store/experiments/selectors';
import { Tracker } from '../../store/tracking/action-creators';
import { getIsAuthenticated } from '../../store/auth/selectors';
import { getHasExposureBeenTracked, setHasExposureBeenTracked } from '../useExperiment';
import { getTracker } from '../../tracking/getTracker';
import { EventName } from '../../tracking/EventName';
import { LoadingKey } from '../../app-active-loading/LoadingKey';
import { ExperimentParameterGroup } from '../ExperimentParameterGroup';
const COAC0002MappedLoadingKeys = new Map([[LoadingKey.LoginAfterSignup, LoadingKey.COAC0002PortalCreated]]);
export const getCOAC0002EndpointLoadingKey = (state, loadingKey) => {
  const parameter = getIsActiveAndAssignedToVariant(state, ExperimentKeys.COAC0002, [ExperimentParameterGroup.Variant_A]);
  if (parameter && loadingKey && COAC0002MappedLoadingKeys.has(loadingKey)) return COAC0002MappedLoadingKeys.get(loadingKey);
  return loadingKey;
};
export const setFlowForCOAC0002 = ({
  stepsToRemove = [],
  isPreVerificationExistingUserSession = false
}) => dispatch => {
  const {
    postExistingAccountSelection
  } = getFlowSteps();
  const variantPreVerificationSteps = [Step.LandingPage, Step.CodeVerification];
  const variantPostAccountCreationSteps = [Step.UseCase];
  const maybeAddHubletSelection = getFlowConfig().shouldSkipHubletSelectionNewUser ? [] : [Step.HubletSelection];
  const variantPostVerificationSteps = [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain, ...maybeAddHubletSelection];
  const nextSteps = [...(isPreVerificationExistingUserSession ? [Step.ExistingUser] : []), ...(isPreVerification() ? variantPreVerificationSteps : []), ...variantPostVerificationSteps, ...variantPostAccountCreationSteps];
  const flowSteps = {
    nextSteps,
    variantPreVerificationSteps,
    variantPostVerificationSteps,
    variantPostAccountCreationSteps,
    postExistingAccountSelection
  };
  if (stepsToRemove.length > 0) {
    // replicating the logic inside FlowStateManager.removeSteps(stepsToRemove)
    Object.keys(flowSteps).forEach(key => {
      flowSteps[key] = NextStepManager.removeSteps(...stepsToRemove)(flowSteps[key]);
    });
  }
  dispatch(setFlow(flowSteps.nextSteps, flowSteps.variantPreVerificationSteps, flowSteps.variantPostVerificationSteps, flowSteps.variantPostAccountCreationSteps, flowSteps.postExistingAccountSelection));
  return nextSteps;
};
export const setupAuthForOAuthExistingUserCOAC0002 = (state, experimentKey, dispatch) => {
  const oAuthProviderSelected = getWhichOAuthSelected(state);
  dispatch(Tracker.signupInteraction(`${experimentKey}-${oAuthProviderSelected}-oauth-existing-user-${getParameter(state, ExperimentKeys.COAC0002)}`));
  dispatch(setFlowForCOAC0002({
    isPreVerificationExistingUserSession: getIsAuthenticated(state) && isPreVerification()
  }));
};
const checkExperimentCOAC0002Active = state => {
  return isExperimentActive(state, ExperimentKeys.COAC0002);
};
const checkExperimentCOAC0002Parameter = state => {
  return getParameter(state, ExperimentKeys.COAC0002);
};
const trackExposure = (shouldTrackExposure, parameter) => {
  const hasExposureBeenTracked = getHasExposureBeenTracked(ExperimentKeys.COAC0002);
  if (shouldTrackExposure && !hasExposureBeenTracked) {
    getTracker().track(EventName.ExperimentExposure, {
      action: `${ExperimentKeys.COAC0002}-group-${parameter}`
    }, true);
    setHasExposureBeenTracked(ExperimentKeys.COAC0002);
  }
};
export const trackCOAC0002ExposureForGooglePrompt = state => {
  const shouldTrackExposure = checkExperimentCOAC0002Active(state);
  const parameter = checkExperimentCOAC0002Parameter(state);
  trackExposure(shouldTrackExposure, parameter);
};