import { PortalTierActionTypes } from './action-types';
export const initialPortalTierState = {
  hasPaidPortals: false,
  hasTrialPortals: false,
  isPartialResults: false
};
const portalTierReducer = (state = initialPortalTierState, action) => {
  switch (action.type) {
    case PortalTierActionTypes.SetPortalTierInfo:
      return Object.assign({}, state, action.portalTiers);
    default:
      return state;
  }
};
export default portalTierReducer;