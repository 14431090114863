import { getDebugLogger } from '../lib/DebugLogger';
const QueueLogger = getDebugLogger('lego__EventQueue');
const TrackerLogger = getDebugLogger('lego__Tracking');
const formatEventLog = (eventName, properties = {}, sendAsBeacon = false) => `\t[${eventName}]\n\t\t${JSON.stringify(properties)}\n\t\tSend as Beacon: ${sendAsBeacon}`;
export const UsageTrackingContainerLogger = {
  queue: (eventName, properties = {}, sendAsBeacon = false) => QueueLogger.debug(`\n...Queueing Event: \n${formatEventLog(eventName, properties, sendAsBeacon)}`),
  drainQueue: queue => {
    QueueLogger.debug(`\n>>> Draining queue: ${queue.length} events\n${queue.map(event => {
      const {
        eventName,
        properties,
        sendAsBeacon = false
      } = event;
      return formatEventLog(eventName, properties, sendAsBeacon);
    }).join('\n')}`);
  },
  track: (eventName, properties = {}, sendAsBeacon = false) => TrackerLogger.debug(`\n----> Track Event: \n${formatEventLog(eventName, properties, sendAsBeacon)}`)
};