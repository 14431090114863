import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { solutionsProviderApplication } from './productLinks';
import { getPortalId } from '../store/auth/selectors';
import { getHublet } from '../store/app/selectors';
import { getCountry } from '../store/signup-data/selectors';
export const getSolutionsProviderRedirect = state => {
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  const country = getCountry(state);
  return makeLinkWithQueryParams({
    link: solutionsProviderApplication({
      portalId,
      hublet
    }),
    hublet,
    queryParamsToInclude: {
      via: 'new-app-signup',
      country
    }
  });
};