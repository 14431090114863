import { GMAIL_URL, getMSOffice365Url, getMSVisualStudioOfficeToolsUrl } from '../utils/urls';
const isSalesExtensionSignup = ({
  queryParams
}) => {
  return queryParams['utm_medium'] === 'marketplaces' && queryParams['opt_sidebar'] === 'sales-extension-signup';
};
export const shouldNotSkipSetupUi = ({
  redirectUrl,
  queryParams = {},
  signupIntent
}) => {
  return queryParams && !isSalesExtensionSignup({
    queryParams
  }) && !((queryParams['skip_setup'] === 'true' || signupIntent === 'marketingFreeEmail') && redirectUrl !== getMSOffice365Url() && redirectUrl !== getMSVisualStudioOfficeToolsUrl());
};
export const maybeGetSalesReplacementRedirectUrl = ({
  redirectUrl,
  queryParams
}) => {
  if (isSalesExtensionSignup({
    queryParams
  })) {
    return GMAIL_URL;
  }
  return redirectUrl;
};