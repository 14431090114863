import enviro from 'enviro';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getUtk } from 'usage-tracker-core/common/defaultTrackers';
import { Environment } from '../types/Environment';
export function fetchPublicVisitorContext() {
  const utk = getUtk();
  return noAuthApiClient.get(`https://t.hs-growth-metrics${enviro.isProd() ? '' : Environment.QA}.com/reporting/v1/context/utk`, {
    query: {
      utk
    }
  });
}