import { debounce } from './debounce';
import { Data } from 'signup-constants/signupData/Data';
import { passwordValidator } from '../step-password/passwordValidator';
import { validateSignupData } from '../store/signup-data-validation/action-creators';
import { codeValidator } from '../store/signup-data-validation/validators/codeValidator';
import { companyDomainValidator } from '../store/signup-data-validation/validators/companyDomainValidator';
import { companyNameValidator } from '../store/signup-data-validation/validators/companyNameValidator';
import { emailValidator } from '../store/signup-data-validation/validators/emailValidator';
import { nameValidator } from '../store/signup-data-validation/validators/nameValidator';
import { phoneNumberValidator } from '../store/signup-data-validation/validators/phoneValidator';
const DEFAULT_DEBOUNCE_WAIT = 400;
const getDebouncedValidationAction = validationConfig => {
  const [dataKey, validator, options = {
    debounceWait: DEFAULT_DEBOUNCE_WAIT
  }] = validationConfig;
  return debounce((dispatch, value) => {
    dispatch(validateSignupData({
      dataKey,
      value,
      validator
    }));
  }, options.debounceWait);
};
const getValidationConfig = validationEntries => validationEntries.reduce((obj, validationConfig) => {
  const [dataKey] = validationConfig;
  return Object.assign({}, obj, {
    [dataKey]: getDebouncedValidationAction(validationConfig)
  });
}, {});
function getValidators() {
  return getValidationConfig([[Data.FirstName, nameValidator, {
    debounceWait: 600
  }], [Data.LastName, nameValidator, {
    debounceWait: 600
  }], [Data.Email, emailValidator, {
    debounceWait: 850
  }], [Data.Password, passwordValidator], [Data.CompanyDomain, companyDomainValidator, {
    debounceWait: 1000
  }], [Data.PhoneNumber, phoneNumberValidator], [Data.OptionalPhoneNumber, phoneNumberValidator], [Data.CompanyName, companyNameValidator], [Data.CodeVerification, codeValidator, {
    debounceWait: 1000
  }]]);
}
const Validators = getValidators();
export function regenerateValidatorsForTesting() {
  // this allows us to spy on debounce and then regenerate the validators
  Object.assign(Validators, getValidators());
}
export const getValidator = dataKey => {
  return Validators[dataKey];
};