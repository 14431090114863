import { hasNewUserSubmittedEmail } from '../flow-management/checkForEmailUtils';
import { isDACHCountry } from '../gdpr/gdprUtils';
import { getCountryCode } from '../store/app/selectors';
import { submitSignupInterestForm } from '../store/signup-interest/action-creators';
import { Tracker } from '../store/tracking/action-creators';
const handleSubmitSignupInterestForm = (state, action, dispatch) => {
  if (hasNewUserSubmittedEmail(action)) {
    const country = getCountryCode(state);
    if (!country) {
      dispatch(Tracker.signupInteraction('no-signup-interest-form-unknown-country'));
    } else if (isDACHCountry(state)) {
      dispatch(Tracker.signupInteraction('no-signup-interest-form-dach-user'));
    } else {
      dispatch(submitSignupInterestForm());
    }
  }
};

/**
 * @description Middleware to submit the Signup Interest Form when
 * - The user enters their email via the Landing Page Form
 * - The user provides their email via google (new and existing user)
 * - The user is an existing user
 */

export const SignupInterestFormMiddleware = store => next => action => {
  const state = store.getState();
  const dispatch = store.dispatch;
  handleSubmitSignupInterestForm(state, action, dispatch);
  return next(action);
};