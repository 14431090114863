import { Tracker } from '../store/tracking/action-creators';
import { setError } from '../store/error/action-creators';
import { ErrorKey } from '../app-active-error/ErrorKey';
import Logger from '../lib/Logger';
import { IdentityVerificationMethod } from '../tracking/IdentityVerificationMethod';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('handleInitiateVerificationResponse');
export const handleInitiateVerificationSuccess = dispatch => {
  dispatch(Tracker.signupInteraction('send-verification-email-success'));
  dispatch(Tracker.verifyIdentityStarted(IdentityVerificationMethod.CodeVerification));
};
export const handleInitiateVerificationError = (error = {
  status: null,
  message: ''
}, dispatch) => {
  dispatch(Tracker.signupInteraction('send-verification-email-fail'));
  const isRateLimitedError = error.status === 429;
  const isSessionExpired = error.status === 503;
  if (isRateLimitedError) {
    return dispatch(setError(ErrorKey.RateLimitedEmailVerification, error));
  }
  if (isSessionExpired) {
    return dispatch(setError(ErrorKey.SessionExpired));
  }
  LOG.error('/initiateVerification error', {
    extra: getSentryExtra(error)
  });
  return dispatch(setError(ErrorKey.EmailVerificationFailed, error));
};