import { getAmplitudeEventBody } from '../hubspot-forms/getAmplitudeEventBody';
import { getPreferredReferrer } from '../i18n/ReferrerUtils';
import { getQueryParamsNoEmbedderOptions } from '../routing/getQueryParamsNoEmbedderOptions';
import { getRouteParams } from '../routing/getRouteParams';
import { Data } from 'signup-constants/signupData/Data';
import { getQueryParamsNoSignupData } from '../signup-data/getQueryParamsNoSignupData';
import { getFlatSignupDataValueMap, getSignupDataValue } from '../store/signup-data/selectors';
import { getImpactAdditionalParams } from '../utils/affiliateUtils';
import { endpointForwardedUtms } from '../utm/utmHelpers';
import { getMicroappKey } from '../routing/getMicroappKey';
export const getCompleteVerificationBody = state => {
  const signupData = getFlatSignupDataValueMap(state);
  const {
    queryParams
  } = getRouteParams();
  const howUtms = endpointForwardedUtms(queryParams);
  const queryParamsWithoutSignupData = getQueryParamsNoSignupData(queryParams, signupData);
  const queryParamsNoEmbedderOptions = getQueryParamsNoEmbedderOptions(queryParamsWithoutSignupData);
  const email = getSignupDataValue(state, Data.Email);
  const token = getSignupDataValue(state, Data.Token);
  const code = getSignupDataValue(state, Data.CodeVerification);
  const params = Object.keys(queryParamsNoEmbedderOptions).filter(key => key !== 'gates').reduce((res, key) => Object.assign({}, res, {
    [key]: queryParamsNoEmbedderOptions[key]
  }), {});
  const microapp = getMicroappKey();
  const baseCompleteVerificationBody = Object.assign({
    additionalUrlParams: Object.assign({}, params, howUtms, getImpactAdditionalParams(), {
      preReferrer: getPreferredReferrer()
    })
  }, getAmplitudeEventBody(state), microapp && {
    microapp
  });
  if (code) {
    const completeCodeVerificationBody = Object.assign(baseCompleteVerificationBody, {
      code,
      email
    });
    return completeCodeVerificationBody;
  } else {
    const completeEmailVerificationBody = Object.assign(baseCompleteVerificationBody, {
      token
    });
    return completeEmailVerificationBody;
  }
};