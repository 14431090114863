import { useState, useEffect } from 'react';
import { usePrevious } from '../utils/usePrevious';
export const useOnImageLoaded = image => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const prevImage = usePrevious(image);
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  useEffect(() => {
    if (prevImage !== image) {
      setImageLoaded(false);
    }
  }, [image, prevImage]);
  return {
    imageLoaded,
    handleImageLoaded
  };
};