import { Step } from 'signup-constants/Step';
import { getMicroappKey } from '../routing/getMicroappKey';
import { isMicroappValid } from 'signup-ui-lego-core/utils/isMicroappValid';
import { Microapp } from 'signup-constants/Microapp';
const CustomMicroappsFlow = {
  [Microapp.CampaignAssistant]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.ProductExperience, Step.ProblemAwareness, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [],
    postExistingAccountSelection: []
  }),
  [Microapp.ClipCreator]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.ProductExperience, Step.ProblemAwareness, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [],
    postExistingAccountSelection: []
  }),
  [Microapp.GuideCreator]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.ProductExperience, Step.ProblemAwareness, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [Step.CrmExperienceLevel],
    postExistingAccountSelection: []
  }),
  [Microapp.EmailSignatureGenerator]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.ProductExperience, Step.ProblemAwareness, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [Step.CrmExperienceLevel],
    postExistingAccountSelection: []
  }),
  [Microapp.BrandKitGenerator]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain, Step.CmsSignupIntent, Step.CmsBusinessMaturity],
    postAccountCreation: [],
    postExistingAccountSelection: []
  }),
  [Microapp.BlogIdeasGenerator]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [],
    postExistingAccountSelection: []
  }),
  [Microapp.LandingPageCreator]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [],
    postExistingAccountSelection: []
  }),
  [Microapp.WebsiteGrader]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain, Step.CmsSignupIntent, Step.CmsBusinessMaturity],
    postAccountCreation: [],
    postExistingAccountSelection: []
  }),
  [Microapp.InvoiceGenerator]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [],
    postExistingAccountSelection: []
  })
};
const DEFAULT_MICROAPP_FLOW_STEPS = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.ProductExperience, Step.ProblemAwareness, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [Step.CrmExperienceLevel],
  postExistingAccountSelection: []
};
const FlowStepsByMicroapp = Object.assign({}, CustomMicroappsFlow);
export const getMicroappFlowSteps = () => {
  const microappKey = getMicroappKey();
  if (!microappKey || !isMicroappValid(microappKey)) {
    return DEFAULT_MICROAPP_FLOW_STEPS;
  }
  const getFlowSteps = FlowStepsByMicroapp[microappKey];
  const flowSteps = getFlowSteps();
  return flowSteps;
};