// @ts-expect-error Untyped module
import ExtensionInstallHelpers from 'ExtensionInstallHelpers/SidekickExtensionInstallHelpers';
import { LoadingScreen } from '../../app-active-loading/LoadingScreen';
import { getFlowSteps } from '../../flow-initial/getFlowConfig';
import { getIntentLink } from '../../intents/intentSelectors';
import { updateFlow } from '../../store/flow/action-creators';
import { getShowProductSetupUIBranch } from '../../store/flow/selectors';
import { getActiveLoading } from '../../store/loading-status/selectors';
import { getIsMobile } from '../../utils/deviceUtils';
import { Step } from 'signup-constants/Step';
export const ProductSetupUIBranch = {
  name: 'ProductSetupUIBranch',
  stateCheck: state => {
    // need to wait on the flow to load in order to process this branch
    const hasSalesExtension = ExtensionInstallHelpers.hasExtension();
    const activeLoading = getActiveLoading(state);
    const isLoading = activeLoading && LoadingScreen[activeLoading];
    return !isLoading && !hasSalesExtension && getShowProductSetupUIBranch(getIntentLink(state));
  },
  rebuildFlow: (flow, state, dispatch) => {
    let postAccountCreationSteps = getFlowSteps().postAccountCreation;
    let postExistingAccountSelectionSteps = getFlowSteps().postExistingAccountSelection;
    let flowSteps = flow;
    if (!getIsMobile()) {
      flowSteps = [...flowSteps, Step.InstallSalesExtension];
      postAccountCreationSteps = [...postAccountCreationSteps, Step.InstallSalesExtension];
      postExistingAccountSelectionSteps = [...postExistingAccountSelectionSteps, Step.InstallSalesExtension];
    }
    dispatch(updateFlow(getFlowSteps().preVerification, getFlowSteps().postVerification, postAccountCreationSteps, postExistingAccountSelectionSteps));
    return flowSteps;
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};