import { Integration } from 'signup-constants/Integration';
import { ErrorKey } from '../app-active-error/ErrorKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { setError } from '../store/error/action-creators';
const INTEGRATED_APP_ERROR_CONFIG = {
  [Integration.Wordpress]: {
    errorKey: ErrorKey.WordpressAuthorizationError,
    errorMessage: 'Wordpress Authorization failed.',
    logger: Logger.getLogger('wordpressAuthorization')
  },
  [Integration.ShopifyEmbedded]: {
    errorKey: ErrorKey.IntegratedAppAuthorizationError,
    errorMessage: 'Shopify Authorization failed.',
    logger: Logger.getLogger('shopifyAuthorization')
  }
};
export const handleIntegratedAppAuthorizationError = integration => (error = {
  message: ''
}, dispatch) => {
  const {
    errorKey,
    errorMessage,
    logger
  } = INTEGRATED_APP_ERROR_CONFIG[integration];
  logger.error(errorMessage, {
    extra: getSentryExtra(error)
  });
  dispatch(setError(errorKey, error));
};