import { Intent } from 'signup-constants/Intent';
import { HubInterestOption } from 'signup-constants/signupData/HubInterestOption';
import { JobFieldOption } from 'signup-constants/signupData/JobFieldOption';
import { getIntentKey } from '../routing/getIntentKey';
import { isValidIntent } from '../utils/isValidIntent';
export const HubInterestToJobField = {
  [HubInterestOption.Sales]: JobFieldOption.Sales,
  [HubInterestOption.CustomerService]: JobFieldOption.Support,
  [HubInterestOption.Marketing]: JobFieldOption.Marketing,
  [HubInterestOption.CMS]: JobFieldOption.Other,
  // This is no longer a valid user choice and can be removed when
  // Operations is removed from HubInterestOption
  [HubInterestOption.Operations]: JobFieldOption.Admin,
  [HubInterestOption.GeneralOverview]: JobFieldOption.Other
};
export const HubInterestToIntent = {
  [HubInterestOption.Sales]: [Intent.conversations, Intent.salesMeetings, Intent.salesExtension, Intent.salesEmailTracking, Intent.salesO365, Intent.salesTemplates, Intent.salesDocuments, Intent.salesCalling, Intent.salesFree, Intent.crmLeadManagement, Intent.salesVsto, Intent.crmDeals, Intent.salesOutlook, Intent.salesProspects, Intent.salesInboxProfiles, Intent.slides, Intent.salesEmailScheduling],
  [HubInterestOption.Marketing]: [Intent.marketingFreeEmail, Intent.marketingFreeForms, Intent.landingpages, Intent.marketingFreeAnalytics, Intent.marketingAds],
  [HubInterestOption.CMS]: [Intent.aiWebsiteBuilder]
};
export const getHubInterestForIntent = () => {
  for (const key in HubInterestToIntent) {
    if (Object.hasOwnProperty.call(HubInterestToIntent, key)) {
      const hubInterestOption = key;
      const intents = HubInterestToIntent[hubInterestOption];
      const intent = getIntentKey();
      if (intents && isValidIntent() && intents.includes(intent)) {
        return hubInterestOption;
      }
    }
  }
  return HubInterestOption.Sales;
};