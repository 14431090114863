import { Integration } from 'signup-constants/Integration';
import { isIntegrationValid } from 'signup-ui-lego-core/utils/isIntegrationValid';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import { Step } from 'signup-constants/Step';
const CustomIntegrationsFlow = {
  [Integration.Wordpress]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanyDomain, Step.CompanySize, Step.Industry, Step.ManagementLevel, Step.CrmExperienceLevel],
    postAccountCreation: [Step.WordpressAuthorization],
    postExistingAccountSelection: [Step.WordpressAuthorization]
  }),
  [Integration.ShopifyEmbedded]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanyDomain, Step.CompanySize, Step.Industry, Step.ManagementLevel, Step.CrmExperienceLevel],
    postAccountCreation: [Step.IntegratedAppAuthorization],
    postExistingAccountSelection: [Step.IntegratedAppAuthorization]
  }),
  [Integration.Wpforms]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanyDomain],
    postAccountCreation: [],
    postExistingAccountSelection: []
  }),
  [Integration.Shopify]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
    postAccountCreation: [Step.CrmExperienceLevel, Step.JobField],
    postExistingAccountSelection: []
  }),
  [Integration.Typeform]: () => ({
    preVerification: [Step.LandingPage, Step.CodeVerification],
    postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanyDomain],
    postAccountCreation: [],
    postExistingAccountSelection: []
  })
};
const DEFAULT_INTEGRATION_FLOW_STEPS = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [Step.CrmExperienceLevel, Step.JobField, Step.IntegrationsAccountCreated],
  postExistingAccountSelection: [Step.IntegrationsAccountCreated]
};
const FlowStepsByIntegration = Object.assign({}, CustomIntegrationsFlow);
export const getIntegrationFlowSteps = () => {
  const integrationKey = getIntegrationKey();
  if (!integrationKey || !isIntegrationValid(integrationKey)) {
    return DEFAULT_INTEGRATION_FLOW_STEPS;
  }
  const getFlowSteps = FlowStepsByIntegration[integrationKey];
  const flowSteps = getFlowSteps();
  return flowSteps;
};