import { useEffect } from 'react';
import { Data } from 'signup-constants/signupData/Data';
import { AppKey } from '../../app/AppKey';
import { getSentryExtra } from '../../error/getSentryExtra';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { EndpointKey } from '../../http/EndpointKey';
import Logger from '../../lib/Logger';
import { OAuthProviders } from '../../oauth/OAuthProviders';
import { getQueryParam } from '../../routing/getQueryParam';
import { getHistory } from '../../routing/history';
import { setWhichOAuthSelected, updateKeyValue } from '../../store/app/action-creators';
import { getIsVerifiedByGoogleCredential } from '../../store/app/selectors';
import { clearCompletedSteps, setFlowLoaded } from '../../store/flow/action-creators';
import { retryEndpoint } from '../../store/http-responses/action-creators';
import { clearLoadingStatus, setStandardLoading } from '../../store/loading-status/action-creators';
import { OAuthExistingUserErrorMessage, oAuthAuthStarted, oAuthAuthSucceeded, verifyOAuthVerificationPayload } from '../../store/oauth/action-creators';
import { setSignupDataValue } from '../../store/signup-data/action-creators';
import { setFlowAfterGoogleCredentialAuthentication } from '../setFlowAfterGoogleCredentialAuthentication';
const LOG = Logger.getLogger('setupGoogleAuthentication');
const removeGoogleCredentialFromUrl = () => {
  const searchParams = new URLSearchParams(getHistory().location.search);
  searchParams.delete('googleAuthenticationCredential');
  getHistory().replace(`${getHistory().location.pathname}?${searchParams}`);
};
export const getIsGoogleCredentialAuthentication = state => {
  const googleAuthenticationCredential = getQueryParam('googleAuthenticationCredential');
  return Boolean(googleAuthenticationCredential) || getIsVerifiedByGoogleCredential(state);
};
export const useGoogleCredentialForAuthentication = () => {
  const dispatch = useAppDispatch();
  const googleAuthenticationCredential = getQueryParam('googleAuthenticationCredential');
  useEffect(() => {
    if (googleAuthenticationCredential && !Array.isArray(googleAuthenticationCredential)) {
      dispatch(setStandardLoading());
      dispatch(oAuthAuthStarted(OAuthProviders.Google));
      dispatch(setWhichOAuthSelected(OAuthProviders.Google));
      dispatch(oAuthAuthSucceeded({
        token: googleAuthenticationCredential
      }, OAuthProviders.Google));
      dispatch(setSignupDataValue(Data.GoogleIdToken, googleAuthenticationCredential));
      dispatch(verifyOAuthVerificationPayload({
        credential: googleAuthenticationCredential
      }, OAuthProviders.Google, true)).then(response => {
        dispatch(updateKeyValue(AppKey.IsVerifiedByGoogleCredential, true));
        let isExistingUser = false;
        if ('message' in response) {
          const {
            message = ''
          } = response;
          isExistingUser = message.includes(OAuthExistingUserErrorMessage);
        }
        if (!isExistingUser) {
          dispatch(setFlowAfterGoogleCredentialAuthentication(response));
          dispatch(clearCompletedSteps());
          dispatch(retryEndpoint(EndpointKey.CompanyInfo));
        }
        dispatch(setFlowLoaded());
      }).catch(error => {
        LOG.error('Error during verification of the google auth credential from the prompt:', {
          extra: getSentryExtra(error)
        });
      }).finally(() => {
        removeGoogleCredentialFromUrl();
        dispatch(clearLoadingStatus());
      });
    }
  }, [dispatch, googleAuthenticationCredential]);
};