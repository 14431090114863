import { ValidationStatus } from 'signup-ui-lego-core/types';
import { Data } from 'signup-constants/signupData/Data';
import { FlowActionTypes } from '../flow/action-types';
import { SignupDataActionTypes } from './action-types';
import { emptySignupData, extendEachDataObjectInStep, mergeSignupData } from './reducerUtils';
import { DataStateKey } from './types';
export const initialSignupDataState = Object.values(Data).reduce((state, dataKey) => {
  state[dataKey] = {
    [DataStateKey.value]: '',
    [DataStateKey.prefilled]: false,
    [DataStateKey.completed]: false,
    [DataStateKey.skipped]: false,
    [DataStateKey.validation]: ValidationStatus.FRESH,
    [DataStateKey.errorMessage]: null,
    [DataStateKey.prefillType]: null
  };
  return state;
}, {});
const signupDataReducer = (state = initialSignupDataState, action) => {
  switch (action.type) {
    case FlowActionTypes.StepCompleted:
      return Object.assign({}, state, extendEachDataObjectInStep(state, action.stepKey, {
        completed: true
      }));
    case SignupDataActionTypes.DataCompleted:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          completed: true
        })
      });
    case SignupDataActionTypes.UpdateData:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          value: action.value,
          validation: action.validation || ValidationStatus.FRESH
        })
      });
    case SignupDataActionTypes.SetStepAsSkipped:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          skipped: true
        })
      });
    case SignupDataActionTypes.BulkUpdate:
      return Object.assign({}, state, mergeSignupData({
        state,
        signupData: action.signupData
      }));
    case SignupDataActionTypes.BulkUpdateValid:
      return Object.assign({}, state, mergeSignupData({
        state,
        signupData: action.signupData,
        validation: ValidationStatus.VALID
      }));
    case SignupDataActionTypes.BulkSetCompleted:
      return Object.assign({}, state, mergeSignupData({
        state,
        signupData: action.signupData,
        completed: true
      }));
    case SignupDataActionTypes.BulkSetValidAndCompleted:
      return Object.assign({}, state, mergeSignupData({
        state,
        signupData: action.signupData,
        validation: ValidationStatus.VALID,
        completed: true
      }));
    case SignupDataActionTypes.BulkSetSkipped:
      return Object.assign({}, state, mergeSignupData({
        state,
        signupData: action.signupData,
        skipped: true
      }));
    case SignupDataActionTypes.BulkEmptyFresh:
      return Object.assign({}, state, emptySignupData(state, action.signupDataKeys));
    case SignupDataActionTypes.DataIsValid:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          value: action.validatedValue,
          validation: ValidationStatus.VALID
        })
      });
    case SignupDataActionTypes.DataIsInvalid:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          value: action.validatedValue,
          validation: ValidationStatus.INVALID,
          errorMessage: action.errorMessage
        })
      });
    case SignupDataActionTypes.ResetDataFlags:
      return Object.assign({}, state, {
        [action.dataKey]: Object.assign({}, state[action.dataKey], {
          completed: false,
          skipped: false
        })
      });
    case SignupDataActionTypes.StampPrefilled:
      return action.prefilledKeys.reduce((newState, key) => {
        return Object.assign({}, newState, {
          [key]: Object.assign({}, newState[key], {
            prefilled: true,
            prefillType: action.prefillType
          })
        });
      }, state);
    default:
      return state;
  }
};
export default signupDataReducer;