import { getQueryParam } from '../routing/getQueryParam';
import { normalizeUrl } from '../utils/normalizeUrl';
export const getOauthRedirect = () => {
  const loginRedirectUrl = new URL(normalizeUrl(getQueryParam('loginRedirectUrl')));
  const {
    hostname,
    pathname,
    searchParams
  } = loginRedirectUrl;
  const redirectUrlParamsRecord = Object.assign({
    client_id: searchParams.get('client_id'),
    redirect_uri: searchParams.get('redirect_uri'),
    scope: searchParams.get('scope')
  }, searchParams.has('state') && {
    state: searchParams.get('state')
  });
  const redirectUrlSearchParams = new URLSearchParams(redirectUrlParamsRecord);
  const redirectUrl = new URL(`https://${hostname}${pathname}?${redirectUrlSearchParams.toString()}`);
  return redirectUrl.href;
};