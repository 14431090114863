import { combineReducers } from 'redux';
import appReducer from './app/reducer';
import authReducer from './auth/reducer';
import errorReducer from './error/reducer';
import existingAccountsReducer from './existing-accounts/reducer';
import experimentsReducer from './experiments/reducer';
import flowReducer from './flow/reducer';
import googleRecaptchaReducer from './google-recaptcha/reducer';
import httpResponsesReducer from './http-responses/reducer';
import integrationsReducer from './integrations/reducer';
import loadingReducer from './loading-status/reducer';
import signupDataReducer from './signup-data/reducer';
import microappReducer from './microapp/reducer';
import typeaheadReducer from './typeahead/reducer';
import { ResetStateActionType } from './app/actions';
import { getInitialState } from './getInitialState';
import portalTierReducer from './portal-tier/reducer';
import predictionsReducer from './predictions/reducer';
const reducers = combineReducers({
  appState: appReducer,
  auth: authReducer,
  error: errorReducer,
  existingAccounts: existingAccountsReducer,
  experiments: experimentsReducer,
  flow: flowReducer,
  httpResponses: httpResponsesReducer,
  integrations: integrationsReducer,
  loadingStatus: loadingReducer,
  signupData: signupDataReducer,
  googleRecaptcha: googleRecaptchaReducer,
  typeahead: typeaheadReducer,
  microapp: microappReducer,
  portalTier: portalTierReducer,
  predictions: predictionsReducer
});
export const rootReducer = (state, action) => {
  if (action.type === ResetStateActionType.ResetState) {
    state = getInitialState();
  }
  return reducers(state, action);
};