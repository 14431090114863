import { EndpointKey } from '../../http/EndpointKey';
import { HttpStatus } from './types';
export const getHttpResponse = (state, endpointKey) => {
  return state.httpResponses.responses[endpointKey];
};
export const getEndpointStatus = (state, endpointKey) => {
  return state.httpResponses.statuses[endpointKey];
};
export const getEndpointRetries = (state, endpointKey) => {
  return state.httpResponses.retries[endpointKey];
};
export const isEndpointStatusFetching = endpointKey => state => {
  return getEndpointStatus(state, endpointKey) === HttpStatus.Fetching;
};
export const isEndpointStatusSuccess = endpointKey => state => {
  return getEndpointStatus(state, endpointKey) === HttpStatus.Success;
};
export const isEndpointStatusError = endpointKey => state => {
  return getEndpointStatus(state, endpointKey) === HttpStatus.Error;
};
export const hasEndpointBeenFetched = (state, endpointKey) => {
  return isEndpointStatusSuccess(endpointKey)(state) || isEndpointStatusError(endpointKey)(state);
};
export const hasAuthenticatedFormBeenSubmitted = state => {
  return hasEndpointBeenFetched(state, EndpointKey.SubmitSurveyAuthenticated);
};
export const hasPostSignupBeenSubmitted = state => {
  return hasEndpointBeenFetched(state, EndpointKey.PostSignup);
};
export const isSignupCompletedFired = state => {
  return hasEndpointBeenFetched(state, EndpointKey.TrackSignupCompleted);
};
export const getRecommendedHubletResponse = state => {
  return getHttpResponse(state, EndpointKey.GetRecommendedHublet);
};
export const getRegisteredLeads = state => {
  return getHttpResponse(state, EndpointKey.PartnerRegistrationLeadLookup);
};