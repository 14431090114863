import experimentsConfig from '../../experiments.yaml';
import { isPortalExperiment } from './getUtkExperiments';
export const getExperimentsConfig = () => {
  return experimentsConfig;
};
export const getPortalExperimentsConfig = () => {
  const experiments = getExperimentsConfig();
  const portalExperiments = Object.keys(experiments).filter(experimentKey => isPortalExperiment(experimentKey));
  const portalExperimentsConfig = {};
  portalExperiments.forEach(experimentKey => {
    portalExperimentsConfig[experimentKey] = experiments[experimentKey];
  });
  return portalExperimentsConfig;
};