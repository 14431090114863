import { default as Image } from '../images/imageUrls';
import { ImageKey } from '../images/ImageKey';
import { ColorThemeOption } from './ColorLibrary';
import { MessageKey } from './MessageKey';
import { MessageTypes } from './MessageTypes';
let MESSAGE;
const getMessages = () => {
  if (typeof MESSAGE === 'undefined') {
    const BLONDE_SHELLEY = MessageTypes.blobProfile({
      imageUrl: [Image.blondeShelley1x, Image.blondeShelley2x, Image.blondeShelley3x],
      colorThemeOption: ColorThemeOption.YellowLight,
      blobBleed: true,
      imageHeight: 360,
      blobBleedConfig: {
        yOffset: -10
      }
    });
    const MARGAUX_IMAGE = MessageTypes.messageBlobCaption({
      imageUrl: [Image.crmCentralPlace1x, Image.crmCentralPlace2x, Image.crmCentralPlace3x],
      imageHeight: 330,
      colorThemeOption: ColorThemeOption.OrangeLight,
      captionImageHeight: 34,
      blobBleedConfig: {
        xOffset: -1.5
      },
      blobBleed: false
    });
    MESSAGE = {
      [MessageKey.CheckYourEmail]: MessageTypes.contentCheckYourEmail(),
      [MessageKey.CheckYourEmailDefaultAnimation]: MessageTypes.animation({
        imageUrl: Image.emailAnimation,
        topMargin: '-70'
      }),
      [MessageKey.CheckYourEmailAlternativeIllustration]: MessageTypes.illustration({
        illustrationName: 'email-confirmation',
        colorThemeOption: ColorThemeOption.YellowLight,
        topMargin: '-20'
      }),
      [MessageKey.CrmCentralPlace]: MARGAUX_IMAGE,
      [MessageKey.WordpressConnect]: MessageTypes.imageMessage({
        imageKey: ImageKey.Wordpress
      }),
      [MessageKey.ShopifyEmbeddedConnect]: MessageTypes.imageMessage({
        imageKey: ImageKey.ShopifyEmbedded
      }),
      [MessageKey.BlondeShelley]: BLONDE_SHELLEY,
      [MessageKey.CampaignAssistant]: MessageTypes.campaignAssistant(),
      [MessageKey.BlogGenerator]: MessageTypes.blogGenerator(),
      [MessageKey.LandingPageCreator]: MessageTypes.landingPageCreator(),
      [MessageKey.UseCase]: MessageTypes.contentUseCase(),
      [MessageKey.CompanyDomain]: MessageTypes.contentCompanyDomain(),
      [MessageKey.InvoiceGenerator]: MessageTypes.invoiceGenerator()
    };
  }
  return MESSAGE;
};
export const getMessage = messageKey => {
  return getMessages()[messageKey];
};
export const setTestMessage = (messageKey, value) => {
  const messages = getMessages();
  messages[messageKey] = value;
  return messages;
};
export const DEFAULT_MESSAGING = {};