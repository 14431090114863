import { canInstallFallbackTimer } from './canInstallFallbackTimer';
import { installRedirectFallbackTimer } from './installRedirectFallbackTimer';
let timerId;
export function clearFallbackRedirectTimerForTesting() {
  clearTimeout(timerId);
  timerId = undefined;
}
export const FallbackRedirectMiddleware = store => next => action => {
  const state = store.getState();
  if (!timerId && canInstallFallbackTimer(state)) {
    timerId = installRedirectFallbackTimer(store);
  }
  return next(action);
};