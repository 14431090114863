import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VerificationType } from '../app/VerificationType';
import { Data } from 'signup-constants/signupData/Data';
import { useSignupData } from '../signup-data/useSignupData';
import { setIsNewUser, setVerificationType } from '../store/app/action-creators';
import { useTrackSignupInitiated } from '../tracking/useTrackSignupInitiated';
import { useView } from '../views/useView';
export const useLandingPage = stepKey => {
  const {
    submit
  } = useView({
    stepKey
  });
  const {
    setData: setToken
  } = useSignupData(Data.Token);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsNewUser(true));
  }, [dispatch]);
  useTrackSignupInitiated();
  const chooseEmailSignUp = () => {
    setToken(null);
    dispatch(setVerificationType(VerificationType.EmailByCode));
    submit();
  };
  return {
    chooseEmailSignUp
  };
};