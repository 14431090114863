import { Data } from 'signup-constants/signupData/Data';
import { getQueryParams } from '../routing/getQueryParams';
import { isPartnerSignup } from '../store/flow/selectors';
import { getQueryParamKeyFromDataKey } from './getQueryParamKeyFromDataKey';
import { isPostVerification } from './settingsSelectors';
import { SignupEmbedder, SignupEmbedderOptions } from 'signup-embedder';
const NON_PREFILLABLE = [Data.Industry, Data.JobRole, Data.Password, Data.CodeVerification, Data.CrmExperienceLevel];
export const shouldSkipPrefilledSteps = () => {
  return SignupEmbedder.isActive() && Boolean(SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_SHOULD_SKIP_PREFILLED_STEPS));
};
export const getPrefilledSignupData = data => {
  return Object.values(Data).filter(dataPoint => !NON_PREFILLABLE.includes(dataPoint)).reduce((prefills, dataKey) => {
    const valueToPrefill = data[getQueryParamKeyFromDataKey(dataKey)];
    if (valueToPrefill !== null && valueToPrefill !== undefined) {
      prefills[dataKey] = data[getQueryParamKeyFromDataKey(dataKey)];
    }
    return prefills;
  }, {});
};
export const getPrefilledDataQueryParams = () => {
  const queryParams = getQueryParams();
  return Object.values(Data).filter(key => {
    if (isPostVerification() && key === Data.Token) {
      return true;
    }
    if (isPartnerSignup()) {
      return key === Data.Country;
    }
    return false;
  }).reduce((prefills, dataKey) => {
    const valueToPrefill = queryParams[getQueryParamKeyFromDataKey(dataKey)];
    if (valueToPrefill !== null && valueToPrefill !== undefined) {
      prefills[dataKey] = queryParams[getQueryParamKeyFromDataKey(dataKey)];
    }
    return prefills;
  }, {});
};