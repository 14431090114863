import noPortalApiClient from 'hub-http/clients/noPortalApiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { HUBLESS_INFO_REQUEST_KEY, HUBLESS_INFO_URL } from '../early-requester/hubless-early-requester/hublessEarlyRequestor';
import { makeRequest } from '../http/makeRequest';
export const checkAuth = hublet => {
  const fetch = () => noPortalApiClient.get(`${getFullUrl('app-api', {
    hubletOverride: hublet
  })}${HUBLESS_INFO_URL}`, {
    ignoreRedirect: true
  });
  return makeRequest({
    earlyRequestKey: HUBLESS_INFO_REQUEST_KEY,
    fetch,
    shouldUseEarlyRequest: true
  });
};