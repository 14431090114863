import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EndpointKey } from '../../http/EndpointKey';
import Logger from '../../lib/Logger';
import { retryEndpoint } from '../../store/http-responses/action-creators';
import { useTracker } from '../../tracking/useTracker';
const LOG = Logger.getLogger('useRecaptchaChallenge');
export const useRecaptchaChallenge = () => {
  const Tracker = useTracker();
  const dispatch = useDispatch();
  const [isChallengeResolved, setIsChallengeResolved] = useState(false);
  useEffect(() => {
    Tracker.signupInteraction('creation-error-recaptcha-fallback');
  });
  const onClick = () => {
    dispatch(retryEndpoint(EndpointKey.Signup, 2200));
  };
  const onResolvedHandler = useCallback(() => {
    setIsChallengeResolved(true);
  }, [setIsChallengeResolved]);
  const onExpiredHandler = useCallback(() => {
    setIsChallengeResolved(false);
  }, [setIsChallengeResolved]);
  const onErrorHandler = useCallback(error => {
    LOG.error(`Recaptcha returned an error: ${error}`);
    setIsChallengeResolved(false);
  }, [setIsChallengeResolved]);
  return {
    isChallengeResolved,
    onClick,
    onErrorHandler,
    onExpiredHandler,
    onResolvedHandler
  };
};