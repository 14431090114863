import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { Data } from 'signup-constants/signupData/Data';
import { isQA } from '../constants/apiEnvironments';
import { makeRequest } from '../http/makeRequest';
import { isRestrictedDomainTest } from '../selenium/seleniumFeatures';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Tracker } from '../store/tracking/action-creators';
import { clearPredicted } from '../store/typeahead/action-creators';
import { getPredictionsFetched } from '../store/typeahead/selectors';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';

/**
 * Fetches information about a given domain.
 *
 *
 * @param {string} domain The domain to fetch informaion.
 * @param {Object<string, any>} options Any additional request options.
 * @returns {PromiseLike<{name: string, domain: string}>} The thenable object.
 */

const fetchCompanyInfo = (dispatch, domain) => {
  const COMPANY_INFO_URL = '/acquisition/v1/companyinfo';
  const fetch = () => {
    if (isQA && isRestrictedDomainTest()) {
      return Promise.reject(new Error('Restricted Domain'));
    }
    dispatch(Tracker.signupInteraction('company-info-prefill-used-fetch'));
    return noAuthApiClient.get(`${COMPANY_INFO_URL}?domain=${domain}`, {
      timeout: 3000
    });
  };
  return makeRequest({
    fetch
  });
};
export const companyInfo = (state, dispatch) => {
  const email = getSignupDataValue(state, Data.Email);
  const userDomain = getSignupDataValue(state, Data.CompanyDomain);
  const userCompanyName = getSignupDataValue(state, Data.CompanyName);
  const industryPredictionsFetched = getPredictionsFetched(state, Data.Industry);
  if (userDomain && userCompanyName) {
    dispatch(Tracker.signupInteraction('company-info-prefill-from-query-string'));
    return Promise.resolve();
  }
  if (industryPredictionsFetched) {
    dispatch(clearPredicted(Data.Industry));
  }
  const domain = getDomainFromEmail(email);
  return fetchCompanyInfo(dispatch, domain);
};