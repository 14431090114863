// @ts-expect-error Untyped module
import Cookies from 'js-cookie';
import { UtmTrackingAttr } from 'signup-constants/UtmTrackingAttr';
import expUtms from '../constants/expUtms';
import HubToUtm from '../constants/HubToUtm';
import { getPreferredReferrer } from '../i18n/ReferrerUtils';
import { getFinalUtms } from '../tracking/getFinalUtms';
import { normalizeObject } from '../utils/objectUtils';
let cachedKeys;
let hubsCachedKeys;
export const getAllowlistedUtmKeys = () => {
  if (cachedKeys) {
    return cachedKeys;
  }
  let allowlistedKeys = new Set();

  // add the rest of the defaults
  Object.values(UtmTrackingAttr).forEach(utmKey => {
    allowlistedKeys = allowlistedKeys.add(utmKey);
  });

  // add alternative exp-* prefixed params that will replace utms
  Object.keys(expUtms).forEach(expKey => {
    allowlistedKeys = allowlistedKeys.add(expKey);
  });
  cachedKeys = allowlistedKeys;
  return cachedKeys;
};
export const getHubsKeyMappedToUtm = () => {
  if (hubsCachedKeys) {
    return hubsCachedKeys;
  }
  let allowlistedKeys = new Set();
  for (const hubsTrackingAttr in HubToUtm) {
    if (Object.hasOwnProperty.call(HubToUtm, hubsTrackingAttr)) {
      allowlistedKeys = allowlistedKeys.add(hubsTrackingAttr);
    }
  }
  hubsCachedKeys = allowlistedKeys;
  return hubsCachedKeys;
};
export const mergeFromExp = utmData => {
  return Object.keys(utmData).reduce((accumulator, curKey) => {
    const curVal = utmData[curKey];
    if (curKey.indexOf('exp-') === 0) {
      const equivalentKey = expUtms[curKey];
      accumulator[equivalentKey] = curVal;
    } else if (typeof accumulator[curKey] === 'undefined') {
      accumulator[curKey] = curVal;
    }
    return accumulator;
  }, {});
};
export const getUtmsFromReferrer = utmData => {
  const referrer = getPreferredReferrer();
  if (referrer && !utmData.utm_campaign && !utmData.utm_source && !utmData.hubs_medium && !utmData.hubs_source) {
    if (!utmData.utm_medium) {
      utmData.utm_medium = 'referral';
    }
    utmData.utm_source = referrer;
  }
  return utmData;
};
const prepareHubsParams = () => {
  const hubsKeyMappedToUtm = getHubsKeyMappedToUtm();
  const firstTouchHubs = {};
  // first-touch params tend to be stored in cookies
  // but we look for the original utm_ key in the cookies, so to keep the original value, if any
  hubsKeyMappedToUtm.forEach(key => {
    const utmKey = key.replace('hubs_', 'utm_');
    const value = Cookies.get(utmKey);
    if (value) {
      firstTouchHubs[key] = value;
    }
  });
  return {
    hubsKeyMappedToUtm,
    firstTouchHubs
  };
};
export const hubsToUtmData = hubsData => {
  const {
    hubsKeyMappedToUtm,
    firstTouchHubs
  } = prepareHubsParams();
  hubsData = normalizeObject(hubsData);

  // only the hubs param to be mapped to an utm param will be considered
  const hubsKeyMappedToUtmArray = Array.from(hubsKeyMappedToUtm);
  const filteredHubsData = Object.fromEntries(Object.entries(hubsData).filter(([key]) => hubsKeyMappedToUtmArray.includes(key)));
  return Object.assign(firstTouchHubs, filteredHubsData);
};
export const parseUtmData = utmData => {
  const allowlistedKeys = getAllowlistedUtmKeys();
  const firstTouchUtms = {};

  // first-touch params tend to be stored in cookies
  allowlistedKeys.forEach(key => {
    const value = Cookies.get(key);
    if (value) {
      firstTouchUtms[key] = value;
    }
  });

  // Duplicate utm query param entries will show up
  // as an array in the utmData object because of the way
  // react-router interprets location.query data. This function
  // will detect multiple utm entries and pick only the first one.
  utmData = normalizeObject(utmData);
  const allowlistedKeysArray = Array.from(allowlistedKeys);
  const cleanedUtmData = Object.fromEntries(Object.entries(utmData).filter(([key]) => allowlistedKeysArray.includes(key)));
  return Object.assign(firstTouchUtms, cleanedUtmData);
};
export const getOnlyExpParams = utmData => {
  const utmKeys = Object.keys(expUtms);
  const filteredUtmData = Object.fromEntries(Object.entries(utmData).filter(([key]) => utmKeys.includes(key)));
  return filteredUtmData;
};
export const endpointForwardedUtms = queryParams => {
  const utmsFiltered = parseUtmData(queryParams);
  return getFinalUtms({
    utmsFiltered,
    shouldTransformReferrerToHubs: true,
    queryParamsToUse: queryParams
  });
};