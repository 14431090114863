export const SURVEY_KEYS = {
  JOB_ROLE: 'jobRole',
  JOB_TITLE: 'jobTitle',
  JOB_FIELD: 'jobField',
  HUB_INTEREST: 'hubInterest',
  CRM_EXPERIENCE: 'crmExperience',
  CMS_SIGNUP_INTENT: 'cmsSignupIntent'
};
export const EXPERIENCE_NONE_PARAM = 'none';
export const EXPERIENCE_HAS_USED_CRM_PARAM = 'has_used_crm';
export const EXPERIENCE_HAS_USED_HUBSPOT = 'has_used_hubspot';
export const SALES_REP_ROLE = 'sales-rep';
export const OWNER_MANAGEMENT_LEVEL = 'Owner';
export const EXECUTIVE_TEAM_MANAGEMENT_LEVEL = 'Executive Team';
export const MANAGER_MANAGEMENT_LEVEL = 'Manager';
export const EMPLOYEE_MANAGEMENT_LEVEL = 'Employee';
export const STUDENT_INTERN_MANAGEMENT_LEVEL = 'Student/Intern';
export const FREELANCER_MANAGEMENT_LEVEL = 'Freelancer';
export const OTHER_MANAGEMENT_LEVEL = 'Other';
export const SALES = 'sales';
export const MARKETING = 'marketing';
export const SERVICES_SUPPORT = 'services-support';
export const OTHER = 'other';
export const SALES_HUB_INTEREST = 'sales';
export const MARKETING_HUB_INTEREST = 'marketing';
export const CUSTOMER_SERVICE_HUB_INTEREST = 'customer-service';
export const CMS_HUB_INTEREST = 'cms';
export const CMS_SIGNUP_BUSINESS_INTENT = 'business';
export const CMS_SIGNUP_PERSONAL_INTENT = 'personal';
export const FALSEY_SURVEY_VALUE = [undefined, null, ''];