module.exports = {
  "acq-0150": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a"
      ]
    }
  },
  "coac-0002": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a"
      ]
    }
  },
  "acq-0154": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a"
      ]
    }
  },
  "acq-0157": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a"
      ]
    }
  },
  "gosu-0006": {
    "identifierType": "PORTAL_ID",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a",
        "variant_b"
      ]
    }
  },
  "acq-0161": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variantA"
      ]
    }
  }
};