import { getLoading } from '../../app-active-loading/LoadingScreen';
export const getLoadingStack = state => state.loadingStatus.loadingStack;
export const getActiveLoading = state => {
  const loadingStack = getLoadingStack(state);
  return Array.isArray(loadingStack) && loadingStack.slice(-1)[0];
};
export const isLoadingKeyActive = (state, loadingKey) => {
  const loadingStack = getLoadingStack(state);
  return Array.isArray(loadingStack) && loadingStack.includes(loadingKey);
};
export const isCopyCarouselFinished = (state, loadingKey) => {
  const copyCarouselFinished = state.loadingStatus.copyCarouselFinished;
  return Boolean(copyCarouselFinished[loadingKey]);
};
export const checkShouldClearLoading = (state, loadingKey) => {
  const loadingConfig = getLoading(loadingKey);
  return Boolean(loadingConfig) && loadingConfig.shouldClearLoading && loadingConfig.shouldClearLoading(state);
};
export const hasLoadingClearingCondition = loadingKey => {
  const loading = getLoading(loadingKey);
  return loading && loading.shouldClearLoading;
};
export const getCanSwitchCopy = loadingKey => state => {
  const loading = getLoading(loadingKey);
  return loading && loading.canSwitchCopy && loading.canSwitchCopy(state);
};
export const getCanShowProgressBar = loadingKey => state => {
  const loading = getLoading(loadingKey);
  return loading && loading.shouldShowProgressBar && loading.shouldShowProgressBar(state);
};