import { ExperimentFetchStatus } from '../../experiments/ExperimentFetchStatus';
import { ExperimentIdentifier } from '../../experiments/ExperimentIdentifier';
import { experimentsEnabled } from '../../experiments/experimentsEnabled';
import { ExperimentActionTypes } from './action-types';
export const initialExperimentsState = {
  treatments: {},
  experimentsEnabled: experimentsEnabled(),
  identifierPhase: ExperimentIdentifier.Uuid,
  experimentsStatus: {
    [ExperimentIdentifier.Uuid]: ExperimentFetchStatus.None,
    [ExperimentIdentifier.Utk]: ExperimentFetchStatus.None,
    [ExperimentIdentifier.PortalId]: ExperimentFetchStatus.None
  },
  experimentUtk: '',
  activeExperimentsParameters: [],
  unenrolledExperiments: []
};
const experiments = (state = initialExperimentsState, action) => {
  switch (action.type) {
    case ExperimentActionTypes.SetExperimentUtk:
      return Object.assign({}, state, {
        experimentUtk: action.utk
      });
    case ExperimentActionTypes.BulkUpdateExperiments:
      return Object.assign({}, state, {
        treatments: Object.assign({}, state.treatments, action.response),
        identifierPhase: action.identifierPhase
      });
    case ExperimentActionTypes.ExperimentsFetched:
      return Object.assign({}, state, {
        experimentsStatus: Object.assign({}, state.experimentsStatus, {
          [action.identifierPhase]: ExperimentFetchStatus.Fetched
        })
      });
    case ExperimentActionTypes.TrackExposure:
      return Object.assign({}, state, {
        treatments: Object.assign({}, state.treatments, {
          [action.id]: Object.assign({}, action.experiment, {
            trackedExposure: true
          })
        })
      });
    case ExperimentActionTypes.FetchExperiments:
      return Object.assign({}, state, {
        identifierPhase: action.identifierPhase,
        experimentsStatus: Object.assign({}, state.experimentsStatus, {
          [action.identifierPhase]: ExperimentFetchStatus.Pending
        })
      });
    case ExperimentActionTypes.UpdateActiveExperimentParameters:
      return Object.assign({}, state, {
        activeExperimentsParameters: action.activeExperimentsParameters
      });
    case ExperimentActionTypes.UnenrollExperiment:
      return Object.assign({}, state, {
        unenrolledExperiments: Array.from(new Set([action.experiment, ...state.unenrolledExperiments]))
      });
    case ExperimentActionTypes.ReenrollExperiment:
      return Object.assign({}, state, {
        unenrolledExperiments: state.unenrolledExperiments.filter(item => {
          return item !== action.experiment;
        })
      });
    default:
      return state;
  }
};
export default experiments;