import JobRoleService from 'signup-ui-job-role/services/JobRoleService';
import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { getTypeaheadTrackedUserInput } from '../store/typeahead/selectors';
import { EventName } from './EventName';
import { trackEvent } from './trackEvent';
export const trackJobRoleCollected = (_action, state) => {
  const jobRole = getSignupDataValue(state, Data.JobRole);
  const jobRoleInput = getTypeaheadTrackedUserInput(Data.JobRole)(state);
  if (!jobRole) {
    return;
  }
  trackEvent(EventName.JobRoleCollected, {
    jobRole,
    jobRoleCategory: JobRoleService.getCategory({
      id: jobRole
    }),
    userInput: jobRoleInput
  });
};