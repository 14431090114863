import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getLoginRedirectUrlOrigin, getReferrerParams } from '../i18n/ReferrerUtils';
import { getIsPostVerification } from '../routing/getIsPostVerification';
import { getQueryParam } from '../routing/getQueryParam';
import { getRouteParams } from '../routing/getRouteParams';
import { Data } from 'signup-constants/signupData/Data';
import { getImpactAdditionalParams } from '../utils/affiliateUtils';
import { getUserDeviceType } from '../utils/deviceUtils';
import { getOnlyExpParams, parseUtmData } from '../utm/utmHelpers';
import getAppFromLoginRedirectUrl from './getAppFromLoginRedirectUrl';
import { getFinalUtms } from './getFinalUtms';
import { getSignupMode } from './getSignupMode';
import { hasAcceptedCookies } from './cookieUtils';
import { checkUrlForUUID } from './uuidUtils';
import getLangLocale from 'I18n/utils/getLangLocale';
import { getUtk } from 'usage-tracker-core/common/defaultTrackers';
export function getInitialTrackerProperties(isPublicTracking = false) {
  const {
    queryParams,
    intentKey,
    integrationKey,
    microappKey
  } = getRouteParams();
  const isPostVerification = getIsPostVerification();
  const email = queryParams[Data.Email.toLowerCase()];
  const utmsFiltered = parseUtmData(queryParams);
  const expParams = getOnlyExpParams(utmsFiltered);
  const finalUtmParams = getFinalUtms({
    utmsFiltered,
    queryParamsToUse: queryParams
  });
  const gclid = getQueryParam('gclid');
  const dclid = getQueryParam('dclid');
  const utmParamsNamedForAmplitude = Object.keys(finalUtmParams).reduce((acc, key) => {
    const property = finalUtmParams[key];
    if (property) {
      acc[`how_${key}`] = property;
    }
    return acc;
  }, {});
  const baseSignupEventParams = {
    namespace: 'signup',
    platform: getUserDeviceType(),
    signupMode: getSignupMode(),
    signupType: getFlowConfig().appName,
    signupVersion: 'odyssey',
    intent: intentKey,
    integration: integrationKey,
    microapp: microappKey
  };
  const sourceTrackingParams = Object.assign({
    internalHubsSignupCta: queryParams['hubs_signup-cta'],
    internalHubsSignupUrl: queryParams['hubs_signup-url'],
    optSidebar: queryParams['opt_sidebar'],
    applicationId: getAppFromLoginRedirectUrl()
  }, getImpactAdditionalParams(), expParams, utmParamsNamedForAmplitude);
  const hubsParameters = {
    internalFirstTouchUrl: queryParams['hubs_content'],
    internalFirstTouchCta: queryParams['hubs_content-cta'],
    internalFirstTouchPostUrl: queryParams['hubs_post'],
    internalFirstTouchPostCta: queryParams['hubs_post-cta'],
    internalOfferUrl: queryParams['hubs_offer'],
    internalSignupLinkType: queryParams['hubs_link']
  };
  const viralityTrackingParams = {
    viralSourcePortalId: queryParams['viralSourcePortalId'],
    viralSourceUserId: queryParams['viralSourceUserId']
  };
  const checkUUIDIsNotInURL = () => {
    return isPublicTracking || !checkUrlForUUID();
  };
  const referrerParams = getReferrerParams();
  const loginRedirectUrl = getQueryParam('loginRedirectUrl');
  const shouldAddEmailToTracker = isPostVerification && hasAcceptedCookies() && checkUUIDIsNotInURL();
  return Object.assign({
    hubId: null
  }, shouldAddEmailToTracker && {
    email
  }, {
    hstc: hasAcceptedCookies() && checkUUIDIsNotInURL() ? `x.${getUtk()}` : null
  }, baseSignupEventParams, sourceTrackingParams, viralityTrackingParams, referrerParams, hubsParameters, loginRedirectUrl && {
    sourceUrl: getLoginRedirectUrlOrigin(loginRedirectUrl)
  }, {
    lang: getLangLocale(),
    gclid,
    dclid
  });
}