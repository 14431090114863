import enviro from 'enviro';
import { Flow } from 'signup-constants/Flow';
import { VerificationType } from '../app/VerificationType';
import { setNewPortalId } from '../http-signup/setNewPortalId';
import { Data } from 'signup-constants/signupData/Data';
import { setHublet, setVerificationType } from '../store/app/action-creators';
import { portalSelected } from '../store/auth/action-creators';
import { getExistingAccounts } from '../store/existing-accounts/selectors';
import { validateOAuthPermissions } from '../store/integrations/action-creators';
import { bulkEmptySignupDataAndSetFresh } from '../store/signup-data/action-creators';
import { Tracker } from '../store/tracking/action-creators';
import { isFlow } from '../utils/isFlow';
import { getRouteParams } from '../routing/getRouteParams';
import { isIntegratedApp, isIntegratedAppCookielessBranch } from '../utils/isIntegratedApp';
export const portalSelect = portalId => (dispatch, getState) => {
  const state = getState();
  const accounts = getExistingAccounts(state);
  const hubId = portalId;
  const selectedAccount = accounts.find(account => account.id === hubId);
  const hublet = selectedAccount ? selectedAccount.hublet : enviro.getHublet();
  const {
    queryParamsMap
  } = getRouteParams();
  const isCookielessFlow = isIntegratedAppCookielessBranch(queryParamsMap);
  dispatch(Tracker.signupInteraction('selected-existing-portal'));
  dispatch(bulkEmptySignupDataAndSetFresh([Data.CompanyDomain, Data.CompanyName], {
    shouldEmptyPrefilledValues: true
  }));
  dispatch(setHublet(hublet));
  setNewPortalId({
    hubId,
    hublet
  }, dispatch);
  if (isFlow(Flow.Integrations) && isIntegratedApp() && !isCookielessFlow) {
    dispatch(validateOAuthPermissions());
  }
  dispatch(setVerificationType(VerificationType.ExistingUserExistingAccount));
  dispatch(portalSelected({
    portalId: hubId,
    hublet
  }));
};