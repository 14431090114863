import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { isBrowserAndApplicationLanguageForLocale, isEnglishLanguage } from '../i18n/LanguageUtils';
import { isFlow } from '../utils/isFlow';
import { Flow } from 'signup-constants/Flow';
export const getIsChatBotEnabledByLanguage = () => {
  const isFrenchLanguage = isBrowserAndApplicationLanguageForLocale(Locale.fr);
  const isGermanLanguage = isBrowserAndApplicationLanguageForLocale(Locale.de);
  const isJapaneseLanguage = isBrowserAndApplicationLanguageForLocale(Locale.ja);
  const isChatbotEnabledByLanguage = isEnglishLanguage() || isFlow(Flow.Crm) && (isFrenchLanguage || isGermanLanguage || isJapaneseLanguage);
  return isChatbotEnabledByLanguage;
};