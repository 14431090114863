import { getFlowKey } from '../routing/getFlowKey';
import { getIsMobile } from '../utils/deviceUtils';
import { Step } from 'signup-constants/Step';
import { FlowConfigRecord } from './FlowConfig';
export const getPostAccountCreationStepsFlowConfig = flowConfig => {
  let postAccountCreationSteps = flowConfig.getFlowSteps().postAccountCreation;
  if (flowConfig.shouldAddHubInterestStep()) {
    postAccountCreationSteps = postAccountCreationSteps.concat([Step.HubInterest]);
  }
  if (flowConfig.showMobileAppPrompt && getIsMobile()) {
    postAccountCreationSteps = postAccountCreationSteps.concat([Step.MobileAppPrompt]);
  }
  return postAccountCreationSteps;
};
export const getFlowConfig = () => {
  const flowKey = getFlowKey();
  const flowConfig = FlowConfigRecord[flowKey];
  if (!flowConfig) {
    throw new Error(`Invalid flow with key ${flowKey}.`);
  }
  return Object.assign({
    getFlowSteps: flowConfig.getFlowSteps
  }, flowConfig.getFlowOptions());
};
export const getFlowSteps = () => {
  const flowConfig = getFlowConfig();
  const {
    preVerification,
    postExistingAccountSelection,
    postVerification
  } = flowConfig.getFlowSteps();
  return {
    preVerification,
    postVerification,
    postExistingAccountSelection,
    postAccountCreation: getPostAccountCreationStepsFlowConfig(flowConfig)
  };
};
export const getOAuthsEnabled = () => {
  const flowConfig = getFlowConfig();
  return flowConfig.oAuthsEnabled;
};