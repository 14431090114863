export let SignupDataActionTypes;
(function (SignupDataActionTypes) {
  SignupDataActionTypes["UpdateData"] = "[Signup Data] Update Data";
  SignupDataActionTypes["BulkUpdate"] = "[Signup Data] Bulk Update";
  SignupDataActionTypes["BulkUpdateValid"] = "[Signup Data] Bulk Update Valid";
  SignupDataActionTypes["BulkEmptyFresh"] = "[Signup Data] Bulk Empty Fresh";
  SignupDataActionTypes["PrefillDataStored"] = "[Signup Data] Prefill Data Stored";
  SignupDataActionTypes["DataIsValid"] = "[Signup Data] Data Is Valid";
  SignupDataActionTypes["DataIsInvalid"] = "[Signup Data] Data Is Invalid";
  SignupDataActionTypes["StampPrefilled"] = "[Signup Data] Stamp Prefilled";
  SignupDataActionTypes["DataCompleted"] = "[Signup Data] Data Completed";
  SignupDataActionTypes["SetStepAsSkipped"] = "[Signup Data] Step Step As Skipped";
  SignupDataActionTypes["BulkSetCompleted"] = "[Signup Data] Bulk Set Completed";
  SignupDataActionTypes["BulkSetSkipped"] = "[Signup Data] Bulk Set Skipped";
  SignupDataActionTypes["ResetDataFlags"] = "[Signup Data] Reset Data Flags";
  SignupDataActionTypes["BulkSetValidAndCompleted"] = "[Signup Data] Bulk Set Valid and Completed";
})(SignupDataActionTypes || (SignupDataActionTypes = {}));