import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { getHublet } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { redirectToUrl } from '../utils/redirectToUrl';
import { canRedirectNewUser } from './canRedirectNewUser';
import { getFallbackOnboardingUrl } from './getFallbackOnboardingUrl';
import { getRedirectMethod } from './getRedirectMethod';
import { getShouldRedirectWindow } from './getShouldRedirectWindow';
import { removeUnloadPopup } from './removeUnloadPopup';
const LOG = Logger.getLogger('redirectAfterPortalCreation');
export const redirectUserAfterPortalCreation = (store, setIsProcessing) => {
  const state = store.getState();
  if (canRedirectNewUser(state)) {
    setIsProcessing(true);
    removeUnloadPopup(store);
    const portalId = getPortalId(state);
    const hublet = getHublet(state);
    const shouldRedirectWindow = getShouldRedirectWindow();
    const getRedirectUrl = getRedirectMethod(state);
    return Promise.resolve(getRedirectUrl()).then(redirectUrl => {
      store.dispatch(redirectToUrl(redirectUrl, shouldRedirectWindow));
    }).catch(error => {
      LOG.error(`Failed to build redirectUrl`, {
        extra: getSentryExtra(error)
      });
      // Fallback to the user-guide
      store.dispatch(redirectToUrl(getFallbackOnboardingUrl(portalId, hublet, true), shouldRedirectWindow));
    }).finally(() => {
      setIsProcessing(false);
    });
  }
  return Promise.resolve();
};