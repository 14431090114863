import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Step } from 'signup-constants/Step';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { getQueryParam } from '../../routing/getQueryParam';
import { getIsNewUser, getIsVerifiedByGoogleCredential, getVerificationType } from '../../store/app/selectors';
import { arePreAccountCreatedExperimentsFetched } from '../../store/experiments/selectors';
import { EventName } from '../../tracking/EventName';
import { getTracker } from '../../tracking/getTracker';
import { useTracker } from '../../tracking/useTracker';
import { useTrackSignupInitiated } from '../../tracking/useTrackSignupInitiated';
import { setVerificationType } from '../../store/app/action-creators';
import { VerificationType } from '../../app/VerificationType';
import { ExperimentActionTypes } from '../../store/experiments/action-types';
import { getUserExperimentIdentifier } from '../../experiments/getUserExperimentIdentifier';
import { ExperimentIdentifier } from '../../experiments/ExperimentIdentifier';
import { ExperimentParameterGroup } from '../../experiments/ExperimentParameterGroup';

// NOTE: this file was merged with google-authentication/google-prompt/googlePromptUtils.ts to remove problematic cycles.
// googlePromptUtils imported GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID which is set to `null` in this file. It is unclear
// if this variable is set manually or if it is set by another build process.
const GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID = null;
const GOOGLE_PROMPT_EXPERIMENT_TREATMENT_QUERY_PARAM = `${GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID}-treatment`;
const GOOGLE_PROMPT_EXPERIMENT_TREATMENTS = [ExperimentParameterGroup.Control, ExperimentParameterGroup.Variant_B, ExperimentParameterGroup.Variant_C];
const PROMPT_DISMISSED_QUERY_PARAM = 'prompt-dismissed';
const PROMPT_CLICKED_QUERY_PARAM = 'prompt-clicked';
const PROMPT_NOT_CLICKED_QUERY_PARAM = 'prompt-not-clicked';
const getIsEnrolledForGooglePromptExperiment = () => {
  const googlePromptExperimentTreatment = getQueryParam(GOOGLE_PROMPT_EXPERIMENT_TREATMENT_QUERY_PARAM);
  const hasGooglePromptExperimenTreatment = GOOGLE_PROMPT_EXPERIMENT_TREATMENTS.includes(googlePromptExperimentTreatment);
  return hasGooglePromptExperimenTreatment;
};
export const isGooglePromptExperimentVariant = () => {
  const googlePromptExperimentTreatment = getQueryParam(GOOGLE_PROMPT_EXPERIMENT_TREATMENT_QUERY_PARAM);
  const isEnrolledForGooglePromptExperiment = getIsEnrolledForGooglePromptExperiment();
  return isEnrolledForGooglePromptExperiment && googlePromptExperimentTreatment === ExperimentParameterGroup.Variant;
};
export const isOnProductExperienceStepAndCredentialVerifiedNewUser = (currentStep, isGoogleCredentialAuthentication, isExistingOAuthUser) => {
  return isGoogleCredentialAuthentication && currentStep === Step.ProductExperience && !isExistingOAuthUser;
};
const getGooglePromptQueryParameterValue = queryParam => {
  const queryParamValue = parseInt(getQueryParam(queryParam), 10);
  return queryParamValue;
};
export const checkIfIsGooglePromptExperimentAndExposureTracked = activeExperimentId => {
  if (!GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID) {
    return false;
  }
  return activeExperimentId === GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID && getHasExposureBeenTrackedForGooglePromptExperiment();
};
export const getActiveGooglePromptExperimentId = () => {
  return getHasExposureBeenTrackedForGooglePromptExperiment() ? [GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID] : [];
};
let hasExposureBeenTrackedForGooglePromptExperimentCache = false;
let hasTrackedPromptInteractions = false;
export function setHasTrackedPromptInteractions(value) {
  hasTrackedPromptInteractions = value;
}
export function getHasExposureBeenTrackedForGooglePromptExperiment() {
  return hasExposureBeenTrackedForGooglePromptExperimentCache;
}
export function resetExposureForGooglePromptExperiment() {
  hasExposureBeenTrackedForGooglePromptExperimentCache = false;
}
function setHasExposureBeenTrackedForGooglePromptExperiment() {
  hasExposureBeenTrackedForGooglePromptExperimentCache = true;
}
const updateGooglePromptExperimentInState = () => dispatch => {
  if (!GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID) {
    return;
  }
  const googlePromptExperimentKey = GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID;
  const experimentIdentifier = getUserExperimentIdentifier();
  const googlePromptExperimentParameter = getQueryParam(GOOGLE_PROMPT_EXPERIMENT_TREATMENT_QUERY_PARAM);
  dispatch({
    type: ExperimentActionTypes.BulkUpdateExperiments,
    response: {
      [googlePromptExperimentKey]: {
        key: GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID,
        identifier: experimentIdentifier,
        status: {
          isActive: true,
          isEnrolled: true,
          isDefaulted: false,
          isCached: false,
          isOverridden: false
        },
        parameters: {
          group: googlePromptExperimentParameter
        },
        maxAge: 0,
        trackedExposure: true
      }
    },
    identifierPhase: ExperimentIdentifier.Utk
  });
};
export const trackGooglePromptExperimentExposure = () => {
  const googlePromptExperimentTreatment = getQueryParam(GOOGLE_PROMPT_EXPERIMENT_TREATMENT_QUERY_PARAM);
  setHasExposureBeenTrackedForGooglePromptExperiment();
  getTracker().track(EventName.ExperimentExposure, {
    action: `${GOOGLE_PROMPT_EXPERIMENT_EXPERIMENT_ID}-group-${googlePromptExperimentTreatment}`
  }, true);
};
export const useTrackGooglePromptUsedPreSignup = () => {
  const dispatch = useAppDispatch();
  const isNewUser = useSelector(getIsNewUser);
  const isVerifiedByGoogleCredential = useSelector(getIsVerifiedByGoogleCredential);
  const verificationType = useSelector(getVerificationType);
  const Tracker = useTracker();
  const isEnrolledForGooglePromptExperiment = getIsEnrolledForGooglePromptExperiment();
  const exposureHasBeenTracked = getHasExposureBeenTrackedForGooglePromptExperiment();
  const experimentsFetched = useSelector(arePreAccountCreatedExperimentsFetched);
  useTrackSignupInitiated(isNewUser && isVerifiedByGoogleCredential);
  useEffect(() => {
    if (isEnrolledForGooglePromptExperiment && experimentsFetched && !exposureHasBeenTracked) {
      trackGooglePromptExperimentExposure();
      dispatch(updateGooglePromptExperimentInState());
      setHasExposureBeenTrackedForGooglePromptExperiment();
    }
    if (!hasTrackedPromptInteractions) {
      const promptDismissedValue = getGooglePromptQueryParameterValue(PROMPT_DISMISSED_QUERY_PARAM);
      const promptNotClickedValue = getGooglePromptQueryParameterValue(PROMPT_NOT_CLICKED_QUERY_PARAM);
      if (!isNaN(promptDismissedValue)) {
        Tracker.signupInteraction('prompt-dismissed');
      }
      if (!isNaN(promptNotClickedValue)) {
        Tracker.signupInteraction('prompt-not-clicked');
      }
      setHasTrackedPromptInteractions(true);
    }
    if (isVerifiedByGoogleCredential && verificationType === VerificationType.Google) {
      dispatch(setVerificationType(VerificationType.GooglePrompt));
      const promptClickedValue = getGooglePromptQueryParameterValue(PROMPT_CLICKED_QUERY_PARAM);
      if (!isNaN(promptClickedValue)) {
        Tracker.signupInteraction('prompt-clicked');
      }
    }
  }, [isVerifiedByGoogleCredential, verificationType, experimentsFetched, exposureHasBeenTracked, isEnrolledForGooglePromptExperiment, Tracker, dispatch]);
};