import { getCurrentDomain, isQA } from '../../constants/apiEnvironments';
const ClientId = {
  QA: '7db8ea87-27a7-42f5-a228-d3c82041ebb1',
  PROD: '1f1bebf6-6e03-4757-a939-400d87a5fd8c'
};
export const getMicrosoftConfig = () => {
  const enviroment = isQA ? 'QA' : 'PROD';
  const redirectUrl = `${getCurrentDomain()}/signup-hubspot-blank`;
  return {
    auth: {
      // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
      clientId: ClientId[enviroment],
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
      authority: 'https://login.microsoftonline.com/common',
      // Full redirect URL, using a blank html file as recommended by microsoft: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirecturi-considerations
      redirectUri: redirectUrl
    },
    cache: {
      cacheLocation: 'sessionStorage',
      // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
};