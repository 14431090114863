export let CountryCode;
(function (CountryCode) {
  CountryCode["Germany"] = "de";
  CountryCode["Switzerland"] = "ch";
  CountryCode["Austria"] = "at";
  CountryCode["Japan"] = "jp";
  CountryCode["US"] = "us";
})(CountryCode || (CountryCode = {}));
export let DachCountryCode;
(function (DachCountryCode) {
  DachCountryCode[DachCountryCode["Germany"] = CountryCode.Germany] = "Germany";
  DachCountryCode[DachCountryCode["Switzerland"] = CountryCode.Switzerland] = "Switzerland";
  DachCountryCode[DachCountryCode["Austria"] = CountryCode.Austria] = "Austria";
})(DachCountryCode || (DachCountryCode = {}));