import { useDispatch } from 'react-redux';
import { AppKey } from '../app/AppKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { setWhichOAuthSelected, setWhichOAuthLoading, updateKeyValue } from '../store/app/action-creators';
import { oAuthAuthFailed, oAuthAuthStarted, oAuthAuthSucceeded, verifyOAuthVerificationPayload } from '../store/oauth/action-creators';
import { useMicrosoftLoginNonce } from './useMicrosoftLoginNonce';
import { getMicrosoftConfig } from './utils/microsoftConfig';
const LOG = Logger.getLogger('useMicrosoft');
export const useMicrosoft = () => {
  const microsoftLoginNonce = useMicrosoftLoginNonce();
  const dispatch = useDispatch();
  if (window.msal) {
    const microsoftApp = new window.msal.PublicClientApplication(getMicrosoftConfig());
    const provider = OAuthProviders.Microsoft;
    const onClick = () => {
      dispatch(oAuthAuthStarted(provider));
      dispatch(setWhichOAuthSelected(provider));
      microsoftApp.loginPopup({
        prompt: 'select_account',
        scopes: ['openid', 'profile', 'email'],
        nonce: microsoftLoginNonce
      }).then(response => {
        dispatch(setWhichOAuthLoading(OAuthProviders.Microsoft));
        dispatch(oAuthAuthSucceeded({
          token: response.idToken
        }, provider));
        dispatch(verifyOAuthVerificationPayload({
          idToken: response.idToken,
          accessToken: response.accessToken
        }, provider)).catch(error => {
          LOG.error('Error during verification of the microsoft access token', {
            extra: getSentryExtra(error)
          });
        });
      }).catch(error => {
        dispatch(oAuthAuthFailed(error, provider));
        if ('errorMessage' in error && error.errorMessage === 'User cancelled the flow.') {
          LOG.warn(`Microsoft User Cancelled Flow`, {
            extra: getSentryExtra(error)
          });
        } else {
          LOG.error(`Error with Microsoft Authentication`, {
            extra: getSentryExtra(error)
          });
        }
        dispatch(updateKeyValue(AppKey.WhichOAuthEndpointFailed, provider));
      });
    };
    return {
      onClick
    };
  }
  return {};
};