import { useResponsiveness } from '../../responsiveness/ResponsivenessProvider';
import { oAuthToButtonWidth } from '../OAuthMapping';
import { useEffect, useState } from 'react';
export const OAUTH_BUTTON_MOBILE_WIDTH = 320;
export const OAUTH_BUTTON_MAX_WIDTH = 400;
export const useOAuthButtonWidth = provider => {
  const {
    shouldResizeOAuthButton
  } = useResponsiveness();
  const [width, setWidth] = useState(shouldResizeOAuthButton ? OAUTH_BUTTON_MOBILE_WIDTH : OAUTH_BUTTON_MAX_WIDTH);
  const microsoftButton = document.getElementById('oauth-button-microsoft');
  useEffect(() => {
    if (microsoftButton) {
      //This will add the width of the Microsoft button if available as the width of the google / apple button. This is because the Microsoft width is dynamic and not hardcoded.
      setWidth(microsoftButton.offsetWidth);
    } else if (oAuthToButtonWidth[provider]) {
      setWidth(shouldResizeOAuthButton ? oAuthToButtonWidth[provider].mobile : oAuthToButtonWidth[provider].max);
    }
  }, [shouldResizeOAuthButton, microsoftButton, provider]);
  return {
    width
  };
};