import registerBasicData from 'I18n/init/register/sync/registerBasicData';
import experimentalLang from 'i2l?query=sporks!../../lang/laboratory-copy-experiments/en.lyaml';
// @ts-expect-error Untyped module
import createExperimentI18nProvider from 'laboratory-lib/i18n/createExperimentI18nProvider';
import { getSentryExtra } from '../error/getSentryExtra';
import { getTreatmentsClient } from '../experiments/treatmentsClient';
import { isPostVerification } from '../flow-settings/settingsSelectors';
import Logger from '../lib/Logger';
import { getPreferredLocale } from './LocaleUtils';
const LOG = Logger.getLogger('setupExperimentalI18n');
export const setupExperimentalI18n = () => {
  // We will not be able to run copy experiments post verification as it requires an
  // early requester. We can't do this because we must wait for the complete verification
  // call to come back to get the experiment UTK.
  if (isPostVerification()) {
    return Promise.resolve();
  }
  const locale = getPreferredLocale();
  const ExperimentalI18nProvider = createExperimentI18nProvider({
    client: getTreatmentsClient()
  });
  ExperimentalI18nProvider.setLocale({
    locale,
    langEnabled: true
  });
  return Promise.all([ExperimentalI18nProvider.register(experimentalLang), registerBasicData(ExperimentalI18nProvider)]).catch(reason => {
    LOG.error('Error occurred while setting up experimental I18n.', {
      extra: getSentryExtra(reason)
    });
  });
};