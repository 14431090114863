import { AppKey } from '../app/AppKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { setCookiesAccepted, updateCookiePreferences, updateKeyValue } from '../store/app/action-creators';
import { GdprEventSetup } from './GdprEvent';
const LOG = Logger.getLogger('setupGdpr');
const onGdprEvent = (event, store) => {
  if (event.detail && event.detail.consent && event.detail.consent.categories && event.detail.consent.previousCategories) {
    const previousAnalyticsCookieValue = event.detail.consent.previousCategories.analytics;
    const newAnalyticsCookieValue = event.detail.consent.categories.analytics;
    if (previousAnalyticsCookieValue !== newAnalyticsCookieValue) {
      store.dispatch(updateCookiePreferences({
        newValue: newAnalyticsCookieValue
      }));
    }
  }
  if (event.detail && event.detail.country) {
    store.dispatch(updateKeyValue(AppKey.Country, event.detail.country));
  }
  if (event.detail && event.detail.consent && event.detail.consent.allowed) {
    store.dispatch(setCookiesAccepted(event.detail.consent.allowed));
  }
};
export const setupGdpr = store => {
  GdprEventSetup().catch(error => {
    LOG.warn('Error with GDPR Setup', {
      extra: getSentryExtra(error)
    });
  });
  document.addEventListener('gdpr', event => {
    onGdprEvent(event, store);
  });
};