import { ViaParam } from 'growth-onboarding-signup-redirect/types';
import { getApplicationLocale } from '../i18n/LocaleUtils';
import { getIntentLink } from '../intents/intentSelectors';
import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { getQueryParam } from '../routing/getQueryParam';
import { getHublet, getIsNewPortalCreated } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { academy, academyTracks } from './productLinks';
export const getAcademyRedirect = state => {
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  const intentLink = getIntentLink(state);
  const isNewPortal = getIsNewPortalCreated(state);
  const params = {
    via: ViaParam.AcademySignup,
    newPortal: isNewPortal.toString(),
    awardType: '',
    language: getApplicationLocale()
  };
  if (intentLink) {
    return makeLinkWithQueryParams({
      link: intentLink,
      queryParamsToInclude: params,
      hublet
    });
  }
  let link = academy({
    portalId,
    hublet
  });
  const hubsSignupUrlQueryParam = getQueryParam('hubs_signup-url');
  if (hubsSignupUrlQueryParam === 'certification-day') {
    params['awardType'] = 'ONLY_SHOW_CERTIFICATE_COURSES';
    link = academyTracks({
      portalId,
      hublet
    });
  }
  return makeLinkWithQueryParams({
    link,
    queryParamsToInclude: params,
    hublet
  });
};