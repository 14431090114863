import { EndpointKey } from '../http/EndpointKey';
import { httpError, httpSuccess } from '../store/http-responses/action-creators';
export const handleCompleteVerificationResponse = response => dispatch => {
  const endpointKey = EndpointKey.CompleteVerification;
  if (!('odysseySignupData' in response) && !('userInformation' in response)) {
    return dispatch(httpError(endpointKey, {
      message: response.message,
      status: response.status,
      data: response.data
    }));
  }
  return dispatch(httpSuccess(endpointKey, response));
};