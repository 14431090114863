import { Microapp } from 'signup-constants/Microapp';
import { updateMicroappState } from '../store/microapp/action-creators';
import { Tracker } from '../store/tracking/action-creators';
import { getPortalId } from '../store/auth/selectors';
import { isExistingUserExistingAccount } from '../app/verificationTypeSelectors';
import { redirectExistingUserAction } from '../store/auth/action-creators';
import { getHublet } from '../store/app/selectors';
const redirectExistingUser = () => (dispatch, getState) => {
  const state = getState();
  const portalId = getPortalId(state);
  if (isExistingUserExistingAccount(state) && portalId) {
    dispatch(redirectExistingUserAction({
      portalId,
      hublet: getHublet(state)
    }));
  }
};
export const handleInvoiceAssetsGenerateSuccess = (response, dispatch) => {
  dispatch(updateMicroappState(Microapp.InvoiceGenerator, response));
  dispatch(redirectExistingUser());
};
export const handleInvoiceAssetsGenerateError = (error, dispatch) => {
  dispatch(Tracker.signupInteraction('invoice-generator-signup-bridge-endpoint-failure'));
  dispatch(redirectExistingUser());
};