import { Flow } from 'signup-constants/Flow';
import { SignupEmbedder } from 'signup-embedder';
import { getQueryParam } from '../routing/getQueryParam';
const COURSE_TYPE = 'course';
const LESSON_TYPE = 'lesson';
const LANDING_PAGE_TYPES = [COURSE_TYPE, LESSON_TYPE];
const getAcademyLandingPageText = (textType, stepKey) => {
  let label = `views.${stepKey}.${textType}.${Flow.Academy}.default`;
  const landingPageType = getQueryParam('landingPageType');
  if (landingPageType && LANDING_PAGE_TYPES.includes(landingPageType) && SignupEmbedder.isEmbedded()) {
    label = `views.${stepKey}.${textType}.${Flow.Academy}.${landingPageType}`;
  }
  return label;
};
export const getAcademyLandingPageTitle = stepKey => {
  return getAcademyLandingPageText('title', stepKey);
};
export const getAcademyLandingPageNextButtonText = stepKey => {
  return getAcademyLandingPageText('nextButtonLabel', stepKey);
};