import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { useEffect, useState } from 'react';
import { makeRequest } from '../http/makeRequest';
import { LOG } from '../oauth/OAuthMiddleware';
import { getSentryExtra } from '../error/getSentryExtra';
export const LOGIN_MICROSOFT_INIT_API = 'login-api/v1/microsoft/create-nonce';
export const LOGIN_MICROSOFT_INIT_KEY = 'microsoft-init';
const getMicrosoftNonceFromLoginApi = () => {
  const fetch = () => noAuthApiClient.get(LOGIN_MICROSOFT_INIT_API);
  return makeRequest({
    earlyRequestKey: LOGIN_MICROSOFT_INIT_KEY,
    fetch,
    shouldUseEarlyRequest: true
  });
};
export const useMicrosoftLoginNonce = () => {
  const [microsoftSignInNonce, setMicrosoftSignInNonce] = useState();
  useEffect(() => {
    getMicrosoftNonceFromLoginApi().then(({
      nonce
    }) => {
      setMicrosoftSignInNonce(nonce);
    }).catch(e => {
      LOG.warn(`Failed to retrieve nonce parameter for Microsoft from login`, {
        extra: getSentryExtra(e)
      });
    });
  }, []);
  return microsoftSignInNonce;
};