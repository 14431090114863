import { AuthActionTypes } from '../store/auth/action-types';
import { FlowActionTypes } from '../store/flow/action-types';
import { HttpResponseActionTypes } from '../store/http-responses/action-types';
import { OAuthActionTypes } from '../store/oauth/action-types';
import { SignupDataActionTypes } from '../store/signup-data/action-types';
import { EndpointHelper } from './EndpointHelper';
const NotTriggered = {
  triggered: false,
  triggerReason: null
};
export let TriggerReason;
(function (TriggerReason) {
  TriggerReason["StateOnlyTrigger"] = "State check returned true";
  TriggerReason["ExplicitReduxActionAndDataFilled"] = "Explicit Redux trigger and all required data is filled";
  TriggerReason["DataFilledByStep"] = "Completed step filled the required data";
  TriggerReason["DataFilledByPrefill"] = "Prefilling filled the required data";
  TriggerReason["DataFilledByOAuth"] = "OAuth filled the required data";
  TriggerReason["DataFilledByCompanyInfo"] = "Data filled by company info endpoint";
  TriggerReason["Retry"] = "Explicit Retry";
  TriggerReason["CompleteVerificationComplete"] = "complete verification complete";
})(TriggerReason || (TriggerReason = {}));
export const shouldRequestEndpoint = (state, action, endpointKey) => {
  const Endpoint = EndpointHelper(state, endpointKey);
  if (Endpoint) {
    if (action.type === HttpResponseActionTypes.RetryEndpoint) {
      return {
        triggered: Endpoint.getShouldRetry(action),
        triggerReason: TriggerReason.Retry
      };
    }
    if (Endpoint.hasAlreadyBeenFetched || !Endpoint.isRequestDataFilled || !Endpoint.doesStateCheckPass || Endpoint.fetchForStepsAreMissed || !Endpoint.isNextStepCorrect) {
      return NotTriggered;
    }
    if (Endpoint.isOnlyByState) {
      return {
        triggered: true,
        triggerReason: TriggerReason.StateOnlyTrigger
      };
    }
    if (Endpoint.isOnlyByTriggerAction) {
      return {
        triggerReason: TriggerReason.ExplicitReduxActionAndDataFilled,
        triggered: Endpoint.getIsTriggerAction(action)
      };
    }
  }
  switch (action.type) {
    case AuthActionTypes.ExistingUser:
    case FlowActionTypes.StepCompleted:
      return {
        triggerReason: TriggerReason.DataFilledByStep,
        triggered: Boolean(Endpoint && Endpoint.isRequestDataFilledByCurrentStep)
      };
    case SignupDataActionTypes.PrefillDataStored:
    case OAuthActionTypes.AuthDataStored:
      return {
        triggerReason: action.type === SignupDataActionTypes.PrefillDataStored ? TriggerReason.DataFilledByPrefill : TriggerReason.DataFilledByOAuth,
        triggered: true
      };
    case FlowActionTypes.PostVerificationFlowSet:
      return {
        triggerReason: TriggerReason.CompleteVerificationComplete,
        triggered: Boolean(Endpoint && Endpoint.isRequestDataFilled)
      };
    default:
      return NotTriggered;
  }
};