import { useSelector } from 'react-redux';
import { getRegisteredLeads } from '../store/http-responses/selectors';
import { isEndpointStatusSuccess } from '../store/http-responses/selectors';
import { EndpointKey } from '../http/EndpointKey';
import { useSignupData } from '../signup-data/useSignupData';
import { Data } from 'signup-constants/signupData/Data';
export const useRegisteredLeads = () => {
  const registeredLeads = useSelector(getRegisteredLeads);
  const isLeadLookupSuccessful = useSelector(isEndpointStatusSuccess(EndpointKey.PartnerRegistrationLeadLookup));
  const {
    setData: setCompanyDomain
  } = useSignupData(Data.CompanyDomain);
  const {
    setData: setCompanyName
  } = useSignupData(Data.CompanyName);
  let leads = [];
  if (isLeadLookupSuccessful && registeredLeads) {
    leads = registeredLeads.map(response => {
      return {
        text: response.domain,
        value: response.domain
      };
    });
  }
  const onCompanyDomainChange = evt => {
    const domainChosen = evt.target.value;
    setCompanyDomain(domainChosen);
    const hasFoundDomainInRegisteredLeads = registeredLeads && registeredLeads.find(item => item.domain === domainChosen);
    if (hasFoundDomainInRegisteredLeads) {
      setCompanyName(hasFoundDomainInRegisteredLeads.company);
    }
  };
  return {
    leads,
    onCompanyDomainChange
  };
};