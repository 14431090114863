import { useEffect } from 'react';
import { EventName } from '../../tracking/EventName';
import { getTracker } from '../../tracking/getTracker';
import { getExternalExperimentParameter } from './trackingExposureUtils';
let hasExposureBeenTrackedForAcqCache = false;
export function getHasExposureBeenTrackedForAcqExperiment() {
  return hasExposureBeenTrackedForAcqCache;
}
export function setHasExposureBeenTrackedForAcqExperiment() {
  hasExposureBeenTrackedForAcqCache = true;
}
export function resetExposureForAcqExperiment() {
  hasExposureBeenTrackedForAcqCache = false;
}
export const trackAcqExperimentExposure = () => {
  setHasExposureBeenTrackedForAcqExperiment();
  const externalExperimentParameter = getExternalExperimentParameter();
  externalExperimentParameter.forEach(value => {
    getTracker().track(EventName.ExperimentExposure, {
      action: value
    }, true);
  });
};
export const useTrackAcqExperimentExposure = () => {
  const exposureHasBeenTracked = getHasExposureBeenTrackedForAcqExperiment();
  useEffect(() => {
    if (!exposureHasBeenTracked) {
      trackAcqExperimentExposure();
    }
  });
};