import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getFullUrl } from 'hubspot-url-utils';
// @ts-expect-error Untyped module
import HubSpotOnlyAppCookieLogin from 'Nabisco/app/HubSpotOnlyAppCookieLogin';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { Tracker } from '../store/tracking/action-creators';
const LOG = Logger.getLogger('LoginClient');
const promiseDelay = function promiseDelay(timeout) {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
};
const retry = function retry({
  fn,
  delay = 10000,
  tries = 2,
  dispatch
}) {
  let numTries = 0;
  let lastError;
  const check = (resolve, reject) => {
    if (numTries >= tries) {
      reject(new Error(`retryUntil exceeded max tries. Last error: ${lastError}`));
    }
    ++numTries;
    return fn().then(() => {
      dispatch(Tracker.loginSuccess(numTries));
      resolve(true);
    }).catch(err => {
      lastError = err;
      return promiseDelay(delay).then(() => {
        LOG.error('Login Retry Triggered', {
          extra: getSentryExtra(err)
        });
        dispatch(Tracker.signupInteraction('login-retry'));
        check(resolve, reject).catch(error => {
          throw error;
        });
      }).catch(error => LOG.error('promiseDelay error:', {
        extra: getSentryExtra(error)
      }));
    });
  };
  return new Promise((resolve, reject) => {
    check(resolve, reject).catch(error => {
      throw error;
    });
  });
};
const googleLoginClient = new HubSpotOnlyAppCookieLogin({
  loginRequest: loginRequestData => {
    const url = `${getFullUrl('app-api')}/login-api/v1/google/one-tap`;
    return noAuthApiClient.post(url, {
      data: loginRequestData
    });
  },
  passThroughFallback: true
});
const microsoftLoginClient = new HubSpotOnlyAppCookieLogin({
  loginRequest: loginRequestData => {
    const url = `${getFullUrl('app-api')}/login-api/v1/microsoft/id-token-login`;
    return noAuthApiClient.post(url, {
      data: loginRequestData
    });
  },
  passThroughFallback: true
});
const appleLoginClient = new HubSpotOnlyAppCookieLogin({
  loginRequest: loginRequestData => {
    const url = `${getFullUrl('app-api')}/login-api/v1/apple/login`;
    return noAuthApiClient.post(url, {
      data: loginRequestData
    });
  },
  passThroughFallback: true
});
const passwordLoginClient = new HubSpotOnlyAppCookieLogin({
  loginRequest: credentials => {
    const url = `${getFullUrl('app-api', {
      hubletOverride: credentials.hublet
    })}/login-api/v1/login`;
    return noAuthApiClient.post(url, {
      data: credentials
    });
  },
  passThroughFallback: true
});
export function loginWithGoogle(idToken) {
  const googleLoginPayload = {
    credential: idToken,
    rememberLogin: false
  };
  return googleLoginClient.login(googleLoginPayload);
}
export function loginWithMicrosoft(idToken) {
  const microsoftLoginPayload = {
    idToken,
    useLongExpiration: true,
    rememberLogin: false
  };
  return microsoftLoginClient.login(microsoftLoginPayload);
}
export function loginWithApple(idToken) {
  const appleLoginPayload = {
    idToken,
    rememberLogin: false,
    client: 'HUBSPOT'
  };
  return appleLoginClient.login(appleLoginPayload);
}
export function passwordLogin(email, password, hublet, dispatch) {
  return retry({
    fn: () => {
      return passwordLoginClient.login({
        rememberLogin: true,
        email,
        password,
        hublet
      });
    },
    dispatch
  });
}
const LoginClient = {
  password: passwordLogin,
  google: loginWithGoogle,
  microsoft: loginWithMicrosoft,
  apple: loginWithApple
};
export default LoginClient;