import quickFetch from 'quick-fetch';
export let REQUEST_TYPE;
(function (REQUEST_TYPE) {
  REQUEST_TYPE["POST"] = "POST";
  REQUEST_TYPE["GET"] = "GET";
})(REQUEST_TYPE || (REQUEST_TYPE = {}));
const makeEarlyRequest = method => ({
  apiUrl,
  data,
  earlyRequestKey
}) => {
  quickFetch.makeEarlyRequest(earlyRequestKey, {
    contentType: 'application/json',
    data: JSON.stringify(data),
    dataType: 'json',
    timeout: 15000,
    type: method,
    url: quickFetch.getApiUrl(apiUrl)
  });
};
export const earlyRequestClient = {
  post: makeEarlyRequest(REQUEST_TYPE.POST),
  get: makeEarlyRequest(REQUEST_TYPE.GET)
};