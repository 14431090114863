export function contains(options, value) {
  // Without any specified check, the check should pass immediately
  if (!options) {
    return true;
  }
  if (options === value) {
    return true;
  }
  return Array.isArray(options) && options.includes(value);
}
export function testAgainstObject(object, candidate) {
  // Without any specified check, the check should pass immediately
  if (!object) {
    return true;
  }
  return Object.keys(object).every(key => contains(object[key], candidate[key]));
}
export function testAgainstArray(options, value) {
  // Without any specified check, the check should pass immediately
  if (!options) {
    return true;
  }
  const values = Array.isArray(value) ? value : [value];
  return Array.isArray(options) && options.every(option => values.includes(option));
}