function _hash(input) {
  let hash = 0;
  if (input.length === 0) {
    return hash;
  }
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    /* eslint-disable-next-line no-bitwise */
    hash = (hash << 5) - hash + char;
    /* eslint-disable-next-line no-bitwise */
    hash = hash & hash;
  }

  /* eslint-disable-next-line no-bitwise */
  return (hash >>> 0).toString(36);
}

/**
 * Generate a dummy company domain from a users email.
 * @param {string} email - The user email e.g. 'testuser@huspot.com'.
 * @return {string} a generated prefixed hashed domain of hubspot-developers-HASH_OF_EMAIL.com e.g. 'hubspot-developers-vceja8.com' .
 */
export const generateDevelopersDomainFromEmail = email => {
  return `hubspot-developers-${_hash(email)}.com`;
};
export const generateEngageAICompanyNameFromEmail = email => {
  return `hubspot-engage-ai-company-${_hash(email)}`;
};