import { useSelector } from 'react-redux';
import { getPostVerificationSteps, getPreVerificationSteps } from '../store/flow/selectors';
import { useCurrentStep } from './useCurrentStep';
import { useUnloadAlert } from './useUnloadAlert';
export const useSetupUnloadAlert = () => {
  const {
    currentStep
  } = useCurrentStep();
  const preVerification = useSelector(getPreVerificationSteps);
  const postVerification = useSelector(getPostVerificationSteps);
  const {
    unloadAlert
  } = useUnloadAlert({
    shouldAdd: preVerification && currentStep === preVerification[1] || postVerification && currentStep === postVerification[0]
  });
  return {
    unloadAlert
  };
};