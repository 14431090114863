import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { oAuthAuthFailed } from '../store/oauth/action-creators';
import ApplePlatform from './ApplePlatform';
const LOG = Logger.getLogger('useLoadAppleScript');
export const useLoadAppleScript = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadPromise = ApplePlatform.loadAppleId();
    loadPromise.then(hasAlreadyLoaded => {
      setLoaded(hasAlreadyLoaded);
    }).catch(error => {
      setLoaded(false);
      LOG.error('An error occured while loading the apple id script', {
        extra: getSentryExtra(error)
      });
      dispatch(oAuthAuthFailed(error, OAuthProviders.Apple));
    }).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);
  return {
    isAppleScriptLoading: isLoading || !isLoaded
  };
};