export const waitForVariable = (variable, target = window, delay = 200, maxRetries = 10) => {
  let retries = 0;
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (target[variable]) {
        clearInterval(interval);
        resolve();
      }
      if (retries === maxRetries) {
        clearInterval(interval);
        reject(new Error('Number of max retries reached'));
      }
      retries++;
    }, delay);
  });
};