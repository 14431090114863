import { Flow } from 'signup-constants/Flow';
import { getFlowKey } from '../routing/getFlowKey';
import { BranchCmsBusinessMaturity } from './branches/BranchCmsBusinessMaturity';
import { BranchIntegratedAppCookieless } from './branches/BranchIntegratedAppCookieless';
import { BranchOptionalPhoneNumber } from './branches/BranchOptionalPhoneNumber';
import { BranchQuestionFallback } from './branches/BranchQuestionFallback';
import { ProductSetupUIBranch } from './branches/ProductSetupUIBranch';
import { BranchCOAC0002 } from './branches/BranchCOAC0002';
import { BranchAddHublet } from './branches/BranchAddHubletStep';
import { BranchAddHubletStandaloneCmsPartner } from './branches/BranchAddHubletStandaloneCMSPartner';
const DefaultBranches = [BranchQuestionFallback];
let FlowBranches;
export const getFlowBranches = () => {
  if (typeof FlowBranches === 'undefined') {
    const softwareSignupBranches = [...DefaultBranches, BranchOptionalPhoneNumber];
    FlowBranches = {
      [Flow.Crm]: [...softwareSignupBranches, BranchCOAC0002, BranchAddHublet],
      [Flow.Marketing]: [...softwareSignupBranches, BranchCOAC0002, BranchAddHublet],
      [Flow.Sales]: [...softwareSignupBranches, ProductSetupUIBranch, BranchCOAC0002, BranchAddHublet],
      [Flow.Service]: [...softwareSignupBranches, BranchCOAC0002, BranchAddHublet],
      [Flow.CmsFree]: [BranchCmsBusinessMaturity, BranchAddHublet, ...softwareSignupBranches],
      [Flow.Integrations]: [BranchIntegratedAppCookieless, BranchAddHublet],
      [Flow.ClassroomTraining]: [BranchAddHublet],
      [Flow.AssetProvider]: [BranchAddHublet],
      [Flow.SolutionsProvider]: [BranchAddHublet],
      [Flow.FbCrm]: [BranchAddHublet],
      [Flow.HubspotForStartups]: [BranchAddHublet],
      [Flow.HubSpotPartners]: [BranchAddHublet],
      [Flow.StandaloneCmsPartner]: [BranchAddHubletStandaloneCmsPartner],
      [Flow.Microapp]: [BranchCmsBusinessMaturity, BranchAddHublet],
      [Flow.Commerce]: [BranchAddHublet]
    };
  }
  return FlowBranches[getFlowKey()] || DefaultBranches;
};