import { EndpointKey } from '../http/EndpointKey';
import { HttpResponseActionTypes } from '../store/http-responses/action-types';
import { trackVisitorContextExistingUser, trackVisitorContextFailure, trackVisitorContextFetch, trackVisitorContextFilled, trackVisitorContextNotFilled, trackVisitorContextRateLimited } from '../store/visitor-context/action-creators';
import { VisitorContextActionTypes } from '../store/visitor-context/action-types';
export const visitorContextActionHandlers = {
  [HttpResponseActionTypes.Error]: (action, state, dispatch) => {
    if (action.endpointKey === EndpointKey.VisitorContextFetch) {
      const status = action.error.status;
      const trackMethod = {
        409: trackVisitorContextExistingUser,
        404: trackVisitorContextFailure,
        429: trackVisitorContextRateLimited
      };
      if (status in trackMethod) {
        dispatch(trackMethod[status]());
      }
    }
  },
  [VisitorContextActionTypes.Fetch]: (action, state, dispatch) => {
    dispatch(trackVisitorContextFetch());
  },
  [VisitorContextActionTypes.Filled]: (action, state, dispatch) => {
    dispatch(trackVisitorContextFilled());
  },
  [VisitorContextActionTypes.NotFilled]: (action, state, dispatch) => {
    dispatch(trackVisitorContextNotFilled());
  }
};