import { isBranchTriggered } from '../store/flow-branches/action-creators';
import { getCurrentStep, getNextSteps } from '../store/flow/selectors';
import { getFlowBranches } from './getFlowBranches';
export const decideBranch = (state, dispatch) => {
  const branches = getFlowBranches();
  const currentFlow = getNextSteps(state);
  const currentStep = getCurrentStep(state);
  const transformedFlow = branches.reduce((flow, branch) => {
    if (dispatch(isBranchTriggered(state, branch))) {
      branch.processed = true;
      branch.processedOnStep = currentStep;
      return branch.rebuildFlow(flow, state, dispatch);
    }
    return flow;
  }, currentFlow);
  return transformedFlow;
};