import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { getApplicationLocale } from '../i18n/LocaleUtils';
import { useSignupData } from '../signup-data/useSignupData';
import { Data } from 'signup-constants/signupData/Data';
import { useEffect, useState } from 'react';
export const useName = () => {
  const [canRenderNameSubtitle, setCanRenderNameSubtitle] = useState(null);
  const locale = getApplicationLocale();
  const isJapanese = locale === Locale.ja;
  const {
    data: firstName
  } = useSignupData(Data.FirstName);
  const {
    data: lastName
  } = useSignupData(Data.LastName);
  const name = isJapanese ? lastName : firstName;
  useEffect(() => {
    if (canRenderNameSubtitle === null) {
      setCanRenderNameSubtitle(typeof name === 'string' && name !== '');
    }
  }, [name, canRenderNameSubtitle]);
  return {
    canRenderNameSubtitle,
    name
  };
};