import { Step } from 'signup-constants/Step';
import { ExperimentKeys } from '../../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../../experiments/ExperimentParameterGroup';
import { getIsActiveAndAssignedToVariant } from '../../store/experiments/selectors';
import { setFlowForCOAC0002 } from '../../experiments/coac0002/COAC0002Utils';
import { getIsExistingUserSession } from '../../store/app/selectors';
import { isPreVerification } from '../../flow-settings/settingsSelectors';
export const BranchCOAC0002 = {
  name: 'BranchCOAC0002',
  stateCheck: state => {
    return getIsActiveAndAssignedToVariant(state, ExperimentKeys.COAC0002, [ExperimentParameterGroup.Variant_A]);
  },
  rebuildFlow: (flow, state, dispatch) => {
    const nextSteps = dispatch(setFlowForCOAC0002({
      isPreVerificationExistingUserSession: getIsExistingUserSession(state) && isPreVerification()
    }));
    return nextSteps;
  },
  processed: false,
  processedOnStep: Step.LandingPage,
  afterData: null,
  afterStep: null
};