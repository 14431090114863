import { useSelector } from 'react-redux';
import { getActiveLoading } from '../store/loading-status/selectors';
import { getIsVerificationExpired } from '../store/app/selectors';
import { LoadingKey } from '../app-active-loading/LoadingKey';
export const useIsFramedCentredModalStep = () => {
  const isVerificationExpired = useSelector(getIsVerificationExpired);
  const activeLoading = useSelector(getActiveLoading);
  const isVerificationExpiredRefresh = isVerificationExpired && (!activeLoading || activeLoading !== LoadingKey.SignupRequest);
  if (isVerificationExpiredRefresh) {
    return {
      isFramedCentredModalStep: true
    };
  }
  return {
    isFramedCentredModalStep: false
  };
};