import { Data } from 'signup-constants/signupData/Data';
const SIGNUP_DATA_TO_KEEP_IN_URL = [Data.Country];
export const getQueryParamsNoSignupData = (queryParams, signupData) => {
  return Object.keys(queryParams).reduce((acc, key) => {
    const datakey = key.toUpperCase();
    const isNotSignupDataKey = !signupData[datakey];
    const shouldKeepKey = SIGNUP_DATA_TO_KEEP_IN_URL.includes(datakey);
    if (isNotSignupDataKey || shouldKeepKey) {
      acc[key] = queryParams[key];
    }
    return acc;
  }, {});
};