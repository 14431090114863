module.exports = {
  "authState": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "eventSubtype": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "surveyInteraction": {
    "name": "survey interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "utmVerification": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "utmMedium": "string",
      "utmSource": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "creationError": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "statusMessage": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "formError": {
    "name": "signup interaction",
    "class": "error",
    "properties": {
      "eventSubtype": "string",
      "formError": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "invalidDomainCollected": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "invalidDomain": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "interaction": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "leavingStep": {
        "type": "string",
        "isOptional": true
      },
      "totalNumberOfCalls": {
        "type": "number",
        "isOptional": true
      },
      "prefilledValue": {
        "type": "string",
        "isOptional": true
      },
      "currentValue": {
        "type": "string",
        "isOptional": true
      },
      "message": {
        "type": "string",
        "isOptional": true
      },
      "numberOfDisplayed": {
        "type": "number",
        "isOptional": true
      },
      "verificationMethod": {
        "type": "string",
        "isOptional": true
      },
      "partner": {
        "type": "string",
        "isOptional": true
      },
      "selectedOption": {
        "type": "array",
        "isOptional": true
      },
      "currentStep": {
        "type": "string",
        "isOptional": true
      },
      "conversationId": {
        "type": "string",
        "isOptional": true
      },
      "ctaClicked": {
        "type": "string",
        "isOptional": true
      },
      "stepName": {
        "type": "string",
        "isOptional": true
      },
      "provider": {
        "type": "string",
        "isOptional": true
      },
      "currency": {
        "type": "string",
        "isOptional": true
      },
      "errorType": {
        "type": "string",
        "isOptional": true
      },
      "errorCode": {
        "type": "string",
        "isOptional": true
      },
      "tour": {
        "type": "string",
        "isOptional": true
      },
      "step": {
        "type": "string",
        "isOptional": true
      },
      "via": {
        "type": "string",
        "isOptional": true
      },
      "fields": {
        "type": "array",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "stepCompleted": {
    "name": "signup step completed",
    "class": "interaction",
    "properties": {
      "stepName": {
        "type": "string"
      },
      "nextStepName": {
        "type": "string",
        "isOptional": true
      },
      "selectedOption": {
        "type": "array",
        "isOptional": true
      },
      "isUsingHubletRecommendation": {
        "type": "boolean",
        "isOptional": true
      },
      "isSkipped": {
        "type": "boolean",
        "isOptional": true
      },
      "numberOfDisplayed": {
        "type": "number",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "view": {
    "name": "signup interaction",
    "class": "view",
    "properties": {
      "stepName": {
        "type": "string"
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "click": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "linkLocation": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "signupInitiated": {
    "name": "signup initiated",
    "class": "interaction",
    "properties": {
      "theme": {
        "type": "string",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "signupPageView": {
    "name": "signup page view",
    "class": "view",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "signupRedirect": {
    "name": "signup redirect",
    "class": "interaction",
    "properties": {
      "newUser": {
        "type": "boolean"
      },
      "newPortal": {
        "type": "boolean"
      },
      "url": {
        "type": "string"
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "signupCompleted": {
    "name": "signup completed",
    "class": "signup",
    "properties": {
      "newUser": {
        "type": "boolean"
      },
      "resurrectionAttempt": {
        "type": "number",
        "isOptional": true
      },
      "doesNotHaveDomain": {
        "type": "boolean",
        "isOptional": true
      },
      "theme": {
        "type": "string",
        "isOptional": true
      },
      "hasBeenAnonymouslyTracked": {
        "type": "boolean",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "verifyIdentityStarted": {
    "name": "Verify Identity Started",
    "class": "interaction",
    "properties": {
      "verificationMethod": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "verifyIdentityCompleted": {
    "name": "Verify Identity Completed",
    "class": "interaction",
    "properties": {
      "verificationMethod": "string",
      "resurrectionAttempt": {
        "type": "number",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "surveyCompleted": {
    "name": "Survey Completed",
    "class": "interaction",
    "namespace": "signup",
    "properties": {
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    }
  },
  "industryCollected": {
    "name": "Industry Collected",
    "class": "interaction",
    "properties": {
      "industryName": "string",
      "industryId": "string",
      "userInput": {
        "type": "string",
        "isOptional": true
      },
      "selectedChoice": {
        "type": "string"
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "jobRoleCollected": {
    "name": "Job Role Collected",
    "class": "interaction",
    "properties": {
      "jobRole": "string",
      "jobRoleCategory": "string",
      "userInput": {
        "type": "string",
        "isOptional": true
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "backButton": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "fromStep": {
        "type": "string"
      },
      "toStep": {
        "type": "string"
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "messagingScrolling": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "percentage": {
        "type": "number"
      },
      "stepName": {
        "type": "string"
      },
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "experimentExposure": {
    "name": "signup interaction",
    "class": "interaction",
    "properties": {
      "action": "string",
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "signup"
  },
  "signupCompletedAnonymous": {
    "name": "signup completed anonymous",
    "class": "signup",
    "namespace": "signup",
    "properties": {
      "sourceUrl": {
        "type": "string",
        "isOptional": true
      },
      "exp-content": {
        "type": "string",
        "isOptional": true
      },
      "exp-source": {
        "type": "string",
        "isOptional": true
      },
      "exp-term": {
        "type": "string",
        "isOptional": true
      },
      "exp-id": {
        "type": "string",
        "isOptional": true
      },
      "exp-medium": {
        "type": "string",
        "isOptional": true
      },
      "exp-campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_medium": {
        "type": "string",
        "isOptional": true
      },
      "verificationType": {
        "type": "string",
        "isOptional": true
      },
      "platform": {
        "type": "string",
        "isOptional": true
      },
      "signupMode": {
        "type": "string",
        "isOptional": false
      },
      "signupType": {
        "type": "string",
        "isOptional": true
      },
      "signupVersion": {
        "type": "string",
        "isOptional": true
      },
      "intent": {
        "type": "string",
        "isOptional": true
      },
      "integration": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupCta": {
        "type": "string",
        "isOptional": true
      },
      "internalHubsSignupUrl": {
        "type": "string",
        "isOptional": true
      },
      "optSidebar": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchCta": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalFirstTouchPostCta": {
        "type": "string",
        "isOptional": true
      },
      "internalOfferUrl": {
        "type": "string",
        "isOptional": true
      },
      "internalSignupLinkType": {
        "type": "string",
        "isOptional": true
      },
      "viralSourcePortalId": {
        "type": "string",
        "isOptional": true
      },
      "viralSourceUserId": {
        "type": "string",
        "isOptional": true
      },
      "referrerHost": {
        "type": "string",
        "isOptional": true
      },
      "referrerPath": {
        "type": "string",
        "isOptional": true
      },
      "referrerUrl": {
        "type": "string",
        "isOptional": true
      },
      "referrerParams": {
        "type": "string",
        "isOptional": true
      },
      "irclickid": {
        "type": "string",
        "isOptional": true
      },
      "mpid": {
        "type": "string",
        "isOptional": true
      },
      "currentPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "lastPageUrl": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_source": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_campaign": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_id": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_term": {
        "type": "string",
        "isOptional": true
      },
      "how_utm_content": {
        "type": "string",
        "isOptional": true
      },
      "experimentParameter": {
        "type": "array",
        "isOptional": true
      },
      "trackerType": {
        "type": "string",
        "isOptional": true
      },
      "applicationId": {
        "type": "string",
        "isOptional": true
      },
      "screen": {
        "type": "string",
        "isOptional": true
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "gclid": {
        "type": "string",
        "isOptional": true
      },
      "dclid": {
        "type": "string",
        "isOptional": true
      },
      "crmExperience": {
        "type": "string",
        "isOptional": true
      },
      "jobField": {
        "type": "string",
        "isOptional": true
      },
      "jobRole": {
        "type": "string",
        "isOptional": true
      },
      "jobRoleDetailed": {
        "type": "string",
        "isOptional": true
      },
      "jobTitle": {
        "type": "string",
        "isOptional": true
      },
      "hubInterest": {
        "type": "string",
        "isOptional": true
      },
      "companySize": {
        "type": "string",
        "isOptional": true
      },
      "companySizeNumber": {
        "type": "number",
        "isOptional": true
      },
      "industry": {
        "type": "string",
        "isOptional": true
      },
      "phoneNumberSubmitted": {
        "type": "boolean",
        "isOptional": true
      },
      "hublet": {
        "type": "string",
        "isOptional": true
      },
      "messagesUtk": {
        "type": "string",
        "isOptional": true
      },
      "purpose": {
        "type": "string",
        "isOptional": true
      },
      "stage": {
        "type": "string",
        "isOptional": true
      },
      "goal": {
        "type": "array",
        "isOptional": true
      },
      "productExperience": {
        "type": "string",
        "isOptional": true
      },
      "problemAwareness": {
        "type": "string",
        "isOptional": true
      },
      "profileId": {
        "type": "string",
        "isOptional": true
      },
      "microapp": {
        "type": "string",
        "isOptional": true
      },
      "team_size": {
        "type": "string",
        "isOptional": true
      },
      "previousValue": {
        "type": "string",
        "isOptional": true
      }
    }
  }
};