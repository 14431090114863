import noPortalApiClient from 'hub-http/clients/noPortalApiClient';
import { getSignupApiEndpoint } from '../http-clients/getSignupApiEndpoint';
import { EndpointPath } from '../http-clients/signupClientPaths';
import { getSignupBody } from '../http-signup/getSignupBody';
import { getHublet } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
export const postSignup = state => {
  const url = getSignupApiEndpoint(EndpointPath.postSignup);
  const postBody = getSignupBody(state);
  const hublet = getHublet(state);
  const portalId = getPortalId(state);
  return noPortalApiClient.post(url, {
    data: {
      signupRequest: Object.assign({}, postBody),
      region: hublet,
      hubId: portalId
    }
  });
};