import { getPrefilledDataQueryParams, getPrefilledSignupData } from '../flow-settings/prefillDataSelectors';
import { bulkUpdate, prefillDataStored, stampPrefilled } from '../store/signup-data/action-creators';
export let PrefillType;
(function (PrefillType) {
  PrefillType["EmbedderPostMessage"] = "EmbedderPostMessage";
  PrefillType["VisitorContext"] = "VisitorContext";
  PrefillType["CompanyInfo"] = "CompanyInfo";
  PrefillType["QueryParam"] = "QueryParam";
})(PrefillType || (PrefillType = {}));
export const prefillEmbeddedSignupData = data => dispatch => {
  const prefills = getPrefilledSignupData(data);
  const dataIsPopulated = Object.keys(prefills).length !== 0;
  if (dataIsPopulated) {
    dispatch(bulkUpdate(prefills));
    dispatch(stampPrefilled(prefills, PrefillType.EmbedderPostMessage));
    dispatch(prefillDataStored());
  }
};
export const prefillQueryParamSignupData = () => dispatch => {
  const prefills = getPrefilledDataQueryParams();
  const dataIsPopulated = Object.keys(prefills).length !== 0;
  if (dataIsPopulated) {
    dispatch(bulkUpdate(prefills));
    dispatch(stampPrefilled(prefills, PrefillType.QueryParam));
    dispatch(prefillDataStored());
  }
};