import { ONBOARDING_MARKETING_FREE } from 'getting-started-shared-tasks/onboardingKeys';
import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import { ONBOARDING_VIEWS } from 'user-context/onboardingSettings';
import { MARKETING_SIGNUP } from '../constants/signupConstants';
import { HubletUrl } from '../utils/urls';
const marketingExperiencesChecks = [{
  redirectPath: portalId => {
    return {
      url: `${HubletUrl.getAppUrl()}/set-up-crm/${portalId}/use-cases`,
      view: ONBOARDING_VIEWS.MARKETING,
      onboardingKey: ONBOARDING_MARKETING_FREE,
      groupKey: FREE_GROUP_KEYS.freeMarketingView
    };
  },
  checks: [{
    signupType: MARKETING_SIGNUP
  }]
}];
export default marketingExperiencesChecks;