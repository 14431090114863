import { isPreVerification } from '../flow-settings/settingsSelectors';
import { setExperimentUtk } from '../store/experiments/action-creators';
import { ExperimentActionTypes } from '../store/experiments/action-types';
import { ExperimentIdentifier } from './ExperimentIdentifier';
import { getExperimentIdentifierPhase, getExperimentsToFetch, getPortalExperiments } from './ExperimentUtils';
import { getUserExperimentIdentifier } from './getUserExperimentIdentifier';
import { getAllUtkExperiments } from './getUtkExperiments';
import { getPortalIdTreatmentsClient } from './portalTreatmentsClient';
import { getTreatmentsClient } from './treatmentsClient';
export const handleBulkTreatmentsSuccess = (response, dispatch, getState) => {
  const identifierPhase = getExperimentIdentifierPhase(getState());
  dispatch({
    type: ExperimentActionTypes.BulkUpdateExperiments,
    response,
    identifierPhase
  });
  return dispatch({
    type: ExperimentActionTypes.ExperimentsFetched,
    identifierPhase
  });
};
export const makeBulkTreatmentsRequest = (state, dispatch, action) => {
  if (action.type !== ExperimentActionTypes.FetchExperiments) {
    return Promise.resolve();
  }
  const experiments = getExperimentsToFetch({
    identifierPhase: action.identifierPhase
  });
  if (experiments.length === 0) {
    return Promise.resolve({});
  }
  if (action.identifierPhase === ExperimentIdentifier.PortalId) {
    const portalIDClient = getPortalIdTreatmentsClient(state);
    return new Promise(resolve => portalIDClient.resolveExposures(getPortalExperiments(), resolve));
  }
  if (isPreVerification()) {
    dispatch(setExperimentUtk(getUserExperimentIdentifier()));
  }
  const utkExperiments = getAllUtkExperiments();
  return new Promise(resolve => getTreatmentsClient().resolveExposures(utkExperiments, resolve));
};