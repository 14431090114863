import { CmsSignupIntentOption } from 'signup-constants/signupData/CmsOption';
import { Data } from 'signup-constants/signupData/Data';
import { FlowStateManager } from '../../flow-management/FlowStateManager';
import { getSignupDataValue } from '../../store/signup-data/selectors';
import { Step } from 'signup-constants/Step';
export const BranchCmsBusinessMaturity = {
  name: 'BranchCmsBusinessMaturity',
  stateCheck: state => {
    const cmsSignupIntentValue = getSignupDataValue(state, Data.CmsSignupIntent);
    return cmsSignupIntentValue === CmsSignupIntentOption.Personal;
  },
  rebuildFlow: (flow, state, dispatch) => {
    return dispatch(FlowStateManager.removeSteps([Step.CmsBusinessMaturity], false));
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};