// @ts-expect-error Interframe is not yet migrated
import { MessageTypes } from 'interframe/Constants';
import { SignupEmbedder } from 'signup-embedder';
import { ACKNOWLEDGE_MESSAGE_TYPE, GOOGLE_AUTH_VERIFIED_EXISTING_USER_PORTAL_CREATED_MESSAGE_TYPE, GOOGLE_AUTH_VERIFIED_NEW_USER_PORTAL_CREATED_MESSAGE_TYPE, HANDSHAKE_MESSAGE_TYPE, SIGNUP_FLOW_NAVIGATION_MESSAGE_TYPE, SIGNUP_INITIAL_FLOW_MESSAGE_TYPE, SIGNUP_REDIRECT_MESSAGE_TYPE, SIGNUP_STARTED_MESSAGE_TYPE, SIGNUP_DATA_MESSAGE_TYPE, LAUNCH_LOGIN_POPUP_MESSAGE_TYPE } from 'signup-embedder/constants/MessageTypes';
import { isCodeVerificationSignup, isExistingUser, isExistingUserExistingAccount, isGoogleSignup, isOAuthSignup } from '../app/verificationTypeSelectors';
import InterframeContainer from '../interframe/InterframeContainer';
import Logger from '../lib/Logger';
import { getFlowKey } from '../routing/getFlowKey';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import { getQueryParams } from '../routing/getQueryParams';
import { AppActionTypes } from '../store/app/action-types';
import { getIsNewUser } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { FlowActionTypes } from '../store/flow/action-types';
import { TrackingActionTypes } from '../store/tracking/action-types';
import { doesTargetWindowExist } from './doesTargetWindowExist';
import { embeddedPrefillData } from '../store/app/action-creators';
import { IntegrationsActionTypes } from '../store/integrations/action-types';
export const onInterframeMessage = (message, dispatch) => {
  const {
    type
  } = message;
  if (type === HANDSHAKE_MESSAGE_TYPE) {
    // By default we will acknowledge all handshakes from all sources
    // In the future if we want to completely disable the embedding for
    // some urls we can use this response to deny the embedding.

    InterframeContainer.getInstance().postResponse(message, {
      type: ACKNOWLEDGE_MESSAGE_TYPE
    }, MessageTypes.JSON);
  } else if (type === SIGNUP_DATA_MESSAGE_TYPE) {
    // Prefill signup data via post message.
    dispatch(embeddedPrefillData(message.data));
  }
};
const postMessageMap = new Map([[AppActionTypes.AppStarted, {
  type: SIGNUP_STARTED_MESSAGE_TYPE
}], [TrackingActionTypes.SignupRedirect, {
  type: SIGNUP_REDIRECT_MESSAGE_TYPE,
  payloadCreator: action => ({
    url: action.url
  })
}], [FlowActionTypes.SetFlow, {
  type: SIGNUP_INITIAL_FLOW_MESSAGE_TYPE,
  payloadCreator: action => ({
    steps: action.nextSteps
  })
}], [FlowActionTypes.GoNextStep, {
  type: SIGNUP_FLOW_NAVIGATION_MESSAGE_TYPE,
  payloadCreator: action => {
    action = action;
    return {
      fromStep: action.step,
      toStep: action.nextSteps.length > 0 ? action.nextSteps[0] : null
    };
  }
}], [TrackingActionTypes.BackButton, {
  type: SIGNUP_FLOW_NAVIGATION_MESSAGE_TYPE,
  payloadCreator: action => {
    action = action;
    return {
      fromStep: action.fromStep,
      toStep: action.toStep
    };
  }
}], [IntegrationsActionTypes.IntegratedAppRedirect, {
  type: SIGNUP_REDIRECT_MESSAGE_TYPE,
  payloadCreator: action => ({
    params: action.params
  })
}], [IntegrationsActionTypes.LaunchLoginPopup, {
  type: LAUNCH_LOGIN_POPUP_MESSAGE_TYPE
}]]);
export const LOG = Logger.getLogger('SignupEmbeddedPostmanMiddleware');
export const SignupEmbeddedPostmanMiddleware = store => next => action => {
  const state = store.getState();
  if (SignupEmbedder.isActive()) {
    const interframe = InterframeContainer.getInstance();
    const queryParams = getQueryParams();
    const isPopup = SignupEmbedder.isPopup();
    if (queryParams['intent'] === 'oauthAuthorization') {
      return next(action);
    }
    const targetWindow = doesTargetWindowExist();
    if (action.type === AppActionTypes.AppStarted) {
      interframe.subscribe(message => onInterframeMessage(message, store.dispatch));
    }
    if (postMessageMap.has(action.type)) {
      const postMessage = postMessageMap.get(action.type);
      const type = postMessage && postMessage.type;
      const payloadCreator = postMessage && postMessage.payloadCreator;
      const flow = getFlowKey();
      const integration = getIntegrationKey();
      const isEmbedded = SignupEmbedder.isEmbedded();
      if (!interframe) {
        LOG.warn('Interframe is undefined, cannot send postMessage', {
          extra: {
            flow,
            integration,
            isPopup,
            isEmbedded,
            actionType: action.type,
            targetWindow: interframe.targetWindow,
            targetOrigin: interframe.targetOrigin
          }
        });
      }
      if (!targetWindow) {
        LOG.warn('Target window is undefined, cannot send postMessage', {
          extra: {
            flow,
            integration,
            isPopup,
            isEmbedded,
            actionType: action.type,
            targetWindow: interframe.targetWindow,
            targetOrigin: interframe.targetOrigin
          }
        });
      }
      if (interframe && targetWindow) {
        const payload = payloadCreator !== undefined ? payloadCreator(action) : {};
        interframe.postMessage(Object.assign({
          type
        }, payload), MessageTypes.JSON);
      }
      if (SignupEmbedder.isPopup() && action.type === TrackingActionTypes.SignupRedirect && (isOAuthSignup(state) || isExistingUser(state) || isExistingUserExistingAccount(state) || isCodeVerificationSignup(state))) {
        window.close();
      }
    }
    if (isGoogleSignup(state) && action.type === AppActionTypes.SignupEnded) {
      const portalId = getPortalId(state);
      const isNewUser = getIsNewUser(state);
      const type = isNewUser ? GOOGLE_AUTH_VERIFIED_NEW_USER_PORTAL_CREATED_MESSAGE_TYPE : GOOGLE_AUTH_VERIFIED_EXISTING_USER_PORTAL_CREATED_MESSAGE_TYPE;
      if (interframe && targetWindow) {
        interframe.postMessage({
          type,
          portalId
        }, MessageTypes.JSON);
      }
    }
  }
  return next(action);
};