import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LoadingScreen } from '../app-active-loading/LoadingScreen';
import { getIsNewUserExperiment } from '../experiments/ExperimentUtils';
import { useCurrentStep } from '../hooks/useCurrentStep';
import { getFlowKey } from '../routing/getFlowKey';
import { getActiveLoading } from '../store/loading-status/selectors';
import { useTracker } from '../tracking/useTracker';
import { getHubSpotConversationsClient } from './getHubSpotConversationsClient';
import { shouldShowChatBot } from './shouldShowChatBot';
import { useSetupChatbot } from './useSetupChatbot';
export const useChatBot = () => {
  const {
    currentStep
  } = useCurrentStep();
  const [loaded, setLoaded] = useState(false);
  const [handlerAdded, setHandlerAdded] = useState(false);
  const isNewUser = useSelector(getIsNewUserExperiment);
  const flowKey = getFlowKey();
  const Tracker = useTracker();
  const activeLoading = useSelector(getActiveLoading);
  const isLoading = activeLoading && LoadingScreen[activeLoading];
  const {
    setupChatbot
  } = useSetupChatbot({
    loaded,
    setLoaded
  });
  useEffect(() => {
    const conversationsClient = getHubSpotConversationsClient();
    if (conversationsClient) {
      setupChatbot();
      if (!handlerAdded) {
        setHandlerAdded(true);
        conversationsClient.on('conversationStarted', ({
          conversation
        }) => {
          Tracker.signupInteraction('chatbot-conversation-started', {
            currentStep,
            conversationId: conversation.conversationId
          });
        });
        conversationsClient.on('conversationClosed', ({
          conversation
        }) => {
          Tracker.signupInteraction('chatbot-conversation-ended', {
            currentStep,
            conversationId: conversation.conversationId
          });
        });
      }
    } else {
      window.hsConversationsOnReady = [() => {
        setupChatbot();
      }];
    }
    return () => {
      const showChatBot = shouldShowChatBot({
        flowKey
      });
      if (!showChatBot && conversationsClient && conversationsClient.widget && loaded) {
        conversationsClient.widget.remove();
        setLoaded(false);
      }
    };
  }, [currentStep, loaded, isNewUser, flowKey, Tracker, isLoading, handlerAdded, setHandlerAdded, setupChatbot]);
};