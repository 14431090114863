import { SLINKY } from 'HubStyleTokens/colors';
import styled from 'styled-components';
import UILoadingButton from 'UIComponents/button/UILoadingButton';
import UIIcon from 'UIComponents/icon/UIIcon';
export const DesktopIcon = styled(UIIcon).withConfig({
  displayName: "NextButtonstyles__DesktopIcon",
  componentId: "sc-1kjsgj2-0"
})(["font-size:17px;"]);
export const StyledLoadingButton = styled(UILoadingButton).withConfig({
  displayName: "NextButtonstyles__StyledLoadingButton",
  componentId: "sc-1kjsgj2-1"
})(["min-height:3rem;padding:12px 8px;line-height:20px !important;font-size:16px !important;white-space:normal !important;width:100%;background-color:#ff5c35 !important;border-color:#ff5c35 !important;&:hover{background-color:#ff6c49 !important;border-color:#ff6c49 !important;}", ""], props => props.disabled && `span {
    color: ${SLINKY}
  }`);