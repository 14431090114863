import { getFlowBranches } from '../../flow-branches/getFlowBranches';
import { hasDataJustBeenFilled } from '../../signup-data/hasDataJustBeenFilled';
import { hasStepJustBeenCompleted } from '../../step/hasStepJustBeenCompleted';
import { getCurrentStep } from '../flow/selectors';
export const unprocessBranch = () => (dispatch, getState) => {
  const state = getState();
  const branches = getFlowBranches();
  branches.forEach(branch => {
    if (branch.processedOnStep === getCurrentStep(state)) {
      branch.processed = false;
      branch.processedOnStep = null;
    }
  });
  return branches;
};
export const isBranchTriggered = (state, branch) => dispatch => {
  if (branch.processed) {
    return false;
  }
  let triggeredByAfterData = true;
  let triggeredByAfterStep = true;
  let triggeredByState = true;
  if (branch.stateCheck) {
    triggeredByState = branch.stateCheck(state, dispatch);
  }
  if (branch.afterData) {
    triggeredByAfterData = hasDataJustBeenFilled(state, branch.afterData);
  }
  if (branch.afterStep) {
    triggeredByAfterStep = hasStepJustBeenCompleted(state, branch.afterStep);
  }
  return triggeredByAfterData && triggeredByAfterStep && triggeredByState;
};