import { Data } from 'signup-constants/signupData/Data';
import { setSignupDataValue } from '../store/signup-data/action-creators';
import { generateDevelopersDomainFromEmail, generateEngageAICompanyNameFromEmail } from '../utils/generateDummyDomainFromEmail';
export const prefillCompanyDomainFromEmail = email => dispatch => {
  const generatedDomain = generateDevelopersDomainFromEmail(email);
  dispatch(setSignupDataValue(Data.CompanyDomain, generatedDomain));
};
export const prefillCompanyDetailsForEngageAI = email => dispatch => {
  const generatedCompanyName = generateEngageAICompanyNameFromEmail(email);
  const generatedDomain = `${generatedCompanyName}.com`;
  dispatch(setSignupDataValue(Data.CompanyDomain, generatedDomain));
  dispatch(setSignupDataValue(Data.CompanyName, generatedCompanyName));
};