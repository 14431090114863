import { useDispatch, useSelector } from 'react-redux';
import { FlowStateManager } from '../flow-management/FlowStateManager';
import { Data } from 'signup-constants/signupData/Data';
import { clickCreateNewAccountSameUser } from '../store/auth/action-creators';
import { getExistingAccounts, getExistingAccountsError, getExistingAccountsFetched, getExistingAccountsFetching, getExistingAccountsRequested, getHaveHubsFailedToLoad } from '../store/existing-accounts/selectors';
import { bulkEmptySignupDataAndSetFresh, bulkUpdate } from '../store/signup-data/action-creators';
import { useTracker } from '../tracking/useTracker';
import { getScoredAccounts, getSortedAccounts } from '../utils/existingAccountUtils';
import { Step } from 'signup-constants/Step';
import { useView } from '../views/useView';
import { portalSelect } from './handlePortalSelect';
import { goPrevStep } from '../store/flow/action-creators';
import { getAuthenticatedUser } from '../store/auth/selectors';
import { setVerificationType } from '../store/app/action-creators';

/**
 * Hook for the Existing Accounts Page.
 * Dispatches tracking interactions for when the user selects an existing portal
 * or opts to create a new one.
 * Handles the required actions for the Hubless Scenario, i.e. log out and
 * create new portal.
 */

export const useExistingAccounts = () => {
  const dispatch = useDispatch();
  const Tracker = useTracker();
  const exAccRequested = useSelector(getExistingAccountsRequested);
  const exAccFetched = useSelector(getExistingAccountsFetched);
  const exAccFetching = useSelector(getExistingAccountsFetching);
  const hubsFailedToLoad = useSelector(getHaveHubsFailedToLoad);
  const exAccError = useSelector(getExistingAccountsError);
  const accounts = useSelector(getExistingAccounts);
  const user = useSelector(getAuthenticatedUser);
  const {
    submit: completeExistingUserStep
  } = useView({
    stepKey: Step.ExistingUser
  });
  const handleHublessCreateNewAccount = () => {
    Tracker.signupInteraction('hubless-selected-create-new-user');
    dispatch(bulkEmptySignupDataAndSetFresh([Data.FirstName, Data.LastName, Data.Email, Data.CompanyName, Data.CompanyDomain, Data.HubletSelection], {
      shouldEmptyPrefilledValues: false
    }));
    dispatch(setVerificationType(null));
    //This is to resolve any flow state changes back to their default on load of the existing user step
    dispatch(goPrevStep());
    completeExistingUserStep();
  };
  const rebuildFlowForExistingUser = isNamePresent => {
    const stepsToBeRemoved = [Step.LandingPage, Step.Verification, Step.CodeVerification, Step.Password];
    if (isNamePresent) {
      stepsToBeRemoved.push(Step.Name);
    }
    dispatch(FlowStateManager.removeSteps(stepsToBeRemoved));
  };
  const handleCreateNewAccount = ({
    hubless = false
  } = {}) => {
    dispatch(clickCreateNewAccountSameUser(hubless));
    let isNamePresent = false;
    if (user) {
      isNamePresent = !!(user.firstName && user.lastName);
      dispatch(bulkUpdate({
        [Data.Email]: user.email,
        [Data.FirstName]: user.firstName,
        [Data.LastName]: user.lastName
      }));
    }
    rebuildFlowForExistingUser(isNamePresent);
  };
  const startSignupForHublessUser = () => handleCreateNewAccount({
    hubless: true
  });
  const scoredAccounts = getScoredAccounts(accounts);
  const sortedAccounts = getSortedAccounts(scoredAccounts);
  return {
    accounts,
    scoredAccounts,
    sortedAccounts,
    requested: exAccRequested,
    fetching: exAccFetching,
    fetched: exAccFetched,
    fetchError: exAccError,
    handleHublessCreateNewAccount,
    startSignupForHublessUser,
    handleCreateNewAccount,
    portalSelect,
    hubsFailedToLoad
  };
};