import enviro from 'enviro';
import { SignupEmbedder } from 'signup-embedder';
import Logger from '../lib/Logger';
import { loadScript } from '../utils/loadScript';
export const setupCookieBanner = () => {
  const hostname = enviro.isProd() ? 'js.hs-scripts.com' : 'js.hs-scriptsqa.com';
  const portalId = enviro.isProd() ? '53' : '99535353';
  return loadScript(`https://${hostname}/${portalId}.js`, 'hs-analytics-script', true, true).then(() => {
    document.body.classList.add('cookie-banner-top-static');
    if (SignupEmbedder.isEmbedded()) {
      document.body.classList.add('cookie-banner-hidden');
    }
  }).catch(error => {
    Logger.getLogger('setupCookieBanner').error(error.message);
  });
};