export const DesktopDims = {
  global: {
    ZERO: 0
  },
  spacing: {
    SM: 10,
    MD: 46,
    LG: 150
  },
  padding: {
    XXS: 13,
    XS: 24,
    LG: 60,
    XL: 130
  },
  height: {
    XS: 48,
    SM: 100,
    MD: 400,
    MD2: 440
  },
  width: {
    MD: 320,
    MD2: 380,
    LG: 450
  }
};
const DIMS = DesktopDims;
export const DesktopAppDims = {
  heading: {
    maxWidth: DIMS.width.MD2,
    h1FontSize: 36,
    h2FontSize: 32,
    h3FontSize: 26,
    h4FontSize: 22
  },
  landingPage: {
    marginTop: '0',
    titleToContentSpacing: 32
  },
  app: {
    top: '50%',
    left: '50%',
    formToMessagingSpacing: DIMS.spacing.LG
  },
  blob: {
    dimensions: DIMS.width.MD
  },
  input: {
    height: DIMS.height.SM
  },
  button: {
    fontSize: 16,
    height: DIMS.height.XS,
    innerPadding: DIMS.padding.XXS,
    iconHeight: 26,
    googleButtonWidth: DIMS.width.LG
  },
  form: {
    inputToButtonSpacing: DIMS.spacing.MD,
    titleMarginTop: DIMS.spacing.SM,
    titleToFormSpacing: DIMS.spacing.MD,
    titleToPretitleSpacing: DIMS.spacing.SM,
    width: DIMS.width.LG,
    height: DIMS.height.MD2
  },
  logo: {
    height: 32
  },
  messaging: {
    contentToCaptionSpacing: DIMS.spacing.MD,
    maxHeight: DIMS.height.MD,
    width: DIMS.width.LG,
    titleFontWeight: 520,
    bodyTextFontSize: 16,
    subHeading: 18
  },
  privacyBanner: {
    fontSize: 15,
    paddingX: DIMS.padding.XL,
    paddingY: DIMS.padding.XS
  },
  selectableButtonOption: {
    smallerHeight: 70,
    smallHeight: 90,
    mediumHeight: 102,
    largeHeight: 124,
    fontSize: 20,
    innerPadding: 4
  },
  illustration: {
    height: 170
  },
  existingUser: {
    marginTop: 30,
    marginBottom: 30,
    padding: 30,
    buttonMarginTop: 37,
    name: {
      marginTop: 16,
      fontSize: 22,
      fontWeight: 505
    }
  },
  errorScreen: {
    marginTop: 0
  }
};