// @ts-expect-error Untyped module
import FireAlarmApp from 'FireAlarmUi/js/FireAlarmApp';
import { SignupEmbedder, SignupEmbedderOptions } from 'signup-embedder';
export const setupFirealarm = () => {
  const shouldShowFireAlarmEmbedded = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_SHOULD_SHOW_FIREALARM);
  const shouldShowFireAlarm = SignupEmbedder.isActive() ? shouldShowFireAlarmEmbedded : true;
  if (shouldShowFireAlarm) {
    const fireAlarm = new FireAlarmApp(window.hubspot, {
      appName: 'signup-ui-lego',
      containers: {
        default: {
          before: document.querySelector('.page')
        }
      }
    });
    window.hubspot[FireAlarmApp.HUBSPOT_FIREALARM_KEY] = fireAlarm;
    fireAlarm.start();
  }
};