import { HubsTrackingAttr } from 'signup-constants/HubsTrackingAttr';
import { UtmTrackingAttr } from 'signup-constants/UtmTrackingAttr';
import HubToUtm from '../constants/HubToUtm';
import { hubsToUtmData, getUtmsFromReferrer, mergeFromExp } from '../utm/utmHelpers';
const transformReferrerMediumToHubs = (finalUtms, queryParams) => {
  //if there's no explicit medium/source in the url
  //and there is a medium source in the calculated
  //and the medium is referrer
  //means that medium / source was implied , so should be replaced
  //with hubs_
  const hasUrlUtmMedium = !!queryParams[UtmTrackingAttr.Medium];
  const hasUrlUtmSource = !!queryParams[UtmTrackingAttr.Source];
  const hasUtmMedium = !!finalUtms[UtmTrackingAttr.Medium];
  const hasUtmSource = !!finalUtms[UtmTrackingAttr.Source];
  const mediumValue = finalUtms[UtmTrackingAttr.Medium];
  if (!hasUrlUtmMedium && !hasUrlUtmSource && hasUtmMedium && hasUtmSource && mediumValue === 'referral') {
    const sourceValue = finalUtms[UtmTrackingAttr.Source];
    delete finalUtms[UtmTrackingAttr.Medium];
    delete finalUtms[UtmTrackingAttr.Source];
    finalUtms[HubsTrackingAttr.Medium] = mediumValue;
    finalUtms[HubsTrackingAttr.Source] = sourceValue;
  }
  return finalUtms;
};
const mapHubsToUtm = (hubsToUtm, howUtms) => {
  for (const hubsTrackingAttr in HubToUtm) {
    if (Object.hasOwnProperty.call(HubToUtm, hubsTrackingAttr)) {
      const utmParameter = HubToUtm[hubsTrackingAttr];
      const hubsOverrideValue = hubsToUtm[hubsTrackingAttr];
      if (hubsOverrideValue && utmParameter) {
        howUtms[utmParameter] = hubsOverrideValue;
      }
    }
  }
  return howUtms;
};
export const getFinalUtms = ({
  utmsFiltered,
  shouldTransformReferrerToHubs = false,
  queryParamsToUse
}) => {
  let utmsFromReferrer = getUtmsFromReferrer(mergeFromExp(utmsFiltered));
  // contains the hubs_* parameters with defined values that should replace the values of relevant utm_*
  const hubsToUtm = hubsToUtmData(queryParamsToUse);
  utmsFromReferrer = mapHubsToUtm(hubsToUtm, utmsFromReferrer);
  //if utm_medium / source are inferred from the referrer (not explicit in the url) we
  // transform them back to be hubs_ so that signup after verification will pick them up for amplitude tracking
  // but GA won't override those originated in the customer cookie
  utmsFromReferrer = shouldTransformReferrerToHubs ? transformReferrerMediumToHubs(utmsFromReferrer, queryParamsToUse) : utmsFromReferrer;
  return utmsFromReferrer;
};