import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { GeneralUserVerificationError } from '../../google-authentication/errors/GeneralUserVerificationError';
import { OAuthToVerifyEndpoint } from '../../oauth/OAuthMapping';
import { OAuthActionTypes } from './action-types';
import { Metrics } from '../../metrics/Metrics';
import { MetricsCounter } from '../../metrics/MetricsCounter';
export const oAuthAuthStarted = provider => dispatch => {
  Metrics.counter(MetricsCounter.OauthAuthStarted, {
    provider
  }).increment();
  return dispatch({
    type: OAuthActionTypes.AuthStarted,
    provider
  });
};
export const oAuthAuthSucceeded = (authResponse, provider) => dispatch => {
  Metrics.counter(MetricsCounter.OauthAuthSucceeded, {
    provider
  }).increment();
  return dispatch({
    type: OAuthActionTypes.AuthSucceeded,
    payload: authResponse,
    provider
  });
};
export const oAuthAuthFailed = (err, provider) => dispatch => {
  Metrics.counter(MetricsCounter.OauthAuthFailed, {
    provider
  }).increment();
  return dispatch({
    type: OAuthActionTypes.AuthFailed,
    payload: err,
    provider
  });
};
export const oAuthVerificationStarted = provider => dispatch => {
  Metrics.counter(MetricsCounter.OauthAuthVerificationStarted, {
    provider
  }).increment();
  return dispatch({
    type: OAuthActionTypes.VerificationStarted,
    provider
  });
};
export const oAuthVerificationSucceeded = (response, provider) => dispatch => {
  Metrics.counter(MetricsCounter.OauthAuthVerificationSucceeded, {
    provider
  }).increment();
  return dispatch({
    type: OAuthActionTypes.VerificationSucceeded,
    payload: response,
    provider
  });
};
export const oAuthPromptVerificationSucceeded = (response, provider) => dispatch => {
  Metrics.counter(MetricsCounter.OauthAuthVerificationSucceeded, {
    provider
  }).increment();
  return dispatch({
    type: OAuthActionTypes.PromptVerificationSucceeded,
    payload: response,
    provider
  });
};
export const oAuthVerificationFailed = (err, provider) => dispatch => {
  Metrics.counter(MetricsCounter.OauthAuthVerificationFailed, {
    provider
  }).increment();
  return dispatch({
    type: OAuthActionTypes.VerificationFailed,
    payload: err,
    provider
  });
};
export const OAuthExistingUserErrorMessage = 'user already exists';
export const verifyOAuthVerificationPayload = (token, provider, isTokenFromGooglePrompt = false) => dispatch => {
  dispatch(oAuthVerificationStarted(provider));
  return noAuthApiClient.post(OAuthToVerifyEndpoint[provider], {
    data: token
  }).then(response => {
    if ('message' in response) {
      const {
        message = ''
      } = response;
      const hasErrorMessage = message.includes(OAuthExistingUserErrorMessage);
      if (hasErrorMessage) {
        dispatch(oAuthVerificationFailed({
          status: OAuthExistingUserErrorMessage
        }, provider));
      }
    } else {
      if (isTokenFromGooglePrompt) {
        dispatch(oAuthPromptVerificationSucceeded(response, provider));
      } else {
        dispatch(oAuthVerificationSucceeded(response, provider));
      }
    }
    return response;
  }).catch(error => {
    if (!isTokenFromGooglePrompt) {
      dispatch(oAuthVerificationFailed(error, provider));
    }
    throw new GeneralUserVerificationError();
  });
};