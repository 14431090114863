import { CRM_SIGNUP, MARKETING_SIGNUP, SALES_SIGNUP, SERVICE_SIGNUP, CMS_FREE_SIGNUP } from 'growth-onboarding-signup-redirect/constants/signupConstants';
import { Flow } from 'signup-constants/Flow';
import { getFlowKey } from '../routing/getFlowKey';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import { isFlow } from '../utils/isFlow';
import { Microapp } from 'signup-constants/Microapp';
import { getMicroappKey } from '../routing/getMicroappKey';
export const FlowOnboardingSignup = new Map([[Flow.Marketing, MARKETING_SIGNUP], [Flow.Crm, CRM_SIGNUP], [Flow.Sales, SALES_SIGNUP], [Flow.Service, SERVICE_SIGNUP], [Flow.CmsFree, CMS_FREE_SIGNUP], [Flow.HubspotForStartups, SALES_SIGNUP], [Microapp.EmailSignatureGenerator, SALES_SIGNUP], [Microapp.BrandKitGenerator, CMS_FREE_SIGNUP], [Microapp.WebsiteGrader, CMS_FREE_SIGNUP]]);
export const getRedirectSignupType = () => {
  const flowKey = getFlowKey();
  const integrationKey = getIntegrationKey();
  const microappKey = getMicroappKey();
  let key = flowKey === Flow.Integrations && integrationKey ? integrationKey : flowKey;
  if (isFlow(Flow.Microapp) && microappKey) {
    key = microappKey;
  }
  return FlowOnboardingSignup.get(key) || FlowOnboardingSignup.get(Flow.Crm);
};