import { Intent } from 'signup-constants/Intent';
import { getIntentKey } from '../routing/getIntentKey';
import { isIntent } from '../utils/isIntent';
import { getQueryParams } from '../routing/getQueryParams';
import { getHublet, getIsNewUser } from '../store/app/selectors';
import { getAuthUserId, getPortalId } from '../store/auth/selectors';
import { IntentLinks } from './intentLinks';
export const doesIntentLinkExist = () => {
  const intentKey = getIntentKey();
  return intentKey && IntentLinks.has(intentKey);
};
export const getIntentLink = (state, portalId = getPortalId(state), hublet = getHublet(state)) => {
  const queryParams = getQueryParams();
  const intentKey = getIntentKey();
  const isExistingUser = !getIsNewUser(state);
  const intentLink = IntentLinks.get(intentKey);
  if (isIntent(Intent.trial) && isExistingUser) {
    queryParams['existing_account'] = 'true';
  }
  let intentRedirectHref;
  if (doesIntentLinkExist() && intentLink) {
    queryParams['userId'] = `${getAuthUserId(state)}`;
    intentRedirectHref = intentLink(portalId, hublet, queryParams);
  }
  return intentRedirectHref;
};