import { TRIAL_SIGNUP } from '../constants/signupConstants';
import { HubletUrl } from '../utils/urls';
import { ONBOARDING_VIEWS } from 'user-context/onboardingSettings';
import { MARKETING_ENTERPRISE_TRIAL_ID, SERVICE_ENTERPRISE_TRIAL_ID, MARKETING_PRO_TRIAL_ID, SERVICE_PROFESSIONAL_TRIAL_ID } from 'self-service-api/constants/Trials';
const trialExperiencesChecks = [{
  redirectPath: portalId => ({
    url: `${HubletUrl.getAppUrl()}/user-guide/${portalId}`,
    view: ONBOARDING_VIEWS.MARKETING
  }),
  checks: [{
    signupType: TRIAL_SIGNUP,
    searchParams: {
      trialId: `${MARKETING_PRO_TRIAL_ID}`
    }
  }]
}, {
  redirectPath: portalId => ({
    url: `${HubletUrl.getAppUrl()}/user-guide/${portalId}`,
    view: ONBOARDING_VIEWS.MARKETING
  }),
  checks: [{
    signupType: TRIAL_SIGNUP,
    searchParams: {
      trialId: `${MARKETING_ENTERPRISE_TRIAL_ID}`
    }
  }]
}, {
  redirectPath: portalId => ({
    url: `${HubletUrl.getAppUrl()}/user-guide/${portalId}`,
    view: ONBOARDING_VIEWS.SERVICE
  }),
  checks: [{
    signupType: TRIAL_SIGNUP,
    searchParams: {
      trialId: `${SERVICE_PROFESSIONAL_TRIAL_ID}`
    }
  }]
}, {
  redirectPath: portalId => ({
    url: `${HubletUrl.getAppUrl()}/user-guide/${portalId}`,
    view: ONBOARDING_VIEWS.SERVICE
  }),
  checks: [{
    signupType: TRIAL_SIGNUP,
    searchParams: {
      trialId: `${SERVICE_ENTERPRISE_TRIAL_ID}`
    }
  }]
}, {
  redirectPath: portalId => ({
    url: `${HubletUrl.getAppUrl()}/user-guide/${portalId}`,
    view: ONBOARDING_VIEWS.SALES
  }),
  checks: [{
    signupType: TRIAL_SIGNUP
  }]
}];
export default trialExperiencesChecks;