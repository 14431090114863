import { ExperimentIdentifier } from '../../experiments/ExperimentIdentifier';
import { ExperimentActionTypes } from './action-types';
import { getExperimentFromState, getParameter, isCopyExperimentActive } from './selectors';
export const trackCopyExperimentExposure = id => (dispatch, getState) => {
  const state = getState();
  const canLogExposure = isCopyExperimentActive(state, id);
  const experimentKey = id;
  if (!canLogExposure) {
    return;
  }
  const experiment = getExperimentFromState(state, experimentKey);
  if (!experiment) return;
  Object.keys(experiment.parameters).forEach(paramKey => {
    dispatch({
      type: ExperimentActionTypes.TrackExposure,
      message: `${id}-${paramKey}-${getParameter(state, experimentKey)}`,
      id: experimentKey,
      experiment
    });
  });
};
export const setExperimentUtk = utk => dispatch => {
  dispatch({
    type: ExperimentActionTypes.SetExperimentUtk,
    utk
  });
};
export const fetchExperiments = ({
  identifierPhase = ExperimentIdentifier.Utk
} = {}) => dispatch => dispatch({
  type: ExperimentActionTypes.FetchExperiments,
  identifierPhase
});
export const updateActiveExperimentsParameters = activeExperimentsParameters => dispatch => {
  dispatch({
    type: ExperimentActionTypes.UpdateActiveExperimentParameters,
    activeExperimentsParameters
  });
};
export const unenrollExperiment = experiment => dispatch => {
  dispatch({
    type: ExperimentActionTypes.UnenrollExperiment,
    experiment
  });
};
export const reenrollExperiment = experiment => dispatch => {
  dispatch({
    type: ExperimentActionTypes.ReenrollExperiment,
    experiment
  });
};