import { CmsBusinessMaturityOption, CmsSignupIntentOption } from 'signup-constants/signupData/CmsOption';
import { CompanySizeOption } from 'signup-constants/signupData/CompanySizeOption';
import { CrmExperienceOption } from 'signup-constants/signupData/CrmExperienceOption';
import { HubInterestOption } from 'signup-constants/signupData/HubInterestOption';
import { JobFieldOption } from 'signup-constants/signupData/JobFieldOption';
import { isOptionShufflingDisabled } from '../selenium/seleniumFeatures';
import { Data } from 'signup-constants/signupData/Data';
import { ProblemAwareness, ProductExperience } from './ConsiderationProfileOptions';
import { UseCase } from 'signup-constants/signupData/UseCaseOption';
import { shuffleArray } from '../utils/data-structure-utils/shuffleArray';
import { eu1, na1 } from 'hubspot-url-utils/hublets-next';
const shuffleOptions = function shuffleOptions(options) {
  return isOptionShufflingDisabled() ? options : shuffleArray(options);
};
export const DataOptions = {
  [Data.CompanySize]: Object.values(CompanySizeOption),
  [Data.JobField]: [...shuffleOptions([JobFieldOption.Sales, JobFieldOption.Marketing, JobFieldOption.Support, JobFieldOption.Admin]), JobFieldOption.Other],
  [Data.CrmExperienceLevel]: [CrmExperienceOption.None, CrmExperienceOption.HasUsedCrm, CrmExperienceOption.HasUsedHubSpot],
  [Data.HubInterest]: shuffleOptions([HubInterestOption.Sales, HubInterestOption.CustomerService, HubInterestOption.Marketing, HubInterestOption.CMS]),
  [Data.HubletSelection]: [na1, eu1],
  [Data.CmsSignupIntent]: [CmsSignupIntentOption.Business, CmsSignupIntentOption.Personal],
  [Data.CmsBusinessMaturity]: [CmsBusinessMaturityOption.ExploringIdea, CmsBusinessMaturityOption.ReadyToLaunch, CmsBusinessMaturityOption.RecentlyLaunched, CmsBusinessMaturityOption.EstablishedBusiness],
  [Data.ProductExperience]: [ProductExperience.yes, ProductExperience.no],
  [Data.ProblemAwareness]: [ProblemAwareness.yes, ProblemAwareness.no],
  [Data.UseCase]: [UseCase.GenerateLeads, UseCase.SalesPipeline, UseCase.MarketingContent, UseCase.AutomateMarketing, UseCase.BuildWebsite, UseCase.CustomerSupport, UseCase.Payments, UseCase.StillExploring]
};

//DO NOT CHANGE ORDER - this is the priority order used downstream
export const UseCasePrioritisation = [UseCase.BuildWebsite, UseCase.MarketingContent, UseCase.GenerateLeads, UseCase.AutomateMarketing, UseCase.SalesPipeline, UseCase.Payments, UseCase.CustomerSupport, UseCase.StillExploring];
export const UseCasePrioritisationCOAC0002 = [UseCase.OrganizeAndTrackContacts, UseCase.GenerateLeads, UseCase.SalesPipeline, UseCase.AutomateMarketing, UseCase.BuildWebsite, UseCase.MarketingContent, UseCase.CustomerSupport, UseCase.Payments, UseCase.StillExploring];
export const UseCaseToHubInterest = {
  [UseCase.GenerateLeads]: HubInterestOption.Marketing,
  [UseCase.SalesPipeline]: HubInterestOption.Sales,
  [UseCase.MarketingContent]: HubInterestOption.Marketing,
  [UseCase.AutomateMarketing]: HubInterestOption.Marketing,
  [UseCase.BuildWebsite]: HubInterestOption.Marketing,
  [UseCase.CustomerSupport]: HubInterestOption.CustomerService,
  [UseCase.Payments]: HubInterestOption.Sales,
  [UseCase.StillExploring]: HubInterestOption.Marketing
};