import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { oAuthAuthFailed } from '../store/oauth/action-creators';
import MicrosoftPlatform from './MicrosoftPlatform';
const LOG = Logger.getLogger('useLoadMicrosoft');
export const useLoadMicrosoft = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    const loadPromise = MicrosoftPlatform.loadMicrosoftIdentityServices();
    loadPromise.then(hasAlreadyLoaded => {
      setLoaded(hasAlreadyLoaded);
    }).catch(error => {
      setLoaded(false);
      LOG.error('An error occured while loading the microsoft identity services', {
        extra: getSentryExtra(error)
      });
      dispatch(oAuthAuthFailed(error, OAuthProviders.Microsoft));
    }).finally(() => {
      setLoading(false);
    });
  }, [dispatch]);
  return {
    isLoading,
    isLoaded
  };
};