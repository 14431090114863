import { AppKey } from '../app/AppKey';
import { ExperimentIdentifier } from '../experiments/ExperimentIdentifier';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { AppActionTypes } from '../store/app/action-types';
import { ExperimentActionTypes } from '../store/experiments/action-types';
import { FlowActionTypes } from '../store/flow/action-types';
import { getPostAccountCreationSteps } from '../store/flow/selectors';
import { getStepConfig } from '../views/getStepConfig';
import { Step } from 'signup-constants/Step';
export const getStepCompletionActionType = stepKey => {
  const stepConfig = getStepConfig(stepKey);
  if (stepConfig && stepConfig.stepCompletedAction) {
    return stepConfig.stepCompletedAction;
  }
  return FlowActionTypes.StepCompleted;
};
export const isOutdatedStepCompletedAction = (action, currentStep) => {
  return action.type === FlowActionTypes.StepCompleted && action.stepKey !== currentStep;
};
export const shouldGoNextStep = (action, currentStep) => {
  const stepCompletedActionType = getStepCompletionActionType(currentStep);
  return action.type === stepCompletedActionType;
};
export const shouldGoToPostAccountCreationSteps = (action, state) => {
  const postAccountCreation = getPostAccountCreationSteps(state);
  if (postAccountCreation.length >= 1 && postAccountCreation[0] !== Step.MobileAppPrompt) {
    return action.type === ExperimentActionTypes.ExperimentsFetched && action.identifierPhase === ExperimentIdentifier.PortalId;
  } else {
    if (getFlowConfig().isUserOnlyFlow) {
      return action.type === AppActionTypes.UpdateKeyValue && action.key === AppKey.IsNewUserCreated;
    }
    return action.type === AppActionTypes.UpdateKeyValue && action.key === AppKey.IsNewPortalCreated;
  }
};