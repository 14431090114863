import { getMessagePlatform } from './getMessagePlatform';
import { MessageOverideConfig } from './MessageOverrideConfig';
import { MessageOverrideKey } from './MessageOverrideKey';
export const messageOverrideConfigExists = ({
  message,
  isMobile,
  step
}) => {
  const platform = getMessagePlatform(isMobile);
  const messageValues = Object.values(MessageOverrideKey);
  const messageConfig = MessageOverideConfig[platform].get(message);
  return messageValues.includes(message) && messageConfig && messageConfig.get(step);
};
export const getOverrideMessage = ({
  isMobile,
  message,
  step
}) => {
  const platform = getMessagePlatform(isMobile);
  let messaging;
  if (messageOverrideConfigExists({
    message,
    isMobile,
    step
  })) {
    messaging = MessageOverideConfig[platform].get(message);
  }
  const messageKey = messaging && messaging.get(step);
  return messageKey || null;
};