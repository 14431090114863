import enviro from 'enviro';
import { getFullUrl } from 'hubspot-url-utils';
import Logger from '../lib/Logger';
import { Environment } from '../types/Environment';
import { normalizeUrl } from './normalizeUrl';
const LOG = Logger.getLogger('urls');
const getHubletUrl = (subDomainPrefix, hublet) => {
  const overrideConfig = {};
  if (hublet) {
    overrideConfig.hubletOverride = hublet;
  }
  return getFullUrl(subDomainPrefix, overrideConfig);
};
export const getHubletAppUrl = hublet => getHubletUrl('app', hublet);
export const getHubletApiUrl = hublet => getHubletUrl('app-api', hublet);
export const getAppUrl = () => {
  const prefix = !enviro.deployed() ? Environment.Local : 'app';
  return getFullUrl(prefix, {});
};
export const isHubSpotHost = hostname => /(app|local).*\.hubspot(qa)?\.com/.test(hostname);
export const getUrlData = stringifiedUrl => {
  let urlData = {};
  if (!stringifiedUrl || stringifiedUrl === '') {
    return urlData;
  }
  try {
    const normalizedUrl = normalizeUrl(stringifiedUrl);
    const url = new URL(normalizedUrl);
    const {
      hostname,
      pathname,
      href,
      search
    } = url;
    urlData = {
      hostName: hostname,
      pathName: pathname,
      href,
      search
    };
  } catch (error) {
    LOG.info('Invalid url', {
      extra: stringifiedUrl
    });
  }
  return urlData;
};