import { isGdprCountry } from './GdprExcludedCountries';
import { getVisitorLocaleInfo } from '../compliance-script/getVisitorLocale';
import Logger from '../lib/Logger';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('GdprEvent');
let eventDetails = {
  consent: null,
  country: null,
  isGdprCountry: false
};

/**
 * Only meant to be used during tests to clear side-effects
 */
export function cleanupDuringTest() {
  window._hsp = [];
  eventDetails = {
    consent: null,
    country: null,
    isGdprCountry: false
  };
}
const doIpLookup = () => getVisitorLocaleInfo();
export const GdprEventSetup = (lookup = doIpLookup) => {
  window._hsp = window._hsp || [];
  const lookupPromise = lookup().then(({
    country
  }) => {
    eventDetails.country = country;
    eventDetails.isGdprCountry = isGdprCountry(country);
    document.dispatchEvent(new CustomEvent('gdpr', {
      detail: eventDetails
    }));
  }).catch(error => {
    LOG.error('Error setting up GDPR event', {
      extra: getSentryExtra(error)
    });
  });
  window._hsp.push(['addPrivacyConsentListener', consent => {
    eventDetails.consent = consent;
    document.dispatchEvent(new CustomEvent('gdpr', {
      detail: eventDetails
    }));
  }]);
  return lookupPromise;
};