import { createClient } from 'laboratory-lib';
import { getExperimentsConfig } from './getExperimentsConfig';
import { getUserExperimentIdentifier } from './getUserExperimentIdentifier';
import { experimentsEnabled } from './experimentsEnabled';
import { getUtk } from 'usage-tracker-core/common/defaultTrackers';
export const getTreatmentsClient = () => {
  return createClient({
    name: 'signup-laboratory-client',
    timeout: 8000,
    experiments: getExperimentsConfig(),
    identifiers: {
      portalId: null,
      utk: getUtk(),
      other: getUserExperimentIdentifier()
    },
    // if the experiments are disabled, we want to get default treatments back from laboratory
    mode: experimentsEnabled() ? 'default' : 'cache-only'
  });
};