import styled from 'styled-components';
import UIIllustration from 'UIComponents/image/UIIllustration';
import UIImage from 'UIComponents/image/UIImage';
import { devices } from 'signup-constants/styles/viewports.styles';
export const LoadingCentered = styled.div.withConfig({
  displayName: "Loadingstyles__LoadingCentered",
  componentId: "sc-1xfyb1d-0"
})(["display:flex;align-items:center;justify-content:center;flex-direction:column;text-align:center;max-height:350px;"]);
export const LoadingImage = styled(UIImage).withConfig({
  displayName: "Loadingstyles__LoadingImage",
  componentId: "sc-1xfyb1d-1"
})(["margin:auto;margin-bottom:1rem;max-height:200px;@media ", "{max-height:300px;}"], devices.tablet);
export const LoadingIllustration = styled(UIIllustration).withConfig({
  displayName: "Loadingstyles__LoadingIllustration",
  componentId: "sc-1xfyb1d-2"
})(["margin:auto;margin-bottom:1rem;"]);