// @ts-expect-error Untyped module
import Cookies from 'js-cookie';
import TrackerContainer from '../tracking/TrackerContainer';
import { getQueryParam } from '../routing/getQueryParam';
import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { EventName } from '../tracking/EventName';
import { getVerificationType } from '../store/app/selectors';
export const signupSuccessTracking = (state, userId) => {
  const email = getSignupDataValue(state, Data.Email);
  const verificationType = getVerificationType(state);
  TrackerContainer.switchToIdentifiableTracker(email, () => {
    if (TrackerContainer.isAnonymousTrackingEnabled) {
      TrackerContainer.track(EventName.SignupCompletedAnonymous, {}, true);
      TrackerContainer.wasAnonymouslyTracked = true;
    }
  });
  TrackerContainer.setProperties({
    email,
    userId,
    verificationType
  });
  const messagesUtk = Cookies.get('messagesUtk') || getQueryParam('messagesUtk');
  TrackerContainer.setProperties({
    messagesUtk
  });
};