import enviro from 'enviro';
import { isUnloadAlertDisabled } from '../selenium/seleniumFeatures';
import { Tracker } from '../store/tracking/action-creators';
import { Step } from 'signup-constants/Step';
let hasLeavePageBeenTracked = false;
let hasTriedToLeavePageBeenTracked = false;
function getHasLeavePageBeenTracked() {
  return hasLeavePageBeenTracked;
}
function getHasTriedToLeavePageBeenTracked() {
  return hasTriedToLeavePageBeenTracked;
}
function setHasLeavePageBeenTracked() {
  hasLeavePageBeenTracked = true;
}
function setHasTriedToLeavePageBeenTracked() {
  hasTriedToLeavePageBeenTracked = true;
}
function resetHasLeavePageBeenTracked() {
  hasLeavePageBeenTracked = false;
}
export const alertBeforeUnload = dispatch => {
  const onVisibilityChange = function onVisibilityChange() {
    const hasLeftPage = getHasLeavePageBeenTracked();
    if (document.visibilityState === 'hidden' && !hasLeftPage) {
      if (getHasTriedToLeavePageBeenTracked()) {
        // if the document is hidden and tried-to-leave-page has been tracked, then the user closed the page
        dispatch(Tracker.leavePage('closed-page'));
      } else {
        // else the user just switched the tab and only left the page
        dispatch(Tracker.leavePage('leave-page'));
      }
      setHasLeavePageBeenTracked();
    }
    if (document.visibilityState === 'visible' && hasLeftPage) {
      // if the document is visible, then the user came back to the tab, but they can leave again
      resetHasLeavePageBeenTracked();
    }
  };
  const addAlert = () => {
    // for local testing, please remove the statement below
    if (!enviro.deployed() || isUnloadAlertDisabled()) {
      return;
    }
    window.onbeforeunload = function () {
      dispatch(Tracker.leavePage('tried-to-leave-page'));
      setHasTriedToLeavePageBeenTracked();
      return '';
    };
    window.addEventListener('visibilitychange', onVisibilityChange, false);
  };
  const removeAlert = () => {
    window.onbeforeunload = null;
    window.removeEventListener('visibilitychange', onVisibilityChange, false);
  };
  return {
    addAlert,
    removeAlert
  };
};
export const stepsWithNoAlert = [Step.Verification, Step.ResendEmail, Step.WordpressAuthorization, Step.IntegratedAppAuthorization, Step.IntegrationsAccountCreated];