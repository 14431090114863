import { getIsQa } from '../constants/apiEnvironments';
import { getQueryParam } from '../routing/getQueryParam';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';
import { Tracker } from '../store/tracking/action-creators';
export const getAppleAuthClient = () => {
  return window.AppleID;
};
export const APPLE_POPUP_BLOCKED = 'popup_blocked_by_browser';
export const APPLE_POPUP_CLOSED = 'popup_closed_by_user';
export const getAppleClientId = () => {
  const clientId = getIsQa() ? 'com.hubspot.apple-sign-in-qa' : 'com.hubspot.apple-sign-in-prod';
  return clientId;
};
export const shouldShowAppleOnQA = () => {
  return getQueryParam('should_show_apple') === 'true' && getIsQa();
};
export const getNameFromAppleUser = user => {
  let firstName = '';
  let lastName = '';
  if (user && user.name) {
    const {
      name
    } = user;
    firstName = name.firstName || '';
    lastName = name.lastName || '';
  }
  return {
    firstName,
    lastName
  };
};
const APPLE_PRIVATE_EMAIL_DOMAINS = ['privaterelay.appleid.com', 'icloud.com'];
export const trackApplePrivateEmail = (dispatch, email) => {
  if (!email) {
    return false;
  }
  const domain = getDomainFromEmail(email);
  if (domain === 'privaterelay.appleid.com') {
    dispatch(Tracker.signupInteraction('apple-private-email-used'));
  } else if (domain === 'icloud.com') {
    dispatch(Tracker.signupInteraction('maybe-apple-private-email-used'));
  }
  return APPLE_PRIVATE_EMAIL_DOMAINS.includes(domain);
};