import { EndpointKey } from '../http/EndpointKey';
import { httpError, httpSuccess } from '../store/http-responses/action-creators';
export const handleSignupResponse = response => dispatch => {
  const endpointKey = EndpointKey.Signup;
  if ('hubId' in response) {
    return dispatch(httpSuccess(endpointKey, response));
  }
  return dispatch(httpError(endpointKey, {
    message: 'Signup Error',
    status: response.status,
    data: response.data
  }));
};