import { Data } from 'signup-constants/signupData/Data';
import { bulkUpdateVisitorContext, trackVisitorContextNewUser, trackVisitorContextNotFound } from '../store/visitor-context/action-creators';
export const handleVisitorContextSuccess = (response, dispatch) => {
  if (response) {
    const visitorContext = {
      [Data.FirstName]: response.firstName,
      [Data.LastName]: response.lastName,
      [Data.Email]: response.email,
      [Data.CompanyDomain]: response.companyDomain,
      [Data.CompanyName]: response.companyName
    };
    dispatch(trackVisitorContextNewUser());
    dispatch(bulkUpdateVisitorContext(visitorContext));
  } else {
    dispatch(trackVisitorContextNotFound());
  }
};