import { Intent } from 'signup-constants/Intent';
import { SignupEmbedderOptions } from 'signup-embedder';
import { getQueryParam } from '../routing/getQueryParam';
export const getShouldRedirectWindow = () => {
  const isEmbedded = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_EMBEDDER_IS_ACTIVE);

  // The window should be redirected either if the instance of signup is standalone
  // or if it is embedded and the intent is for oauth

  return !isEmbedded || getQueryParam('intent') === Intent.oauthAuthorization;
};