import { StepStatus } from 'signup-constants/StepStatus';
import { FlowActionTypes } from './action-types';
export const initialFlowState = {
  currentStep: null,
  nextSteps: [],
  stepStatus: StepStatus.None,
  prevFlowStates: [],
  preVerificationSteps: [],
  postVerificationSteps: [],
  postAccountCreationSteps: [],
  postExistingAccountSelectionSteps: [],
  hasFlowLoaded: false
};
const popPrevFlowState = state => {
  if (state.prevFlowStates.length === 0) {
    return state;
  }
  const lastIndex = state.prevFlowStates.length - 1;
  const stepState = state.prevFlowStates[lastIndex];
  const newPrevFlowStates = state.prevFlowStates.splice(0, lastIndex);
  return Object.assign({}, state, {
    currentStep: stepState.completedStep,
    nextSteps: stepState.nextSteps,
    stepStatus: StepStatus.Pending,
    prevFlowStates: newPrevFlowStates,
    preVerificationSteps: stepState.preVerificationSteps,
    postVerificationSteps: stepState.postVerificationSteps,
    postExistingAccountSelectionSteps: stepState.postExistingAccountSelectionSteps,
    postAccountCreationSteps: stepState.postAccountCreationSteps
  });
};
const flowReducer = (state = initialFlowState, action) => {
  switch (action.type) {
    case FlowActionTypes.SetFlow:
      return Object.assign({}, state, {
        nextSteps: action.nextSteps,
        preVerificationSteps: action.preVerificationSteps,
        postVerificationSteps: action.postVerificationSteps,
        postAccountCreationSteps: action.postAccountCreationSteps,
        postExistingAccountSelectionSteps: action.postExistingAccountSelectionSteps
      });
    case FlowActionTypes.UpdateFlow:
      return Object.assign({}, state, {
        preVerificationSteps: action.preVerificationSteps,
        postVerificationSteps: action.postVerificationSteps,
        postAccountCreationSteps: action.postAccountCreationSteps,
        postExistingAccountSelectionSteps: action.postExistingAccountSelectionSteps
      });
    case FlowActionTypes.ClearCompletedSteps:
      return Object.assign({}, state, {
        prevFlowStates: []
      });
    case FlowActionTypes.StepCompleted:
      if (action.stepKey !== state.currentStep) {
        return state;
      }
      return Object.assign({}, state, {
        stepStatus: StepStatus.Completed
      });
    case FlowActionTypes.ReplaceCurrentStep:
      return Object.assign({}, state, {
        currentStep: action.newStep,
        stepStatus: StepStatus.Pending
      });
    case FlowActionTypes.GoNextStep:
      return Object.assign({}, state, {
        currentStep: action.step,
        nextSteps: action.nextSteps,
        stepStatus: StepStatus.Pending,
        prevFlowStates: state.currentStep ? [...state.prevFlowStates, {
          completedStep: state.currentStep,
          nextSteps: state.nextSteps,
          preVerificationSteps: action.preVerificationSteps,
          postVerificationSteps: action.postVerificationSteps,
          postAccountCreationSteps: action.postAccountCreationSteps,
          postExistingAccountSelectionSteps: action.postExistingAccountSelectionSteps
        }] : state.prevFlowStates
      });
    case FlowActionTypes.GoPrevStep:
      return Object.assign({}, state, popPrevFlowState(state));
    case FlowActionTypes.Load:
      return Object.assign({}, state, {
        hasFlowLoaded: true
      });
    case FlowActionTypes.SetFlowLoaded:
      return Object.assign({}, state, {
        hasFlowLoaded: true
      });
    case FlowActionTypes.UpdatePreviousFlowStates:
      return Object.assign({}, state, {
        prevFlowStates: action.previousFlowStates
      });
    case FlowActionTypes.ClearNextSteps:
      return Object.assign({}, state, {
        nextSteps: []
      });
    default:
      return state;
  }
};
export default flowReducer;