import { getStepNumbering } from './stepNumberingSelectors';
import { getHasVerifiedIdentity } from '../app/verificationTypeSelectors';
import { getPostAccountCreationSteps } from '../store/flow/selectors';
import { getNextStep } from '../store/flow/selectors';
export const getIsAccountCreationStep = (state, nextSteps) => {
  const {
    currentStep,
    totalSteps
  } = getStepNumbering(state, nextSteps);
  const hasVerifiedIdentity = getHasVerifiedIdentity(state);
  const postAccountCreation = getPostAccountCreationSteps(state);
  if (postAccountCreation.length > 0) {
    return hasVerifiedIdentity && getNextStep(state) === postAccountCreation[0];
  }
  return hasVerifiedIdentity && currentStep === totalSteps;
};