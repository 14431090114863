export let TrackingActionTypes;
(function (TrackingActionTypes) {
  TrackingActionTypes["SignupInitiated"] = "[Tracking] SignupInitiated";
  TrackingActionTypes["AuthState"] = "[Tracking] AuthState";
  TrackingActionTypes["SignupInteraction"] = "[Tracking] Signup Interaction";
  TrackingActionTypes["StepView"] = "[Tracking] Step View";
  TrackingActionTypes["MessagingScrolling"] = "[Tracking] Messaging Scrolling";
  TrackingActionTypes["Click"] = "[Tracking] Click";
  TrackingActionTypes["LeavePage"] = "[Tracking] Leave Page";
  TrackingActionTypes["FormError"] = "[Tracking] Form Error";
  TrackingActionTypes["IndustryCollected"] = "[Tracking] IndustryCollected";
  TrackingActionTypes["BackButton"] = "[Tracking] Back Button";
  TrackingActionTypes["CreationError"] = "[Tracking] Creation Error";
  TrackingActionTypes["UtmVerification"] = "[Tracking] Utm Verification";
  TrackingActionTypes["VerifyIdentityStarted"] = "[Tracking] Verify Identity Started";
  TrackingActionTypes["VerifyIdentityCompleted"] = "[Tracking] Verify Identity Completed";
  TrackingActionTypes["SignupRedirect"] = "[Tracking] Signup Redirect";
  TrackingActionTypes["SurveyInteraction"] = "[Tracking] Survey Interaction";
  TrackingActionTypes["SurveyCompleted"] = "[Tracking] Survey Completed";
  TrackingActionTypes["InvalidDomain"] = "[Tracking] Invalid Domain";
  TrackingActionTypes["LoginSuccess"] = "[Tracking] Login Success";
  TrackingActionTypes["SignupPageView"] = "[Tracking] Signup Page View";
})(TrackingActionTypes || (TrackingActionTypes = {}));