import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { getFlowKey } from '../routing/getFlowKey';
import { useResponsiveness } from '../responsiveness/ResponsivenessProvider';
export const useLoadingTitle = ({
  loadingKey
}) => {
  const flowKey = getFlowKey();
  const {
    isMobile
  } = useResponsiveness();
  const titleWithFlowKey = `loading.${loadingKey}.${flowKey}.title`;
  const subTitleWithFlowKey = `loading.${loadingKey}.${flowKey}.subtitle`;
  let titleI18n = isValidI18nKey(`${titleWithFlowKey}_1`) ? titleWithFlowKey : `loading.${loadingKey}.title`;
  if (isMobile) {
    const mobileTitleWithFlowKey = `loading.${loadingKey}.${flowKey}.mobile_title`;
    if (isValidI18nKey(`${mobileTitleWithFlowKey}_1`)) {
      titleI18n = mobileTitleWithFlowKey;
    }
  }
  const subtitleI18n = isValidI18nKey(`${subTitleWithFlowKey}_1`) ? subTitleWithFlowKey : `loading.${loadingKey}.subtitle`;
  return {
    titleI18n,
    subtitleI18n
  };
};