import styled from 'styled-components';
import UIIcon from 'UIComponents/icon/UIIcon';
import UIImage from 'UIComponents/image/UIImage';
import UILogo from 'UIComponents/image/UILogo';
import { devices } from '../../styles/viewports.styles';
export const HubSpotWordmark = styled(UILogo).withConfig({
  displayName: "HeaderLogostyles__HubSpotWordmark",
  componentId: "u9qsu-0"
})(["@media ", "{display:inline-block;width:8rem;}"], devices.desktop);
export const HubSpotSprocket = styled(UIIcon).withConfig({
  displayName: "HeaderLogostyles__HubSpotSprocket",
  componentId: "u9qsu-1"
})(["width:27px;height:27px;transform:translateY(7px);"]);
export const CustomLogo = styled(UIImage).withConfig({
  displayName: "HeaderLogostyles__CustomLogo",
  componentId: "u9qsu-2"
})(["@media ", "{display:inline-block;}"], devices.desktop);