import { IntegrationsActionTypes } from './action-types';
export const initialIntegrationsState = {
  integratedAppAuthResponse: {
    access_token: ``,
    expires_in: 0,
    portal_domain: ``,
    portal_id: 0,
    portal_name: ``,
    refresh_token: ``
  },
  integratedAppValidPermissions: false,
  integratedAppAuthFinished: false,
  integratedAppAuthSucceeded: false
};
const integrationReducer = (state = initialIntegrationsState, action) => {
  switch (action.type) {
    case IntegrationsActionTypes.UpdateIntegratedAppAuthorizationResponseAction:
      return Object.assign({}, state, {
        integratedAppAuthResponse: Object.assign({}, action.integratedAppAuthResponse),
        integratedAppAuthSucceeded: true
      });
    case IntegrationsActionTypes.SetIntegratedAppPermissions:
      return Object.assign({}, state, {
        integratedAppValidPermissions: action.valid,
        integratedAppAuthFinished: true
      });
    default:
      return state;
  }
};
export default integrationReducer;