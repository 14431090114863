import enviro from 'enviro';
import { getQueryParams } from '../routing/getQueryParams';
import { getRedirectSignupType } from '../signup-completed-redirection/getRedirectSignupType';
import { redirectToIntent } from '../signup-completed-redirection/redirectToIntent';
import PortalIdContainer from '../portal-id-container/PortalIdContainer';
export const hasO365Params = () => {
  const queryParams = getQueryParams();
  if (queryParams) {
    const utmSource = queryParams['utm_source'];
    const utmCampaign = queryParams['hubs_campaign'];
    const intent = queryParams['intent'];
    return utmSource === 'microsoft' && utmCampaign === 'office-web-store' && intent === 'salesO365';
  }
  return false;
};
export const hasVstoParams = () => {
  const queryParams = getQueryParams();
  if (queryParams) {
    const utmSource = queryParams['utm_source'];
    const intent = queryParams['intent'];
    return utmSource === 'outlook-desktop-client' && intent === 'salesVsto';
  }
  return false;
};
export const shouldShowLoginButton = () => {
  return hasVstoParams() || hasO365Params();
};
export const getSalesExtensionRedirect = (state, intentLink) => {
  // The applications we are redirecting to here are SalesO365 and SalesVsto and they are on na1
  return redirectToIntent({
    portalId: PortalIdContainer.get(),
    intentLink,
    signupType: getRedirectSignupType(),
    hublet: enviro.getHublet()
  });
};