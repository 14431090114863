import { getSignupDataValue } from '../store/signup-data/selectors';
import { ProblemAwareness, ProductExperience } from './ConsiderationProfileOptions';
import { Data } from 'signup-constants/signupData/Data';
export let ConsiderationProfile;
(function (ConsiderationProfile) {
  ConsiderationProfile["SolutionBuyer"] = "solution-buyer";
  ConsiderationProfile["ProblemBuyer"] = "problem-buyer";
  ConsiderationProfile["ProductBuyer"] = "product-buyer";
  ConsiderationProfile["UnexpectingBuyer"] = "unexpecting-buyer";
})(ConsiderationProfile || (ConsiderationProfile = {}));
const considerationProfileMatrix = {
  [ProductExperience.yes]: {
    [ProblemAwareness.yes]: ConsiderationProfile.SolutionBuyer,
    [ProblemAwareness.no]: ConsiderationProfile.ProductBuyer
  },
  [ProductExperience.no]: {
    [ProblemAwareness.yes]: ConsiderationProfile.ProblemBuyer,
    [ProblemAwareness.no]: ConsiderationProfile.UnexpectingBuyer
  }
};
export const getConsiderationProfile = state => {
  const productExperience = getSignupDataValue(state, Data.ProductExperience);
  const problemAwareness = getSignupDataValue(state, Data.ProblemAwareness);
  if (problemAwareness && productExperience) {
    return considerationProfileMatrix[productExperience][problemAwareness];
  }
  return null;
};
export const getProblemAwarenessForApi = state => {
  const problemAwareness = getSignupDataValue(state, Data.ProblemAwareness);
  return problemAwareness ? problemAwareness === ProblemAwareness.yes : null;
};
export const getProductExperienceForApi = state => {
  const productExperience = getSignupDataValue(state, Data.ProductExperience);
  return productExperience ? productExperience === ProductExperience.yes : null;
};
export const getProblemAwarenessString = odysseySignupData => {
  return odysseySignupData['problemAwareness'] ? ProblemAwareness.yes : ProblemAwareness.no;
};
export const getProductExperienceString = odysseySignupData => {
  return odysseySignupData['productExperience'] ? ProductExperience.yes : ProductExperience.no;
};