import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { startupApplication } from './productLinks';
export const getHubSpotForStartupsRedirect = ({
  portalId,
  hublet,
  intentLink,
  isNewPortal = false
}) => makeLinkWithQueryParams({
  link: startupApplication({
    portalId,
    hublet
  }),
  queryParamsToInclude: {
    via: isNewPortal ? 'hubspot-for-startups-signup' : 'hubspot-for-startups-existing-account',
    redirect: intentLink
  },
  hublet
});