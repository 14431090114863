import { ErrorKey } from '../app-active-error/ErrorKey';
import Logger from '../lib/Logger';
import { getHublet, getIsNewUser } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { setError } from '../store/error/action-creators';
import { Tracker } from '../store/tracking/action-creators';
import { getIsMobile } from '../utils/deviceUtils';
import { redirectToUrl } from '../utils/redirectToUrl';
import { canRedirectNewUser } from './canRedirectNewUser';
import { getFallbackOnboardingUrl } from './getFallbackOnboardingUrl';
import { getShouldRedirectWindow } from './getShouldRedirectWindow';
import { setRedirectUrl } from '../store/app/action-creators';
import { getRedirectMethod } from './getRedirectMethod';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('FallbackRedirectMiddleware');
const FALLBACK_TIMEOUT = getIsMobile() ? 12000 : 9000;
export const installRedirectFallbackTimer = (store, wait = FALLBACK_TIMEOUT) => {
  return setTimeout(() => {
    const state = store.getState();
    const portalId = getPortalId(state);
    const hublet = getHublet(state);
    const isNewUser = getIsNewUser(state);
    LOG.error('Fallback timer on signup finish triggered', {
      extra: {
        getShouldRedirectWindow: getShouldRedirectWindow(),
        canRedirectNewUser: canRedirectNewUser(state)
      }
    });
    store.dispatch(Tracker.signupInteraction('signup-finished-fallback-timer', {}, true));
    const shouldRedirectCurrentWindow = getShouldRedirectWindow();
    const getRedirectUrl = getRedirectMethod(state);
    const shouldRedirectWindow = getShouldRedirectWindow();
    if (!shouldRedirectCurrentWindow) {
      Promise.resolve(getRedirectUrl()).then(redirectUrl => {
        store.dispatch(setRedirectUrl(redirectUrl));
        store.dispatch(Tracker.signupInteraction('embedded-signup-continue-screen'));
        store.dispatch(setError(ErrorKey.EmbeddedSignupContinueScreen));
      }).catch(error => {
        LOG.error(`Failed to build redirectUrl`, {
          extra: getSentryExtra(error)
        });

        // Fallback to the user-guide
        store.dispatch(redirectToUrl(getFallbackOnboardingUrl(portalId, hublet, isNewUser), shouldRedirectWindow));
      });
    } else {
      store.dispatch(redirectToUrl(getFallbackOnboardingUrl(portalId, hublet, isNewUser)));
    }
  }, wait);
};