import { useDispatch } from 'react-redux';
import { getLoginUrl } from '../http-login/getLoginUrl';
import { redirectToUrl } from '../utils/redirectToUrl';
export const useLoginRedirect = () => {
  const dispatch = useDispatch();
  const redirectToLogin = ({
    redirectUrl = '',
    email = '',
    hublet
  } = {}) => {
    dispatch(redirectToUrl(getLoginUrl({
      redirectUrl,
      email,
      hublet
    })));
  };
  return {
    redirectToLogin
  };
};