import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { memoize } from './memoize';

/**
 * Fetches all industries available.
 *
 * @param {Object<string, any>} options Any additional request options.
 * @returns {PromiseLike<{name: string, domain: string}>} The thenable object.
 */
export const fetchIndustriesAvailable = memoize((options = {}) => {
  return noAuthApiClient.get(`/acquisition/v1/companyinfo/industries/available`, Object.assign({
    timeout: 5000,
    maxRetries: 5
  }, options));
}, options => JSON.stringify(options));