// @ts-expect-error Untyped module
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { ValidationStatus } from '../types';
export let EmailError;
(function (EmailError) {
  EmailError["NOT_AN_EMAIL"] = "NOT_AN_EMAIL";
  EmailError["INVALID_USERNAME"] = "invalid username";
  EmailError["SECONDARY_EMAIL"] = "email-pending-verification";
})(EmailError || (EmailError = {}));
export const syncEmailValidator = email => {
  if (email && EmailAddressPattern.test(email)) {
    return ValidationStatus.VALID;
  }
  return EmailError.NOT_AN_EMAIL;
};