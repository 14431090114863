import { handleInitialInterestError } from '../hubspot-forms/handleInitialInterestResponse';
import { initialInterest } from '../hubspot-forms/initialInterest';
import { SignupInterestActionTypes } from '../store/signup-interest/action-types';
import { EndpointKey } from './EndpointKey';
import { isDACHCountry } from '../gdpr/gdprUtils';
import { getFlowConfig } from '../flow-initial/getFlowConfig';

// NOTE: This object was wrapped in a function to avoid problematic cycles when frontend-infra-core migrates to Webpack 5
export const getEndpointsSignupInterest = () => ({
  [EndpointKey.InitialInterest]: {
    once: true,
    stateCheck: state => !isDACHCountry(state) && getFlowConfig().shouldSubmitSignupInterest,
    method: initialInterest,
    handleError: handleInitialInterestError,
    reduxActionType: SignupInterestActionTypes.SubmitForm
  }
});