import enviro from 'enviro';
import { Environment } from '../types/Environment';
import { getFullUrl } from 'hubspot-url-utils';
export const getIsQa = enviro.isQa;
export const isQA = enviro.isQa();
export const isLocal = isQA && !enviro.deployed();
export const isProd = enviro.isProd();
export const getDomainSuffix = () => {
  return enviro.isQa() ? Environment.QA : '';
};
export const getCurrentDomain = () => {
  const prefix = !enviro.deployed() ? Environment.Local : 'app';
  return getFullUrl(prefix);
};

// Used throughout signup before auth to get links, default to NA1
export const getApplicationDomain = (hublet = enviro.getHublet()) => {
  const prefix = !enviro.deployed() && enviro.isQa() ? Environment.Local : 'app';
  return getFullUrl(prefix, {
    hubletOverride: hublet
  });
};
export const getEnv = () => {
  if (!enviro.deployed()) return Environment.Local;
  return enviro.isQa() ? Environment.QA : Environment.Prod;
};