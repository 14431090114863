import { VerificationType } from '../app/VerificationType';
import { EXPERIMENT_ID } from '../experiments/Experiments';
import { getFlowSteps } from '../flow-initial/getFlowConfig';
import { OptionalDataKeys } from '../signup-data/OptionalDataKeys';
import { getProblemAwarenessString, getProductExperienceString } from '../signup-data/considerationProfileUtils';
import { completeVerificationComplete, setVerificationType } from '../store/app/action-creators';
import { fetchExperiments, setExperimentUtk, updateActiveExperimentsParameters } from '../store/experiments/action-creators';
import { bulkSetSignupDataAndSetValid, bulkSetSignupDataCompleted, setStepAsSkipped } from '../store/signup-data/action-creators';
import { Tracker } from '../store/tracking/action-creators';
import { IdentityVerificationMethod } from '../tracking/IdentityVerificationMethod';
import { camelToScreamingSnake } from '../utils/data-structure-utils/camelToScreamingSnake';
import { screamingSnakeToCamel } from '../utils/data-structure-utils/screamingSnakeToCamel';
function getSetStepsAsSkippedActions(receivedData) {
  const {
    preVerification
  } = getFlowSteps();
  const actionCreators = [];
  OptionalDataKeys.forEach(dataKey => {
    const step = dataKey;
    const key = screamingSnakeToCamel(dataKey);
    if (preVerification.includes(step) && !receivedData[key]) {
      actionCreators.push(setStepAsSkipped(dataKey));
    }
  });
  return actionCreators;
}
function getSignupDataFromReceivedData(receivedData) {
  return Object.keys(receivedData).filter(dataKey => dataKey !== EXPERIMENT_ID).reduce((result, camelDataKey) => {
    const key = camelToScreamingSnake(camelDataKey);
    const data = receivedData[camelDataKey];
    if (data) {
      result[key] = data;
    }
    return result;
  }, {});
}
export const handleCompleteVerificationSuccess = (response, dispatch) => {
  const {
    activeExperimentsParameters,
    userInformation,
    odysseySignupData,
    email
  } = response;
  const receivedData = Object.assign({}, userInformation, odysseySignupData, {
    email
  }, odysseySignupData && odysseySignupData['problemAwareness'] !== null && odysseySignupData['problemAwareness'] !== undefined && {
    problemAwareness: getProblemAwarenessString(odysseySignupData)
  }, odysseySignupData && odysseySignupData['productExperience'] !== null && odysseySignupData['productExperience'] !== undefined && {
    productExperience: getProductExperienceString(odysseySignupData)
  });
  if (activeExperimentsParameters) {
    dispatch(updateActiveExperimentsParameters(activeExperimentsParameters));
  }
  getSetStepsAsSkippedActions(receivedData).forEach(dispatch);
  const signupData = getSignupDataFromReceivedData(receivedData);
  dispatch(setVerificationType(VerificationType.Email));
  dispatch(Tracker.verifyIdentityCompleted(IdentityVerificationMethod.EmailVerification));
  if (receivedData.experimentId) {
    dispatch(setExperimentUtk(receivedData.experimentId));
  }
  dispatch(fetchExperiments());
  dispatch(bulkSetSignupDataCompleted(signupData));
  dispatch(bulkSetSignupDataAndSetValid(signupData));
  return dispatch(completeVerificationComplete());
};