export let ValidationStatus;
(function (ValidationStatus) {
  ValidationStatus["FRESH"] = "FRESH";
  ValidationStatus["VALID"] = "VALID";
  ValidationStatus["INVALID"] = "INVALID";
})(ValidationStatus || (ValidationStatus = {}));
export let CompanyDomainErrors;
(function (CompanyDomainErrors) {
  CompanyDomainErrors["generic"] = "generic";
  CompanyDomainErrors["genericSubpages"] = "generic-subpages";
  CompanyDomainErrors["socialMedia"] = "social-media";
  CompanyDomainErrors["emailProvider"] = "email-provider";
  CompanyDomainErrors["searchEngine"] = "search-engine";
  CompanyDomainErrors["invalidCountryCode"] = "invalid country code";
  CompanyDomainErrors["existingPartnerDomain"] = "existing partner domain";
  CompanyDomainErrors["invalidPartnerDomain"] = "invalid partner domain";
  CompanyDomainErrors["invalidDomain"] = "invalid domain";
})(CompanyDomainErrors || (CompanyDomainErrors = {}));