import { useEffect, useRef } from 'react';
export const useIntersectionObserver = (intersectionCallback, threshold = [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]) => {
  const observedElementRef = useRef(null);
  useEffect(() => {
    if ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype && observedElementRef.current) {
      const observer = new IntersectionObserver(entries => {
        if (entries.length > 0) {
          const entry = entries[0];
          if (entry && entry.isIntersecting) {
            intersectionCallback(entry);
          }
        }
      }, {
        threshold
      });
      observer.observe(observedElementRef.current);
    }
  }, [intersectionCallback, threshold]);
  return {
    observedElementRef
  };
};