import { getMessagePlatform } from './getMessagePlatform';
import { Industry } from 'signup-ui-lego-core/constants/Industry';
import { StepByIndustry } from './StepByIndustry';
export const industryHasConfig = industry => {
  const industryValues = Object.values(Industry);
  return industryValues.includes(industry) && StepByIndustry.has(industry);
};
export const getIndustrySpecificMessaging = ({
  isMobile,
  industry
}) => {
  const platform = getMessagePlatform(isMobile);
  let industryMessaging;
  if (industryHasConfig(industry)) {
    industryMessaging = StepByIndustry.get(industry);
  }
  return industryMessaging ? industryMessaging[platform] : null;
};