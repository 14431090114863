import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentStep } from '../store/flow/selectors';
export const useCurrentStep = () => {
  const params = useParams();
  const stepFromState = useSelector(getCurrentStep);
  const currentStep = params.step || stepFromState;
  return {
    currentStep
  };
};