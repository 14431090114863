import { getDataKeysByStep } from '../step/getStepData';
import { unprocessBranch } from '../store/flow-branches/action-creators';
import { FlowActionTypes } from '../store/flow/action-types';
import { getCurrentStep } from '../store/flow/selectors';
import { resetDataFlags } from '../store/signup-data/action-creators';
import { executeStepBefore } from '../views/getStepConfig';
import { setNextStep } from './setNextStep';
import { isOutdatedStepCompletedAction, shouldGoNextStep, shouldGoToPostAccountCreationSteps } from './stepperMiddlewareUtils';
export const StepperMiddleware = store => next => action => {
  const result = next(action);
  const state = store.getState();
  const currentStep = getCurrentStep(state);
  if (action.type === FlowActionTypes.SetFlow && action.shouldMoveToNextStep) {
    store.dispatch(setNextStep());
    return result;
  }
  if (action.type === FlowActionTypes.GoPrevStep) {
    store.dispatch(unprocessBranch());
    if (currentStep) {
      const dataKeys = getDataKeysByStep([currentStep]);
      dataKeys.forEach(dataKey => store.dispatch(resetDataFlags(dataKey)));
    }
    return result;
  }
  if (currentStep) {
    if (isOutdatedStepCompletedAction(action, currentStep)) {
      return result;
    }
    if (action.type === FlowActionTypes.StepCompleted) {
      store.dispatch(executeStepBefore(currentStep));
    }
    if (shouldGoNextStep(action, currentStep)) {
      store.dispatch(setNextStep());
    }
    if (shouldGoToPostAccountCreationSteps(action, state)) {
      store.dispatch(setNextStep({
        accountCreated: true
      }));
    }
  }
  return result;
};