import Raven from 'raven-js';
import { getHistory } from '../routing/history';
export const setExtraContext = preventLoading => {
  const queryParams = new URLSearchParams(getHistory().location.search);
  const isEmbedded = Boolean(queryParams.get('_seia'));
  Raven.setExtraContext({
    'is-embedded': isEmbedded
  });
  Raven.setExtraContext({
    'is-probably-bot': preventLoading
  });
};