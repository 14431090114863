import apiClient from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { isPortalIdDefined } from '../store/auth/selectors';
import { getHublet } from '../store/app/selectors';
import { isNewLoggedInAccount } from './endpointStateChecks';
import { EndpointKey } from './EndpointKey';
import { getIsActiveAndAssignedToVariant, getParameter } from '../store/experiments/selectors';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { ExperimentParameterGroup } from '../experiments/ExperimentParameterGroup';
import Logger from '../lib/Logger';
import { getSentryExtra } from '../error/getSentryExtra';
import { retryEndpoint } from '../store/http-responses/action-creators';
import { getEndpointRetries } from '../store/http-responses/selectors';
import { Tracker } from '../store/tracking/action-creators';
const GROWTH_SIDE_PANEL_ELIGIBILITY_API_PATH = '/adoption-delivery-system/v1/growth-side-panel/eligibility';
const patchGrowthSidePanelEligibility = state => {
  const hublet = getHublet(state);
  const coac0002ExperimentParameter = getParameter(state, ExperimentKeys.COAC0002);
  const coac0002Experiment = coac0002ExperimentParameter === ExperimentParameterGroup.Variant_A;
  const isEligible = coac0002Experiment;
  return apiClient.patch(`${getFullUrl('app-api', {
    hubletOverride: hublet
  })}${GROWTH_SIDE_PANEL_ELIGIBILITY_API_PATH}`, {
    data: {
      isEligible
    }
  });
};
export const LOG = Logger.getLogger('patchGrowthSidePanelEligibilityError');
export const EndpointsGrowthSidePanel = {
  [EndpointKey.EndpointsGrowthSidePanel]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: state =>
    // Is the Portal created so we can use the endpoint?
    isPortalIdDefined(state) && getIsActiveAndAssignedToVariant(state, ExperimentKeys.COAC0002, [ExperimentParameterGroup.Control, ExperimentParameterGroup.Variant_A]) &&
    // Is the user authed for API usage?
    isNewLoggedInAccount(state),
    method: patchGrowthSidePanelEligibility,
    handleError: (error, dispatch, getState) => {
      dispatch(Tracker.signupInteraction('error-store-treatment-side-pannel'));
      LOG.error('Error persisting Growth Side Panel Eligibility', {
        extra: getSentryExtra(error)
      });
      const numberOfStoreTreatmentRetries = getEndpointRetries(getState(), EndpointKey.EndpointsGrowthSidePanel);

      // Retry every second, up to 5 times
      if (numberOfStoreTreatmentRetries < 5) {
        dispatch(Tracker.signupInteraction('retry-store-treatment-side-pannel'));
        dispatch(retryEndpoint(EndpointKey.EndpointsGrowthSidePanel, 1000));
      }
    }
  }
};