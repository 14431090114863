import { LoadingKey } from '../app-active-loading/LoadingKey';
import { handleIntegratedAppAuthorizationError } from '../http-integrated-app/handleIntegratedAppAuthorizationResponse';
import { makeOAuthAuthorizationRequest, validateOAuthPermissions, connectShopifyStore } from '../http-integrated-app/integratedAppAuthorization';
import { EndpointKey } from '../http/EndpointKey';
import { IntegrationsActionTypes } from '../store/integrations/action-types';
import { setIntegratedAppPermissions } from '../store/integrations/action-creators';
import { checkIfLoggedInIfNotExistingAccount } from '../http/endpointStateChecks';
import { isSignupDataCompleted } from '../store/signup-data/selectors';
import { Data } from 'signup-constants/signupData/Data';
import { Integration } from 'signup-constants/Integration';
import { isIntegration } from '../utils/isIntegration';
export const EndpointsIntegrations = {
  [EndpointKey.ValidateWordpressOAuthPermissions]: {
    reduxActionType: IntegrationsActionTypes.ValidateWordpressOAuthPermissions,
    method: validateOAuthPermissions(Integration.Wordpress),
    loadingKey: LoadingKey.StandardLoading,
    handleError: (error, dispatch) => dispatch(setIntegratedAppPermissions(false)),
    handleSuccess: (_, dispatch) => dispatch(setIntegratedAppPermissions(true))
  },
  [EndpointKey.ValidateShopifyOAuthPermissions]: {
    reduxActionType: IntegrationsActionTypes.ValidateShopifyEmbeddedOAuthPermissions,
    method: validateOAuthPermissions(Integration.ShopifyEmbedded),
    loadingKey: LoadingKey.StandardLoading,
    handleError: (error, dispatch) => dispatch(setIntegratedAppPermissions(false)),
    handleSuccess: (_, dispatch) => dispatch(setIntegratedAppPermissions(true))
  },
  [EndpointKey.WordpressAuthorization]: {
    stateCheck: state => {
      return isSignupDataCompleted(state, Data.IntegratedAppRecaptchaToken) && checkIfLoggedInIfNotExistingAccount(state) && isIntegration(Integration.Wordpress);
    },
    loadingKey: LoadingKey.StandardLoading,
    method: makeOAuthAuthorizationRequest(Integration.Wordpress),
    handleError: handleIntegratedAppAuthorizationError(Integration.Wordpress),
    isStateOnlyTrigger: true,
    once: true
  },
  [EndpointKey.ShopifyEmbeddedAuthorization]: {
    stateCheck: state => {
      return isSignupDataCompleted(state, Data.IntegratedAppRecaptchaToken) && checkIfLoggedInIfNotExistingAccount(state) && isIntegration(Integration.ShopifyEmbedded);
    },
    loadingKey: LoadingKey.StandardLoading,
    method: connectShopifyStore,
    handleError: handleIntegratedAppAuthorizationError(Integration.ShopifyEmbedded),
    isStateOnlyTrigger: true,
    once: true
  }
};