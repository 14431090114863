import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { getFlowKey } from '../routing/getFlowKey';
import { getCurrentStep } from '../store/flow/selectors';
export const getStepTitle = state => {
  const currentStep = getCurrentStep(state);
  const flowKey = getFlowKey();
  const flowTitle = `views.${currentStep}.title.${flowKey}`;
  const defaultTitle = `views.${currentStep}.title.default`;
  const title = isValidI18nKey(flowTitle) ? flowTitle : defaultTitle;
  return title;
};