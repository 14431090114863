import { hubSpotEngageAIRedirect } from './productLinks';
import { getHublet, getIsNewUser } from '../store/app/selectors';
import { getQueryParam } from '../routing/getQueryParam';
import { isHubSpotHost } from '../utils/urls';
import { getPortalId } from '../store/auth/selectors';
export const getEngageAIFlowRedirect = state => {
  const loginRedirectUrl = getQueryParam('loginRedirectUrl');
  if (isHubSpotHost(loginRedirectUrl)) {
    return loginRedirectUrl;
  }
  const isNewUser = getIsNewUser(state);
  const hublet = getHublet(state);
  const portalId = getPortalId(state);
  return hubSpotEngageAIRedirect({
    hublet,
    isNewUser,
    portalId
  });
};