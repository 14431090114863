import { useSignupEmbedder } from 'signup-embedder/hooks/useSignupEmbedder';
import { Metrics } from '../metrics/Metrics';
import { MetricsCounter } from '../metrics/MetricsCounter';
export const useTrackEmbedderUsage = () => {
  const {
    isActive: isInstanceOfSignupEmbedder,
    isEmbedded,
    isPopup,
    embeddingPage
  } = useSignupEmbedder();
  if (isInstanceOfSignupEmbedder) {
    const dimensions = {
      isEmbedded: isEmbedded.toString(),
      isPopup: isPopup.toString()
    };
    if (embeddingPage) Object.assign(dimensions, {
      embeddingPage
    });
    Metrics.counter(MetricsCounter.Embedder, dimensions).increment();
  }
};