// @ts-expect-error Untyped module
import DomainPattern from 'PatternValidationJS/patterns/Domain';
import { CompanyDomainErrors, ValidationStatus } from '../types';
import { hasEmoji } from './matchers';
export const normalizeUrl = url => {
  const hasRelativeProtocol = url.substring(0, 2) === '//';
  if (hasRelativeProtocol) {
    url = `https:${url}`;
  }
  if (url && url.substring(0, 8) !== 'https://' && url.substring(0, 7) !== 'http://') {
    url = `https://${url}`;
  }
  return url;
};
export const syncCompanyDomainValidator = (domain, isUsingOptionalDomain) => {
  let domainPathname = '/';
  let domainHostname;
  try {
    const normalizedUrl = normalizeUrl(domain);
    const domainUrl = new URL(normalizedUrl);
    domainHostname = domainUrl.hostname;
    domainPathname = domainUrl.pathname;
  } catch (error) {
    return CompanyDomainErrors.generic;
  }
  if (!domainHostname || !DomainPattern.test(domainHostname)) {
    return CompanyDomainErrors.generic;
  }
  if (!isUsingOptionalDomain && domain.includes('hubspot-freecmstools.com')) {
    return CompanyDomainErrors.generic;
  }
  if (domainPathname.length > 1) {
    return CompanyDomainErrors.genericSubpages;
  }
  if (hasEmoji(domain)) {
    return CompanyDomainErrors.generic;
  }
  return ValidationStatus.VALID;
};