import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HubsTrackingAttr } from 'signup-constants/HubsTrackingAttr';
import { SignupEmbedderOptions } from 'signup-embedder';
import { Metrics } from '../metrics/Metrics';
import { MetricsCounter } from '../metrics/MetricsCounter';
import { getFlowKey } from '../routing/getFlowKey';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import { getQueryParam } from '../routing/getQueryParam';
import { Tracker } from '../store/tracking/action-creators';
export const useTrackPopupBlocked = () => {
  const shouldTrackPopupBlocked = Boolean(SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_POPUP_BLOCKED));
  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldTrackPopupBlocked) {
      const flowKey = getFlowKey();
      const integrationKey = getIntegrationKey();
      const hubsSignupUrl = getQueryParam(HubsTrackingAttr.SignupUrl);
      const hubsSignupCta = getQueryParam(HubsTrackingAttr.SignupCta);
      dispatch(Tracker.signupInteraction('signup-from-popup-blocked'));
      Metrics.counter(MetricsCounter.PopupBlocked, {
        flowKey,
        integrationKey: integrationKey || '',
        hubsSignupUrl,
        hubsSignupCta
      }).increment();
    }
  }, [shouldTrackPopupBlocked, dispatch]);
};