export let ErrorKey;
(function (ErrorKey) {
  ErrorKey["EmailVerificationNotFound"] = "EMAIL_VERIFICATION_NOT_FOUND";
  ErrorKey["InternalError"] = "INTERNAL_ERROR";
  ErrorKey["LoginFailed"] = "LOGIN_FAILED";
  ErrorKey["StandardCreationError"] = "STANDARD_CREATION_ERROR";
  ErrorKey["VerifyFirstCreationError"] = "VERIFY_FIRST_CREATION_ERROR";
  ErrorKey["RateLimitedCreationError"] = "RATE_LIMITED_CREATION_ERROR";
  ErrorKey["RateLimitedEmailVerification"] = "RATE_LIMITED_EMAIL_VERIFICATION";
  ErrorKey["OAuthExistingLoginFailed"] = "OAUTH_EXISTING_LOGIN_FAILED";
  ErrorKey["OAuthPendingEmailVerification"] = "OAUTH_PENDING_EMAIL_VERIFICATION";
  ErrorKey["EmailVerificationFailed"] = "EMAIL_VERIFICATION_FAILED";
  ErrorKey["SessionExpired"] = "SESSION_EXPIRED";
  ErrorKey["RecaptchaCreationError"] = "RECAPTCHA_CREATION_ERROR";
  ErrorKey["PartnerExistingAccountError"] = "PARTNER_EXISTING_ACCOUNT_ERROR";
  ErrorKey["AlreadyAPartner"] = "ALREADY_A_PARTNER";
  ErrorKey["WordpressAuthorizationError"] = "WORDPRESS_AUTHORIZATION_ERROR";
  ErrorKey["BlankScreenLoadingError"] = "BLANK_SCREEN_LOADING_ERROR";
  ErrorKey["PartnerNoRegisteredLeads"] = "PARTNER_NO_REGISTERED_LEADS";
  ErrorKey["UnauthedPartnerUserError"] = "UNAUTHED_PARTNER_USER_ERROR";
  ErrorKey["NoPartnerPortalsError"] = "NO_PARTNER_PORTALS_ERROR";
  ErrorKey["EmbeddedSignupContinueScreen"] = "EMBEDDED_SIGNUP_CONTINUE_SCREEN";
  ErrorKey["RecaptchaMissingInformationError"] = "RECAPTCHA_MISSING_INFORMATION_ERROR";
  ErrorKey["MissingBlogIdeasGeneratorScopesError"] = "MISSING_BLOG_IDEAS_GENERATOR_SCOPES_ERROR";
  ErrorKey["IntegratedAppAuthorizationError"] = "INTEGRATED_APP_AUTHORIZATION_ERROR";
})(ErrorKey || (ErrorKey = {}));