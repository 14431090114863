import { useEffect } from 'react';
import { usePrevious } from '../utils/usePrevious';
import { useCurrentStep } from './useCurrentStep';
export const useRestoreScroll = () => {
  const {
    currentStep
  } = useCurrentStep();
  const prevStep = usePrevious(currentStep);
  useEffect(() => {
    if (prevStep !== currentStep) {
      window.scrollTo(0, 0);
    }
  }, [currentStep, prevStep]);
};