export const devices = {
  tablet: `screen and (min-width: 769px)`,
  desktop: `screen and (min-width: 1025px)`,
  large: `screen and (min-width: 1201px)`,
  extraLarge: `screen and (min-width: 1600px)`
};
export const cookieBannerDevices = {
  small: `(max-width: 750px)`,
  medium: `(max-width: 800px)`,
  large: `(max-width: 1152px)`
};