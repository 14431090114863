import { Flow } from 'signup-constants/Flow';
import imageUrls from '../images/imageUrls';
import { getFlowKey } from '../routing/getFlowKey';
const SignupRequestLoadingIllustration = {
  [Flow.Purchase]: 'payments-subscriptions'
};
export const getSignupRequestLoadingImageConfig = () => {
  const flowKey = getFlowKey();
  if (SignupRequestLoadingIllustration[flowKey]) {
    return {
      imageUrl: SignupRequestLoadingIllustration[flowKey],
      isIllustration: true,
      illustrationHeight: '300px'
    };
  }
  return {
    imageUrl: imageUrls.welcomeImage
  };
};