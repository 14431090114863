import { IP_LOCATION_URL, IP_LOCATION_REQUEST_KEY } from '../early-requester/iplocation-early-requester/iplocationEarlyRequester';
import { makeRequest } from '../http/makeRequest';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
export const checkIpLocation = () => {
  try {
    const fetch = () => noAuthApiClient.get(IP_LOCATION_URL);
    return makeRequest({
      earlyRequestKey: IP_LOCATION_REQUEST_KEY,
      fetch,
      shouldUseEarlyRequest: true,
      onError: (xhr, resolve, reject) => {
        reject(xhr);
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};