import { Product } from 'signup-ui-lego-core/constants/Product';
import { hasMarketing, hasMarketingPaid } from './ProductUtils';
export const POINTS = {
  super: 100,
  ultra: 30,
  high: 20,
  medium: 10,
  low: 5
};
const getScoringFunctionArgs = portal => {
  return {
    products: portal.products,
    users: portal.numberOfUsers,
    contacts: portal.numberOfContacts
  };
};
export const getCommonScore = (totalScore, products, users, contacts) => {
  if (products.includes(Product.Partner)) {
    totalScore = totalScore + POINTS.super;
  }
  if (users > 5) {
    totalScore = totalScore + POINTS.medium;
  } else if (users > 2) {
    totalScore = totalScore + POINTS.low;
  }
  if (contacts > 100) {
    totalScore = totalScore + POINTS.medium;
  } else if (contacts > 10) {
    totalScore = totalScore + POINTS.low;
  }
  return totalScore;
};

// Trying to surface the most active account
export const getScoreSalesProducts = account => {
  const {
    products,
    users,
    contacts
  } = getScoringFunctionArgs(account);
  let totalScore = 0;
  if (products.includes(Product.Sales)) {
    totalScore = totalScore + POINTS.medium;
  }
  if (products.includes(Product.SalesPro)) {
    totalScore = totalScore + POINTS.ultra;
  }
  if (hasMarketing(products)) {
    totalScore = totalScore + POINTS.ultra;
  }
  totalScore = getCommonScore(totalScore, products, users, contacts);
  return totalScore;
};

// Trying to surface any accounts that already have Leadin
export const getScoreLeadin = account => {
  const {
    products,
    users,
    contacts
  } = getScoringFunctionArgs(account);
  let totalScore = 0;
  if (products.includes(Product.MarketingFree)) {
    totalScore = totalScore + POINTS.medium;
  }
  if (hasMarketingPaid(products)) {
    totalScore = totalScore + POINTS.ultra;
  }
  if (products.includes(Product.Sales)) {
    totalScore = totalScore + POINTS.medium;
  }
  totalScore = getCommonScore(totalScore, products, users, contacts);
  return totalScore;
};
export const getScoreService = account => {
  const {
    products,
    users,
    contacts
  } = getScoringFunctionArgs(account);
  let totalScore = 0;
  if (products.includes(Product.ServiceFree)) {
    totalScore += POINTS.high;
  }
  totalScore = getCommonScore(totalScore, products, users, contacts);
  return totalScore;
};
export const getScoreAcademy = account => {
  const {
    products,
    users,
    contacts
  } = getScoringFunctionArgs(account);
  let totalScore = 0;
  if (products.includes(Product.Community)) {
    totalScore = totalScore + POINTS.ultra;
  }
  if (hasMarketing(products) && products.includes(Product.Sales)) {
    totalScore = totalScore + POINTS.high;
  }
  totalScore = getCommonScore(totalScore, products, users, contacts);
  return totalScore;
};
export const getScoreDevelopers = account => {
  const {
    products,
    users
  } = getScoringFunctionArgs(account);
  let totalScore = 0;
  if (products.includes(Product.Developers)) {
    totalScore = totalScore + POINTS.ultra;
  }
  if (users > 5) {
    totalScore = totalScore + POINTS.medium;
  } else if (users > 2) {
    totalScore = totalScore + POINTS.low;
  }
  return totalScore;
};
export const getScoreCmsDevelopers = account => {
  const {
    products,
    users,
    contacts
  } = getScoringFunctionArgs(account);
  let totalScore = 0;
  if (products.includes(Product.StandaloneCmsDeveloper)) {
    totalScore += POINTS.high;
  }
  totalScore = getCommonScore(totalScore, products, users, contacts);
  return totalScore;
};