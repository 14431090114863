export const MobileDims = {
  global: {
    ZERO: 0,
    HUNDRED_PERCENT: '100%'
  },
  spacing: {
    XS: 8,
    SM: 16,
    MD: 30,
    MD2: 48,
    LG: 70,
    XL: 95
  },
  padding: {
    XS: 8,
    SM: 10,
    MD: 12
  },
  width: {
    SM: 220,
    MD: 280,
    LG: 320,
    MAX: 375
  },
  height: {
    SM: 48,
    MD: 280
  }
};
const DIMS = MobileDims;
export const MobileAppDims = {
  heading: {
    h1FontSize: '2rem',
    h2FontSize: 32,
    h3FontSize: 26,
    h4FontSize: 22
  },
  landingPage: {
    marginTop: '12%'
  },
  app: {
    maxWidth: 460,
    formToMessagingSpacing: DIMS.spacing.MD
  },
  blob: {
    dimensions: DIMS.width.SM
  },
  input: {
    height: DIMS.spacing.XL
  },
  button: {
    fontSize: 16,
    height: DIMS.height.SM,
    innerPadding: DIMS.padding.MD,
    iconHeight: 28,
    googleButtonWidth: DIMS.width.LG
  },
  form: {
    inputToButtonSpacing: DIMS.spacing.MD,
    titleMarginTop: DIMS.global.ZERO,
    titleToFormSpacing: DIMS.spacing.MD,
    titleToPretitleSpacing: DIMS.spacing.XS,
    width: DIMS.width.LG,
    height: DIMS.height.MD
  },
  logo: {
    height: 30
  },
  messaging: {
    contentToCaptionSpacing: DIMS.spacing.MD2,
    maxHeight: DIMS.global.HUNDRED_PERCENT,
    width: DIMS.width.SM,
    titleFontWeight: 450,
    bodyTextFontSize: 15,
    bodyTextFontSizeNoTitle: 17,
    subHeading: 17
  },
  privacyBanner: {
    fontSize: 13,
    paddingX: DIMS.padding.MD,
    paddingY: DIMS.padding.MD
  },
  selectableButtonOption: {
    smallHeight: 64,
    mediumHeight: 80,
    largeHeight: 100,
    fontSize: 22,
    innerPadding: 3
  },
  illustration: {
    height: 170
  },
  existingUser: {
    marginTop: 30,
    marginBottom: 30,
    padding: 30,
    buttonMarginTop: 35,
    name: {
      marginTop: 16,
      fontSize: 21,
      fontWeight: 505
    }
  },
  errorScreen: {
    marginTop: '12%'
  }
};