export const arrayContainsAll = function arrayContainsAll({
  array = [],
  element,
  containedArray = []
}) {
  if (element) {
    return array.indexOf(element) !== -1;
  }
  if (containedArray) {
    return containedArray.every(el => array.indexOf(el) !== -1);
  }
  return false;
};