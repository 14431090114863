import { Flow } from 'signup-constants/Flow';
import { isFlow } from '../utils/isFlow';
import { isValidIntent } from '../utils/isValidIntent';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { shouldAddHubInterestStep } from '../flow-initial/shouldAddHubInterestStep';
import { Data } from 'signup-constants/signupData/Data';
import { getHubInterestForIntent, HubInterestToJobField } from '../signup-data/hubInterestMappings';
import { isSoftwareSignup } from '../store/flow/selectors';
import { setSignupDataValue } from '../store/signup-data/action-creators';
import { getHubInterestOptionForTrial } from '../trial/trialUtils';
export const prefillHubInterest = () => dispatch => {
  let hubInterest;
  if (isFlow(Flow.Trial)) {
    hubInterest = getHubInterestOptionForTrial();
  }
  if (isSoftwareSignup()) {
    if (isValidIntent()) {
      hubInterest = getHubInterestForIntent();
    } else if (!shouldAddHubInterestStep()) {
      hubInterest = getFlowConfig().hubInterest;
    }
  }
  if (hubInterest) {
    dispatch(setSignupDataValue(Data.HubInterest, hubInterest));
    dispatch(setSignupDataValue(Data.JobField, HubInterestToJobField[hubInterest]));
  }
};