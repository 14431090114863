import { Flow } from 'signup-constants/Flow';
import { getIntentKey } from '../routing/getIntentKey';
import { isFlow } from '../utils/isFlow';
import { ExperimentKeys, TEST_KEY } from './ExperimentKeys';
import { enrollOnFlow } from './experimentDefaults';
import { getIsMobile } from '../utils/deviceUtils';
import { getIsNewUser } from '../store/app/selectors';
import { isBrowserAndApplicationLanguageForLocale } from '../i18n/LanguageUtils';
import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { getPreferredLocale } from '../i18n/LocaleUtils';
export const EXPERIMENT_ID = 'experimentId';
const SEEN_EXPERIMENTS = new Set();
const Experiments = new Map([[ExperimentKeys.COAC0002, {
  shouldEnroll: () => {
    const isEnrolledFlow = isFlow([Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service]);
    const isMobile = getIsMobile();
    const intentKey = getIntentKey();
    return isEnrolledFlow && !isMobile && !intentKey;
  }
}], [ExperimentKeys.ACQ0154, {
  shouldEnroll: state => {
    const isEnrolledFlow = isFlow([Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service]);
    const isNewUser = getIsNewUser(state);
    return isEnrolledFlow && isNewUser;
  }
}], [ExperimentKeys.ACQ0157, {
  shouldEnroll: () => false
}], [ExperimentKeys.GOSU0006, {
  shouldEnroll: state => {
    const isEnrolledFlow = isFlow([Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service]);
    const isMobile = getIsMobile();
    const intentKey = getIntentKey();
    const isNewUser = getIsNewUser(state);
    return isEnrolledFlow && isNewUser && !isMobile && !intentKey;
  }
}], [ExperimentKeys.ACQ0161, {
  shouldEnroll: state => {
    const isEnrolledFlow = isFlow([Flow.Crm, Flow.Marketing, Flow.Sales, Flow.Service]);
    const isNewUser = getIsNewUser(state);
    const isJapaneseLanguage = isBrowserAndApplicationLanguageForLocale(Locale.ja) || getPreferredLocale() === Locale.ja;
    return isEnrolledFlow && isNewUser && isJapaneseLanguage;
  }
}]]);
export const getExperiment = id => {
  // we want to prefer this solution over mocking getExperiment so that the
  // seen experiments tracking below continues to work and doesn't get
  // accidentally mocked
  if (id === TEST_KEY) {
    return {
      shouldEnroll: () => enrollOnFlow([Flow.Crm])
    };
  }
  SEEN_EXPERIMENTS.add(id);
  return Experiments.get(id);
};
export function getSeenExperimentsForTesting() {
  return Array.from(SEEN_EXPERIMENTS);
}