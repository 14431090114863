export let UseCase;
(function (UseCase) {
  UseCase["GenerateLeads"] = "generate-leads";
  UseCase["BuildWebsite"] = "build-websites-and-landing-pages";
  UseCase["SalesPipeline"] = "grow-and-manage-sales-pipeline";
  UseCase["MarketingContent"] = "create-marketing-content";
  UseCase["CustomerSupport"] = "scale-customer-support";
  UseCase["AutomateMarketing"] = "automate-marketing";
  UseCase["Payments"] = "billing-and-collecting-payments";
  UseCase["StillExploring"] = "still-exploring";
  UseCase["OrganizeAndTrackContacts"] = "organize-and-track-contacts";
})(UseCase || (UseCase = {}));