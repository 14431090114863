import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { facebookRedirect } from './productLinks';
import { getHublet, getIsNewPortalCreated } from '../store/app/selectors';
import { getIntentLink } from '../intents/intentSelectors';
export const getFacebookCrmRedirect = state => {
  const hublet = getHublet(state);
  const intentLink = getIntentLink(state);
  const isNewPortal = getIsNewPortalCreated(state);
  if (intentLink) {
    return makeLinkWithQueryParams({
      link: intentLink,
      hublet
    });
  }
  return facebookRedirect({
    isNewPortal,
    hublet
  });
};