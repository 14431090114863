import apiClient from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { getHublet } from '../store/app/selectors';
import { getQueryParam } from '../routing/getQueryParam';
export const generateInvoiceAssets = state => {
  const externalInvoiceId = getQueryParam('external_invoice_id');
  if (!externalInvoiceId) {
    return Promise.reject(new Error('external_invoice_id query parameter is required'));
  }
  const hublet = getHublet(state);
  const requestUrl = `${getFullUrl('app-api', {
    hubletOverride: hublet
  })}/integrations-ai/v1/invoice-generator-signup-bridge/bridge/${externalInvoiceId}`;
  return apiClient.post(requestUrl);
};