import { Locale } from 'signup-ui-lego-core/constants/Locale';
import imageUrls from './imageUrls';
import { ImageKey } from './ImageKey';
export const InternationalizedImages = {
  [ImageKey.Wordpress]: {
    [Locale.en]: imageUrls.wordpressAuthorizationConnect
  },
  [ImageKey.ShopifyEmbedded]: {
    [Locale.en]: imageUrls.shopifyEmbeddedAuthorizationConnect
  }
};
export const getInternationalizedImage = (key, locale) => {
  if (key && InternationalizedImages[key][locale]) {
    return InternationalizedImages[key][locale];
  }
  return InternationalizedImages[key][Locale.en];
};