import { parse } from 'hub-http/helpers/params';
import { getRouteParams } from '../routing/getRouteParams';
import { getLoginSuccessStatus } from '../store/auth/selectors';
export const LOGIN_CODE_2FA_STATUS = 'SUSPICIOUS_USER_MUST_CONFIRM';
export const getGatesWithUrlOverrides = gates => {
  const {
    gates: overrideGates
  } = parse(getRouteParams().search);
  if (!overrideGates) {
    return gates;
  }
  if (!Array.isArray(overrideGates)) {
    return [...gates, overrideGates];
  }
  return [...gates, ...overrideGates];
};
export const getLoginStatusFromResponse = response => {
  let status;
  if (Array.isArray(response) && Object.keys(response[0]).includes('status')) {
    status = response[0].status;
  }
  return status;
};
export const getEmailFromResponse = response => {
  let email = null;
  if (Array.isArray(response) && Object.keys(response[0]).includes('email') && typeof response[0].email === 'string') {
    email = response[0].email;
  }
  return email;
};
export const checkLoginStatusIsSuspiciousUser = state => {
  const status = getLoginSuccessStatus(state);
  return status === LOGIN_CODE_2FA_STATUS;
};