import { UseCasePrioritisation, UseCasePrioritisationCOAC0002 } from '../signup-data/signupDataOptions';
export const getHighestPriorityUseCase = useCases => {
  if (useCases.length === 1) {
    return useCases[0];
  }
  return UseCasePrioritisation.find(useCase => useCases.includes(useCase));
};
export const getHighestPriorityUseCaseCOAC0002 = useCases => {
  if (useCases.length === 1) {
    return useCases[0];
  }
  return UseCasePrioritisationCOAC0002.find(useCase => useCases.includes(useCase));
};