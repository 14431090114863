import * as Colors from 'HubStyleTokens/colors';
export let ColorThemeOption;
(function (ColorThemeOption) {
  ColorThemeOption["OrangeLight"] = "ORANGE_LIGHT";
  ColorThemeOption["OrangeMedium"] = "ORANGE_MEDIUM";
  ColorThemeOption["OrangeDark"] = "ORANGE_DARK";
  ColorThemeOption["YellowLight"] = "YELLOW_LIGHT";
  ColorThemeOption["YellowDark"] = "YELLOW_DARK";
  ColorThemeOption["BlueLight"] = "BLUE_LIGHT";
  ColorThemeOption["BlueDark"] = "BLUE_DARK";
  ColorThemeOption["GreenLight"] = "GREEN_LIGHT";
  ColorThemeOption["GreenDark"] = "GREEN_DARK";
  ColorThemeOption["White"] = "WHITE";
  ColorThemeOption["Grey"] = "GREY";
  ColorThemeOption["Marigold"] = "MARIGOLD";
  ColorThemeOption["Pink"] = "PINK";
})(ColorThemeOption || (ColorThemeOption = {}));
export const defaultColorOptions = {
  innerBlobColor: Colors.MARIGOLD_MEDIUM,
  outerBlobColor: Colors.MARIGOLD_LIGHT,
  smallDecorativeBlobColor: Colors.MARIGOLD
};
export const ColorTheme = new Map([[ColorThemeOption.OrangeLight, {
  innerBlobColor: Colors.SORBET_MEDIUM,
  outerBlobColor: Colors.SORBET_LIGHT,
  smallDecorativeBlobColor: Colors.SORBET
}], [ColorThemeOption.OrangeMedium, {
  innerBlobColor: Colors.SORBET,
  outerBlobColor: Colors.SORBET_LIGHT
}], [ColorThemeOption.OrangeDark, {
  innerBlobColor: Colors.SORBET,
  outerBlobColor: Colors.SORBET_MEDIUM
}], [ColorThemeOption.YellowLight, {
  innerBlobColor: Colors.MARIGOLD_MEDIUM,
  outerBlobColor: Colors.MARIGOLD_LIGHT,
  smallDecorativeBlobColor: Colors.MARIGOLD
}], [ColorThemeOption.YellowLight, {
  innerBlobColor: Colors.MARIGOLD_MEDIUM,
  outerBlobColor: Colors.MARIGOLD_LIGHT,
  smallDecorativeBlobColor: Colors.MARIGOLD
}], [ColorThemeOption.YellowDark, {
  innerBlobColor: Colors.MARIGOLD,
  outerBlobColor: Colors.MARIGOLD_MEDIUM
}], [ColorThemeOption.BlueLight, {
  innerBlobColor: Colors.CALYPSO_MEDIUM,
  outerBlobColor: Colors.CALYPSO_LIGHT,
  smallDecorativeBlobColor: Colors.CALYPSO
}], [ColorThemeOption.BlueDark, {
  innerBlobColor: Colors.CALYPSO,
  outerBlobColor: Colors.CALYPSO_LIGHT
}], [ColorThemeOption.GreenLight, {
  innerBlobColor: Colors.OZ_MEDIUM,
  outerBlobColor: Colors.OZ_LIGHT
}], [ColorThemeOption.GreenDark, {
  innerBlobColor: Colors.OZ,
  outerBlobColor: Colors.OZ_LIGHT
}], [ColorThemeOption.White, {
  innerBlobColor: Colors.OLAF,
  outerBlobColor: Colors.MARIGOLD_LIGHT
}], [ColorThemeOption.Grey, {
  innerBlobColor: Colors.KOALA,
  outerBlobColor: Colors.GYPSUM
}], [ColorThemeOption.Marigold, {
  innerBlobColor: '#FFFEFD',
  outerBlobColor: Colors.MARIGOLD_LIGHT
}], [ColorThemeOption.Pink, {
  innerBlobColor: Colors.CANDY_APPLE_MEDIUM,
  outerBlobColor: Colors.CANDY_APPLE_LIGHT
}]]);