import { getFlowConfig } from '../flow-initial/getFlowConfig';
import Logger from '../lib/Logger';
import { trackConvertGoal } from './trackConvertGoal';
const LOG = Logger.getLogger('getConvertTrackMethod');
const trackOrLog = (convertGoal, goalType) => {
  try {
    // Here we try to call the convert tracking and if an error is thrown we will log
    // into Sentry explaining what was the problem, this is to prevent the error bubbling
    // into the React all and causing a crash.
    trackConvertGoal(convertGoal);
  } catch (error) {
    if (error instanceof Error) {
      LOG.error(`The convert tracking method for goal type ${goalType} throwed an error.
            Error: ${error}`, {
        extra: {
          stack: error.stack
        }
      });
    }
  }
};
export const getConvertTrackMethod = goalType => {
  return () => {
    const convertGoalRecord = getFlowConfig().convertGoal;
    const convertGoal = convertGoalRecord && convertGoalRecord[goalType];
    if (convertGoal) {
      trackOrLog(convertGoal, goalType);
    }
  };
};