import { CompanySizeOption } from './CompanySizeOption';
import { CompanySizeNumber } from './CompanySizeNumber';
export const CompanySizeAttributes = {
  [CompanySizeOption.One]: {
    companySizeNumber: CompanySizeNumber.One,
    companySizeLabel: '1',
    companySizeRange: {
      min: 1,
      max: 1
    }
  },
  [CompanySizeOption.TwoToFive]: {
    companySizeNumber: CompanySizeNumber.TwoToFive,
    companySizeLabel: '2 to 5',
    companySizeRange: {
      min: 2,
      max: 5
    }
  },
  [CompanySizeOption.SixToTen]: {
    companySizeNumber: CompanySizeNumber.SixToTen,
    companySizeLabel: '6 to 10',
    companySizeRange: {
      min: 6,
      max: 10
    }
  },
  [CompanySizeOption.ElevenToTwentyFive]: {
    companySizeNumber: CompanySizeNumber.ElevenToTwentyFive,
    companySizeLabel: '11 to 25',
    companySizeRange: {
      min: 11,
      max: 25
    }
  },
  [CompanySizeOption.TwentySixToFifty]: {
    companySizeNumber: CompanySizeNumber.TwentySixToFifty,
    companySizeLabel: '26 to 50',
    companySizeRange: {
      min: 26,
      max: 50
    }
  },
  [CompanySizeOption.FiftyOneToTwoHundred]: {
    companySizeNumber: CompanySizeNumber.FiftyOneToTwoHundred,
    companySizeLabel: '51 to 200',
    companySizeRange: {
      min: 51,
      max: 200
    }
  },
  [CompanySizeOption.TwoHundredOneToOneThousand]: {
    companySizeNumber: CompanySizeNumber.TwoHundredOneToOneThousand,
    companySizeLabel: '201 to 1,000',
    companySizeRange: {
      min: 201,
      max: 1000
    }
  },
  [CompanySizeOption.OneThousandOnetoTenThousand]: {
    companySizeNumber: CompanySizeNumber.OneThousandOnetoTenThousand,
    companySizeLabel: '1,001 to 10,000',
    companySizeRange: {
      min: 1001,
      max: 10000
    }
  },
  [CompanySizeOption.TenThousandOneOrMore]: {
    companySizeNumber: CompanySizeNumber.TenThousandOneOrMore,
    companySizeLabel: '10,001 or more',
    companySizeRange: {
      min: 10001,
      max: Infinity
    }
  }
};