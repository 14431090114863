import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getActiveError } from '../store/error/selectors';
import { getCurrentStep } from '../store/flow/selectors';
import { getHistory } from './history';
export function useSyncStepToUrl() {
  const currentStep = useSelector(state => getCurrentStep(state));
  const activeError = useSelector(state => getActiveError(state));
  useEffect(() => {
    let stepValue = currentStep || activeError;
    if (stepValue) {
      stepValue = stepValue.toLowerCase();
      const searchParams = new URLSearchParams(getHistory().location.search);
      if (searchParams.get('step') !== stepValue) {
        searchParams.set('step', stepValue);
        getHistory().replace(`${getHistory().location.pathname}?${searchParams}`);
      }
    }
  }, [activeError, currentStep]);
}