import { Data } from 'signup-constants/signupData/Data';
import { getSentryExtra } from '../error/getSentryExtra';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import Logger from '../lib/Logger';
import { getQueryParam } from '../routing/getQueryParam';
import { OPTIONAL_DOMAIN_QUERY_PARAM } from '../step-company-domain/useCompanyDomain';
import { bulkUpdate } from '../store/signup-data/action-creators';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Tracker } from '../store/tracking/action-creators';
import { storePredictedTypeaheadValues } from '../store/typeahead/action-creators';
import { arrayContainsAll } from '../utils/data-structure-utils/arrayContainsAll';
import { getDomainFromEmail } from '../utils/getDomainFromEmail';
import { isTestingOrInternalEmailDomain } from '../utils/isTestingOrInternalEmailDomain';
import { getCompanySizeOptionFromEmployeesRange } from './getCompanySizeFromEmployeesRange';
import { SignupDataActionTypes } from '../store/signup-data/action-types';
import { setIsFreeEmailDomain } from '../store/app/action-creators';
import { getCurrentStep } from '../store/flow/selectors';
import { Step } from 'signup-constants/Step';
import { PrefillType } from '../prefilling/prefillSignupData';
import { storePredictedCompanySize } from '../store/predictions/action-creators';
const LOG = Logger.getLogger('handleCompanyInfo');
const handleCompanySizeUpdate = (employeesRange, dispatch) => {
  const companySizeOption = getCompanySizeOptionFromEmployeesRange(employeesRange);
  if (!companySizeOption) {
    dispatch(Tracker.signupInteraction('company-size-prefill-empty'));
    return;
  }
  dispatch(Tracker.signupInteraction('company-size-prefill-success', {
    selectedOption: [companySizeOption]
  }));
  dispatch(storePredictedCompanySize(companySizeOption));
};
export const handleCompanyInfoSuccess = ({
  domain,
  name,
  industries = [],
  isFreeEmailDomain,
  employeesRange
}, dispatch, getState) => {
  const industriesArray = industries || [];
  const state = getState();
  dispatch(Tracker.signupInteraction('company-info-prefill-success'));
  const email = getSignupDataValue(state, Data.Email);
  const domainFromEmail = getDomainFromEmail(email);
  const userDomain = getSignupDataValue(state, Data.CompanyDomain);
  const userCompanyName = getSignupDataValue(state, Data.CompanyName);
  const hasOptionalDomainParam = getQueryParam(OPTIONAL_DOMAIN_QUERY_PARAM);
  const currentStep = getCurrentStep(state);
  const shouldPreFillCompanyNameByFlowConfig = getFlowConfig().shouldPreFillCompanyName;
  handleCompanySizeUpdate(employeesRange, dispatch);
  const shouldPreFillCompanyName = !userCompanyName && Boolean(name) && shouldPreFillCompanyNameByFlowConfig && currentStep !== Step.CompanyName;
  if (shouldPreFillCompanyName) {
    dispatch(Tracker.signupInteraction('company-name-prefill-success'));
  }
  let shouldUpdateDomain = !userDomain && Boolean(domain) && currentStep !== Step.CompanyDomain;
  if (isTestingOrInternalEmailDomain(domainFromEmail)) {
    domain = 'hubspot-test.com';
    shouldUpdateDomain = shouldUpdateDomain && !hasOptionalDomainParam;
  }
  if (shouldUpdateDomain) {
    dispatch(Tracker.signupInteraction('company-domain-prefill-success'));
  }
  const predicted = industriesArray.length > 0;
  const industryNames = predicted ? industriesArray.map(industry => industry.name) : [];
  dispatch(Tracker.signupInteraction(predicted ? 'industry-prefill-success' : 'industry-prefill-empty-industries', {
    numberOfDisplayed: industriesArray.length
  }));
  const dataToUpdate = Object.assign({}, shouldUpdateDomain && domain && {
    [Data.CompanyDomain]: domain
  }, shouldPreFillCompanyName && name && {
    [Data.CompanyName]: name
  });
  const prefilledData = [...Object.keys(dataToUpdate), ...[industryNames.length > 0 ? [Data.Industry] : []]];
  if (isFreeEmailDomain) {
    dispatch(setIsFreeEmailDomain());
  }
  dispatch(bulkUpdate(dataToUpdate));
  dispatch(storePredictedTypeaheadValues(industryNames, Data.Industry));
  dispatch({
    type: SignupDataActionTypes.StampPrefilled,
    prefilledKeys: prefilledData,
    prefillType: PrefillType.CompanyInfo
  });
};
export const handleCompanyInfoError = (error, dispatch, getState) => {
  dispatch(Tracker.signupInteraction(`industry-prefill-${error.status}`));
  dispatch(Tracker.signupInteraction(`company-info-prefill-error-${error.status}`));
  const email = getSignupDataValue(getState(), Data.Email);
  const domainFromEmail = getDomainFromEmail(email);

  // Here we only log unknown statuses.
  // 404 - Returned when we don't find any company information for the requested domain
  // 503 - Returned when the Biden API is down or unreachable.
  if (!arrayContainsAll({
    array: [404, 503],
    element: error.status
  })) {
    LOG.error('Company Information Request Failed', {
      extra: Object.assign({}, getSentryExtra(error), {
        domainFromEmail
      })
    });
  }
};