import { ErrorKey } from '../app-active-error/ErrorKey';
import { LOGIN_CODE_2FA_STATUS, getEmailFromResponse, getLoginStatusFromResponse } from '../auth/authUtils';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { OAuthToDataToken, oAuthToLoginFunction } from '../oauth/OAuthMapping';
import { setWhichOAuthLoading } from '../store/app/action-creators';
import { oAuthExistingAuthFailed, setLoginSuccessStatus, setupAuthForOAuthExistingUser, updateAuth } from '../store/auth/action-creators';
import { setError } from '../store/error/action-creators';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Tracker } from '../store/tracking/action-creators';
const LOG = Logger.getLogger('onOAuthExistingUser');
export const onOAuthExistingUser = (state, provider) => dispatch => {
  const oAuthToken = OAuthToDataToken[provider];
  const oAuthLoginFunction = oAuthToLoginFunction[provider];
  dispatch(Tracker.signupInteraction(`${provider}-auth-completed-existing-user`));
  if (!oAuthToken || !oAuthLoginFunction) {
    LOG.error(`Missing oAuth information for ${provider}: ${!oAuthToken && 'missing token'} ${!oAuthLoginFunction && 'missing login function'}`);
    dispatch(oAuthExistingAuthFailed(provider));
    dispatch(setError(ErrorKey.OAuthExistingLoginFailed));
  }
  const token = getSignupDataValue(state, OAuthToDataToken[provider]);
  const oAuthLogin = oAuthToLoginFunction[provider];
  oAuthLogin(token).then((response = {}) => {
    const status = getLoginStatusFromResponse(response);
    const email = getEmailFromResponse(response);
    if (status) {
      dispatch(setLoginSuccessStatus(status));
    }
    dispatch(setupAuthForOAuthExistingUser());
    if (status === LOGIN_CODE_2FA_STATUS && email) {
      dispatch(updateAuth({
        auth: {
          email
        }
      }));
    }
  }).catch((error = {}) => {
    const {
      message
    } = error;
    LOG.warn(`Error verifying ${provider} oauth on login service`, {
      extra: Object.assign({}, getSentryExtra(error), {
        message
      })
    });
    dispatch(oAuthExistingAuthFailed(provider));
    dispatch(setError(ErrorKey.OAuthExistingLoginFailed, error));
  }).finally(() => {
    dispatch(setWhichOAuthLoading(null));
  });
};