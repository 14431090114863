import { getRouteParams } from '../routing/getRouteParams';
import { TrialId, TrialToHubInterestOption } from './trialConstants';
export const TRIAL_ID = 'trialid';
export const parseTrialId = trialId => {
  return trialId && parseInt(trialId, 10);
};
export const getParsedTrialId = () => {
  const {
    search
  } = getRouteParams();
  const queryParamsMap = new URLSearchParams(search.toLowerCase());
  const trialdIdQueryParam = queryParamsMap.get(TRIAL_ID);
  const parsedTrialId = parseTrialId(trialdIdQueryParam);
  return parsedTrialId;
};
export const isValidTrialId = trialId => Object.values(TrialId).includes(trialId);
export const getHubInterestOptionForTrial = () => {
  const trialId = getParsedTrialId();
  if (trialId) {
    return TrialToHubInterestOption.get(trialId);
  }
  return null;
};