import { CRM_SIGNUP, SALES_SIGNUP } from '../constants/signupConstants';
import { gettingStartedLink } from '../utils/urls';
import { getViaRedirectionParam } from '../utils/redirectUtils';
import salesExperiences from './salesExperiences';
import marketingExperiences from './marketingExperiences';
import servicesExperiences from './servicesExperiences';
import trialExperiences from './trialExperiences';
import cmsExperiences from './cmsExperiences';
import setupExperiences from './setupExperiences';
import unknownExperiences from './unknownExperiences';
import { maybeGetSalesReplacementRedirectUrl, shouldNotSkipSetupUi } from './salesIntentLinkData';
export const gettingStartedRedirect = () => ({
  redirectPath: gettingStartedLink,
  redirectParams: {
    enroll: 'true'
  }
});
export const maybeGetReplacementRedirectUrl = (redirectUrl, {
  queryParams,
  signupType
}) => {
  switch (signupType) {
    case SALES_SIGNUP:
      return maybeGetSalesReplacementRedirectUrl({
        redirectUrl,
        queryParams
      });
    default:
      return redirectUrl;
  }
};
export const getDecorationParams = ({
  intentLink,
  signupIntent,
  signupType,
  survey,
  queryParams
}) => {
  return Object.assign({}, survey.crmExperience && {
    crmExperience: survey.crmExperience
  }, signupType && {
    via: getViaRedirectionParam(signupType)
  }, signupType === SALES_SIGNUP && Object.assign({}, shouldNotSkipSetupUi({
    redirectUrl: intentLink,
    queryParams,
    signupIntent
  }) && {
    redirect: intentLink
  }), signupType === CRM_SIGNUP && survey.hubInterest && {
    hubInterest: survey.hubInterest
  });
};
export const onboardingRedirectChecks = [...setupExperiences, ...salesExperiences, ...marketingExperiences, ...servicesExperiences, ...trialExperiences, ...cmsExperiences,
// Unknown experiences should be last as a falback value
...unknownExperiences];