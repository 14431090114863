import { useSelector } from 'react-redux';
import ResendCodeFooter from '../step-code-verification/ResendCodeFooter';
import { getCurrentStep } from '../store/flow/selectors';
import { Step } from 'signup-constants/Step';
export const StepFooters = {
  [Step.CodeVerification]: {
    component: ResendCodeFooter
  }
};
export const useFooter = () => {
  const stepKey = useSelector(getCurrentStep);
  if (!stepKey) {
    return null;
  }
  return StepFooters[stepKey] || null;
};