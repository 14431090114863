import enviro from 'enviro';
import { createContainer } from 'usage-tracker-multi';
import legoEvents from '../../events.yaml';
import { getInitialTrackerProperties } from './getInitialTrackerProperties';
import { UsageTrackingContainerLogger as LOG } from './UsageTrackingContainerLogger';
import { getHistoryForTracking } from './getHistoryForTracking';
import { hasAcceptedCookies } from './cookieUtils';
let container;
export const getPublicTracker = (trackerType = 'anonymous', extraProperties = {}, onBeforeSwitch = () => {}) => {
  if (!container) {
    container = createContainer({
      history: getHistoryForTracking()
    });
  }
  return container.createTracker({
    events: legoEvents,
    trackerType,
    properties: Object.assign({}, getInitialTrackerProperties(trackerType === 'public'), {
      trackerType: 'xhr-request'
    }, extraProperties),
    debug: () => !!enviro.debug('usage-tracker'),
    preserveTrackerProperties: true,
    lastKnownEventProperties: ['screen'],
    isExternalHost: false,
    onBeforeSwitch
  });
};

/**
 * Exported only for testing.
 */
export const getTrackerContainer = () => ({
  TRACKER: {},
  queue: [],
  isAnonymousTrackingEnabled: false,
  identifiableTrackingEnabled: false,
  wasAnonymouslyTracked: false,
  get() {
    return this.TRACKER;
  },
  setupTracking() {
    if (hasAcceptedCookies()) {
      this.switchToIdentifiableTracker();
    } else {
      this.enableAnonTracking();
    }
  },
  enableAnonTracking() {
    if (!this.isAnonymousTrackingEnabled) {
      this.TRACKER = getPublicTracker('anonymous');
    }
    this.isAnonymousTrackingEnabled = true;
    this.identifiableTrackingEnabled = false;
    this.drainQueue();
  },
  switchToIdentifiableTracker(email = '', onBeforeSwitch) {
    if (!this.identifiableTrackingEnabled) {
      this.TRACKER = getPublicTracker('public', email ? {
        email
      } : {}, onBeforeSwitch);
    }
    this.identifiableTrackingEnabled = true;
    this.isAnonymousTrackingEnabled = false;
    this.drainQueue();
  },
  isTrackerEnabled() {
    return this.isAnonymousTrackingEnabled || this.identifiableTrackingEnabled;
  },
  recreateTracking() {
    this.isAnonymousTrackingEnabled = false;
    this.identifiableTrackingEnabled = false;
    this.setupTracking();
  },
  onCookieChange(newValue) {
    if (this.identifiableTrackingEnabled && newValue === false) {
      // switch to anonymous tracking if the user rejects cookies
      this.track('interaction', {
        action: 'cookie-preference-update-switch-tracker'
      });
      this.enableAnonTracking();
    }
  },
  shouldQueue() {
    return !this.isAnonymousTrackingEnabled && !this.identifiableTrackingEnabled;
  },
  getBeaconTracker() {
    return this.get().clone({
      bypassPool: true,
      properties: {
        trackerType: 'beacon'
      }
    });
  },
  getHttpTracker() {
    return this.get();
  },
  queueEvent({
    eventName,
    properties = {},
    sendAsBeacon = false
  }) {
    LOG.queue(eventName, properties, sendAsBeacon);
    this.queue.push({
      eventName,
      properties,
      sendAsBeacon
    });
  },
  drainQueue() {
    if (this.hasQueuedEvents()) {
      LOG.drainQueue(this.queue);
    }
    while (this.queue.length > 0) {
      const eventToSend = this.queue.shift();
      if (eventToSend) {
        const {
          eventName,
          properties,
          sendAsBeacon = false
        } = eventToSend;
        this._track({
          eventName,
          properties,
          sendAsBeacon
        });
      }
    }
  },
  hasQueuedEvents() {
    return this.queue.length;
  },
  track(eventName, properties = {}, sendAsBeacon = false) {
    if (this.shouldQueue()) {
      this.queueEvent({
        eventName,
        properties,
        sendAsBeacon
      });
      return;
    }
    LOG.track(eventName, properties, sendAsBeacon);
    this._track({
      eventName,
      properties,
      sendAsBeacon
    });
  },
  enableNonIdentifiableTracking() {
    this.track('interaction', {
      action: 'anonymous-tracking-enabled'
    });
  },
  setProperties(properties) {
    this.get().setProperties(properties);
  },
  set(trackerInstance) {
    this.TRACKER = trackerInstance;
  },
  resetTracker() {
    this.TRACKER = getPublicTracker();
  },
  /**
   * Internal track function that chooses the right tracker before
   * calling the track method.
   *
   * @param {String} eventName The event name.
   * @param {Object} properties The properties object.
   * @param {boolean} [sendAsBeacon=false]
   */
  _track({
    eventName,
    properties = {},
    sendAsBeacon = false
  }) {
    const tracker = sendAsBeacon ? this.getBeaconTracker() : this.getHttpTracker();
    tracker.track(eventName, properties);
  }
});
const TrackerContainer = getTrackerContainer();
export default TrackerContainer;