import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { normalizeUrl } from '../utils/normalizeUrl';
const LOG = Logger.getLogger('getValidatedCompanyDomain');
export const getValidatedCompanyDomain = signupDataCompanyDomain => {
  let domainUrl;
  if (signupDataCompanyDomain) {
    const normalizedUrl = normalizeUrl(signupDataCompanyDomain);
    try {
      domainUrl = new URL(normalizedUrl);
    } catch (error) {
      LOG.warn('Invalid company domain', {
        extra: Object.assign({}, getSentryExtra(error), {
          signupDataCompanyDomain
        })
      });
    }
  }
  return domainUrl ? domainUrl.hostname : '';
};