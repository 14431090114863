import apiClient from 'hub-http/clients/apiClient';
import { getFullUrl } from 'hubspot-url-utils';
import { getHublet } from '../store/app/selectors';
import { Data } from 'signup-constants/signupData/Data';
import { getCountry, getSignupDataValue } from '../store/signup-data/selectors';
export const provisionPartner = (partnerType, state) => {
  const domain = getSignupDataValue(state, Data.CompanyDomain);
  const hublet = getHublet(state);
  const country = getCountry(state);
  return apiClient.post(`${getFullUrl('app-api', {
    hubletOverride: hublet
  })}/partnerdb/v1/provisioning/touchless`, {
    data: Object.assign({
      partnerType,
      domain
    }, country && {
      countryCode: country
    })
  });
};