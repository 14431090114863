import { compose } from 'redux';
export const toLowerCaseKeys = (obj, deep = false) => Object.keys(obj).reduce((acc, key) => {
  acc[key.toLowerCase()] = deep && typeof obj[key] === 'object' ? toLowerCaseKeys(obj[key]) : obj[key];
  return acc;
}, {});
export const transformParamArraysToValues = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    const curVal = obj[key];
    acc[key] = Array.isArray(curVal) ? curVal[0] : curVal;
    return acc;
  }, {});
};
export const normalizeObject = utmData => {
  const normalizeData = compose(toLowerCaseKeys, transformParamArraysToValues);
  return normalizeData(utmData);
};