import { Data } from 'signup-constants/signupData/Data';
import { isPreVerification } from '../flow-settings/settingsSelectors';
import { AuthActionTypes } from '../store/auth/action-types';
import { FlowActionTypes } from '../store/flow/action-types';
import { OAuthActionTypes } from '../store/oauth/action-types';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Step } from 'signup-constants/Step';
import { getStepConfig } from '../views/getStepConfig';
export const isCompleteEmailStepAction = action => {
  if (action.type === FlowActionTypes.StepCompleted) {
    const currentStep = action.stepKey;
    const stepConfig = getStepConfig(currentStep);
    return action.type === FlowActionTypes.StepCompleted && currentStep !== Step.ExistingUser && stepConfig && stepConfig.data.includes(Data.Email);
  }
  return false;
};
export const hasJustBeenAuthenticated = action => {
  return action.type === AuthActionTypes.InitializeAuth && action.isAuthenticated && 'email' in action && isPreVerification();
};
export const isOAuthExistingUserAction = action => {
  return action.type === AuthActionTypes.ExistingOAuthUser && action.email !== null;
};
export const isOAuthNewUserAction = action => {
  return action.type === OAuthActionTypes.AuthDataStored;
};
export const getHasSubmittedEmail = action => {
  return isCompleteEmailStepAction(action) || hasJustBeenAuthenticated(action) || isOAuthExistingUserAction(action) || isOAuthNewUserAction(action);
};
export const hasNewUserSubmittedEmail = action => {
  return isCompleteEmailStepAction(action) || isOAuthNewUserAction(action);
};
export const getEmailFromAction = action => {
  if (hasJustBeenAuthenticated(action) || isOAuthExistingUserAction(action)) {
    return action.email;
  }
  return null;
};
export const executeCallbackIfEmailSubmitted = (state, action, callback) => {
  const email = getEmailFromAction(action) || getSignupDataValue(state, Data.Email);
  if (getHasSubmittedEmail(action) && email) {
    callback(email);
  }
};