import apiClient from 'hub-http/clients/apiClient';
import { getPortalId } from '../store/auth/selectors';
import { getFullUrl } from 'hubspot-url-utils';
import { getHublet } from '../store/app/selectors';
export const fetchRegisteredLeads = state => {
  return apiClient.get(`${getFullUrl('app-api', {
    hubletOverride: getHublet(state)
  })}/partner-registration/v1/registrations`, {
    portalId: getPortalId(state)
  });
};