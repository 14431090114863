import { Intent } from 'signup-constants/Intent';
import { SignupEmbedderOptions } from 'signup-embedder';
import { getApplicationDomain, getDomainSuffix } from '../constants/apiEnvironments';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import PortalIdContainer from '../portal-id-container/PortalIdContainer';
import { getQueryParam } from '../routing/getQueryParam';
import { getRouteParams } from '../routing/getRouteParams';
import { facebookRedirect } from '../signup-completed-redirection/productLinks';
import { TrialId } from '../trial/trialConstants';
import { parseTrialId } from '../trial/trialUtils';
import { parseScopesFromUrl } from '../utils/oauthUtils';
import { createQueryParamString, makeLinkWithQueryParams } from './linkUtils';
import { Microapp } from 'signup-constants/Microapp';
const marketingFreeLeadFlows = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/lead-flows/${portalId}`;
};
const marketingFreeForms = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/forms/${portalId}`;
};
const marketingFreeEmail = (portalId = PortalIdContainer.get(), hublet) => {
  // enroll parameter is for enrolling in the Getting Started onboarding page.
  return `${getApplicationDomain(hublet)}/email/${portalId}?enroll=true`;
};
const marketplaceTheme = (portalId = PortalIdContainer.get(), hublet) => {
  const themeSlug = getQueryParam('theme');
  const marketplaceUrl = `${getApplicationDomain(hublet)}/ecosystem/${portalId}/marketplace/website`;
  return themeSlug ? `${marketplaceUrl}/${themeSlug}` : `${marketplaceUrl}`;
};
const marketingAds = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/ads/${portalId}/onboarding?adsCampaign=true`;
};
const salesDefault = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/contacts/${portalId}`;
};
const salesO365 = (__portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/office365app/signup-success`;
};
const salesVsto = (__portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/sales-client-iframes/vsto/onboarding/signup`;
};
const crmDeals = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/contacts/${portalId}/deals`;
};
const conversations = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/live-messages-settings/${portalId}/onboarding?channelType=LIVE_CHAT`;
};
const salesTemplates = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/templates/${portalId}`;
};
const salesSequences = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/sequences/${portalId}`;
};
const salesDocuments = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/presentations/${portalId}`;
};
const salesProspects = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/contacts/${portalId}/visits`;
};
const salesMeetings = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/meetings/${portalId}`;
};
const salesPro = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/browse/${portalId}/compare/sales`;
};
const serviceDashboard = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/reports-dashboard/${portalId}/service`;
};
const academyPlaylist = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  const academyUrl = `${getApplicationDomain(hublet)}/academy/${portalId}`;
  const playlistId = intentParams['playlist'];
  return playlistId ? `${academyUrl}/saved-items/playlists/${playlistId}` : `${academyUrl}/saved-items/playlists`;
};
const academyVideo = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  const academyUrl = `${getApplicationDomain(hublet)}/academy/${portalId}`;
  const videoId = intentParams['video'];
  return videoId ? `${academyUrl}/shortvideo/${videoId}` : `${academyUrl}/results?contentType=SHORT_FORM_CONTENT`;
};
const learningCenter = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  const trackId = intentParams['track'];
  const lessonId = intentParams['lesson'];
  const contentId = intentParams['content'];
  const academyUrl = `${getApplicationDomain(hublet)}/academy/${portalId}`;
  if (trackId && lessonId && contentId) {
    return `${academyUrl}/tracks/${trackId}/${lessonId}/${contentId}`;
  } //trackId && lessonId only lead to 404
  else if (lessonId && contentId) {
    return `${academyUrl}/lessons/${lessonId}/${contentId}`;
  } else if (trackId) {
    return `${academyUrl}/tracks/${trackId}`;
  } else if (lessonId) {
    return `${academyUrl}/lessons/${lessonId}`;
  }
  return `${academyUrl}`;
};
const leadAds = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/ads/${portalId}`;
};
const fbCrm = (portalId = PortalIdContainer.get(), hublet) => {
  return facebookRedirect({
    portalId,
    hublet
  });
};
const landingpages = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/active-theme-create/${portalId}/create-page/landing-page/select-theme`;
};
const companygrader = (__portalId = PortalIdContainer.get(), __hublet, intentParams = {}) => {
  const userId = intentParams['userId'];
  return `https://network.hubspot.com/company-grader?hubspot_user_id=${userId}`;
};
const connectIntegration = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  const {
    integrationKey
  } = getRouteParams();
  const validOptionalIntegrationKeys = ['connectState'];
  const filteredQueryObject = Object.fromEntries(Object.entries(intentParams).filter(([key]) => validOptionalIntegrationKeys.includes(key)));
  const queryString = createQueryParamString(filteredQueryObject);
  return `${getApplicationDomain(hublet)}/integrations-beta/${portalId}/all-integrations?connect=${integrationKey}&${queryString}`;
};
export const trial = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  let trialId;
  const baseUrl = `${getApplicationDomain(hublet)}/trial/${portalId}`;
  if (Object.keys(intentParams).length === 0) {
    return baseUrl;
  }
  if (intentParams['trialId']) trialId = parseTrialId(intentParams['trialId']);

  // Set special redirect after Trial Staging UI for CMS Hub trials
  if (!intentParams['redirectTarget'] && (trialId === TrialId.CmsEnterprise || trialId === TrialId.CmsProfessional)) {
    intentParams.redirectTarget = `${getApplicationDomain(hublet)}/website/${portalId}/splash`;
  }
  return `${baseUrl}?${createQueryParamString(intentParams)}&via=trial-signup`;
};
export const purchase = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  const VIA_PARAM = getFlowConfig().viaParam;
  const dtbId = intentParams['dtbId'];
  const bundledOffer = intentParams['bundledOffer'];
  const purchaseUrl = `${getApplicationDomain(hublet)}/purchase`;

  // eslint-disable-next-line no-nested-ternary
  const link = dtbId ? `${purchaseUrl}/${portalId}/dynamic/${dtbId}` : bundledOffer ? `${purchaseUrl}/${portalId}/bundled-offer/${bundledOffer}` : `${purchaseUrl}/${portalId}`;
  const queryParamsToInclude = {
    via: VIA_PARAM,
    merchandise: intentParams['merchandise'],
    term: intentParams['term'],
    currency: intentParams['currency'],
    classroomEventId: intentParams['classroomEventId'],
    bundledOffer: intentParams['bundledOffer'],
    utm_source: intentParams['utm_source'],
    utm_campaign: intentParams['utm_campaign'],
    utm_medium: intentParams['utm_medium'],
    utm_term: intentParams['utm_term'],
    utm_content: intentParams['utm_content'],
    dtb_source: intentParams['dtb_source'],
    dtb_source_version: intentParams['dtb_source_version']
  };
  return makeLinkWithQueryParams({
    link,
    queryParamsToInclude,
    hublet
  });
};
const slides = () => {
  return `https://network.hubspot${getDomainSuffix()}.com/api/auth/login?redirect_to=/slides`;
};
const gmail = () => {
  return 'https://mail.google.com/mail/';
};
const getCommunityRedirect = () => {
  const baseCommunityURL = 'https://community.hubspot.com/';
  return baseCommunityURL;
};
const getCommunityStagingRedirect = () => {
  const baseCommunityURL = 'https://community-stage.hubspot.com/';
  return baseCommunityURL;
};
const oauthAuthorization = (portalId = PortalIdContainer.get(), hublet) => {
  const clientId = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_ID);
  const scopesFromUrl = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_OAUTH_SCOPES);
  const scopes = scopesFromUrl && parseScopesFromUrl(scopesFromUrl);
  const redirect_uri = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_OAUTH_REDIRECT);
  const state = SignupEmbedderOptions.getOptionValue(SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_STATE);
  const oauthAppPath = 'embedded/authorize';
  const addStateQueryParam = state ? `&state=${state}` : '';
  const queryString = `client_id=${clientId}&scopes=${scopes}&redirect_uri=${redirect_uri}${addStateQueryParam}`;
  const oauthIntentLink = new URL(`${getApplicationDomain(hublet)}/oauth/${portalId}/${oauthAppPath}?${queryString}`);
  return oauthIntentLink.href;
};
const blackAtInboundRedirect = () => {
  return 'https://community.hubspot.com/t5/Black-INBOUND/ct-p/blackatinbound';
};
export const campaignAssistantRedirect = (portalId = PortalIdContainer.get(), hublet, queryParams = {}) => {
  const searchParams = new URLSearchParams({
    via: 'campaign-assistant-signup'
  });
  const assetType = queryParams['marketing_asset_type'];
  const externalExperimentParam = queryParams['external-experiment-parameter'];
  if (assetType) {
    searchParams.append('marketing_asset_type', assetType);
  }
  if (externalExperimentParam) {
    searchParams.append('external-experiment-parameter', externalExperimentParam);
  }
  return `${getApplicationDomain(hublet)}/campaign-assistant/${portalId}/signup-redirect?${searchParams}`;
};
export const walkthrough = (portalId = PortalIdContainer.get(), hublet, queryParams = {}) => {
  const searchParams = new URLSearchParams();
  if (queryParams['start_guide_capture']) {
    searchParams.append('start_guide_capture', queryParams['start_guide_capture']);
  }
  return `${getApplicationDomain(hublet)}/guide-creator/${portalId}/auth/redirect?${searchParams}`;
};
export const blogZeroStateRedirect = (portalId = PortalIdContainer.get(), hublet, queryParams = {}) => {
  const blogTopic = queryParams['blog_topic'];
  const queryString = blogTopic ? `?blog_topic=${blogTopic}` : '';
  return `${getApplicationDomain(hublet)}/website/${portalId}/blog/posts${queryString}`;
};
export const blogIdeasGeneratorMicroappRedirect = (portalId = PortalIdContainer.get(), hublet, queryParams = {}) => {
  const blogTopic = queryParams['blog_topic'];
  const queryString = new URLSearchParams({
    via: 'blog-ideas-generator-signup'
  });
  if (blogTopic) {
    queryString.set('blog_topic', blogTopic);
  }
  return `${getApplicationDomain(hublet)}/blog-ideas-generator/${portalId}?${queryString}`;
};
export const clipCreatorRedirect = (portalId = PortalIdContainer.get(), hublet) => {
  return `${getApplicationDomain(hublet)}/clip-creator/${portalId}/onboarding?getting-started=true&via=clip-creator-signup`;
};
const aiOnboardingFlowRedirect = (portalId = PortalIdContainer.get(), hublet) => `${getApplicationDomain(hublet)}/cms-onboarding/${portalId}/ai-website-builder/flow`;
export const landingPageCreatorRedirect = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  const urlSearchParams = new URLSearchParams();
  const landingPageUUID = intentParams['landing_page_uuid'];
  if (landingPageUUID) {
    urlSearchParams.append('landing_page_uuid', landingPageUUID);
  }
  return `${getApplicationDomain(hublet)}/campaign-assistant/${portalId}/landing-page-creator?${urlSearchParams}`;
};
export const invoiceGeneratorRedirect = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  const invoiceId = intentParams.invoiceId;
  const invoiceEditUrl = invoiceId ? `/invoice/edit/${invoiceId}` : '';
  return `${getApplicationDomain(hublet)}/invoices/${portalId}${invoiceEditUrl}?via=${Microapp.InvoiceGenerator}-microapp`;
};
const livechatBuilder = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  return `${getApplicationDomain(hublet)}/livechat-unboxing/${portalId}/create?chatState=${intentParams.chatState}&dayMode=${intentParams.dayMode}`;
};
const meetingSchedulerUnboxing = (portalId = PortalIdContainer.get(), hublet, intentParams = {}) => {
  return `${getApplicationDomain(hublet)}/meetings-scheduler-unboxing/${portalId}/create?meetingState=${intentParams.meetingState}`;
};
export const IntentLinks = new Map([[Intent.academyLesson, learningCenter], [Intent.academyPlaylist, academyPlaylist], [Intent.academyLesson, learningCenter], [Intent.academyVideo, academyVideo], [Intent.community, getCommunityRedirect], [Intent.communityStaging, getCommunityStagingRedirect], [Intent.companygrader, companygrader], [Intent.connectIntegration, connectIntegration], [Intent.conversations, conversations], [Intent.crmDeals, crmDeals], [Intent.hubspotnetwork, companygrader], [Intent.landingpages, landingpages], [Intent.leadAds, leadAds], [Intent.learningCenter, learningCenter], [Intent.livechatBuilder, livechatBuilder], [Intent.marketingAds, marketingAds], [Intent.marketingFreeLeadFlows, marketingFreeLeadFlows], [Intent.marketingFreeForms, marketingFreeForms], [Intent.marketingFreeEmail, marketingFreeEmail], [Intent.marketplaceTheme, marketplaceTheme], [Intent.meetingSchedulerUnboxing, meetingSchedulerUnboxing], [Intent.purchase, purchase], [Intent.salesCalling, salesDefault], [Intent.salesContactProfile, salesDefault], [Intent.salesDocuments, salesDocuments], [Intent.salesEmailScheduling, salesDefault], [Intent.salesEmailTracking, salesDefault], [Intent.salesFree, salesDefault], [Intent.salesExtension, gmail], [Intent.salesMeetings, salesMeetings], [Intent.salesOutlook, salesDefault], [Intent.salesO365, salesO365], [Intent.salesPro, salesPro], [Intent.salesProspects, salesProspects], [Intent.salesSequences, salesSequences], [Intent.salesTemplates, salesTemplates], [Intent.salesVsto, salesVsto], [Intent.service, serviceDashboard], [Intent.slides, slides], [Intent.trial, trial], [Intent.oauthAuthorization, oauthAuthorization], [Intent.fbCrm, fbCrm], [Intent.blackAtInbound, blackAtInboundRedirect], [Intent.walkthrough, walkthrough], [Intent.aiWebsiteBuilder, aiOnboardingFlowRedirect], [Intent.blog, blogZeroStateRedirect]]);