import { EndpointKey } from '../../http/EndpointKey';
import { Data } from 'signup-constants/signupData/Data';
import { getHttpResponse } from '../http-responses/selectors';
export const getGuessedDataBiden = dataKey => state => {
  const response = getHttpResponse(state, EndpointKey.CompanyInfo);
  if (response) {
    switch (dataKey) {
      case Data.CompanyName:
        return response.name;
      case Data.CompanyDomain:
        return response.domain;
      case Data.Industry:
        return response.industries;
      default:
        break;
    }
  }
  return null;
};
export const getGuessedDataVisitorContext = dataKey => state => {
  const response = getHttpResponse(state, EndpointKey.VisitorContextFetch);
  if (response) {
    switch (dataKey) {
      case Data.CompanyName:
        return response.companyName;
      case Data.CompanyDomain:
        return response.companyDomain;
      case Data.FirstName:
        return response.firstName;
      case Data.LastName:
        return response.lastName;
      case Data.Email:
        return response.email;
      default:
        break;
    }
  }
  return null;
};