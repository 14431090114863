import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { ClearLoadingMiddleware } from '../app-active-loading/ClearLoadingMiddleware';
import { LoadingBlankScreenMiddleware } from '../app-active-loading/LoadingBlankScreenMiddleware';
import { SignupEmbeddedPostmanMiddleware } from '../embedded/SignupEmbeddedPostmanMiddleware';
import { ExperimentsTrackingMiddleware } from '../experiments/ExperimentsTrackingMiddleware';
import { InitialFlowMiddleware } from '../flow-initial/InitialFlowMiddleware';
import { StepperMiddleware } from '../flow-management/StepperMiddleware';
import { HttpRequesterMiddleware } from '../http/HttpRequesterMiddleware';
import { HttpResponsesHandlerMiddleware } from '../http/HttpResponsesHandlerMiddleware';
import { SignupInterestFormMiddleware } from '../hubspot-forms/SignupInterestFormMiddleware';
import { OAuthMiddleware } from '../oauth/OAuthMiddleware';
import { PrefillingMiddleware } from '../prefilling/PrefillingMiddleware';
import { RecaptchaMiddleware } from '../recaptcha/RecaptchaMiddleware';
import { FallbackRedirectMiddleware } from '../signup-completed-redirection/FallbackRedirectMiddleware';
import { RedirectionMiddleware } from '../signup-completed-redirection/RedirectionMiddleware';
import { ValidationMiddleware } from '../signup-data-validation/ValidationMiddleware';
import { ExistingUserMiddleware } from '../step-existing-user/ExistingUserMiddleware';
import { rootReducer } from '../store';
import FirstEngagementTrackingMiddleware from '../tracker-middlewares/FirstEngagementTrackingMiddleware';
import { TrackingAppMiddleware } from '../tracking/TrackingAppMiddleware';
import { UsageTrackingMiddleware } from '../tracking/UsageTrackingMiddleware';
import { VisitorContextMiddleware } from '../visitor-context-fetch/VisitorContextMiddleware';
import { isQA } from '../constants/apiEnvironments';
const composeEnhancers = isQA ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
export default function configureStore(extraMiddlewares = [], preloadedState) {
  return createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(thunk, ...extraMiddlewares, PrefillingMiddleware, ExperimentsTrackingMiddleware, InitialFlowMiddleware, ExistingUserMiddleware, VisitorContextMiddleware, SignupInterestFormMiddleware, TrackingAppMiddleware, StepperMiddleware, RecaptchaMiddleware, ValidationMiddleware, HttpRequesterMiddleware, HttpResponsesHandlerMiddleware, OAuthMiddleware, UsageTrackingMiddleware, LoadingBlankScreenMiddleware, RedirectionMiddleware, FirstEngagementTrackingMiddleware, FallbackRedirectMiddleware, SignupEmbeddedPostmanMiddleware, ClearLoadingMiddleware)));
}