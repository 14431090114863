import { AuthActionTypes } from './action-types';
export const initialAuthState = {
  initialized: false,
  authenticated: false,
  auth: {
    email: ''
  },
  user: null,
  gates: [],
  portal: {},
  portalId: null,
  userId: null,
  loginSuccessStatus: ''
};
const auth = (state = initialAuthState, action) => {
  switch (action.type) {
    case AuthActionTypes.SetPortalId:
      return Object.assign({}, state, {
        portalId: action.portalId
      });
    case AuthActionTypes.SetUserId:
      return Object.assign({}, state, {
        userId: action.userId
      });
    case AuthActionTypes.UpdateAuth:
      return Object.assign({}, state, action.data);
    case AuthActionTypes.InitializeAuth:
      return Object.assign({}, state, {
        initialized: true,
        authenticated: action.isAuthenticated
      });
    case AuthActionTypes.SetLoginSuccessStatus:
      return Object.assign({}, state, {
        loginSuccessStatus: action.loginSuccessStatus
      });
    default:
      return state;
  }
};
export default auth;