import { getFlowConfig } from '../flow-initial/getFlowConfig';
import Logger from '../lib/Logger';
const LOG = Logger.getLogger('trackGoogleAnalyticsPageView');
export const trackGoogleAnalyticsPageView = () => {
  const appName = getFlowConfig().appName;
  try {
    const pageView = {
      event: 'VirtualPageview',
      virtualPageURL: `/signup/completed/${appName}`,
      virtualPageTitle: `${appName} Signup Completed`
    };
    const w = window;
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push(pageView);
  } catch (error) {
    LOG.warn('Unable to send page google page view push', {
      extra: {
        error
      }
    });
  }
};