import I18n from 'I18n';
import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider';
import registerBasicData from 'I18n/init/register/sync/registerBasicData';
import lang from 'i2l?query=sporks!../../lang/en.lyaml';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { getPreferredLocale } from './LocaleUtils';
const LOG = Logger.getLogger('setupI18n');
export const setupI18n = () => {
  const locale = getPreferredLocale();
  const I18nPublicProvider = createPublicI18nProvider({
    manuallySetLocale: true
  });
  I18nPublicProvider.setLocale({
    locale,
    langEnabled: true
  });
  return Promise.all([I18nPublicProvider.register(lang), registerBasicData(I18nPublicProvider)]).then(() => {
    document.title = I18n.text('pageTitle.default');
  }).catch(reason => {
    LOG.error('Error occurred while setting up I18n.', {
      extra: getSentryExtra(reason)
    });

    // This is a blocking setup method so we should throw an error
    throw new Error('Error occurred while setting up I18n.');
  });
};