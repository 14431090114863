import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveError } from '../store/error/selectors';
import { Tracker } from '../store/tracking/action-creators';
import { useCurrentStep } from './useCurrentStep';
export const useTrackStepView = () => {
  const {
    currentStep
  } = useCurrentStep();
  const activeError = useSelector(getActiveError);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentStep || activeError) {
      const stepName = activeError || currentStep;
      dispatch(Tracker.stepView(stepName));
    }
  }, [currentStep, activeError, dispatch]);
};