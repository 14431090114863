export let FlowActionTypes;
(function (FlowActionTypes) {
  FlowActionTypes["Load"] = "[Flow] Load";
  FlowActionTypes["Reload"] = "[Flow] Reload";
  FlowActionTypes["SetFlow"] = "[Flow] Set Flow";
  FlowActionTypes["SetInitialFlow"] = "[Flow] Set Initial Flow";
  FlowActionTypes["StepCompleted"] = "[Flow] Step Completed";
  FlowActionTypes["ReplaceCurrentStep"] = "[Flow] Replace Current Step";
  FlowActionTypes["ClearCompletedSteps"] = "[Flow] Clear Completed Steps";
  FlowActionTypes["GoNextStep"] = "[Flow] Go Next Step";
  FlowActionTypes["GoPrevStep"] = "[Flow] Go Prev Step";
  FlowActionTypes["PostVerificationFlowSet"] = "[Flow] Post Verification Flow Set";
  FlowActionTypes["FinishSignup"] = "[Flow] Finish Signup";
  FlowActionTypes["MobileAppStepCompleted"] = "[Flow] Mobile App Step Completed";
  FlowActionTypes["InsertNextStep"] = "[Flow] Insert Next Step";
  FlowActionTypes["UpdateFlow"] = "[Flow] Update Flow";
  FlowActionTypes["UpdatePreviousFlowStates"] = "[Flow] Update Previous Flow States";
  FlowActionTypes["ClearNextSteps"] = "[Flow] Clear Next Steps";
  FlowActionTypes["SetFlowLoaded"] = "[Flow] Set Flow loaded";
})(FlowActionTypes || (FlowActionTypes = {}));