import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { Flow } from 'signup-constants/Flow';
import { CompanyDomainErrors } from 'signup-ui-lego-core/types';
import { syncCompanyDomainValidator } from 'signup-ui-lego-core/validaton/companyDomainValidator';
import { getFlowKey } from '../../../routing/getFlowKey';
import { getValidatedCompanyDomain } from '../../../signup-data/getValidatedCompanyDomain';
import { getIsUsingOptionalDomain } from '../../app/selectors';
import { isPartnerSignup } from '../../flow/selectors';
import { DomainValidationErrorMessages, PartnerDomainSignupType } from '../../signup-data-validation/types';
import { getCountry } from '../../signup-data/selectors';
import { Tracker } from '../../tracking/action-creators';
import { ValidationError } from '../errors/ValidationError';
import getCompanyDomainEndpoint from '../utils/getCompanyDomainEndpoint';
const sync = (domain, state) => {
  return syncCompanyDomainValidator(domain, getIsUsingOptionalDomain(state));
};
const async = (domain, dispatch, state) => {
  const flowKey = getFlowKey();
  const validatedDomainUrl = getValidatedCompanyDomain(domain);
  const requestBody = {
    domain: validatedDomainUrl,
    useNewErrorMessages: true
  };
  const endpoint = getCompanyDomainEndpoint();
  if (isPartnerSignup()) {
    requestBody.signupType = PartnerDomainSignupType.get(flowKey);
  }
  if (flowKey === Flow.SolutionsProvider) {
    requestBody.countryCode = getCountry(state);
  }
  return noAuthApiClient.post(endpoint, {
    data: requestBody
  }).then(response => {
    // We need this check because the BE sends 200 responses with
    // the error as part of the response. I know. Bleh.
    if ('status' in response) {
      dispatch(Tracker.formError('domain', `${response.status}`));
      // Because this is a situation where the request worked (status 200)
      // but there is an error as part of the response body we need to
      // throw an error to call the catch method below.
      throw new ValidationError(200, response);
    }
    return response;
  }).catch(error => {
    if (error instanceof ValidationError) {
      dispatch(Tracker.invalidDomainCollected(domain));
      let message = error.message;
      if (Object.values(CompanyDomainErrors).includes(error.response.message)) {
        message = error.response.message;
      } else if (Object.values(CompanyDomainErrors).includes(error.response.status)) {
        message = error.response.status;
      }
      dispatch(Tracker.formError('domain', `${message}`));

      // Rethrow using the message, for example, generic, social-media, etc.
      throw error;
    }
    throw new Error(error.message);
  });
};
const getErrorMessage = (_value, error) => {
  // From sync validation
  if (typeof error === 'string') {
    return DomainValidationErrorMessages.get(error);
  } else if (error instanceof ValidationError) {
    if (DomainValidationErrorMessages.has(error.response.message)) {
      return DomainValidationErrorMessages.get(error.response.message);
    }
    if (DomainValidationErrorMessages.has(error.response.status)) {
      return DomainValidationErrorMessages.get(error.response.status);
    }
  }
  return DomainValidationErrorMessages.get(CompanyDomainErrors.generic);
};
export const companyDomainValidator = {
  sync,
  async,
  getErrorMessage
};