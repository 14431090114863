import TrackerContainer from './TrackerContainer';
import { getIsNewUser, getIsExistingUserSession, getIsNewPortalCreated } from '../store/app/selectors';
import { getAuthState, getIsAuthInitialized, getIsAuthenticated } from '../store/auth/selectors';
import { isPostVerification } from '../flow-settings/settingsSelectors';
import { AppActionTypes } from '../store/app/action-types';
const enableTracking = (action, store) => {
  const state = store.getState();
  const isNewUser = getIsNewUser(state);
  const isNewPortal = getIsNewPortalCreated(state);
  const isExistingUserSession = getIsExistingUserSession(state);

  // To enable tracking at the beginning of the flow
  if (getIsAuthInitialized(state) && !getIsAuthenticated(state) && !isNewPortal && !TrackerContainer.isAnonymousTrackingEnabled && !TrackerContainer.identifiableTrackingEnabled) {
    TrackerContainer.setupTracking();
  }

  // To enable identifiable tracking switching to existing user
  if (!isNewUser && isExistingUserSession && !isPostVerification() && TrackerContainer.isAnonymousTrackingEnabled) {
    const authState = getAuthState(state);
    if (authState.auth) {
      TrackerContainer.switchToIdentifiableTracker(authState.auth.email);
      TrackerContainer.setProperties({
        email: authState.auth.email
      });
    }
    TrackerContainer.setProperties({
      userId: authState.userId,
      hubId: authState.portalId
    });
  }

  // only handle cookie changes for new users, existing users are already authenticated
  if (action.type === AppActionTypes.UpdateCookiePreferences && isNewUser) {
    TrackerContainer.onCookieChange(action.newValue);
  }
};

/**
 * @description Middleware to setup tracking
 * - Setup initial tracking when - The user accepts the cookie banner - identifable
 * - Setup initial tracking when - The user accepts rejected/ignored the cookie banner - anonymous
 */
export const TrackingAppMiddleware = store => next => action => {
  enableTracking(action, store);
  return next(action);
};