import { Data } from 'signup-constants/signupData/Data';
export const getTypeaheadState = state => {
  return state.typeahead;
};
export const getPredictedTypeaheadValues = (state, dataKey) => {
  return getTypeaheadState(state)[dataKey].predicted;
};
export const getClickedToSeeAll = (state, dataKey) => {
  return getTypeaheadState(state)[dataKey].clickedToSeeAll;
};
export const getPredictionsFetched = (state, dataKey) => {
  return getTypeaheadState(state)[dataKey].predictionsFetched;
};
export const industryPredictionsSuccessfullyFetched = state => {
  return Boolean(getPredictionsFetched(state, Data.Industry));
};
export const getTypeaheadTrackedUserInput = dataKey => state => {
  return getTypeaheadState(state)[dataKey].trackedUserInput;
};
export const getTypeaheadSelectedValue = dataKey => state => {
  return getTypeaheadState(state)[dataKey].selectedValue;
};
export const getTypeaheadInputValue = dataKey => state => {
  return getTypeaheadState(state)[dataKey].inputValue;
};