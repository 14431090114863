import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsGoogleCredentialAuthentication } from '../google-authentication/google-prompt/useGoogleCredentialForAuthentication';
import { getIsFlowInitialized } from '../store/app/selectors';
import { loadFlow } from '../store/flow/action-creators';
import { useAppDispatch } from './useAppDispatch';
export const useLoadFlow = () => {
  const isFlowInitialized = useSelector(getIsFlowInitialized);
  const isFlowLoadedByGoogleCredentialAuthentication = useSelector(getIsGoogleCredentialAuthentication);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isFlowInitialized && !isFlowLoadedByGoogleCredentialAuthentication) {
      dispatch(loadFlow());
    }
  }, [isFlowInitialized, isFlowLoadedByGoogleCredentialAuthentication, dispatch]);
};