/**
 * Normalize a URL.
 * - If it has a relative protocol, add the defaultProtocol (https:)
 * - If if does not contain a protocol, add the defaultProtocol (https:)
 *
 * @param {string} url The URL to be normalized.
 * @param {Object} options The normalization options.
 * @returns {string} The normalized URL.
 */
export const normalizeUrl = url => {
  const hasRelativeProtocol = url.substring(0, 2) === '//';
  if (hasRelativeProtocol) {
    url = `https:${url}`;
  }
  if (url && url.substring(0, 8) !== 'https://' && url.substring(0, 7) !== 'http://') {
    url = `https://${url}`;
  }
  return url;
};