import { useEffect, useState } from 'react';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import Logger from '../lib/Logger';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('useAppleLoginNonce');
const getAppleNonceFromLoginApi = () => {
  return noAuthApiClient.get('login-api/v1/apple/create-nonce');
};
export const useAppleLoginNonce = () => {
  const [appleNonce, setAppleNonce] = useState();
  useEffect(() => {
    getAppleNonceFromLoginApi().then(({
      nonce
    }) => {
      setAppleNonce(nonce);
    }).catch(e => {
      LOG.warn(`Failed to retrieve nonce parameter for Apple from login`, {
        extra: getSentryExtra(e)
      });
    });
  }, []);
  return {
    appleNonce
  };
};