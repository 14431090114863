import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { getHublet } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { marketPlaceProvidersTemplates } from './productLinks';
export const getAssetProviderRedirect = state => {
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  return makeLinkWithQueryParams({
    link: marketPlaceProvidersTemplates({
      portalId,
      hublet
    }),
    hublet,
    queryParamsToInclude: {
      via: 'asset-provider-signup'
    }
  });
};