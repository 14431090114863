import { getInitialFlow } from '../../flow-initial/getInitialFlow';
import { NextStepManager } from '../../flow-management/NextStepManager';
import { skipPrefilled } from '../../flow-management/skipPrefilled';
import { isPostVerification } from '../../flow-settings/settingsSelectors';
import { getQueryParams } from '../../routing/getQueryParams';
import { getExistingUserStep } from '../../step-existing-user/existingUserUtils';
import { getIsAuthenticated } from '../auth/selectors';
import { Tracker } from '../tracking/action-creators';
import { FlowActionTypes } from './action-types';
export const completeStepAction = ({
  stepKey
}) => dispatch => {
  return dispatch({
    type: FlowActionTypes.StepCompleted,
    stepKey
  });
};
export const replaceCurrentStep = newStep => dispatch => {
  return dispatch({
    type: FlowActionTypes.ReplaceCurrentStep,
    newStep
  });
};
export const insertNextStep = newStep => dispatch => {
  return dispatch({
    type: FlowActionTypes.InsertNextStep,
    newStep
  });
};
export const mobileStepCompleted = () => dispatch => {
  return dispatch({
    type: FlowActionTypes.MobileAppStepCompleted
  });
};
export const finishSignup = () => dispatch => {
  return dispatch({
    type: FlowActionTypes.FinishSignup
  });
};
export const updateFlow = (preVerificationSteps, postVerificationSteps, postAccountCreationSteps, postExistingAccountSelectionSteps) => dispatch => {
  return dispatch({
    type: FlowActionTypes.UpdateFlow,
    preVerificationSteps,
    postVerificationSteps,
    postAccountCreationSteps,
    postExistingAccountSelectionSteps
  });
};
export const setFlow = (nextSteps, preVerificationSteps, postVerificationSteps, postAccountCreationSteps, postExistingAccountSelectionSteps, shouldMoveToNextStep = true) => dispatch => {
  if (shouldMoveToNextStep) {
    return dispatch({
      type: FlowActionTypes.SetFlow,
      nextSteps,
      preVerificationSteps,
      postVerificationSteps,
      postAccountCreationSteps,
      postExistingAccountSelectionSteps,
      shouldMoveToNextStep
    });
  } else {
    return dispatch(updateFlow(preVerificationSteps, postVerificationSteps, postAccountCreationSteps, postExistingAccountSelectionSteps));
  }
};
export const goNextStep = ({
  step,
  nextSteps,
  preVerificationSteps,
  postVerificationSteps,
  postAccountCreationSteps,
  postExistingAccountSelectionSteps
}) => dispatch => {
  return dispatch({
    type: FlowActionTypes.GoNextStep,
    step,
    nextSteps,
    preVerificationSteps,
    postVerificationSteps,
    postAccountCreationSteps,
    postExistingAccountSelectionSteps
  });
};
export const goPrevStep = () => dispatch => {
  return dispatch({
    type: FlowActionTypes.GoPrevStep
  });
};
export const postVerificationFlowSet = () => dispatch => {
  return dispatch({
    type: FlowActionTypes.PostVerificationFlowSet
  });
};
export const setInitialFlow = () => (dispatch, getState) => {
  const state = getState();
  const flow = getInitialFlow();
  let nextSteps = flow.initialFlow;
  if (getIsAuthenticated(state) && !isPostVerification()) {
    nextSteps = NextStepManager.pushNextSteps(getExistingUserStep())(nextSteps);
  }
  nextSteps = skipPrefilled(nextSteps, state);
  return dispatch(setFlow(nextSteps, flow.preVerification, flow.postVerification, flow.postAccountCreation, flow.postExistingAccountSelection));
};
export const clearCompletedSteps = () => dispatch => {
  return dispatch({
    type: FlowActionTypes.ClearCompletedSteps
  });
};
export const loadFlow = () => dispatch => {
  const queryParams = getQueryParams();
  dispatch(Tracker.utmVerification(queryParams));
  return dispatch({
    type: FlowActionTypes.Load
  });
};
export const reloadFlow = () => dispatch => {
  dispatch({
    type: FlowActionTypes.Reload
  });
};
export const clearNextSteps = () => dispatch => {
  dispatch({
    type: FlowActionTypes.ClearNextSteps
  });
};
export const setFlowLoaded = () => dispatch => {
  dispatch({
    type: FlowActionTypes.SetFlowLoaded
  });
};