import { ValidationStatus } from 'signup-ui-lego-core/types';
import { SignupDataActionTypes } from '../signup-data/action-types';
import { isAsyncValidationResultUpToDate } from '../signup-data/selectors';
export const dataValid = ({
  dataKey,
  validatedValue
}) => dispatch => dispatch({
  type: SignupDataActionTypes.DataIsValid,
  dataKey,
  validatedValue
});
export const dataInvalid = ({
  dataKey,
  validatedValue,
  errorMessage,
  error
}) => dispatch => {
  return dispatch({
    type: SignupDataActionTypes.DataIsInvalid,
    dataKey,
    validatedValue,
    errorMessage,
    error
  });
};
export const validateSignupData = ({
  dataKey,
  value,
  validator
}) => (dispatch, getState) => {
  const syncValidationMessage = validator.sync(value, getState(), dispatch);
  if (syncValidationMessage !== ValidationStatus.VALID) {
    const errorMessage = validator.getErrorMessage(value, syncValidationMessage, dataKey);
    return dispatch(dataInvalid({
      dataKey,
      validatedValue: value,
      errorMessage
    }));
  }
  if (validator.async) {
    return validator.async(value, dispatch, getState()).then(() => {
      if (isAsyncValidationResultUpToDate(getState(), dataKey, value)) {
        dispatch(dataValid({
          dataKey,
          validatedValue: value
        }));
      }
    }).catch(error => {
      const errorMessage = validator.getErrorMessage(value, error, dataKey);
      if (isAsyncValidationResultUpToDate(getState(), dataKey, value)) {
        dispatch(dataInvalid({
          dataKey,
          validatedValue: value,
          errorMessage,
          error
        }));
      }
    });
  }
  return dispatch(dataValid({
    dataKey,
    validatedValue: value
  }));
};