import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
// @ts-expect-error Untyped module
import PasswordPattern from 'PatternValidationJS/patterns/Password';
import { ValidationStatus } from 'signup-ui-lego-core/types';
import { stripDiacritics } from 'UIComponents/js/utils/Diacritics';
import { Tracker } from '../store/tracking/action-creators';
import { PasswordBreachedError } from './errors/PasswordBreachedError';
import { PasswordCheckRequestFailed } from './errors/PasswordCheckRequestFailed';
const getErrorMessage = (_value, error) => {
  if (error instanceof PasswordBreachedError) {
    return error.getMessage();
  }

  // Returning an empty string actually just mark the field as not valid
  return '';
};
const async = (password, dispatch) => {
  dispatch(Tracker.signupInteraction('breached-password-check-if-safe-call'));
  const requestBody = {
    password
  };
  return noAuthApiClient.post('/signup/v1/sec/check', {
    data: requestBody
  }).catch(error => {
    dispatch(Tracker.signupInteraction('breached-password-check-if-safe-error'));
    dispatch(Tracker.formError('password-error', `${error.errorCode} - ${error.errorMessage}`));
    throw new PasswordCheckRequestFailed();
  }).then(breached => {
    let interaction = 'breached-password-safe';
    if (breached) {
      interaction = 'breached-password-not-safe';
      throw new PasswordBreachedError();
    }
    dispatch(Tracker.signupInteraction(interaction));
  });
};
export const passwordPatternRules = password => {
  return PasswordPattern.testRules(stripDiacritics(password));
};
const sync = password => {
  if (password && PasswordPattern.test(stripDiacritics(password))) {
    return ValidationStatus.VALID;
  }
  return ValidationStatus.INVALID;
};
export const passwordValidator = {
  sync,
  async,
  getErrorMessage
};