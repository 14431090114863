import { setIsNewUserCreated } from '../../store/app/action-creators';
import { setUserId } from '../../store/auth/action-creators';
import { httpError, httpSuccess } from '../../store/http-responses/action-creators';
import { EndpointKey } from '../EndpointKey';
import { signupSuccessTracking } from '../../http-signup/signupSuccessTracking';
export const handleUserOnlySignupSuccess = ({
  creatorId
}, dispatch, getState) => {
  dispatch(setUserId(creatorId));
  signupSuccessTracking(getState(), creatorId);
  dispatch(setIsNewUserCreated(true));
};
export const handleUserOnlySignupResponse = response => dispatch => {
  const endpointKey = EndpointKey.Signup;
  if ('creatorId' in response) {
    return dispatch(httpSuccess(endpointKey, response));
  }
  return dispatch(httpError(endpointKey, {
    message: 'Signup Error',
    status: response.status,
    data: response.data
  }));
};