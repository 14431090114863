export let VisitorContextTrackingInteractions;
(function (VisitorContextTrackingInteractions) {
  VisitorContextTrackingInteractions["Fetch"] = "visitor-context-fetch";
  VisitorContextTrackingInteractions["Filled"] = "visitor-context-filled";
  VisitorContextTrackingInteractions["NotFilled"] = "visitor-context-not-filled";
  VisitorContextTrackingInteractions["ExistingUser"] = "visitor-context-existing-user";
  VisitorContextTrackingInteractions["Failure"] = "visitor-context-unable-to-fetch-email-validation";
  VisitorContextTrackingInteractions["RateLimited"] = "visitor-context-rate-limited";
  VisitorContextTrackingInteractions["NewUser"] = "visitor-context-new-user";
  VisitorContextTrackingInteractions["NotFound"] = "visitor-context-contact-not-found";
})(VisitorContextTrackingInteractions || (VisitorContextTrackingInteractions = {}));