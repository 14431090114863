import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["intent"];
import { getRouteParams } from '../routing/getRouteParams';
import { trial } from '../intents/intentLinks';
import { getPortalId } from '../store/auth/selectors';
import { getHublet, getIsNewUser } from '../store/app/selectors';
export const getTrialRedirect = state => {
  const {
    queryParams
  } = getRouteParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const remainingQueryParams = _objectWithoutPropertiesLoose(queryParams, _excluded);
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  const isNewUser = getIsNewUser(state);
  if (!isNewUser) {
    remainingQueryParams['existing_user'] = 'true';
  }
  return trial(portalId, hublet, remainingQueryParams);
};