import { Flow } from 'signup-constants/Flow';
import { Integration } from 'signup-constants/Integration';
import { getHistory } from './history';
import { Microapp } from 'signup-constants/Microapp';
export const getRouteParams = () => {
  const pathname = getHistory().location.pathname;
  const flowFromPath = pathname.split('/')[1];
  const flowKey = Object.values(Flow).includes(flowFromPath) ? flowFromPath : Flow.Crm;
  const search = getHistory().location.search.substring(1);
  const queryParamsMap = new URLSearchParams(search);
  const queryParams = {};
  queryParamsMap.forEach((value, key) => {
    queryParams[key] = value;
  });
  const intentKey = queryParamsMap.get('intent') || null;
  const integrationKeyFromQuery = queryParamsMap.get('integration');
  let integrationKey = null;
  if (integrationKeyFromQuery && Object.values(Integration).includes(integrationKeyFromQuery)) {
    integrationKey = integrationKeyFromQuery;
  }
  const microappKeyFromQuery = queryParamsMap.get('microapp');
  let microappKey = null;
  if (microappKeyFromQuery && Object.values(Microapp).includes(microappKeyFromQuery)) {
    microappKey = microappKeyFromQuery;
  }
  return {
    flowKey,
    queryParams,
    queryParamsMap,
    search,
    intentKey,
    integrationKey,
    microappKey
  };
};