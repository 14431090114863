import PortalIdContainer from '../portal-id-container/PortalIdContainer';
import { setPortalId } from '../store/auth/action-creators';
import TrackerContainer from '../tracking/TrackerContainer';
export const setNewPortalId = ({
  hubId,
  hublet
}, dispatch) => {
  PortalIdContainer.set(hubId);
  TrackerContainer.setProperties({
    hubId,
    hublet
  });
  dispatch(setPortalId(hubId));
};