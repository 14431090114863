import { getPropertiesToTrack } from '../tracking/getPropertiesToTrack';
export class StepConfigFactory {
  static getStepConfig(stepKey, data = [], view, {
    hideProgressBar = false,
    hideStepNumber = false,
    hideBackButton = false,
    shouldShowPrivacyBanner = false,
    trackSelectedOption = false,
    isWideStep = false,
    getStepPropertiesToTrack = state => getPropertiesToTrack(state)[stepKey],
    waveType,
    shouldShowWavePostAccountCreation = true,
    showLogo = true,
    showMessagingAboveContent = false,
    alignCentredStepAtTheTop = true,
    stepToButtonColumn,
    showNextButtonIcon = true,
    shouldRestrictWidth = true,
    titleContainer
  } = {}) {
    return {
      hideProgressBar,
      hideStepNumber,
      hideBackButton,
      shouldShowPrivacyBanner,
      trackSelectedOption,
      getStepPropertiesToTrack,
      data,
      stepKey,
      view,
      waveType,
      isWideStep,
      shouldShowWavePostAccountCreation,
      showMessagingAboveContent,
      alignCentredStepAtTheTop,
      stepToButtonColumn,
      showNextButtonIcon,
      showLogo,
      shouldRestrictWidth,
      titleContainer
    };
  }
}