export const OuterBlobPath = 'M825.22,472.79c-74,130.3,5.62,226,81.17,226,86.67,0,262.87-95.65,238-211C1123.15,389.57,877.28,381.12,825.22,472.79Z';
export const InnerBlobPath = 'M758.67,709C673.41,691.81,619.83,451.43,702,403c139.42-82.22,281.37,82.72,286.11,188.39C991.24,661.76,843.94,726.15,758.67,709Z';
export const InnerBlob = {
  viewBox: '0 20 327 360',
  path: InnerBlobPath,
  transform: 'translate(-661.14 -380.79)'
};
export const OuterBlob = {
  viewBox: '0 -30 370 350',
  path: OuterBlobPath,
  transform: 'translate(-795.73 -408.81)'
};