import { isPostVerification } from '../../flow-settings/settingsSelectors';
import { EndpointKey } from '../../http/EndpointKey';
import { getStepData } from '../../step/getStepData';
import { getActiveError } from '../../store/error/selectors';
import { getPostAccountCreationSteps, getPostVerificationSteps, getPreVerificationSteps } from '../../store/flow/selectors';
import { isEndpointStatusSuccess } from '../../store/http-responses/selectors';
import { getSignupData } from '../../store/signup-data/selectors';
import { Tracker } from '../../store/tracking/action-creators';
import { isValidStepKey } from '../../views/getStepConfig';
import { Step } from 'signup-constants/Step';
let fallbackSteps;
const stepsToIgnore = [Step.CodeVerification, Step.Verification];
function stateCheck(state, dispatch) {
  if (!isPostVerification() || getActiveError(state) || !isEndpointStatusSuccess(EndpointKey.CompleteVerification)(state)) {
    return false;
  } else {
    const preVerificationSteps = getPreVerificationSteps(state);
    const stateData = getSignupData(state);
    fallbackSteps = preVerificationSteps.filter(step => !stepsToIgnore.includes(step)).reduce((stepsToAdd, step) => {
      if (isValidStepKey(step)) {
        const stepData = getStepData(step);
        stepData.forEach(dataKey => {
          const dataKeyAsStep = dataKey;
          if ((!stateData[dataKey] || !stateData[dataKey].value) && !stateData[dataKey].skipped && !stateData[dataKey].completed) {
            if (Object.values(Step).includes(dataKeyAsStep)) {
              stepsToAdd.push(dataKeyAsStep);
            }
          }
        });
      }
      return stepsToAdd;
    }, []);
    const shouldTrigger = fallbackSteps.length > 0;
    if (shouldTrigger) {
      dispatch(Tracker.signupInteraction('question-fallback-triggered'));
    }
    return shouldTrigger;
  }
}
function rebuildFlow(flow, state) {
  return [...getPostVerificationSteps(state), ...fallbackSteps, ...getPostAccountCreationSteps(state)];
}
export const BranchQuestionFallback = {
  name: 'BranchQuestionFallback',
  stateCheck,
  rebuildFlow,
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};