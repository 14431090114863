import { EndpointKey } from './EndpointKey';
import { PortalTierActionTypes } from '../store/portal-tier/action-types';
import { setPortalTierInfo } from '../store/portal-tier/action-creators';
import { requestPortalTiers } from '../portal-tier/requestPortalTiers';
import { Tracker } from '../store/tracking/action-creators';
import Logger from '../lib/Logger';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('EndpointsPortalTier');
export const EndpointsPortalTier = {
  [EndpointKey.GetPortalTiers]: {
    handleSuccess: (response, dispatch) => {
      dispatch(setPortalTierInfo(response));
    },
    handleError: (error, dispatch) => {
      dispatch(Tracker.signupInteraction('fetch-portal-tiers-errors'));
      LOG.error('Fetch Portal Tiers error', {
        extra: getSentryExtra(error)
      });
    },
    method: requestPortalTiers,
    reduxActionType: PortalTierActionTypes.FetchPortalTierInfo
  }
};