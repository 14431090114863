import { ErrorKey } from '../app-active-error/ErrorKey';
import { AppKey } from '../app/AppKey';
import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { Metrics } from '../metrics/Metrics';
import { MetricsCounter } from '../metrics/MetricsCounter';
import { Data } from 'signup-constants/signupData/Data';
import { updateKeyValue } from '../store/app/action-creators';
import { setError } from '../store/error/action-creators';
import { setSignupDataValue } from '../store/signup-data/action-creators';
const LOG = Logger.getLogger('onOAuthVerifyError');
export const onOAuthVerifyError = (error, provider) => dispatch => {
  try {
    const {
      status,
      responseJSON
    } = error;
    LOG.error(`Error verifying ${provider} oauth on signup service`, {
      extra: getSentryExtra(error)
    });
    if (responseJSON) {
      const {
        errorTokens
      } = responseJSON;
      if (status === 400 && errorTokens && errorTokens.email && errorTokens.email[0].indexOf('email-pending-verification') >= 0) {
        dispatch(setSignupDataValue(Data.Email, errorTokens.email[1]));
        dispatch(setError(ErrorKey.OAuthPendingEmailVerification, error));
        LOG.warn(`Error verifying ${provider} oauth on signup service - email is pending verification`);
      } else if (status === 403) {
        Metrics.counter(MetricsCounter.OauthFailedToVerifyToken, {
          provider
        }).increment();
        LOG.warn(`Token not verified by ${provider} verifier`, {
          extra: getSentryExtra(error)
        });
      } else {
        Metrics.counter(MetricsCounter.OauthVerificationUnknownError, {
          provider
        }).increment();
      }
    }
    dispatch(updateKeyValue(AppKey.WhichOAuthEndpointFailed, provider));
  } catch (err) {
    dispatch(updateKeyValue(AppKey.WhichOAuthEndpointFailed, provider));
    LOG.error(`Error parsing ${provider} verification json`, {
      extra: getSentryExtra(err)
    });
  }
};