export const devices = {
  tablet: `screen and (min-width: 769px)`,
  desktop: `screen and (min-width: 1025px)`,
  large: `screen and (min-width: 1201px)`,
  extraLarge: `screen and (min-width: 1600px)`
};
export const height = {
  large: `screen and (min-height: 1040px)`,
  medium: `screen and (min-height: 800px)`,
  small: `screen and (min-height: 600px)`
};