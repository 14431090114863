import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { NameError, syncNameValidator } from 'signup-ui-lego-core/validaton/nameValidator';
const NAME_ERROR_MESSAGE = {
  [NameError.REQUIRED]: ['Name Field Cannot be blank'],
  [NameError.INVALID]: ['Name cannot contain a number', 'Name cannot contain illegal characters'],
  [NameError.URL]: ['Name cannot contain a URL.', 'illegal script tag detected.', 'illegal url detected'],
  [NameError.MAX_LENGTH]: ['Exceeds max length']
};
export const getNameErrorMapping = errorMessage => {
  let error = NameError.INVALID;
  Object.entries(NAME_ERROR_MESSAGE).forEach(([key, value]) => {
    if (value.includes(errorMessage)) {
      error = key;
    }
  });
  return error;
};
const async = name => {
  const data = {
    name
  };
  return noAuthApiClient.post('/signup/v1/validation/name', {
    data
  }).catch(error => {
    if ('responseJSON' in error) {
      const apiErrorResponse = error.responseJSON;
      throw new Error(getNameErrorMapping(apiErrorResponse.message));
    }
    throw new Error(NameError.INVALID);
  });
};
const getErrorMessage = (_value, error, dataKey) => {
  return `data.${dataKey}.error.${error}`;
};
export const nameValidator = {
  sync: syncNameValidator,
  async,
  getErrorMessage
};