import { SignupEmbedderOptions } from 'signup-embedder';
import { getRouteParams } from '../routing/getRouteParams';
export const SignupEmbedderOptionsArray = Array.from(SignupEmbedderOptions.protectedOptionsRemovable);
export const removeURLParameter = url => {
  if (!url) return '';
  const {
    queryParamsMap
  } = getRouteParams();
  const splittedURL = url.split('?');
  if (splittedURL.length >= 2) {
    SignupEmbedderOptionsArray.map(key => queryParamsMap.delete(key));
    const redirectURL = `${splittedURL[0]}?${queryParamsMap.toString()}`;
    return redirectURL;
  }
  return url;
};