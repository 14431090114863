export const JOB_ROLE_LIST = [{
  id: 'account-collector'
}, {
  id: 'account-executive'
}, {
  id: 'account-manager'
}, {
  id: 'account-representative'
}, {
  id: 'accountant'
}, {
  id: 'accounting-analyst'
}, {
  id: 'accounting-director'
}, {
  id: 'accounts-payable-clerk'
}, {
  id: 'actor'
}, {
  id: 'actuary'
}, {
  id: 'addiction-counselor'
}, {
  id: 'administrative-analyst'
}, {
  id: 'administrative-assistant'
}, {
  id: 'administrative-manager'
}, {
  id: 'administrative-specialist'
}, {
  id: 'application-developer'
}, {
  id: 'architect'
}, {
  id: 'archivist'
}, {
  id: 'area-sales-manager'
}, {
  id: 'artificial-intelligence-engineer'
}, {
  id: 'artist'
}, {
  id: 'assistant-engineer'
}, {
  id: 'assistant-golf-professional'
}, {
  id: 'assistant-manager'
}, {
  id: 'associate'
}, {
  id: 'astronomer'
}, {
  id: 'atmospheric-scientist'
}, {
  id: 'attorney'
}, {
  id: 'auditing-clerk'
}, {
  id: 'auditor'
}, {
  id: 'b2b-sales-specialist'
}, {
  id: 'bank-teller'
}, {
  id: 'barber'
}, {
  id: 'barista'
}, {
  id: 'barrister'
}, {
  id: 'beautician'
}, {
  id: 'bellhop'
}, {
  id: 'benefits-manager'
}, {
  id: 'biological-engineer'
}, {
  id: 'biologist'
}, {
  id: 'biostatistician'
}, {
  id: 'board-member'
}, {
  id: 'boilermaker'
}, {
  id: 'bookkeeper'
}, {
  id: 'branch-manager'
}, {
  id: 'brand-manager'
}, {
  id: 'brand-strategist'
}, {
  id: 'budget-analyst'
}, {
  id: 'building-inspector'
}, {
  id: 'business-analyst'
}, {
  id: 'business-consultant'
}, {
  id: 'business-development-representative'
}, {
  id: 'business-manager'
}, {
  id: 'call-center-rep'
}, {
  id: 'call-center-supervisor'
}, {
  id: 'camera-operator'
}, {
  id: 'campaign-specialist'
}, {
  id: 'caregiver'
}, {
  id: 'caretaker'
}, {
  id: 'carpenter'
}, {
  id: 'cashier'
}, {
  id: 'casino-host'
}, {
  id: 'chef'
}, {
  id: 'chemical-engineer'
}, {
  id: 'chemist'
}, {
  id: 'chief-administration-officer'
}, {
  id: 'chief-business-officer'
}, {
  id: 'chief-creative-officer'
}, {
  id: 'chief-customer-officer'
}, {
  id: 'chief-data-officer'
}, {
  id: 'chief-design-officer'
}, {
  id: 'chief-engineer'
}, {
  id: 'chief-executive-officer'
}, {
  id: 'chief-financial-officer'
}, {
  id: 'chief-human-resources-officer'
}, {
  id: 'chief-information-officer'
}, {
  id: 'chief-marketing-officer'
}, {
  id: 'chief-operating-officer'
}, {
  id: 'chief-product-officer'
}, {
  id: 'chief-sales-officer'
}, {
  id: 'chief-strategy-officer'
}, {
  id: 'chief-technology-officer'
}, {
  id: 'civil-engineer'
}, {
  id: 'client-service-specialist'
}, {
  id: 'cloud-architect'
}, {
  id: 'cna'
}, {
  id: 'columnist'
}, {
  id: 'commerical-loan-officer'
}, {
  id: 'communication-coordinator'
}, {
  id: 'communications-director'
}, {
  id: 'community-manager'
}, {
  id: 'computer-animator'
}, {
  id: 'computer-programmer'
}, {
  id: 'computer-scientist'
}, {
  id: 'concierge'
}, {
  id: 'conservation-scientist'
}, {
  id: 'construction-worker'
}, {
  id: 'consultant'
}, {
  id: 'content-creator'
}, {
  id: 'content-marketing-manager'
}, {
  id: 'content-strategist'
}, {
  id: 'content-writer'
}, {
  id: 'continuous-improvement-consultant'
}, {
  id: 'continuous-improvement-lead'
}, {
  id: 'contractor'
}, {
  id: 'controller'
}, {
  id: 'copy-editor'
}, {
  id: 'copywriter'
}, {
  id: 'cosmetologist'
}, {
  id: 'counselor'
}, {
  id: 'couples-counselor'
}, {
  id: 'crane-operator'
}, {
  id: 'creative-director'
}, {
  id: 'credit-authorizer'
}, {
  id: 'credit-counselor'
}, {
  id: 'cruise-director'
}, {
  id: 'cruise-ship-attendant'
}, {
  id: 'customer-care-associate'
}, {
  id: 'customer-care-representative'
}, {
  id: 'customer-experience-director'
}, {
  id: 'customer-experience-specialist'
}, {
  id: 'customer-service-agent'
}, {
  id: 'customer-service-expert'
}, {
  id: 'customer-service-engineer'
}, {
  id: 'customer-service-executive'
}, {
  id: 'customer-service-manager'
}, {
  id: 'customer-service-representative'
}, {
  id: 'customer-service-supervisor'
}, {
  id: 'customer-success-intern'
}, {
  id: 'customer-success-manager'
}, {
  id: 'customer-success-team-lead'
}, {
  id: 'customer-support-associate'
}, {
  id: 'customer-support-manager'
}, {
  id: 'customer-support-representative'
}, {
  id: 'data-analyst'
}, {
  id: 'data-entry'
}, {
  id: 'dental-hygienist'
}, {
  id: 'design-manager'
}, {
  id: 'devops-engineer'
}, {
  id: 'digital-advertising-manager'
}, {
  id: 'digital-marketing-manager'
}, {
  id: 'digital-marketing-specialist'
}, {
  id: 'director-inside-sales'
}, {
  id: 'direct-salesperson'
}, {
  id: 'director'
}, {
  id: 'director-of-maintenance'
}, {
  id: 'director-of-photography'
}, {
  id: 'director-of-product-design'
}, {
  id: 'doctor'
}, {
  id: 'drafter'
}, {
  id: 'economist'
}, {
  id: 'ecommerce-marketing-specialist'
}, {
  id: 'editor'
}, {
  id: 'educator'
}, {
  id: 'electrical-engineer'
}, {
  id: 'electrician'
}, {
  id: 'engineer'
}, {
  id: 'engineering-technician'
}, {
  id: 'entertainment-specialist'
}, {
  id: 'entrepreneur'
}, {
  id: 'esthetician'
}, {
  id: 'event-planner'
}, {
  id: 'events-manager'
}, {
  id: 'executive'
}, {
  id: 'executive-assistant'
}, {
  id: 'facilities-manager'
}, {
  id: 'file-clerk'
}, {
  id: 'film-critic'
}, {
  id: 'finance-director'
}, {
  id: 'finance-manager'
}, {
  id: 'financial-analyst'
}, {
  id: 'financial-planner'
}, {
  id: 'financial-services-representative'
}, {
  id: 'flight-attendant'
}, {
  id: 'founder'
}, {
  id: 'freelancer'
}, {
  id: 'front-desk-associate'
}, {
  id: 'front-desk-manager'
}, {
  id: 'funeral-attendant'
}, {
  id: 'general-manager'
}, {
  id: 'geological-engineer'
}, {
  id: 'geologist'
}, {
  id: 'ghostwriter'
}, {
  id: 'grant-writer'
}, {
  id: 'graphic-designer'
}, {
  id: 'group-sales'
}, {
  id: 'growth-marketer'
}, {
  id: 'guidance-counselor'
}, {
  id: 'hair-stylist'
}, {
  id: 'heacy-equipment-officer'
}, {
  id: 'help-desk-worker'
}, {
  id: 'hotel-front-door-worker'
}, {
  id: 'hotel-manager'
}, {
  id: 'hotel-receptionist'
}, {
  id: 'housekeeper'
}, {
  id: 'human-resources-manager'
}, {
  id: 'hvac-technician'
}, {
  id: 'illustrator'
}, {
  id: 'implementation-consultant'
}, {
  id: 'implementation-specialist'
}, {
  id: 'information-architect'
}, {
  id: 'information-security-analyst'
}, {
  id: 'interior-designer'
}, {
  id: 'intern'
}, {
  id: 'interpreter'
}, {
  id: 'investor'
}, {
  id: 'iron-worker'
}, {
  id: 'it-manager'
}, {
  id: 'it-professional'
}, {
  id: 'it-specialist'
}, {
  id: 'jobseeker'
}, {
  id: 'journalist'
}, {
  id: 'lawyer'
}, {
  id: 'lecturer'
}, {
  id: 'librarian'
}, {
  id: 'life-coach'
}, {
  id: 'locksmith'
}, {
  id: 'lodging-manager'
}, {
  id: 'machinery-operator'
}, {
  id: 'maintenance-engineer'
}, {
  id: 'makeup-artist'
}, {
  id: 'management-consultant'
}, {
  id: 'managing-director'
}, {
  id: 'manager'
}, {
  id: 'manicurist'
}, {
  id: 'manufactoring-assembler'
}, {
  id: 'market-developer-manager'
}, {
  id: 'market-researcher'
}, {
  id: 'marketing-communications-manager'
}, {
  id: 'marketing-consultant'
}, {
  id: 'marketing-director'
}, {
  id: 'marketing-executive'
}, {
  id: 'marketing-manager'
}, {
  id: 'marketing-operations-analyst'
}, {
  id: 'marketing-operations-specialist'
}, {
  id: 'marketing-operations-manager'
}, {
  id: 'marketing-research-analyst'
}, {
  id: 'marketing-specialist'
}, {
  id: 'mason'
}, {
  id: 'mathematician'
}, {
  id: 'mechanic'
}, {
  id: 'mechanical-engineer'
}, {
  id: 'media-buyer'
}, {
  id: 'media-relations-coordinator'
}, {
  id: 'medical-adminstrator'
}, {
  id: 'medical-laboratory-tech'
}, {
  id: 'medical-researcher'
}, {
  id: 'medical-transcriptionist'
}, {
  id: 'meeting-planner'
}, {
  id: 'mental-health-counselor'
}, {
  id: 'merchandising-associate'
}, {
  id: 'mining-engineer'
}, {
  id: 'molecular-scientist'
}, {
  id: 'motion-picture-director'
}, {
  id: 'music-producer'
}, {
  id: 'musician'
}, {
  id: 'nail-technician'
}, {
  id: 'network-administrator'
}, {
  id: 'novelist-wrtier'
}, {
  id: 'nuclear-engineer'
}, {
  id: 'nurse'
}, {
  id: 'nurse-practitioner'
}, {
  id: 'office-assistant'
}, {
  id: 'office-clerk'
}, {
  id: 'office-manager'
}, {
  id: 'operations-analyst'
}, {
  id: 'operations-assistant'
}, {
  id: 'operations-coordinator'
}, {
  id: 'operations-director'
}, {
  id: 'operations-manager'
}, {
  id: 'operations-professional'
}, {
  id: 'orderly'
}, {
  id: 'outside-sales-manager'
}, {
  id: 'owner'
}, {
  id: 'painter'
}, {
  id: 'paralegal'
}, {
  id: 'parking-attendant'
}, {
  id: 'partner'
}, {
  id: 'payroll-clerk'
}, {
  id: 'payroll-manager'
}, {
  id: 'personal-assistant'
}, {
  id: 'personal-trainer'
}, {
  id: 'petroleum-engineer'
}, {
  id: 'pharmacist'
}, {
  id: 'pharmacy-assistant'
}, {
  id: 'phlebotomist'
}, {
  id: 'photographer'
}, {
  id: 'physical-therapist'
}, {
  id: 'physical-therapy-assistant'
}, {
  id: 'physicist'
}, {
  id: 'pipefitter'
}, {
  id: 'plant-engineer'
}, {
  id: 'playwright'
}, {
  id: 'plumber'
}, {
  id: 'political-scientist'
}, {
  id: 'porter'
}, {
  id: 'president'
}, {
  id: 'product-designer'
}, {
  id: 'product-management-director'
}, {
  id: 'product-manager'
}, {
  id: 'product-marketing-director'
}, {
  id: 'product-marketer'
}, {
  id: 'production-engineer'
}, {
  id: 'professor'
}, {
  id: 'program-adminstrator'
}, {
  id: 'program-manager'
}, {
  id: 'project-management-director'
}, {
  id: 'project-manager'
}, {
  id: 'proposal-writer'
}, {
  id: 'public-relations'
}, {
  id: 'public-relations-specialist'
}, {
  id: 'quality-assurance-manager'
}, {
  id: 'quality-assurance-specialist'
}, {
  id: 'quality-control-coordinator'
}, {
  id: 'quality-engineer'
}, {
  id: 'real-estate-agent'
}, {
  id: 'real-estate-broker'
}, {
  id: 'receptionist'
}, {
  id: 'recruiter'
}, {
  id: 'reiki-practitioner'
}, {
  id: 'research-assistant'
}, {
  id: 'researcher'
}, {
  id: 'reservationist'
}, {
  id: 'restaurant-chain-executive'
}, {
  id: 'restaurant-manager'
}, {
  id: 'retail-worker'
}, {
  id: 'revenue-operations-analyst'
}, {
  id: 'revenue-operations-manager'
}, {
  id: 'revenue-operations-specialist'
}, {
  id: 'risk-manager'
}, {
  id: 'roofer'
}, {
  id: 'safety-engineer'
}, {
  id: 'sales-analyst'
}, {
  id: 'sales-associate'
}, {
  id: 'sales-director'
}, {
  id: 'sales-engineer'
}, {
  id: 'sales-executive'
}, {
  id: 'sales-manager'
}, {
  id: 'sales-operations-analyst'
}, {
  id: 'sales-operations-manager'
}, {
  id: 'sales-operations-specialist'
}, {
  id: 'sales-representative'
}, {
  id: 'salon-manager'
}, {
  id: 'school-counselor'
}, {
  id: 'screenwriter'
}, {
  id: 'scrum-master'
}, {
  id: 'secretary'
}, {
  id: 'senior-designer'
}, {
  id: 'senior-vice-president'
}, {
  id: 'senior-vice-president-accounting'
}, {
  id: 'senior-vice-president-administration'
}, {
  id: 'senior-vice-president-business-development'
}, {
  id: 'senior-vice-president-corporate-communications'
}, {
  id: 'senior-vice-president-customer-service'
}, {
  id: 'senior-vice-president-engineering'
}, {
  id: 'senior-vice-president-human-resources'
}, {
  id: 'senior-vice-president-marketing'
}, {
  id: 'senior-vice-president-operations'
}, {
  id: 'senior-vice-president-product-management'
}, {
  id: 'senior-vice-president-product-marketing'
}, {
  id: 'senior-vice-president-public-relations'
}, {
  id: 'senior-vice-president-sales'
}, {
  id: 'senior-vice-president-software-development'
}, {
  id: 'senior-vice-president-technology'
}, {
  id: 'seo-manager'
}, {
  id: 'seo-strategist'
}, {
  id: 'server'
}, {
  id: 'server-administrator'
}, {
  id: 'sheet-metal-worker'
}, {
  id: 'skin-care-specialist'
}, {
  id: 'social-media-assistant'
}, {
  id: 'social-media-manager'
}, {
  id: 'social-media-specialist'
}, {
  id: 'social-worker'
}, {
  id: 'sociologist'
}, {
  id: 'software-engineer'
}, {
  id: 'solar-photovoltaic-installer'
}, {
  id: 'solicitor'
}, {
  id: 'sound-engineer'
}, {
  id: 'spa-manager'
}, {
  id: 'speech-pathologist'
}, {
  id: 'speechwriter'
}, {
  id: 'sql-developer'
}, {
  id: 'store-manager'
}, {
  id: 'student'
}, {
  id: 'support-specialist'
}, {
  id: 'system-administrator'
}, {
  id: 'systems-engineer'
}, {
  id: 'taper'
}, {
  id: 'teacher'
}, {
  id: 'team-lead'
}, {
  id: 'technical-specialist'
}, {
  id: 'technical-support-specialist'
}, {
  id: 'technical-writer'
}, {
  id: 'telework-nurse-doctor'
}, {
  id: 'therapist'
}, {
  id: 'title-analyst'
}, {
  id: 'title-researcher'
}, {
  id: 'translator'
}, {
  id: 'travel-agent'
}, {
  id: 'travel-nurse'
}, {
  id: 'travel-writer'
}, {
  id: 'ux-designer'
}, {
  id: 'valet'
}, {
  id: 'vehicle-or-equipment-cleaner'
}, {
  id: 'vice-president'
}, {
  id: 'vice-president-accounting'
}, {
  id: 'vice-president-administration'
}, {
  id: 'vice-president-business-development'
}, {
  id: 'vice-president-corporate-communications'
}, {
  id: 'vice-president-customer-service'
}, {
  id: 'vice-president-engineering'
}, {
  id: 'vice-president-human-resources'
}, {
  id: 'vice-president-marketing'
}, {
  id: 'vice-president-operations'
}, {
  id: 'vice-president-product-management'
}, {
  id: 'vice-president-product-marketing'
}, {
  id: 'vice-president-public-relations'
}, {
  id: 'vice-president-sales'
}, {
  id: 'vice-president-software-development'
}, {
  id: 'vice-president-technology'
}, {
  id: 'video-editor'
}, {
  id: 'video-game-writer'
}, {
  id: 'video-or-film-producer'
}, {
  id: 'virtual-assistant'
}, {
  id: 'volunteer'
}, {
  id: 'web-designer'
}, {
  id: 'web-developer'
}, {
  id: 'web-editor'
}, {
  id: 'wedding-coordinator'
}, {
  id: 'welder'
}, {
  id: 'well-driller'
}, {
  id: 'yoga-instructor'
}];