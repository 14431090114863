import { isPostVerification } from '../flow-settings/settingsSelectors';
import { getFlowSteps } from './getFlowConfig';
export const getInitialFlow = () => {
  const {
    preVerification,
    postVerification,
    postAccountCreation,
    postExistingAccountSelection
  } = getFlowSteps();
  const initialFlow = isPostVerification() ? [...postVerification, ...postAccountCreation] : [...preVerification, ...postVerification, ...postAccountCreation];
  return {
    initialFlow,
    preVerification,
    postVerification,
    postAccountCreation,
    postExistingAccountSelection
  };
};