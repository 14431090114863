import { setIsOAuthExistingUser } from '../app/action-creators';
import { AuthActionTypes } from './action-types';
export const setPortalId = portalId => dispatch => {
  dispatch({
    type: AuthActionTypes.SetPortalId,
    portalId
  });
};
export const setUserId = userId => dispatch => {
  dispatch({
    type: AuthActionTypes.SetUserId,
    userId
  });
};
export const setupAuthentication = () => dispatch => {
  dispatch({
    type: AuthActionTypes.SetupAuth
  });
};
export const setupAuthForNewPortal = () => dispatch => {
  dispatch({
    type: AuthActionTypes.SetupAuthForNewPortal
  });
};
export const setupAuthForOAuthExistingUser = () => dispatch => {
  dispatch({
    type: AuthActionTypes.SetupAuthForOAuthExistingUser
  });
};
export const initializeAuth = (isAuthenticated, email) => dispatch => {
  dispatch({
    type: AuthActionTypes.InitializeAuth,
    isAuthenticated,
    email
  });
};
export const updateAuth = data => dispatch => {
  dispatch({
    type: AuthActionTypes.UpdateAuth,
    data
  });
};
export const existingOAuthUser = (email, provider) => dispatch => {
  dispatch({
    type: AuthActionTypes.ExistingOAuthUser,
    provider,
    email
  });
  dispatch(setIsOAuthExistingUser(true));
};
export const newOAuthUser = provider => dispatch => {
  dispatch({
    type: AuthActionTypes.NewOAuthUser,
    provider
  });
};
export const oAuthExistingAuthFailed = provider => dispatch => {
  dispatch({
    type: AuthActionTypes.OAuthAuthenticationFailed,
    provider
  });
};
export const existingUser = () => dispatch => {
  dispatch({
    type: AuthActionTypes.ExistingUser
  });
};
export const clickCreateNewAccountSameUser = hubless => dispatch => {
  dispatch({
    type: AuthActionTypes.ClickCreateNewAccountSameUser,
    hubless
  });
};
export const redirectExistingUserAction = ({
  portalId,
  hublet
} = {}) => dispatch => {
  dispatch({
    type: AuthActionTypes.RedirectExistingUser,
    payload: {
      portalId,
      hublet
    }
  });
};
export const portalSelected = ({
  portalId,
  hublet
}) => dispatch => {
  dispatch({
    type: AuthActionTypes.PortalSelected,
    payload: {
      portalId,
      hublet
    }
  });
};
export const setLoginSuccessStatus = loginSuccessStatus => dispatch => {
  dispatch({
    type: AuthActionTypes.SetLoginSuccessStatus,
    loginSuccessStatus
  });
};