import Logger from '../lib/Logger';
import { Tracker } from '../store/tracking/action-creators';
const LOG = Logger.getLogger('redirectToUrl');
export const redirectToUrl = (url, shouldRedirectWindow = true) => dispatch => {
  if (!url || typeof url === 'string' && url.trim() === '') {
    LOG.error('Redirecting to a null or empty url');
    return;
  }
  dispatch(Tracker.redirect({
    url
  }));
  if (shouldRedirectWindow) {
    window.location.replace(url);
  }
};