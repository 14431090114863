export const initialPredictionsState = {
  companySize: ''
};
const predictionsReducer = (state = initialPredictionsState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_INFO_PREDICTION':
      return Object.assign({}, state, {
        companySize: action.payload
      });
    default:
      return state;
  }
};
export default predictionsReducer;