import { IntegrationsActionTypes } from './action-types';
import { getIntegrationKey } from '../../routing/getIntegrationKey';
import { Integration } from 'signup-constants/Integration';
export const updateIntegratedAppAuthResponse = integratedAppAuthResponse => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.UpdateIntegratedAppAuthorizationResponseAction,
    integratedAppAuthResponse
  });
};
export const setIntegratedAppPermissions = valid => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.SetIntegratedAppPermissions,
    valid
  });
};
export const validateOAuthPermissions = () => dispatch => {
  const integration = getIntegrationKey();
  switch (integration) {
    case Integration.ShopifyEmbedded:
      dispatch({
        type: IntegrationsActionTypes.ValidateShopifyEmbeddedOAuthPermissions
      });
      break;
    case Integration.Wordpress:
      dispatch({
        type: IntegrationsActionTypes.ValidateWordpressOAuthPermissions
      });
      break;
    default:
      dispatch({
        type: IntegrationsActionTypes.SetIntegratedAppPermissions,
        valid: false
      });
      break;
  }
};
export const integratedAppRedirect = ({
  queryParams,
  isNewPortal,
  isLoggedInIfNotExistingAccount,
  isntExistingAccount,
  hubId,
  hublet,
  signupBody,
  loginBody
}) => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.IntegratedAppRedirect,
    params: {
      queryParams,
      isNewPortal,
      isLoggedInIfNotExistingAccount,
      isntExistingAccount,
      hubId,
      hublet,
      signupBody,
      loginBody
    }
  });
};
export const launchLoginPopup = () => dispatch => {
  dispatch({
    type: IntegrationsActionTypes.LaunchLoginPopup
  });
};