// @ts-expect-error bender-url not typed
import clock from 'bender-url!../../images/illustrations/clock.svg';
// @ts-expect-error bender-url not typed
import computerError from 'bender-url!../../images/illustrations/computer-error.svg';
// @ts-expect-error bender-url not typed
import email from 'bender-url!../../images/illustrations/email.svg';
// @ts-expect-error bender-url not typed
import emailWarning from 'bender-url!../../images/illustrations/email-warning.svg';
// @ts-expect-error bender-url not typed
import door from 'bender-url!../../images/illustrations/door.svg';
// @ts-expect-error bender-url not typed
import closedDoor from 'bender-url!../../images/illustrations/closed-door.svg';
// @ts-expect-error bender-url not typed
import computerSprocket from 'bender-url!../../images/illustrations/computer-sprocket.svg';
// @ts-expect-error bender-url not typed
import negative from 'bender-url!../../images/illustrations/negative.svg';
// @ts-expect-error bender-url not typed
import neutral from 'bender-url!../../images/illustrations/neutral.svg';
// @ts-expect-error bender-url not typed
import happy from 'bender-url!../../images/illustrations/happy.svg';
// @ts-expect-error bender-url not typed
import targetNoBackground from 'bender-url!../../images/illustrations/target-no-background.svg';
// @ts-expect-error bender-url not typed
import playbooks from 'bender-url!../../images/illustrations/playbooks.svg';
// @ts-expect-error bender-url not typed
import contactProperties from 'bender-url!../../images/illustrations/contact-properties.svg';
export default {
  clock,
  computerError,
  email,
  emailWarning,
  door,
  closedDoor,
  computerSprocket,
  negative,
  neutral,
  happy,
  targetNoBackground,
  playbooks,
  contactProperties
};