export const getRequestIdleCallback = () => window.requestIdleCallback || function (callback) {
  const start = Date.now();
  return setTimeout(() => {
    callback({
      didTimeout: false,
      timeRemaining: () => {
        return Math.max(0, 50 - (Date.now() - start));
      }
    });
  }, 1);
};
export const getCancelIdleCallback = () => window.cancelIdleCallback || function (handle) {
  clearTimeout(handle);
};
export const requestIdleCallback = getRequestIdleCallback();
export const cancelIdleCallback = getCancelIdleCallback();