import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExperimentFromState, getParameter, isExperimentActive } from '../store/experiments/selectors';
import { EventName } from '../tracking/EventName';
import { getTracker } from '../tracking/getTracker';
import { ExperimentParameterGroup } from './ExperimentParameterGroup';
import { getExperiment } from './Experiments';
import logExposure from './logExposure';
import { experimentsEnabled } from './experimentsEnabled';
export const TrackWhen = {
  assignedToVariant: 'assignedToVariant',
  assignedToVariantA: 'assignedToVariantA',
  assignedToVariantB: 'assignedToVariantB',
  assignedToControl: 'assignedToControl',
  assignedToControlOrVariantA: 'assignedToControlOrVariantA',
  enrolled: 'enrolled',
  never: 'never'
};
function getShouldTrackExposure(trackWhen, isAssignedToControl, isAssignedToVariant, isAssignedToSpecificVariant) {
  switch (trackWhen) {
    case TrackWhen.assignedToVariant:
      return isAssignedToVariant();
    case TrackWhen.assignedToControl:
      return isAssignedToControl();
    case TrackWhen.assignedToVariantA:
      return isAssignedToSpecificVariant(ExperimentParameterGroup.Variant_A);
    case TrackWhen.assignedToVariantB:
      return isAssignedToSpecificVariant(ExperimentParameterGroup.Variant_B);
    case TrackWhen.assignedToControlOrVariantA:
      return isAssignedToControl() || isAssignedToSpecificVariant(ExperimentParameterGroup.Variant_A);
    case TrackWhen.never:
      return false;
    case TrackWhen.enrolled:
    default:
      return true;
  }
}
let HasExposureBeenTrackedCache = {};
export function getHasExposureBeenTracked(experimentId) {
  return HasExposureBeenTrackedCache[experimentId];
}
export function setHasExposureBeenTracked(experimentId) {
  HasExposureBeenTrackedCache[experimentId] = true;
}
export function resetExposureTracking() {
  HasExposureBeenTrackedCache = {};
}
export const useExperiment = (experimentId, trackWhen, shouldTrackLabExposure = false) => {
  const experimentObj = useMemo(() => getExperiment(experimentId), [experimentId]);
  const experiment = useSelector(state => getExperimentFromState(state, experimentId));
  const parameter = useSelector(state => getParameter(state, experimentId));
  const experimentActive = useSelector(state => isExperimentActive(state, experimentId));
  const shouldEnroll = useSelector(state => {
    return experimentObj && experimentsEnabled() && experimentObj.shouldEnroll(state);
  });
  const dispatch = useDispatch();
  const isAssignedToControl = useCallback(() => {
    return parameter === ExperimentParameterGroup.Control && experimentActive;
  }, [experimentActive, parameter]);
  const isAssignedToVariant = useCallback(() => {
    const variants = [ExperimentParameterGroup.Variant, ExperimentParameterGroup.VariantACopyExp, ExperimentParameterGroup.Variant_A, ExperimentParameterGroup.Variant_B, ExperimentParameterGroup.Variant_C, ExperimentParameterGroup.Variant_D];
    return variants.includes(parameter) && experimentActive;
  }, [experimentActive, parameter]);
  const isAssignedToSpecificVariant = useCallback(experimentParameterGroup => {
    return experimentParameterGroup === parameter && experimentActive;
  }, [experimentActive, parameter]);
  const trackExperimentExposure = useCallback(() => {
    if (experimentActive && !getHasExposureBeenTracked(experimentId)) {
      setHasExposureBeenTracked(experimentId);
      getTracker().track(EventName.ExperimentExposure, {
        action: `${experimentId}-group-${parameter}`
      }, true);
    }
  }, [experimentActive, experimentId, parameter]);
  useEffect(() => {
    const exposureHasBeenTracked = getHasExposureBeenTracked(experimentId);
    const shouldTrackExposure = getShouldTrackExposure(trackWhen, isAssignedToControl, isAssignedToVariant, isAssignedToSpecificVariant);
    if (experiment && shouldEnroll && !exposureHasBeenTracked && shouldTrackExposure) {
      setHasExposureBeenTracked(experimentId);
      getTracker().track(EventName.ExperimentExposure, {
        action: `${experimentId}-group-${experiment.parameters.group}`
      }, true);
      if (shouldTrackLabExposure) {
        dispatch(logExposure(experimentId));
      }
    }
  }, [experiment, experimentActive, experimentId, isAssignedToControl, isAssignedToVariant, shouldEnroll, trackWhen, shouldTrackLabExposure, dispatch, isAssignedToSpecificVariant]);
  return {
    parameter,
    isExperimentActive: experimentActive,
    isAssignedToControl,
    isAssignedToVariant,
    isAssignedToSpecificVariant,
    trackExperimentExposure
  };
};