import { ValidationStatus } from 'signup-ui-lego-core/types';
import { getConfigDataOfStep } from '../../views/getStepConfig';
import { DataStateKey } from './types';
export const mergeSignupDataItem = ({
  state,
  dataKey,
  newValue,
  validation,
  completed = false,
  skipped = false
}) => Object.assign({}, state[dataKey], {
  [DataStateKey.value]: newValue
}, validation && {
  [DataStateKey.validation]: validation
}, completed && {
  [DataStateKey.completed]: completed
}, skipped && {
  [DataStateKey.skipped]: skipped
});
export const mergeFreshSignupDataItem = (state, dataKey) => Object.assign({}, state[dataKey], {
  [DataStateKey.value]: '',
  [DataStateKey.validation]: ValidationStatus.FRESH
});
export const mergeSignupData = ({
  state,
  signupData,
  validation,
  completed = false,
  skipped = false
}) => {
  const dataKeys = Object.keys(signupData);
  return dataKeys.reduce((dataWithMeta, dataKey) => {
    const value = signupData[dataKey];
    if (value) {
      dataWithMeta[dataKey] = mergeSignupDataItem({
        state,
        dataKey,
        newValue: value,
        validation,
        completed,
        skipped
      });
    }
    return dataWithMeta;
  }, {});
};
export const emptySignupData = (state, signupData) => {
  return signupData.reduce((dataWithMeta, dataKey) => {
    dataWithMeta[dataKey] = mergeFreshSignupDataItem(state, dataKey);
    return dataWithMeta;
  }, {});
};
export const extendEachDataObjectInStep = (state, stepKey, extension) => {
  const data = getConfigDataOfStep(stepKey);
  return data.reduce((dataWithMeta, dataKey) => {
    return Object.assign({}, dataWithMeta, {
      [dataKey]: Object.assign({}, state[dataKey], extension)
    });
  }, {});
};