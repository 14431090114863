import { fromJS } from 'immutable';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
export const getScoredAccounts = accounts => {
  return accounts.map(account => {
    const scoringFunction = getFlowConfig().scoringFunction;
    const portalScore = scoringFunction(account);
    account['portalScore'] = portalScore;
    return account;
  });
};
export const getSortedAccounts = accounts => {
  const listOfAccounts = fromJS(accounts.sort((first, second) => {
    const firstPortalScore = first.portalScore;
    const secondPortalScore = second.portalScore;
    return secondPortalScore && firstPortalScore ? secondPortalScore - firstPortalScore : 0;
  }));
  return listOfAccounts;
};