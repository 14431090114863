import { removeBulkInArray, replaceElementWithBulk } from './arrayUtils';
export const pushNextSteps = (...nextSteps) => flow => {
  return [...nextSteps, ...flow.filter(step => nextSteps.indexOf(step) < 0)];
};
export const removeSteps = (...stepsToRemove) => flow => {
  return removeBulkInArray(flow, stepsToRemove);
};
export const pushNextAndRemove = (nextSteps, stepsToRemove) => flow => {
  return removeBulkInArray(pushNextSteps(...nextSteps)(flow), stepsToRemove);
};
export const replaceStep = (oldStep, ...newSteps) => flow => {
  return replaceElementWithBulk(flow, oldStep, newSteps);
};
export const addStepsAfterStep = (step, ...newSteps) => flow => {
  return flow.reduce((newFlow, value) => {
    if (value === step) {
      newFlow.push(step, ...newSteps);
      return newFlow;
    }
    newFlow.push(value);
    return newFlow;
  }, []);
};
export const NextStepManager = {
  pushNextSteps,
  removeSteps,
  replaceStep,
  pushNextAndRemove,
  addStepsAfterStep
};