import { Flow } from 'signup-constants/Flow';
import { Data } from 'signup-constants/signupData/Data';
import { LoadingKey } from '../../app-active-loading/LoadingKey';
import SignupClient from '../../http-clients/SignupClient';
import { handleSignupError } from '../../http-signup/handleSignupError';
import { isFlow } from '../../utils/isFlow';
import { loginNewUser } from '../../http-login/loginNewUser';
import { handleLoginNewPortalFailure } from '../../http-survey/handleLoginNewPortalResponse';
import { AppActionTypes } from '../../store/app/action-types';
import { getIsNewUserCreated } from '../../store/app/selectors';
import { isUserIdDefined } from '../../store/auth/selectors';
import { FlowActionTypes } from '../../store/flow/action-types';
import { generateRecaptchaToken } from '../../store/google-recaptcha/action-creators';
import { HttpResponseActionTypes } from '../../store/http-responses/action-types';
import { Tracker } from '../../store/tracking/action-creators';
import { trackGoogleAnalyticsPageView } from '../../tracking/trackGoogleAnalyticsPageView';
import { EndpointKey } from '../EndpointKey';
import { addRequiredVerificationData } from '../addRequiredVerificationData';
import { shouldRequirePassword } from '../shouldRequirePassword';
import { handleUserOnlySignupResponse, handleUserOnlySignupSuccess } from './handleUserOnlySignupResponse';
export const EndpointsUserOnlyFlow = {
  [EndpointKey.Signup]: {
    stateCheck: () => isFlow([Flow.Connect]),
    getRequestData: state => {
      return [Data.Email, Data.FirstName, Data.LastName, ...addRequiredVerificationData(state), ...(shouldRequirePassword(state) ? [Data.Password] : [])];
    },
    handleResponse: handleUserOnlySignupResponse,
    handleError: handleSignupError,
    handleSuccess: handleUserOnlySignupSuccess,
    loadingKey: LoadingKey.SignupRequest,
    method: SignupClient.signup,
    reduxActionType: FlowActionTypes.FinishSignup,
    once: true,
    /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
    onBefore: (dispatch, action) => {
      if (action.type !== HttpResponseActionTypes.RetryEndpoint) {
        return dispatch(generateRecaptchaToken('before_finish_signup'));
      }
      return Promise.resolve();
    }
  },
  [EndpointKey.LoginNewPortal]: {
    isStateOnlyTrigger: true,
    loadingKey: LoadingKey.LoginAfterSignup,
    once: true,
    stateCheck: state => getIsNewUserCreated(state),
    method: loginNewUser,
    handleSuccess: (response, dispatch) => dispatch({
      type: AppActionTypes.SignupEnded
    }),
    handleError: handleLoginNewPortalFailure
  },
  [EndpointKey.TrackSignupCompleted]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: state => getIsNewUserCreated(state) && isUserIdDefined(state),
    method: (state, dispatch) => new Promise(resolve => {
      dispatch(Tracker.signupCompleted());
      trackGoogleAnalyticsPageView();
      resolve(true);
    })
  }
};