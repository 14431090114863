import I18n from 'I18n';
import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { getFlowKey } from '../routing/getFlowKey';
export const useInputPlaceholder = dataKey => {
  const labelExists = label => I18n.lookup(label);
  const flowKey = getFlowKey();
  const flowSpecificMessageKey = `data.${dataKey}.${flowKey}`;
  const defaultFlowMessageKey = `data.${dataKey}.default`;
  const defaultMessageKey = `data.${dataKey}`;
  let messageKey = defaultMessageKey;
  if (labelExists(flowSpecificMessageKey)) messageKey = flowSpecificMessageKey;else if (labelExists(defaultFlowMessageKey)) messageKey = defaultFlowMessageKey;
  return isValidI18nKey(`${messageKey}.placeholder`) ? I18n.text(`${messageKey}.placeholder`) : I18n.text(`${messageKey}.label`);
};