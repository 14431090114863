import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import { ONBOARDING_CMS_FREE } from 'getting-started-shared-tasks/onboardingKeys';
import { ONBOARDING_VIEWS } from 'user-context/onboardingSettings';
import { CMS_SIGNUP_BUSINESS_INTENT } from '../constants/surveyConstants';
import { getAiOnboardingFlowUrl, HubletUrl } from '../utils/urls';
import { CMS_FREE_SIGNUP } from '../constants/signupConstants';
const cmsExperiencesChecks = [{
  redirectPath: portalId => {
    return {
      url: getAiOnboardingFlowUrl(portalId),
      view: ONBOARDING_VIEWS.CMS,
      onboardingKey: ONBOARDING_CMS_FREE,
      groupKey: FREE_GROUP_KEYS.freeContentView
    };
  },
  checks: [{
    signupType: CMS_FREE_SIGNUP,
    survey: {
      cmsSignupIntent: CMS_SIGNUP_BUSINESS_INTENT
    }
  }]
}, {
  redirectPath: portalId => {
    return {
      url: `${HubletUrl.getAppUrl()}/user-guide/${portalId}`,
      view: ONBOARDING_VIEWS.CMS,
      onboardingKey: ONBOARDING_CMS_FREE,
      groupKey: FREE_GROUP_KEYS.freeContentView
    };
  },
  checks: [{
    signupType: CMS_FREE_SIGNUP
  }]
}];
export default cmsExperiencesChecks;