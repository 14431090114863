export const getIntegratedAppAuthResponse = state => {
  return state.integrations.integratedAppAuthResponse;
};
export const getHasIntegratedAppAuthSucceeded = state => {
  return state.integrations.integratedAppAuthSucceeded;
};
export const getIntegratedAppValidPermissions = state => {
  return state.integrations.integratedAppValidPermissions;
};
export const getIsIntegratedAppAuthFinished = state => {
  return state.integrations.integratedAppAuthFinished;
};