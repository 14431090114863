import { getFullUrl } from 'hubspot-url-utils';
import { prefetchPage } from 'prefetcher/utils/prefetchPage';
import { ExperimentIdentifier } from '../experiments/ExperimentIdentifier';
import { setHublet, setIsNewUser, setNewPortalCreated } from '../store/app/action-creators';
import { fetchExperiments } from '../store/experiments/action-creators';
import { getPostAccountCreationSteps } from '../store/flow/selectors';
import { Step } from 'signup-constants/Step';
import { setNewPortalId } from './setNewPortalId';
import { signupSuccessTracking } from './signupSuccessTracking';
import { Metrics } from '../metrics/Metrics';
import { MetricsCounter } from '../metrics/MetricsCounter';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Data } from 'signup-constants/signupData/Data';
export const handleSignupSuccess = (response, dispatch, getState) => {
  const state = getState();
  const hubletFromState = getSignupDataValue(state, Data.HubletSelection);
  const {
    hubId,
    userCreated,
    region: hublet,
    creatorId
  } = response;
  const postAccountCreation = getPostAccountCreationSteps(state);
  if (hubletFromState && hublet !== hubletFromState) {
    Metrics.counter(MetricsCounter.HubletMismatch).increment();
  }
  signupSuccessTracking(state, creatorId);
  setNewPortalId({
    hubId,
    hublet
  }, dispatch);
  dispatch(setHublet(hublet));
  dispatch(setNewPortalCreated());
  dispatch(setIsNewUser(userCreated));
  if (postAccountCreation[0] !== Step.MobileAppPrompt) {
    dispatch(fetchExperiments({
      identifierPhase: ExperimentIdentifier.PortalId
    }));
  }
  prefetchPage('setup-guide-ui-prefetch', {
    url: getFullUrl('app', {
      hubletOverride: hublet
    })
  });
};