import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppKey } from '../app/AppKey';
import { getApplicationLocale } from '../i18n/LocaleUtils';
import Logger from '../lib/Logger';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { setWhichOAuthSelected, updateKeyValue } from '../store/app/action-creators';
import { oAuthAuthStarted, oAuthAuthSucceeded, verifyOAuthVerificationPayload } from '../store/oauth/action-creators';
import { Tracker } from '../store/tracking/action-creators';
import GooglePlatform from './GooglePlatform';
import { useOAuthButtonWidth } from '../oauth/utils/useOAuthButtonWidth';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('useGoogleIdentityServices');

// extracted from https://developers.google.com/identity/gsi/web/reference/html-reference?hl=en#data-attributes
const GOOGLE_BUTTON_DATA_ATTRIBUTES = {
  text: 'continue_with',
  theme: 'filled_blue',
  context: 'signup',
  size: 'large'
};
const renderGoogleButton = (buttonElement, googleClientId, width) => {
  if (!buttonElement || !googleClientId) {
    return;
  }
  GooglePlatform.getGoogleIdentityServicesClient().accounts.id.renderButton(buttonElement, Object.assign({}, GOOGLE_BUTTON_DATA_ATTRIBUTES, {
    locale: getApplicationLocale(),
    width
  }));
};
export const useGoogleIdentityServices = ({
  googleButtonRef,
  libraryHasLoaded,
  googleSignInNonce
}) => {
  const dispatch = useDispatch();
  const {
    width
  } = useOAuthButtonWidth(OAuthProviders.Google);
  const handleGoogleIdentityServicesCallback = useCallback(({
    credential
  }) => {
    dispatch(updateKeyValue(AppKey.WhichOAuthEndpointFailed, null));
    dispatch(oAuthAuthStarted(OAuthProviders.Google));
    dispatch(setWhichOAuthSelected(OAuthProviders.Google));
    dispatch(oAuthAuthSucceeded({
      token: credential
    }, OAuthProviders.Google));
    dispatch(Tracker.signupInteraction('google-button-click'));
    dispatch(updateKeyValue(AppKey.FirstOAuthPartnerClick, 'google'));
    dispatch(verifyOAuthVerificationPayload({
      credential
    }, OAuthProviders.Google)).catch(error => {
      LOG.error('Error during verification of the google credential', {
        extra: getSentryExtra(error)
      });
    });
  }, [dispatch]);
  useEffect(() => {
    if (!googleSignInNonce || !libraryHasLoaded) {
      return;
    }
    const googleClientId = GooglePlatform.getClientId();
    const googleIdentityServicesClient = GooglePlatform.getGoogleIdentityServicesClient();
    if (!googleIdentityServicesClient || !googleIdentityServicesClient.accounts || !googleIdentityServicesClient.accounts.id) {
      LOG.warn('Google accounts or id are not existing properties.');
      return;
    }
    googleIdentityServicesClient.accounts.id.initialize({
      client_id: googleClientId,
      callback: handleGoogleIdentityServicesCallback,
      nonce: googleSignInNonce,
      auto_prompt: false,
      use_fedcm_for_prompt: true
    });
    renderGoogleButton(googleButtonRef.current, googleClientId, width);
  }, [googleSignInNonce, libraryHasLoaded, googleButtonRef, width, handleGoogleIdentityServicesCallback, dispatch]);
};