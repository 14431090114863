import { useEffect, useRef } from 'react';
import { ErrorKey } from '../app-active-error/ErrorKey';
import { LoadingKey } from '../app-active-loading/LoadingKey';
import { useAppDispatch } from './useAppDispatch';
import { Tracker } from '../store/tracking/action-creators';
import { setError } from '../store/error/action-creators';
let timerId;
export const clearErrorTimeoutForTesting = () => {
  clearTimeout(timerId);
  timerId = undefined;
};
export const useSetErrorAfterTimeout = (loadingKey, delay = 10000, errorKey = ErrorKey.BlankScreenLoadingError) => {
  const isBlankLoadingScreen = loadingKey === LoadingKey.BlankScreenLoading;
  const dispatch = useAppDispatch();
  const timeoutId = useRef(-1);
  useEffect(() => {
    if (isBlankLoadingScreen) {
      dispatch(Tracker.signupInteraction('blank-loading-screen-error-view-shown'));
    }
  }, [dispatch, isBlankLoadingScreen]);
  useEffect(() => {
    if (!isBlankLoadingScreen || timerId) return () => {};
    const setErrorKey = () => {
      dispatch(Tracker.signupInteraction('set-error-after-timeout-triggered'));
      dispatch(setError(errorKey));
    };
    timeoutId.current = setTimeout(setErrorKey, delay);
    timerId = timeoutId.current;
    return () => {
      clearTimeout(timeoutId.current);
      clearErrorTimeoutForTesting();
    };
  }, [delay, dispatch, errorKey, isBlankLoadingScreen]);
};