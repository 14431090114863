import { getQueryParams } from '../routing/getQueryParams';
import { getLocationOrigin } from '../routing/locationUtils';
import trimEmptyValuesFromObject from '../utils/trimEmptyValuesFromObject';
const PARAMS_TO_DELETE = ['redirect', 'redirect_url', 'intent', 'token', 'flowPath', 'step'];
const filterQueryParamsObject = (extraQueryParams = {}) => {
  const queryParams = getQueryParams();
  const params = Object.assign({}, queryParams, extraQueryParams);
  PARAMS_TO_DELETE.forEach(param => !extraQueryParams[param] && delete params[param]);
  Object.keys(params).map(key => {
    return (!params[key] || !key) && delete params[key];
  });
  return params;
};
export const cleanupSignupSpecificQueryParams = (queryParamsToFilter = {}) => {
  const filteredParams = Object.fromEntries(Object.entries(queryParamsToFilter).filter(([key]) => !PARAMS_TO_DELETE.includes(key)));
  return filteredParams;
};
export const createQueryParamString = paramObject => {
  return Object.keys(paramObject).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramObject[key])}`).join('&');
};

/**
 * Build a link (usually within the product, or back to signup ) based on the following parameters
 *
 * @param link the relative path (possibly without the domain) to send to, example `/dashboard/something`
 * @param queryParamsToInclude eventually extra param to be carried with the final URL
 * @param hublet to specify which hublet we should redirect to
 * @returns {string}
 */
export const makeLinkWithQueryParams = ({
  link,
  queryParamsToInclude = {},
  hublet
}) => {
  const params = filterQueryParamsObject(trimEmptyValuesFromObject(queryParamsToInclude));
  const paramsString = createQueryParamString(params);
  const nextLinkHasParams = link.indexOf('?') > -1;
  const additionalQueryParams = paramsString ? `${nextLinkHasParams ? '&' : '?'}${paramsString}` : '';
  const origin = link.indexOf('//') !== -1 ? '' : getLocationOrigin(hublet);
  return `${origin}${link}${additionalQueryParams}`;
};