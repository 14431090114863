export let LocaleName;
(function (LocaleName) {
  LocaleName["de"] = "German";
  LocaleName["en"] = "English";
  LocaleName["es"] = "Spanish";
  LocaleName["fr"] = "French";
  LocaleName["ja"] = "Japanese";
  LocaleName["it"] = "Italian";
  LocaleName["nl"] = "Dutch";
  LocaleName["ptBr"] = "Brazilian Portuguese";
  LocaleName["fi"] = "Finnish";
  LocaleName["pl"] = "Polish";
  LocaleName["sv"] = "Swedish";
})(LocaleName || (LocaleName = {}));