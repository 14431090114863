export let CompanySizeNumber;
(function (CompanySizeNumber) {
  CompanySizeNumber[CompanySizeNumber["One"] = 1] = "One";
  CompanySizeNumber[CompanySizeNumber["TwoToFive"] = 5] = "TwoToFive";
  CompanySizeNumber[CompanySizeNumber["SixToTen"] = 10] = "SixToTen";
  CompanySizeNumber[CompanySizeNumber["ElevenToTwentyFive"] = 25] = "ElevenToTwentyFive";
  CompanySizeNumber[CompanySizeNumber["TwentySixToFifty"] = 50] = "TwentySixToFifty";
  CompanySizeNumber[CompanySizeNumber["FiftyOneToTwoHundred"] = 200] = "FiftyOneToTwoHundred";
  CompanySizeNumber[CompanySizeNumber["TwoHundredOneToOneThousand"] = 1000] = "TwoHundredOneToOneThousand";
  CompanySizeNumber[CompanySizeNumber["OneThousandOnetoTenThousand"] = 10000] = "OneThousandOnetoTenThousand";
  CompanySizeNumber[CompanySizeNumber["TenThousandOneOrMore"] = 10001] = "TenThousandOneOrMore";
})(CompanySizeNumber || (CompanySizeNumber = {}));