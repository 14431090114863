import { Flow } from 'signup-constants/Flow';
import { isFlow } from '../utils/isFlow';
import { getHublet } from '../store/app/selectors';
import { redirectExistingUserAction } from '../store/auth/action-creators';
import { getPortalId } from '../store/auth/selectors';
export const handleProvisionPartnerSuccess = (_response, dispatch, getState) => {
  const state = getState();
  if (isFlow(Flow.SolutionsProvider)) {
    const portalId = getPortalId(state);
    const hublet = getHublet(state);
    if (portalId) {
      dispatch(redirectExistingUserAction({
        portalId,
        hublet
      }));
    }
  }
};