export let AppKey;
(function (AppKey) {
  AppKey["HasRefreshedNewPortalAuth"] = "hasRefreshedNewPortalAuth";
  AppKey["Hublet"] = "hublet";
  AppKey["VerificationType"] = "verificationType";
  AppKey["VerificationExpired"] = "verificationExpired";
  AppKey["IsNewPortalCreated"] = "isNewPortalCreated";
  AppKey["IsNewUserCreated"] = "isNewUserCreated";
  AppKey["IsNewUser"] = "isNewUser";
  AppKey["CompleteVerificationComplete"] = "completeVerificationComplete";
  AppKey["Country"] = "country";
  AppKey["IsUsingOptionalDomain"] = "isUsingOptionalDomain";
  AppKey["IsOAuthExistingUser"] = "IsOAuthExistingUser";
  AppKey["IsExistingUserSession"] = "isExistingUserSession";
  AppKey["RedirectUrl"] = "redirectUrl";
  AppKey["FirstOAuthPartnerClick"] = "firstOAuthPartnerClick";
  AppKey["IsVerifiedByGoogleCredential"] = "isVerifiedByGoogleCredential";
  AppKey["WhichOAuthEndpointFailed"] = "whichOAuthEndpointFailed";
  AppKey["WhichOAuthSelected"] = "whichOAuthSelected";
  AppKey["HasAcceptedCookies"] = "hasAcceptedCookies";
  AppKey["WhichOAuthLoading"] = "whichOAuthLoading";
  AppKey["IsFreeEmailDomain"] = "isFreeEmailDomain";
})(AppKey || (AppKey = {}));