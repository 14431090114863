import { getIntentLink } from '../intents/intentSelectors';
import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { getHublet, getIsNewPortalCreated } from '../store/app/selectors';
import { getIntegratedAppAuthResponse } from '../store/integrations/selectors';
import { integratedAppRedirect, wordpressRedirect } from './productLinks';
export const getWordPressRedirect = state => {
  const hublet = getHublet(state);
  const intentLink = getIntentLink(state);
  const isNewPortal = getIsNewPortalCreated(state);
  const wordpressAuthorizationResponse = getIntegratedAppAuthResponse(state);
  if (intentLink) {
    return makeLinkWithQueryParams({
      link: intentLink,
      hublet
    });
  }
  return wordpressRedirect({
    isNewPortal,
    hublet,
    authorization: wordpressAuthorizationResponse
  });
};
export const getIntegratedAppRedirect = state => {
  const hublet = getHublet(state);
  const isNewPortal = getIsNewPortalCreated(state);
  const authorization = getIntegratedAppAuthResponse(state);
  return integratedAppRedirect({
    isNewPortal,
    hublet,
    authorization
  });
};