import styled from 'styled-components';
import { devices } from 'signup-constants/styles/viewports.styles';
import { Step } from 'signup-constants/Step';
import { LayoutType } from 'signup-components/types';
export const MessagingContentHolder = styled.div.withConfig({
  displayName: "AppViewComponentstyles__MessagingContentHolder",
  componentId: "y189g5-0"
})(["width:100%;", " @media ", "{width:450px;overflow:visible;}"], props => props.theme.embedded && `width: \\min(calc(100vw - 2 * 1.25rem), 28rem);`, devices.desktop);
export const LayoutTemplateContentContainer = styled.div.withConfig({
  displayName: "AppViewComponentstyles__LayoutTemplateContentContainer",
  componentId: "y189g5-1"
})(["", " ", ""], props => props.$layoutType === LayoutType.LeftAligned && props.$stepKey !== Step.LandingPage && `
    min-width: 452px;

  @media ${devices.desktop} {
     width: 572px;
  }
  `, props => props.$layoutType === LayoutType.CentredStep && `
    @media ${devices.tablet} {
    width: 572px;

    ${props.$shouldRestrictWidth ? `
      padding-left: 48px;
      padding-right: 48px;
      width: 528px;
    ` : ''}
    ${props.$stepKey === Step.Industry ? `width: ${props.$clickedToSeeAll ? `528px !important;
          padding:0 48px;` : `572px !important`}` : ''}

  }


  @media ${devices.desktop} {
    ${props.$stepKey === Step.ManagementLevel ? `width: 652px` : ''}
    ${props.$stepKey === Step.UseCase ? `width: 572px` : ''}
    ${props.$stepKey === Step.CompanySize ? `width: 652px` : ''}
    ${props.$stepKey === Step.Industry && !props.$clickedToSeeAll ? `width: 652px !important` : ''}
  }

      margin: auto;

`);
export const LayoutTemplateBelowTitleContainer = styled.div.withConfig({
  displayName: "AppViewComponentstyles__LayoutTemplateBelowTitleContainer",
  componentId: "y189g5-2"
})(["@media ", ",@media ", ",{", "}margin:auto;"], devices.tablet, devices.desktop, props => props.$shouldRestrictWidth && `
      ${!props.$isPostAccountCreationStep && `padding-left: 48px;
         padding-right: 48px;
        `}

      width: 528px;
    `);