let _ = t => t,
  _t;
import { OLAF } from 'HubStyleTokens/colors';
import { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle(_t || (_t = _`
  html {
    min-height: 100%;
  }
  body {
    min-height: 100% !important;
    font-size: 17px !important;
    background-color: ${0};

    small {
      font-size: 14px !important;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .cookie-banner-top-static {
    padding-top: 0.1px;
  }

  body:not([class*="cookie-banner-"]) {
    #hs-eu-cookie-confirmation {
      display: none !important;
    }
  }

  .cookie-banner-hidden {
    #hs-eu-cookie-confirmation {
      display: none !important;
    }
  }
`), OLAF);