import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { getQueryParam } from '../routing/getQueryParam';
import { Data } from 'signup-constants/signupData/Data';
import { useSignupData } from '../signup-data/useSignupData';
import { setIsUsingOptionalDomain } from '../store/app/action-creators';
import { useTracker } from '../tracking/useTracker';
import { checkCompanySizeIsLessThan5, getPresetOptionalDomain } from './companyDomainUtils';
export const OPTIONAL_DOMAIN_QUERY_PARAM = 'optional_domain';
export const useCompanyDomain = () => {
  const Tracker = useTracker();
  const dispatch = useDispatch();
  const isOptionalDomainEnabledByFlow = getFlowConfig().isOptionalDomainEnabled;
  const hasOptionalDomainParam = Boolean(getQueryParam(OPTIONAL_DOMAIN_QUERY_PARAM));
  const {
    data: companySize
  } = useSignupData(Data.CompanySize);
  const {
    data: companyName
  } = useSignupData(Data.CompanyName);
  const {
    data: companyDomain,
    setData: setCompanyDomain
  } = useSignupData(Data.CompanyDomain);
  const optionalDomain = getPresetOptionalDomain(companyName);
  const isOptionalDomainEnabled = checkCompanySizeIsLessThan5(companySize) && (hasOptionalDomainParam || isOptionalDomainEnabledByFlow);
  useEffect(() => {
    if (!isOptionalDomainEnabled && optionalDomain === companyDomain) {
      setCompanyDomain('');
    }
  });
  const onOptionalDomainSubmit = () => {
    Tracker.signupInteraction('user-does-not-have-domain', {
      companySize
    });
  };
  const setUsingOptionalDomain = value => {
    dispatch(setIsUsingOptionalDomain(value));
  };
  return {
    isOptionalDomainEnabled,
    optionalDomain,
    onOptionalDomainSubmit,
    setUsingOptionalDomain
  };
};