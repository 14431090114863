import { makeLinkWithQueryParams } from '../intents/linkUtils';
import { getHublet } from '../store/app/selectors';
import { getPortalId } from '../store/auth/selectors';
import { developers } from './productLinks';
export const getDeveloperRedirect = state => {
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  const link = developers({
    portalId,
    hublet
  });
  return makeLinkWithQueryParams({
    link,
    hublet
  });
};