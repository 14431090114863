import { ValidationStatus } from 'signup-ui-lego-core/types';
import { runReducersFirst } from '../http/runReducersFirst';
import { dataValid } from '../store/signup-data-validation/action-creators';
import { SignupDataActionTypes } from '../store/signup-data/action-types';
import { getValidator } from './getValidator';

/**
 * Triggering Actions: BULK_UPDATE, UPDATE_DATA
 * @description  either bulk updates the validation for a list of data values,
 * or updates the validation for an individual piece of data.
 * If a validator is present, the data value will be run through the validator,
 * else the data value is valid if not null.
 */
export const ValidationMiddleware = store => next => action => {
  if (action.type === SignupDataActionTypes.BulkUpdate) {
    const signupData = action.signupData;
    const dataKeys = Object.keys(signupData);
    dataKeys.map(dataKey => {
      const value = signupData[dataKey];
      if (!value) {
        return null;
      }
      const validator = getValidator(dataKey);
      if (validator) {
        return validator(store.dispatch, value);
      }
      return store.dispatch(dataValid({
        dataKey,
        validatedValue: value
      }));
    });
    return next(action);
  }
  if (action.type === SignupDataActionTypes.UpdateData) {
    const {
      dataKey,
      value
    } = action;
    const dispatchResult = runReducersFirst(next, action);
    const dispatchDataValidationAction = getValidator(dataKey);
    if (!dispatchDataValidationAction) {
      return next(Object.assign({}, action, {
        validation: value ? ValidationStatus.VALID : ValidationStatus.INVALID
      }));
    }
    dispatchDataValidationAction(store.dispatch, value);
    return dispatchResult;
  }
  return next(action);
};