import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { getApplicationLocale } from '../i18n/LocaleUtils';
const ArticleLanguage = {
  [Locale.ja]: 'jp',
  [Locale.ptBr]: 'br',
  [Locale.de]: 'de',
  [Locale.es]: 'es',
  [Locale.fr]: 'fr'
};
export const getLegalLink = urlPath => {
  let legalUrl = 'https://legal.hubspot.com';
  const locale = getApplicationLocale();
  const localisedArticleLang = ArticleLanguage[locale];
  if (locale !== Locale.en && localisedArticleLang) {
    legalUrl = `${legalUrl}/${localisedArticleLang}`;
  }
  return `${legalUrl}/${urlPath}`;
};