import { EndpointsExperiments } from './EndpointsExperiments';
import { getEndpointsPrefilling } from './getEndpointsPrefilling';
import { EndpointsSignupClient } from './EndpointsSignupClient';
import { EndpointsSignupCompleted } from './EndpointsSignupCompleted';
import { EndpointsGrowthSidePanel } from './EndpointsGrowthSidePanel';
import { getFlowEndpointOverrides } from './getFlowEndpointOverrides';
import { getEndpointsSignupInterest } from './getEndpointsSignupInterest';
import { EndpointsEndOfSignup } from './EndpointsEndOfSignup';
import { getEndpointsAuth } from './getEndpointsAuth';
import { EndpointsStoreCOAC0002Treatment } from './EndpointsStoreCoac0002Treatment';
import { EndpointsPortalTier } from './EndpointsPortalTier';
export const getEndpointConfig = flowKey => {
  return Object.assign({}, getEndpointsAuth(), getEndpointsPrefilling(), EndpointsSignupClient, EndpointsSignupCompleted, EndpointsStoreCOAC0002Treatment, EndpointsGrowthSidePanel, EndpointsEndOfSignup, EndpointsExperiments, EndpointsPortalTier, getEndpointsSignupInterest(), getFlowEndpointOverrides(flowKey));
};
export const getEndpoint = endpointKey => getEndpointConfig()[endpointKey];
export const setEndpointFetched = endpointKey => {
  const endpoint = getEndpointConfig()[endpointKey];
  if (endpoint) {
    endpoint.fetched = true;
  }
};