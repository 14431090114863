import { executeCallbackIfEmailSubmitted } from '../flow-management/checkForEmailUtils';
import { shouldRetryCompanyInfo } from '../http-company-info/shouldRetryCompanyInfo';
import { EndpointKey } from '../http/EndpointKey';
import { AppActionTypes } from '../store/app/action-types';
import { FlowActionTypes } from '../store/flow/action-types';
import { isDeveloperFlow, isEngageAIFlow } from '../store/flow/selectors';
import { retryEndpoint } from '../store/http-responses/action-creators';
import { prefillCompanyDetailsForEngageAI, prefillCompanyDomainFromEmail } from './prefillCompanyDomainFromEmail';
import { prefillHubInterest } from './prefillHubInterest';
import { prefillEmbeddedSignupData, prefillQueryParamSignupData } from './prefillSignupData';
export const PrefillingMiddleware = store => next => action => {
  const dispatchResult = next(action);
  const state = store.getState();
  if (action.type === AppActionTypes.AppStarted) {
    store.dispatch(prefillQueryParamSignupData());
  }
  if (action.type === AppActionTypes.EmbeddedPrefill) {
    store.dispatch(prefillEmbeddedSignupData(action.data));
  }
  if (action.type === FlowActionTypes.Load) {
    store.dispatch(prefillHubInterest());
  }
  if (isDeveloperFlow()) {
    executeCallbackIfEmailSubmitted(store.getState(), action, email => {
      store.dispatch(prefillCompanyDomainFromEmail(email));
    });
  }
  if (isEngageAIFlow()) {
    executeCallbackIfEmailSubmitted(store.getState(), action, email => {
      store.dispatch(prefillCompanyDetailsForEngageAI(email));
    });
  }
  if (shouldRetryCompanyInfo(state, action)) {
    store.dispatch(retryEndpoint(EndpointKey.CompanyInfo));
  }
  return dispatchResult;
};