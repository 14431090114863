export let ExperimentKeys;
(function (ExperimentKeys) {
  ExperimentKeys["COAC0002"] = "coac-0002";
  ExperimentKeys["ACQ0154"] = "acq-0154";
  ExperimentKeys["ACQ0157"] = "acq-0157";
  ExperimentKeys["GOSU0006"] = "gosu-0006";
  ExperimentKeys["ACQ0161"] = "acq-0161";
})(ExperimentKeys || (ExperimentKeys = {}));
export const getExperimentKeys = () => {
  return Object.values(ExperimentKeys);
};
export const TEST_KEY = 'TEST-002';