// @ts-expect-error Untyped module
import Cookies from 'js-cookie';
import { isQA } from '../constants/apiEnvironments';
import { seleniumTestHasUtk } from '../selenium/seleniumFeatures';
export const SELENIUM_FAKE_UTK = 'selenium-fake-utk';
const getHstc = () => {
  return Cookies.get('__hstc') || null;
};
export const getUtk = () => {
  if (isQA && seleniumTestHasUtk()) {
    return SELENIUM_FAKE_UTK;
  }
  let utk = Cookies.get('hubspotutk');
  if (!utk) {
    utk = getHstc();
    if (utk) {
      utk = utk.split('.')[1];
    }
  }
  return utk || null;
};