import { useState, useEffect, useCallback } from 'react';
import { ScreenSize } from './MediaQuery';
export const useWidthMediaQueries = () => {
  const sortedSizes = Object.values(ScreenSize).sort((a, b) => b - a);
  const mediaQueryLists = sortedSizes.map(q => window.matchMedia(`(min-width: ${q}px)`));
  const getMatchedScreenSize = useCallback(() => {
    const index = mediaQueryLists.indexOf(mediaQueryLists.filter(mql => mql.matches)[0]);
    return sortedSizes[index];
  }, [mediaQueryLists, sortedSizes]);

  // State and setter for matched screen width
  const [matchedSize, setMatchedSize] = useState(getMatchedScreenSize());
  useEffect(() => {
    const handler = () => setMatchedSize(getMatchedScreenSize());
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach(mql => mql.addEventListener('change', () => {
      handler();
    }));
    // Remove listeners on cleanup
    return () => mediaQueryLists.forEach(mql => mql.removeEventListener('change', () => {
      handler();
    }));
  }, [getMatchedScreenSize, mediaQueryLists]);
  return matchedSize;
};