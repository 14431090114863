import IndustryService from 'industries-core/services/IndustryService';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Data } from 'signup-constants/signupData/Data';
import { useSignupData } from '../signup-data/useSignupData';
import { getClickedToSeeAll, getPredictedTypeaheadValues, getTypeaheadSelectedValue, getTypeaheadTrackedUserInput, industryPredictionsSuccessfullyFetched } from '../store/typeahead/selectors';
import { useTracker } from '../tracking/useTracker';
import { Step } from 'signup-constants/Step';
import { useView } from '../views/useView';
const INDUSTRY_UNKNOWN = 'industry-unknown';
export const useIndustry = () => {
  const {
    submit
  } = useView({
    stepKey: Step.Industry
  });
  const Track = useTracker();
  const input = useSelector(getTypeaheadTrackedUserInput(Data.Industry));
  const {
    data: industryValue
  } = useSignupData(Data.Industry);
  const selectedIndustryText = useSelector(getTypeaheadSelectedValue(Data.Industry));
  const {
    data: selectedIndustryValue,
    setData: setIndustry
  } = useSignupData(Data.Industry);
  const [isLoading, setIsLoading] = useState(false);
  const clickedToSeeAll = useSelector(state => getClickedToSeeAll(state, Data.Industry));
  const predictedIndustries = useSelector(state => getPredictedTypeaheadValues(state, Data.Industry)) || [];
  const predictionsFetched = useSelector(state => industryPredictionsSuccessfullyFetched(state));
  const predictedOptions = {};
  predictedIndustries.forEach(industry => predictedOptions[industry] = {
    value: industry,
    text: IndustryService.translate(industry)
  });
  const predictionsDismissed = Boolean(predictedIndustries.length) && selectedIndustryValue && predictedIndustries.indexOf(selectedIndustryValue) === -1;
  useEffect(() => {
    let timeoutId;
    if (predictionsFetched) {
      setIsLoading(false);
    } else if (!industryValue) {
      timeoutId = setTimeout(() => setIsLoading(true), 500);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [predictionsFetched, industryValue]);
  const IndustryTracker = {
    showAll: () => Track.signupInteraction('industry-prefill-showall'),
    prefillChosen: () => Track.signupInteraction('industry-prefill-chosen')
  };
  const submitIndustry = () => {
    if (!selectedIndustryValue) {
      setIndustry(INDUSTRY_UNKNOWN);
      Track.signupInteraction('industry-unknown');
    }
    submit();
  };
  return {
    selectedIndustryValue,
    selectedIndustryText,
    setIndustry,
    predictedIndustries,
    predictedOptions,
    predictionsDismissed,
    predictionsFetched,
    IndustryTracker,
    clickedToSeeAll,
    input,
    submitIndustry,
    isLoading
  };
};