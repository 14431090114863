import { CRM_SIGNUP } from '../constants/signupConstants';
import { HubletUrl } from '../utils/urls';
const setupExperiences = [{
  redirectPath: portalId => {
    return {
      url: `${HubletUrl.getAppUrl()}/set-up-crm/${portalId}/use-cases`
    };
  },
  checks: [{
    signupType: CRM_SIGNUP
  }]
}];
export default setupExperiences;