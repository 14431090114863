import enviro from 'enviro';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { EmailError, syncEmailValidator } from 'signup-ui-lego-core/validaton/emailValidator';
import { Tracker } from '../../tracking/action-creators';
import { ValidationError } from '../errors/ValidationError';
import { getStatusMessage } from '../utils/getStatusMessage';
import { getDomainFromEmail } from '../../../utils/getDomainFromEmail';
import { freemailDomains } from '../../../experiments/acq0143/freemailDomains';
import { setIsFreeEmailDomain } from '../../app/action-creators';
const sync = (email, state, dispatch) => {
  const domain = getDomainFromEmail(email);
  const isFreeEmailDomain = freemailDomains.includes(domain);
  if (isFreeEmailDomain) {
    dispatch(setIsFreeEmailDomain());
  }
  return syncEmailValidator(email);
};
const async = (email, dispatch) => {
  const requestBody = {
    email
  };
  return noAuthApiClient.post('/signup/v1/validation/email', {
    data: requestBody
  }).then(response => {
    // We need this check because the BE sends 200 responses with
    // the error as part of the response. I know. Bleh.
    // TODO: Get BE to always return a non 200 if the data is not valid
    if ('status' in response) {
      dispatch(Tracker.formError('email-error', `${response.status}`));
      // Because this is a situation where the request worked (status 200)
      // but there is an error as part of the response body we need to
      // throw an error to call the catch method below.
      throw new ValidationError(200, response);
    }
    return response;
  }).catch(error => {
    let formError;

    // Here is where the ValidationError thrown above will be handled
    if (error instanceof ValidationError) {
      formError = error.response.status;
    } else {
      formError = error.status === 403 ? 'forbidden-email-domain' : getStatusMessage(error.responseText);
    }
    dispatch(Tracker.formError('email-error', formError));
    throw error;
  });
};
const getErrorMessage = (_value, error, dataKey) => {
  if (typeof error === 'string') {
    // From Sync Validation
    if (error === EmailError.NOT_AN_EMAIL) {
      return `data.${dataKey}.error.invalid`;
    }
  } else if (error instanceof ValidationError) {
    if (error.response.message === EmailError.SECONDARY_EMAIL) {
      return `data.${dataKey}.error.secondaryEmail`;
    }
    if (error.response.status === EmailError.INVALID_USERNAME) {
      return `data.${dataKey}.error.invalid`;
    }
  } else {
    if (error.status === 403) {
      return enviro.isProd() ? `data.${dataKey}.error.forbidden` : `data.${dataKey}.error.forbiddenQA`;
    }
  }
  return `data.${dataKey}.error.default`;
};
export const emailValidator = {
  sync,
  async,
  getErrorMessage
};