import { useSelector } from 'react-redux';
import { LayoutType, WaveType } from 'signup-components/types';
import { Step } from 'signup-constants/Step';
import { useSignupEmbedder } from 'signup-embedder/hooks/useSignupEmbedder';
import { LoadingKey } from '../app-active-loading/LoadingKey';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { useMessage } from '../messaging/useMessage';
import { getActiveError } from '../store/error/selectors';
import { getPostAccountCreationSteps } from '../store/flow/selectors';
import { getActiveLoading } from '../store/loading-status/selectors';
import { getStepConfig } from '../views/getStepConfig';
import { useCurrentStep } from './useCurrentStep';
import { TrackWhen, useExperiment } from '../experiments/useExperiment';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
import { useIsFramedCentredModalStep } from './useIsFramedCerntredModalStep';
export const getWaveType = ({
  currentStep,
  postAccountCreationSteps,
  activeLoading,
  stepConfig
}) => {
  const isPostAccountCreationStep = postAccountCreationSteps.includes(currentStep);
  if (activeLoading || !stepConfig) {
    return undefined;
  }
  if (isPostAccountCreationStep && getFlowConfig().shouldUseWaveOnPostAccountCreation || currentStep === Step.InstallSalesExtension) {
    return WaveType.LowOrange;
  }
  return stepConfig.waveType;
};
export const useLayoutTemplate = () => {
  const postAccountCreationSteps = useSelector(getPostAccountCreationSteps);
  const {
    currentStep
  } = useCurrentStep();
  const activeError = useSelector(getActiveError);
  const step = activeError || currentStep;
  const stepConfig = getStepConfig(step);
  const {
    isActive: isInstanceOfSignupEmbedder
  } = useSignupEmbedder();
  const {
    isFramedCentredModalStep
  } = useIsFramedCentredModalStep();
  const showMessagingAboveContent = stepConfig && stepConfig.showMessagingAboveContent;
  const {
    shouldDisplayMessaging
  } = useMessage();
  const {
    isAssignedToVariant: isCOAC002Variant
  } = useExperiment(ExperimentKeys.COAC0002, TrackWhen.never);
  const layoutType = shouldDisplayMessaging && !showMessagingAboveContent ? LayoutType.TwoColumn : getFlowConfig().getLayoutType();
  const activeLoading = useSelector(getActiveLoading);
  const waveType = getWaveType({
    currentStep,
    postAccountCreationSteps,
    activeLoading,
    stepConfig
  });
  const isPostAccountCreationStep = postAccountCreationSteps.includes(currentStep);
  const isNotVerificationLoading = activeLoading !== LoadingKey.InitiateVerificationRequest;
  const stepConfigHasProgressBar = stepConfig && stepConfig.stepKey && !stepConfig.hideProgressBar;
  const shouldShowProgressBar = isNotVerificationLoading && !activeError && stepConfigHasProgressBar;
  if (isFramedCentredModalStep) {
    return {
      layoutType: LayoutType.FramedCentredModalStep,
      waveType,
      shouldShowProgressBar
    };
  }
  if (activeLoading) {
    return {
      layoutType: LayoutType.CentredStep,
      waveType,
      shouldShowProgressBar
    };
  }
  if (isPostAccountCreationStep && !isInstanceOfSignupEmbedder) {
    if (currentStep === Step.UseCase && isCOAC002Variant()) {
      return {
        layoutType: LayoutType.CentredStep,
        shouldShowProgressBar
      };
    }
    return {
      layoutType,
      waveType,
      shouldShowProgressBar
    };
  }
  return {
    layoutType,
    waveType,
    shouldShowProgressBar
  };
};