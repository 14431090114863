import { designManager } from '../signup-completed-redirection/productLinks';
import { getPortalId } from '../store/auth/selectors';
import { getHublet } from '../store/app/selectors';
export const getStandaloneCmsPartnerRedirect = state => {
  const portalId = getPortalId(state);
  const hublet = getHublet(state);
  return designManager({
    portalId,
    hublet
  });
};