import { getStepData } from '../../step/getStepData';
import { Step } from 'signup-constants/Step';
import { VisitorContextTrackingInteractions } from '../../visitor-context-fetch/VisitorContextTrackingInteractions';
import { bulkUpdate } from '../signup-data/action-creators';
import { getPreFilledSignupDataKeys, getSignupDataValues } from '../signup-data/selectors';
import { Tracker } from '../tracking/action-creators';
import { VisitorContextActionTypes } from './action-types';
import { shouldSkipPrefilledSteps } from '../../flow-settings/prefillDataSelectors';
export const fetchVisitorContext = () => dispatch => {
  return dispatch({
    type: VisitorContextActionTypes.Fetch
  });
};
export const filledVisitorContext = () => dispatch => {
  return dispatch({
    type: VisitorContextActionTypes.Filled
  });
};
export const notFilledVisitorContext = () => dispatch => {
  return dispatch({
    type: VisitorContextActionTypes.NotFilled
  });
};
export const bulkUpdateVisitorContext = visitorContext => (dispatch, getState) => {
  const state = getState();
  const landingPageDataKeys = getStepData(Step.LandingPage);
  const landingPageDataValues = getSignupDataValues(state, landingPageDataKeys);
  const canUpdateLandingPage = landingPageDataValues.every(dataValue => !dataValue);
  let actionToDispatch = notFilledVisitorContext;
  if (canUpdateLandingPage) {
    // Check if any of the values are not null, undefined or an empty string
    const doesContextHasValidData = Object.values(visitorContext).some(v => v !== null && v !== undefined && v !== '');
    if (doesContextHasValidData) {
      if (shouldSkipPrefilledSteps()) {
        const updatedVisitorContext = Object.assign({}, visitorContext);
        getPreFilledSignupDataKeys(state).forEach(key => {
          if (key in updatedVisitorContext) {
            delete updatedVisitorContext[key];
          }
        });
        dispatch(bulkUpdate(updatedVisitorContext));
      } else {
        dispatch(bulkUpdate(visitorContext));
      }
      actionToDispatch = filledVisitorContext;
    }
  }
  dispatch(actionToDispatch());
};
export const trackVisitorContextFetch = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.Fetch));
};
export const trackVisitorContextFilled = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.Filled));
};
export const trackVisitorContextNotFilled = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.NotFilled));
};
export const trackVisitorContextNewUser = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.NewUser));
};
export const trackVisitorContextNotFound = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.NotFound));
};
export const trackVisitorContextExistingUser = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.ExistingUser));
};
export const trackVisitorContextFailure = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.Failure));
};
export const trackVisitorContextRateLimited = () => dispatch => {
  dispatch(Tracker.signupInteraction(VisitorContextTrackingInteractions.RateLimited));
};