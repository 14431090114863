import { trialIdToTrialNames } from 'self-service-api/constants/Trials';
import { Flow } from 'signup-constants/Flow';
import { isFlow } from '../utils/isFlow';
import { buildField } from '../hubspot-forms/form-data-utils/hubspotFormsUtils';
import { getQueryParam } from '../routing/getQueryParam';
export const getTrialName = () => {
  const trialId = Number(getQueryParam('trialId'));
  const trialName = trialIdToTrialNames[trialId];
  return trialName || '';
};
export const getTrialSource = () => {
  return getQueryParam('utm_source') || getQueryParam('dtt_source');
};
export const updateFormFieldsTrialInformation = fields => {
  if (isFlow(Flow.Trial)) {
    const trialSource = getTrialSource();
    const trialName = getTrialName();
    if (trialName) {
      fields.push(buildField(trialName, 'recent_in_app_trial_product'));
    }
    if (trialSource) {
      fields.push(buildField(trialSource, 'trial_source'));
    }
  }
};