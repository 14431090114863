import isValidI18nKey from 'I18n/utils/isValidI18nKey';
import { Flow } from 'signup-constants/Flow';
import { SignupEmbedder } from 'signup-embedder';
import { isFlow } from '../utils/isFlow';
import { Step } from 'signup-constants/Step';
import { getAcademyLandingPageNextButtonText, getAcademyLandingPageTitle } from './getAcademyLandingPageText';
import { getMicroappKey } from '../routing/getMicroappKey';
export const getLandingPageTitle = flowKey => {
  const stepKey = Step.LandingPage;
  const microappKey = getMicroappKey();
  let titleLabel = `views.${stepKey}.title.${flowKey}`;
  let subtitleLabel = `views.${stepKey}.subtitle.${flowKey}`;
  if (isFlow(Flow.Academy)) {
    titleLabel = getAcademyLandingPageTitle(stepKey);
  }
  if (isFlow(Flow.Microapp) && microappKey) {
    titleLabel = `views.${stepKey}.title.${flowKey}.${microappKey}`;
    subtitleLabel = `views.${stepKey}.subtitle.${flowKey}.${microappKey}`;
  }
  const title = isValidI18nKey(titleLabel) ? titleLabel : `views.${stepKey}.title.generic`;
  return {
    title,
    subtitleLabel
  };
};
export const getLandingPageButton = () => {
  const isEmbedded = SignupEmbedder.isEmbedded();

  // check if is Academy Flow and embedded flow
  if (isFlow(Flow.Academy) && isEmbedded) {
    return getAcademyLandingPageNextButtonText(Step.LandingPage);
  }
  return `${Step.LandingPage}.buttonLabel.default`;
};