import { useEffect } from 'react';
import { ConvertGoalType } from '../convert/ConvertGoalType';
import { useConvertTrack } from '../convert/useConvertTrack';
import { useTracker } from './useTracker';
let hasSignupInitiatedBeenTrackedCache = false;
export function getHasSignupInitiatedBeenTracked() {
  return hasSignupInitiatedBeenTrackedCache;
}
export function setHasSignupInitiatedBeenTracked(value) {
  hasSignupInitiatedBeenTrackedCache = value;
}
export const useTrackSignupInitiated = (shouldTrackSignupInitiated = true) => {
  const Track = useTracker();
  useConvertTrack(ConvertGoalType.SignupInitiated);
  const hasSignupInitiatedBeenTracked = getHasSignupInitiatedBeenTracked();
  useEffect(() => {
    if (!getHasSignupInitiatedBeenTracked() && shouldTrackSignupInitiated) {
      Track.signupInitiated();
      setHasSignupInitiatedBeenTracked(true);
    }
  }, [Track, hasSignupInitiatedBeenTracked, shouldTrackSignupInitiated]);
};