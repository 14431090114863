export const setupMonocle = () => {
  try {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.src = 'https://mcl.spur.us/d/mcl.js?tk=3CjaJaxSGzYLmalddVROqFxBnniKb0TUbh2Z78yqupuJ0QM1hEm38GknbBuXYIPfmuUFXD0unaeIsvvJjbHYjK3xnqig8eSpbQCx1xinTWnPapqqlFxUtzCspCzGd4AdTq8DjSmxOzRxVq7obrhWRvvz8Y747rBoW4fd2PC3dDjM4qDLC6d2P6m1QDym4GLeJlXkMcHS3kut1L78AIsbbGkgEGBv7HQXUBBge3yqgT1vCD06EToyvKRyoriUMh47mOK29UyXS9VPKnFa8iD2IU8szBU986Bwu87mxSljyQsSMbwoFikuqOrUohKNodtZxBxmmLO40pWqsNy04Up67yVxGBNTquxlGYJZgozOeoxtqK2BNNMOyeNQ3yK82zxLzr4Ov1LxOtuSI';
      script.id = '_mcl';
      script.async = true;
      document.head.appendChild(script);
    }
  } catch (_unused) {
    // for now, let this function fail silently in the background
  }
  return Promise.resolve();
};