import Logger from '../lib/Logger';
import { getCurrentStep, getNextSteps, hasFlowLoaded, isEndOfFlow } from '../store/flow/selectors';
import { clearLoadingStatus, setLoadingStatus } from '../store/loading-status/action-creators';
import { getActiveLoading, isLoadingKeyActive } from '../store/loading-status/selectors';
import { LoadingKey } from './LoadingKey';
const LOG = Logger.getLogger('LoadingBlankScreenMiddleware');
const isBlankScreen = state => isEndOfFlow(state) && !getActiveLoading(state);
const shouldClearBlankScreenLoading = state => !isEndOfFlow(state) && isLoadingKeyActive(state, LoadingKey.BlankScreenLoading);
export const LoadingBlankScreenMiddleware = store => next => action => {
  const result = next(action);
  const loadingKey = LoadingKey.BlankScreenLoading;
  const state = store.getState();
  const currentStep = getCurrentStep(state);
  if (isBlankScreen(state)) {
    if (getNextSteps(state).length === 0 && hasFlowLoaded(state)) {
      LOG.info('Blank Loading Screen Shown', {
        extra: currentStep
      });
    }
    store.dispatch(setLoadingStatus(loadingKey));
  }
  if (shouldClearBlankScreenLoading(state)) {
    store.dispatch(clearLoadingStatus(loadingKey));
  }
  return result;
};