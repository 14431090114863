export let MessageKey;
(function (MessageKey) {
  MessageKey["CheckYourEmail"] = "CHECK_YOUR_EMAIL";
  MessageKey["CheckYourEmailDefaultAnimation"] = "CHECK_YOUR_EMAIL_DEFAULT_ANIMATION";
  MessageKey["CheckYourEmailAlternativeIllustration"] = "CHECK_YOUR_EMAIL_ALTERNATIVE_ILLUSTRATION";
  MessageKey["CrmCentralPlace"] = "CRM_CENTRAL_PLACE";
  MessageKey["WordpressConnect"] = "WORDPRESS_CONNECT";
  MessageKey["BlondeShelley"] = "BLONDE_SHELLEY";
  MessageKey["CampaignAssistant"] = "CAMPAIGN_ASSISTANT";
  MessageKey["BlogGenerator"] = "BLOG_GENERATOR";
  MessageKey["LandingPageCreator"] = "LANDING_PAGE_CREATOR";
  MessageKey["UseCase"] = "USE_CASE";
  MessageKey["CompanyDomain"] = "COMPANY_DOMAIN";
  MessageKey["InvoiceGenerator"] = "INVOICE_GENERATOR";
  MessageKey["ShopifyEmbeddedConnect"] = "SHOPIFY_EMBEDDED_CONNECT";
})(MessageKey || (MessageKey = {}));