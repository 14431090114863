import { CodeVerificationActionTypes } from './action-types';
export const codeVerificationStart = () => dispatch => {
  dispatch({
    type: CodeVerificationActionTypes.Start
  });
};
export const codeVerificationSuccess = () => dispatch => {
  dispatch({
    type: CodeVerificationActionTypes.Success
  });
};
export const codeVerificationFailed = () => dispatch => {
  dispatch({
    type: CodeVerificationActionTypes.Failed
  });
};
export const codeVerificationExpiredRefreshInitiate = () => dispatch => {
  dispatch({
    type: CodeVerificationActionTypes.ExpiredRefreshInitiate
  });
};