import { getCurrentStep } from '../store/flow/selectors';
import { signupDataAreFilled } from '../store/signup-data/selectors';
import { hasIntersection } from '../utils/data-structure-utils/hasIntersection';
import { getStepData } from '../step/getStepData';
export const hasDataJustBeenFilled = (state, data) => {
  if (!data) {
    return false;
  }
  const currentStep = getCurrentStep(state);
  if (currentStep) {
    const currentData = getStepData(currentStep);
    const hasLastStepCompletedData = hasIntersection(data, currentData) && signupDataAreFilled(state, data);
    return hasLastStepCompletedData;
  }
  return false;
};