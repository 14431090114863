import Logger from '../lib/Logger';
import { normalizeUrl } from '../utils/normalizeUrl';
import { getHistory } from '../routing/history';
import { getFullUrl } from 'hubspot-url-utils';
const LOG = Logger.getLogger('ReferrerUtils');

/**
 * Get the preferred referrer based on the following priorities:
 *
 * 1. The OAuth payload referrer property.
 * 2. The preReferrer query parameter = explicit referrer coming back from verification link.
 * 3. The document referrer.
 * 4. The hubs_signup-url parameter as a fall back for all CTA coming from website.
 *
 * @returns {string} The preferred referrer.
 */
export const getPreferredReferrer = () => {
  const queryParams = new URLSearchParams(getHistory().location.search);
  const preReferrer = queryParams.get('preReferrer');
  const hubsSignupUrlReferrer = queryParams.get('hubs_signup-url');
  const documentReferrer = document.referrer;
  const referrer = normalizeUrl(preReferrer || hubsSignupUrlReferrer || documentReferrer || '');
  return referrer;
};
export const getLoginRedirectUrlOrigin = url => {
  let urlInstance;
  try {
    urlInstance = new URL(url);
  } catch (_unused) {
    LOG.info('Invalid login redirect url', {
      extra: url
    });
    return '';
  }
  const origin = urlInstance.origin;
  if (origin === `${getFullUrl('app')}` || origin === `${getFullUrl('app-eu1')}`) {
    const substring = urlInstance.pathname.substring(1);
    const regexMatchPortalId = substring.match('/(.*?)/');
    if (regexMatchPortalId) {
      const portalId = regexMatchPortalId[1];
      if (portalId && parseInt(portalId, 10)) {
        urlInstance.pathname = urlInstance.pathname.replace(portalId, '-');
        return `${urlInstance.origin}${urlInstance.pathname}`;
      }
    }
  }
  return origin;
};
export const getReferrerParams = () => {
  const referrer = getPreferredReferrer();
  if (!referrer || referrer === '') {
    return {};
  }
  try {
    const referrerUrl = new URL(referrer);
    return {
      referrerHost: referrerUrl.hostname,
      referrerPath: referrerUrl.pathname,
      referrerUrl: referrerUrl.href,
      referrerParams: referrerUrl.search.substr(1)
    };
  } catch (error) {
    LOG.info('Invalid refferer url', {
      extra: referrer
    });
    return {};
  }
};