import getWordPressConnectUrl from 'hubspot-plugin-common/getWordPressConnectUrl';
import { getApplicationDomain } from '../constants/apiEnvironments';
import PortalIdContainer from '../portal-id-container/PortalIdContainer';
import { getQueryParam } from '../routing/getQueryParam';
import { getFullUrl } from 'hubspot-url-utils';
export const academy = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/academy/${portalId}`;
};
export const academyTracks = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/academy/${portalId}/tracks`;
};
export const developers = ({
  hublet,
  portalId = PortalIdContainer.get()
}) => {
  return `${getApplicationDomain(hublet)}/developers/${portalId}`;
};
export const cmsDevelopers = ({
  hublet,
  portalId = PortalIdContainer.get()
}) => {
  return `${getApplicationDomain(hublet)}/design-manager/${portalId}`;
};
export const startupApplication = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  const hubsSignupCta = getQueryParam('hubs_signup-cta');
  const redirectLinkBase = `${getApplicationDomain(hublet)}/hsfx-programs/${portalId}`;
  if (hubsSignupCta && hubsSignupCta === 'hsfx-elevate') {
    return `${redirectLinkBase}/elevate`;
  }
  if (hubsSignupCta && hubsSignupCta === 'hsfx-strategic-partner') {
    return `${redirectLinkBase}/strategic-partner`;
  }
  return redirectLinkBase;
};
export const startupPartnersApplication = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/hsfx-programs/${portalId}/partner`;
};
export const solutionsProviderApplication = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/partner-home/${portalId}/application`;
};
export const partnerClients = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/partner/${portalId}/clients`;
};
export const directoryListingApplication = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/partner-home/${portalId}/solutions-directory-application`;
};
export const marketPlaceProviders = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/marketplace-providers/${portalId}/profile`;
};
export const marketPlaceProvidersTemplates = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/marketplace-providers/${portalId}/templates`;
};
export const wordpressRedirect = ({
  isNewPortal,
  hublet,
  authorization
}) => {
  const wpAdminUrl = getQueryParam('adminUrl');
  const wpNonce = getQueryParam('nonce');
  return getWordPressConnectUrl(wpAdminUrl, wpNonce, Object.assign({}, authorization, {
    hublet,
    is_new_portal: isNewPortal
  }));
};
export const integratedAppRedirect = ({
  isNewPortal,
  hublet,
  authorization,
  portalId = PortalIdContainer.get()
}) => {
  const adminUrl = getQueryParam('adminUrl');
  const queryParams = Object.assign({}, authorization, {
    hublet,
    is_new_portal: isNewPortal,
    portal_id: portalId
  });
  return Object.entries(queryParams).reduce((url, [key, value]) => value ? `${url}&${encodeURIComponent(key)}=${encodeURIComponent(value)}` : url, `${adminUrl}?`);
};
export const facebookRedirect = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  const pageId = getQueryParam('page_id');
  return `${getApplicationDomain(hublet)}/ads-external-setup/${portalId}/facebook?page_id=${pageId}`;
};
export const leadsKnowledgeBaseArticle = () => {
  return 'https://knowledge.hubspot.com/partner-tools/create-shared-partner-deals';
};
export const learnAboutPartners = () => {
  return 'https://www.hubspot.com/partners';
};
export const designManager = ({
  portalId = PortalIdContainer.get(),
  hublet
}) => {
  return `${getApplicationDomain(hublet)}/design-manager/${portalId}/getting-started`;
};
export const hubSpotConnectRedirect = ({
  isNewUser
}) => {
  const qsParam = isNewUser ? 'create-profile' : 'must-accept-tos';
  return `${getFullUrl('app')}/connect-beta/auth-redirect?${qsParam}=true`;
};
export const hubSpotEngageAIRedirect = ({
  isNewUser,
  portalId
}) => {
  if (isNewUser) {
    return `${getFullUrl('app')}/engage-ai/${portalId}/get-started`;
  }
  return `${getFullUrl('app')}/engage-ai/${portalId}/home`;
};