export let AuthActionTypes;
(function (AuthActionTypes) {
  AuthActionTypes["SetupAuth"] = "[Auth] Setup Auth";
  AuthActionTypes["SetupAuthForNewPortal"] = "[Auth] Setup Auth For New Portal";
  AuthActionTypes["SetupAuthForOAuthExistingUser"] = "[Auth] Setup Auth For OAuth Existing User";
  AuthActionTypes["SetPortalId"] = "[Auth] Set Portal Id";
  AuthActionTypes["InitializeAuth"] = "[Auth] Initialize Auth";
  AuthActionTypes["UpdateAuth"] = "[Auth] Update Auth";
  AuthActionTypes["ExistingOAuthUser"] = "[Auth] Existing OAuth User";
  AuthActionTypes["NewOAuthUser"] = "[Auth] New OAuth User";
  AuthActionTypes["ExistingUser"] = "[Auth] Existing User";
  AuthActionTypes["RedirectExistingUser"] = "[Auth] Redirect Existing User";
  AuthActionTypes["ClickCreateNewAccountSameUser"] = "[Auth] Click Create New Account Same User";
  AuthActionTypes["PortalSelected"] = "[Auth] Portal Selected";
  AuthActionTypes["OAuthAuthenticationFailed"] = "[Auth] OAuth Exisitng Authentication Failed";
  AuthActionTypes["SetUserId"] = "[Auth] Set User Id";
  AuthActionTypes["SetLoginSuccessStatus"] = "[Auth] Set Login Success Status";
})(AuthActionTypes || (AuthActionTypes = {}));