// @ts-expect-error Untyped module
import Cookies from 'js-cookie';
import { getUtk } from 'usage-tracker-core/common/defaultTrackers';

// __hs_cookie_cat_pref value comes in this format e.g: 1:true,2:false,3:false
// 1 = Analytics
// 2 = Advertisement
// 3 = Functionality

export const hasDeclinedCookies = () => Cookies.get('__hs_cookie_cat_pref') && Cookies.get('__hs_cookie_cat_pref').includes('1:false') || Cookies.get('__hs_opt_out') === 'yes';
export const hasAcceptedCookies = () => {
  return getUtk() && (Cookies.get('__hs_cookie_cat_pref') && Cookies.get('__hs_cookie_cat_pref').includes('1:true') || Cookies.get('__hs_opt_out') === 'no') || false;
};