import styled from 'styled-components';
import Small from 'UIComponents/elements/Small';
import UIFormControl from 'UIComponents/form/UIFormControl';
import { devices } from 'signup-constants/styles/viewports.styles';
export const TypeaheadFormControl = styled(UIFormControl).withConfig({
  displayName: "Formsstyles__TypeaheadFormControl",
  componentId: "zwd344-0"
})(["min-height:6.8rem;label{font-size:16px;font-weight:400;}@media ", "{min-height:8.25rem;}"], devices.desktop);
export const ValidationText = styled(Small).withConfig({
  displayName: "Formsstyles__ValidationText",
  componentId: "zwd344-1"
})(["font-weight:400;"]);
export const SignupDataInputIcon = styled.div.withConfig({
  displayName: "Formsstyles__SignupDataInputIcon",
  componentId: "zwd344-2"
})(["position:absolute;bottom:0;top:0;right:2px;display:flex;align-items:center;"]);