import I18n from 'I18n';
import { CATEGORY_JOB_ROLES_MAP } from '../data/CategoryJobRolesMap';
import { JOB_ROLE_LIST } from '../data/JobRoleList';
/**
 * The JobRole Service.
 *
 * @export
 * @class JobRoleService
 */
export class JobRoleService {
  constructor() {
    this.state = {
      shouldUseRelevantSearch: false
    };
  }
  /**
   * Get all available job roles.
   *
   * @returns {Array.<{ id: String, value: String, category: String, text: String }>}
   * @memberof JobRoleService
   */
  all() {
    return new Promise(resolve => {
      resolve(JOB_ROLE_LIST.map(this.transform.bind(this)));
    });
  }

  /**
   * Transform a jobRole entry adding extra properties needed.
   *
   * @param {{ id: String }} jobRole
   * @returns {{ id: String, value: String, category: String, text: String}} The transformed object.
   * @memberof JobRoleService
   */
  transform(jobRole) {
    return Object.assign({}, jobRole, {
      value: jobRole.id,
      category: this.getCategory(jobRole),
      text: I18n.text(`jobRole.${jobRole.id}`)
    });
  }

  /**
   * Get the category that a jobRole should be contained.
   *
   * @param {{ id: String }} jobRole
   * @returns {String} The category of a given jobRole.
   * @memberof JobRoleService
   */
  getCategory(jobRole) {
    if (!jobRole || !jobRole.id) {
      return 'other';
    }
    for (const key in CATEGORY_JOB_ROLES_MAP) {
      if (Object.hasOwnProperty.call(CATEGORY_JOB_ROLES_MAP, key)) {
        const jobRoles = CATEGORY_JOB_ROLES_MAP[key];
        if (jobRoles.includes(jobRole.id)) {
          return key;
        }
      }
    }
    return 'other';
  }
  setShouldUseRelevantSearch(shouldUseRelevantSearch) {
    this.state.shouldUseRelevantSearch = shouldUseRelevantSearch;
  }
}
export default new JobRoleService();