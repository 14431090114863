import { getFlowSteps } from '../flow-initial/getFlowConfig';
import { getInitialFlow } from '../flow-initial/getInitialFlow';
import { setFlow } from '../store/flow/action-creators';
import { getFlowStepsFromState, getNextSteps, hasFlowLoaded } from '../store/flow/selectors';
import { removeBulkInArray, replaceElementWithBulk } from './arrayUtils';
export const getFlowandNextSteps = state => {
  const flowLoaded = hasFlowLoaded(state);
  let flowSteps = getFlowSteps();
  let oldNextSteps = getInitialFlow().initialFlow;
  if (flowLoaded) {
    flowSteps = getFlowStepsFromState(state);
    oldNextSteps = getNextSteps(state);
  }
  return {
    flowSteps,
    oldNextSteps
  };
};
export const pushNextSteps = (...nextSteps) => (dispatch, getState) => {
  const state = getState();
  const {
    flowSteps,
    oldNextSteps
  } = getFlowandNextSteps(state);
  if (oldNextSteps.length > 0) {
    //Go through each array of steps to find where the new step should be in the flow
    Object.keys(flowSteps).forEach(key => {
      if (flowSteps[key].includes(oldNextSteps[0])) {
        //Remove duplicates
        flowSteps[key] = flowSteps[key].filter(step => !nextSteps.includes(step));
        const oldNextStepIndex = flowSteps[key].indexOf(oldNextSteps[0]);
        //Slice the array at the correct step to insert the new steps
        flowSteps[key] = [...flowSteps[key].slice(0, oldNextStepIndex), ...nextSteps, ...flowSteps[key].slice(oldNextStepIndex)];
      }
    });
  }
  const newNextSteps = [...nextSteps, ...oldNextSteps.filter(step => !nextSteps.includes(step))];
  dispatch(setFlow(newNextSteps, flowSteps.preVerification, flowSteps.postVerification, flowSteps.postAccountCreation, flowSteps.postExistingAccountSelection));
  return newNextSteps;
};
export const replaceStep = (oldStep, ...newSteps) => (dispatch, getState) => {
  const state = getState();
  const {
    flowSteps,
    oldNextSteps
  } = getFlowandNextSteps(state);
  Object.keys(flowSteps).forEach(key => {
    if (flowSteps[key].includes(oldStep)) {
      flowSteps[key] = replaceElementWithBulk(flowSteps[key], oldStep, newSteps);
    }
  });
  const newNextSteps = replaceElementWithBulk(oldNextSteps, oldStep, newSteps);
  dispatch(setFlow(newNextSteps, flowSteps.preVerification, flowSteps.postVerification, flowSteps.postAccountCreation, flowSteps.postExistingAccountSelection));
  return newNextSteps;
};
export const removeSteps = (stepsToRemove, shouldMoveToNextStep = true) => (dispatch, getState) => {
  const state = getState();
  const {
    flowSteps,
    oldNextSteps
  } = getFlowandNextSteps(state);
  Object.keys(flowSteps).forEach(key => {
    flowSteps[key] = removeBulkInArray(flowSteps[key], stepsToRemove);
  });
  const newNextSteps = removeBulkInArray(oldNextSteps, stepsToRemove);
  dispatch(setFlow(newNextSteps, flowSteps.preVerification, flowSteps.postVerification, flowSteps.postAccountCreation, flowSteps.postExistingAccountSelection, shouldMoveToNextStep));
  return newNextSteps;
};
export const addLastStepsBeforeAccountCreation = (...nextSteps) => (dispatch, getState) => {
  const state = getState();
  const {
    flowSteps,
    oldNextSteps
  } = getFlowandNextSteps(state);
  let newNextSteps = [...oldNextSteps];
  if (oldNextSteps.length > 0) {
    const stepBeforeAccountCreation = oldNextSteps.indexOf(flowSteps.postVerification[flowSteps.postVerification.length - 1]);
    if (stepBeforeAccountCreation !== -1) {
      newNextSteps = [...oldNextSteps.slice(0, stepBeforeAccountCreation + 1), ...nextSteps, ...oldNextSteps.slice(stepBeforeAccountCreation + 1)];
    }
  }
  dispatch(setFlow(newNextSteps, flowSteps.preVerification, [...flowSteps.postVerification, ...nextSteps], flowSteps.postAccountCreation, flowSteps.postExistingAccountSelection, false));
  return newNextSteps;
};
export const FlowStateManager = {
  replaceStep,
  removeSteps,
  pushNextSteps,
  addLastStepsBeforeAccountCreation
};