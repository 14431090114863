export let MetricsCounter;
(function (MetricsCounter) {
  MetricsCounter["StandardErrorCreation"] = "standard-creation-error";
  MetricsCounter["Embedder"] = "embedder";
  MetricsCounter["PopupBlocked"] = "signup-from-popup-blocked";
  MetricsCounter["RecaptchaSignupError"] = "recaptcha-signup-error";
  MetricsCounter["UserUnauthorized"] = "user_unauthorized";
  MetricsCounter["EmbeddingPageContainsUnknownDomain"] = "embedding-page-contains-unknown-domain";
  MetricsCounter["OauthFailedToVerifyToken"] = "oauth-failed-to-verify-token";
  MetricsCounter["OauthVerificationUnknownError"] = "oauth-verification-unknown-error";
  MetricsCounter["OauthAuthStarted"] = "oauth-auth-started";
  MetricsCounter["OauthAuthSucceeded"] = "oauth-auth-succeeded";
  MetricsCounter["OauthAuthFailed"] = "oauth-auth-failed";
  MetricsCounter["OauthAuthVerificationStarted"] = "oauth-auth-verification-started";
  MetricsCounter["OauthAuthVerificationSucceeded"] = "oauth-auth-verification-succeeded";
  MetricsCounter["OauthAuthVerificationFailed"] = "oauth-auth-verification-failed";
  MetricsCounter["SignupInitiated"] = "signup-initiated";
  MetricsCounter["SignupCompleted"] = "signup-completed";
  MetricsCounter["RetryBlogScopesEndpoint"] = "retry-blog-scopes-endpoint";
  MetricsCounter["SetupAppError"] = "setup-app-error";
  MetricsCounter["HubletMismatch"] = "hublet-mismatch";
})(MetricsCounter || (MetricsCounter = {}));