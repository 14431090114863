import { checkIfIsGooglePromptExperimentAndExposureTracked, getActiveGooglePromptExperimentId } from '../google-authentication/google-prompt/googlePromptTracking';
import { getRouteParams } from '../routing/getRouteParams';
import { getIsExistingUserSession, getIsNewPortalCreated } from '../store/app/selectors';
import { getActiveExperimentsIds, getParameter } from '../store/experiments/selectors';
import { getActiveCopyExperimentIds } from './copyExperimentUtils';
import { ExperimentIdentifier } from './ExperimentIdentifier';
import { experimentsEnabled } from './experimentsEnabled';
import { getExperimentsConfig } from './getExperimentsConfig';
import { getUtk } from './getUtk';
import { getAllUtkExperiments, isPortalExperiment } from './getUtkExperiments';
import { getHasExposureBeenTracked } from './useExperiment';
const EXTERNAL_EXPERIMENT_PARAM = 'external-experiment-parameter';
export const getPortalExperiments = () => {
  const experiments = getExperimentsConfig();
  return Object.keys(experiments).filter(experimentKey => isPortalExperiment(experimentKey), []);
};
export const getExperimentsToFetch = ({
  identifierPhase
}) => {
  if (!experimentsEnabled()) {
    return [];
  }
  if (identifierPhase === ExperimentIdentifier.PortalId) {
    return getPortalExperiments();
  }
  return getAllUtkExperiments();
};
export const getPreAcountCreatedExperimentIdentifierPhase = () => {
  return getUtk() ? ExperimentIdentifier.Utk : ExperimentIdentifier.Uuid;
};
export const getExperimentIdentifierPhase = state => {
  if (getIsNewPortalCreated(state)) {
    return ExperimentIdentifier.PortalId;
  }
  return getPreAcountCreatedExperimentIdentifierPhase();
};
export const getActiveExperimentsParametersForInitiateVerification = state => {
  const activeExperimentsIds = getActiveExperimentsIds(state);
  const activeCopyExperimentIds = getActiveCopyExperimentIds(state);
  const activeExperimentsParameters = [...activeExperimentsIds, ...activeCopyExperimentIds, ...getActiveGooglePromptExperimentId()].map(activeExperimentId => {
    const activeExperimentParameter = getParameter(state, activeExperimentId);
    return `${activeExperimentId}-group-${activeExperimentParameter}`;
  });
  return activeExperimentsParameters;
};
export const getExternalExperimentTreatments = () => {
  const queryParams = getRouteParams().queryParamsMap.getAll(EXTERNAL_EXPERIMENT_PARAM);
  return queryParams;
};
export const getActiveExperimentsParameters = state => {
  const activeExperimentsParametersFromState = state.experiments.activeExperimentsParameters;
  const activeExperimentsIds = getActiveExperimentsIds(state);
  const activeCopyExperimentIds = getActiveCopyExperimentIds(state);
  const externalExperimentTreatments = getExternalExperimentTreatments();
  const activeExperimentsParameters = [...activeExperimentsIds, ...activeCopyExperimentIds, ...getActiveGooglePromptExperimentId()].filter(activeExperimentId => getHasExposureBeenTracked(activeExperimentId) || checkIfIsGooglePromptExperimentAndExposureTracked(activeExperimentId)).map(activeExperimentId => {
    const activeExperimentParameter = getParameter(state, activeExperimentId);
    return `${activeExperimentId}-group-${activeExperimentParameter}`;
  });
  const filteredExperimentParams = activeExperimentsParameters.filter(activeExperimentParam => !activeExperimentsParametersFromState.includes(activeExperimentParam));
  return [...externalExperimentTreatments, ...activeExperimentsParametersFromState, ...filteredExperimentParams];
};
export const getIsNewUserExperiment = state => {
  return !getIsExistingUserSession(state);
};
export const getActiveExperiments = state => {
  const activeExperimentsIds = getActiveExperimentsIds(state);
  return activeExperimentsIds.reduce((activeExperiments, activeExperimentId) => {
    const activeExperimentParameter = getParameter(state, activeExperimentId);
    return Object.assign({}, activeExperiments, {
      [activeExperimentId]: activeExperimentParameter
    });
  }, {});
};