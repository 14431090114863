import { SupportedBrowsers } from './SupportedBrowsers';
import { getUserDeviceInfo } from './userAgentUtils';
export let DeviceType;
(function (DeviceType) {
  DeviceType["mobile"] = "mobile";
  DeviceType["ios"] = "ios";
  DeviceType["android"] = "android";
  DeviceType["desktop"] = "desktop";
})(DeviceType || (DeviceType = {}));
export const getIsMobile = () => {
  return getUserDeviceInfo().any;
};
export const getUserDeviceType = () => {
  const deviceInfo = getUserDeviceInfo();
  if (deviceInfo.apple.device) {
    return DeviceType.ios;
  }
  if (deviceInfo.android.device) {
    return DeviceType.android;
  }
  if (deviceInfo.any) {
    return DeviceType.mobile;
  }
  return DeviceType.desktop;
};
export const isSafariOrIos = () =>
// safari is not a supported property on the Window type
// See: https://hubspot.slack.com/archives/C017FT4P2Q0/p1637193449092700
getUserDeviceType() === DeviceType.ios || window.safari || navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) || navigator.userAgent.indexOf(SupportedBrowsers.Safari) > -1 && navigator.userAgent.indexOf(SupportedBrowsers.Chrome) < 0;
export const isFirefox = () => navigator.userAgent.indexOf(SupportedBrowsers.Firefox) > -1 && navigator.userAgent.indexOf(SupportedBrowsers.Chrome) < 0;
export const isReducedMotionEnabled = () => {
  const reducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
  const isEnabled = reducedMotionMediaQuery && reducedMotionMediaQuery.matches;
  return isEnabled;
};