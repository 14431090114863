import { handleSetupAuthFailure, handleSetupAuthForNewPortalFailure, handleSetupAuthForNewPortalSuccess, handleSetupAuthForOAuthExistingUserFailure, handleSetupAuthForOAuthExistingUserSuccess, handleSetupAuthSuccess } from '../auth/authHandlers';
import { checkAuth } from '../auth/checkAuthApi';
import { getHublet } from '../store/app/selectors';
import { AuthActionTypes } from '../store/auth/action-types';
import { EndpointKey } from './EndpointKey';
// NOTE: This object was wrapped in a function to avoid problematic cycles when frontend-infra-core migrates to Webpack 5
export const getEndpointsAuth = () => ({
  [EndpointKey.SetupAuth]: {
    reduxActionType: AuthActionTypes.SetupAuth,
    method: state => {
      const hublet = getHublet(state);
      return checkAuth(hublet);
    },
    handleSuccess: handleSetupAuthSuccess,
    handleError: handleSetupAuthFailure
  },
  [EndpointKey.SetupAuthForNewPortal]: {
    reduxActionType: AuthActionTypes.SetupAuthForNewPortal,
    method: state => {
      const hublet = getHublet(state);
      return checkAuth(hublet);
    },
    handleSuccess: handleSetupAuthForNewPortalSuccess,
    handleError: handleSetupAuthForNewPortalFailure
  },
  [EndpointKey.SetupAuthForOAuthExistingUser]: {
    reduxActionType: AuthActionTypes.SetupAuthForOAuthExistingUser,
    method: state => {
      const hublet = getHublet(state);
      return checkAuth(hublet);
    },
    handleSuccess: handleSetupAuthForOAuthExistingUserSuccess,
    handleError: handleSetupAuthForOAuthExistingUserFailure
  }
});