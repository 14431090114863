import { FlowActionTypes } from '../store/flow/action-types';
import { isPostVerification } from '../flow-settings/settingsSelectors';
import { postVerificationFlowSet, clearCompletedSteps, setInitialFlow } from '../store/flow/action-creators';
export const InitialFlowMiddleware = store => next => action => {
  const dispatchResult = next(action);
  if (action.type === FlowActionTypes.Load || action.type === FlowActionTypes.Reload) {
    store.dispatch(setInitialFlow());
    if (isPostVerification()) {
      store.dispatch(postVerificationFlowSet());
    }
    store.dispatch(clearCompletedSteps());
  }
  return dispatchResult;
};