import { TypeaheadActionTypes } from './action-types';
export const storePredictedTypeaheadValues = (predicted, dataKey) => dispatch => {
  return dispatch({
    type: TypeaheadActionTypes.StorePredicted,
    predicted,
    dataKey
  });
};
export const updateTypeaheadTrackedUserInput = (trackedUserInput, dataKey) => dispatch => {
  return dispatch({
    type: TypeaheadActionTypes.UpdateTrackedUserInput,
    trackedUserInput,
    dataKey
  });
};
export const updateTypeaheadSelectedValue = (selectedValue, dataKey) => dispatch => {
  return dispatch({
    type: TypeaheadActionTypes.UpdateSelectedValue,
    selectedValue,
    dataKey
  });
};
export const updateTypeaheadInputValue = (inputValue, dataKey) => dispatch => {
  return dispatch({
    type: TypeaheadActionTypes.UpdateInputValue,
    inputValue,
    dataKey
  });
};
export const setClickedToSeeAll = dataKey => dispatch => {
  return dispatch({
    type: TypeaheadActionTypes.SetClickedToSeeAll,
    dataKey
  });
};
export const clearPredicted = dataKey => dispatch => {
  return dispatch({
    type: TypeaheadActionTypes.ClearPredicted,
    dataKey
  });
};