import PortalIdParser from 'PortalIdParser';

// Why calling the PortalIdParser.get here?
// Ideally we should not rely on the PortalIdParser inside other parts of
// the code directly. Calling here just set the stage and some defaults
// used by other libraries, such as, hub-http.
let PORTAL_ID = PortalIdParser.get();
const PortalIdContainer = {
  get() {
    return PORTAL_ID || null;
  },
  set(portalId) {
    PORTAL_ID = Number(portalId);

    // If everything went well on the PortalIdParser call the window.hubspot.portal
    // should be filled with { id: undefined } and by setting it here, the following
    // libraries will use this value as the default data for requests as defined in
    // https://git.hubteam.com/HubSpot/PortalIdParser/blob/master/static/js/index.ts#L49-L51
    PortalIdParser.id = PORTAL_ID;
    if (window.hubspot && window.hubspot.portal) {
      window.hubspot.portal.id = PORTAL_ID;
    }
  }
};
export default PortalIdContainer;