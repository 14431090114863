import styled from 'styled-components';
import { CALYPSO, OZ_MEDIUM, KOALA } from 'HubStyleTokens/colors';
import UILink from 'UIComponents/link/UILink';
export const InputStyles = styled.div.withConfig({
  displayName: "TextInputstyles__InputStyles",
  componentId: "sc-5kcv37-0"
})(["display:inline-block;position:relative;vertical-align:left;border-color:white !important;background-color:white !important;width:100%;input{padding-top:14px !important;padding-bottom:18px !important;", "}.Country,input,.CompanyDomainDropdown{border-bottom-color:", " !important;border:2px solid;border-left:none !important;border-right:none !important;border-top:none !important;", " ", " ", "}.Country,.CompanyDomainDropdown{margin-bottom:20px;}div[data-selenium-id='country']:hover{box-shadow:none !important;background:white;}div[aria-expanded='true']{border-bottom-color:", " !important;}input[aria-invalid='true']{border-bottom-color:#bf2434 !important;}input:focus{", "}input[aria-invalid='true'],input[aria-invalid='true']:focus{box-shadow:none !important;}"], props => props.industryStyle && `
      margin-bottom: -3px;
    `, CALYPSO, props => props.success && `
      border-bottom-color: ${OZ_MEDIUM} !important;
    `, props => props.error && `
      border-bottom-color: #bf2434 !important;
    `, props => props.disabled && `
      background-color: ${KOALA} !important;
      box-shadow: none !important;
      border-bottom-color: #cbd6e2 !important;
    `, CALYPSO, props => props.industryStyle && `
        border-bottom-color: ${CALYPSO} !important;
        ${props.success && `
          border-bottom-color: ${OZ_MEDIUM} !important;
        `}
        ${props.error && `
          border-bottom-color: #bf2434 !important;
        `}
      `);
export const StyledUILink = styled(UILink).withConfig({
  displayName: "TextInputstyles__StyledUILink",
  componentId: "sc-5kcv37-1"
})(["color:#0b8484;"]);