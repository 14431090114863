import styled from 'styled-components';
import { devices } from 'signup-constants/styles/viewports.styles';
export const BlobImage = styled.img.withConfig({
  displayName: "Blobstyles__BlobImage",
  componentId: "sc-1wz7135-0"
})(["object-fit:cover;transition:transform cubic-bezier(0.04,0.78,0.43,0.99) 2s;transition-delay:100ms,100ms;", ""], props => props.showBorder ? `
    &&& {
      border: 1px solid ${props.colorTheme.innerBlobColor};
      box-shadow: 0 2px 8px ${props.colorTheme.innerBlobColor};
      border-radius: 4px;
    }

  ` : ``);
export const BlobWrapper = styled.div.withConfig({
  displayName: "Blobstyles__BlobWrapper",
  componentId: "sc-1wz7135-1"
})(["opacity:", ";height:40vh;display:flex;justify-content:center;@media ", "{min-height:300px;height:40vh;max-height:450px;}"], props => props.imageLoaded ? '1' : '0', devices.desktop);
export const BlobBleadImage = styled.img.withConfig({
  displayName: "Blobstyles__BlobBleadImage",
  componentId: "sc-1wz7135-2"
})(["object-fit:cover;clip-path:inset( 0 0 ", " 0 );"], props => props.blobBleedConfig && props.blobBleedConfig.top ? `${props.blobBleedConfig.top}px` : '50%');
export const StyledVideoComponent = styled.video.withConfig({
  displayName: "Blobstyles__StyledVideoComponent",
  componentId: "sc-1wz7135-3"
})(["height:200px;width:200px;@media ", "{height:300px;width:300px;}@media ", "{height:300px;width:300px;}", ""], devices.desktop, devices.tablet, props => props.renderBlob ? `
  &&& {
    width: 100%;
    height: 100%;
    border: 1px solid
      ${props.innerBlobColor};
    border: ${props.innerBlobColor};
    border-radius: 4px;
  }
` : ``);
export const ForeignObject = styled.foreignObject.withConfig({
  displayName: "Blobstyles__ForeignObject",
  componentId: "sc-1wz7135-4"
})(["display:flex;justify-content:center;min-height:100%;"]);
export const BlobProfileName = styled.div.withConfig({
  displayName: "Blobstyles__BlobProfileName",
  componentId: "sc-1wz7135-5"
})(["font-size:1.2rem;"]);
export const BlobSvg = styled.svg.withConfig({
  displayName: "Blobstyles__BlobSvg",
  componentId: "sc-1wz7135-6"
})(["overflow:visible !important;"]);
export const BlobContentWrapper = styled.div.withConfig({
  displayName: "Blobstyles__BlobContentWrapper",
  componentId: "sc-1wz7135-7"
})(["display:flex;min-height:100%;justify-content:center;"]);
export const LargeDecorativeBlob = styled.div.withConfig({
  displayName: "Blobstyles__LargeDecorativeBlob",
  componentId: "sc-1wz7135-8"
})(["height:50px;width:50px;margin-top:40px;margin-left:30px;border-radius:50%;"]);
export const SmallDecorativeBlob = styled.div.withConfig({
  displayName: "Blobstyles__SmallDecorativeBlob",
  componentId: "sc-1wz7135-9"
})(["height:30px;width:30px;margin-right:40px;border-radius:50%;"]);
export const DecorativeBlobContainer = styled.div.withConfig({
  displayName: "Blobstyles__DecorativeBlobContainer",
  componentId: "sc-1wz7135-10"
})(["display:flex;position:absolute;right:0;flex-direction:column;align-items:flex-end;"]);