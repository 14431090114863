export let EventName;
(function (EventName) {
  EventName["SignupInitiated"] = "signupInitiated";
  EventName["SignupCompleted"] = "signupCompleted";
  EventName["SignupRedirect"] = "signupRedirect";
  EventName["StepCompleted"] = "stepCompleted";
  EventName["Interaction"] = "interaction";
  EventName["SurveyInteraction"] = "surveyInteraction";
  EventName["AuthState"] = "authState";
  EventName["View"] = "view";
  EventName["MessagingScrolling"] = "messagingScrolling";
  EventName["Click"] = "click";
  EventName["FormError"] = "formError";
  EventName["BackButton"] = "backButton";
  EventName["CreationError"] = "creationError";
  EventName["UtmVerification"] = "utmVerification";
  EventName["VerifyIdentityCompleted"] = "verifyIdentityCompleted";
  EventName["VerifyIdentityStarted"] = "verifyIdentityStarted";
  EventName["ExperimentExposure"] = "experimentExposure";
  EventName["SurveyCompleted"] = "surveyCompleted";
  EventName["IndustryCollected"] = "industryCollected";
  EventName["InvalidDomainCollected"] = "invalidDomainCollected";
  EventName["JobRoleCollected"] = "jobRoleCollected";
  EventName["SignupCompletedAnonymous"] = "signupCompletedAnonymous";
  EventName["ExperimentUnenrolled"] = "experimentUnenrolled";
  EventName["SignupPageView"] = "signupPageView";
})(EventName || (EventName = {}));